import {Client} from 'lib/client';
import {SamplePricesResponse, SampleType} from 'lib/sample/types';
import {extractPayload} from 'lib/transport/utils';

export type Payload = {
  productItems: {
    productId: string;
    selectedType?: SampleType;
  }[];
};

type ResponseBody = {
  payload: SamplePricesResponse;
};

export function loadSamplePrices(client: Client, payload: Payload): Promise<SamplePricesResponse> {
  return client.mainApi.post<ResponseBody>('samples/prices', {body: payload}).then(extractPayload);
}
