import {createStore, createEvent} from 'lib/effector';

type DialogsManagerState = {
  dialogOpened: boolean;
};

const initialState: DialogsManagerState = {
  dialogOpened: false,
};

export const $modalsManager = createStore<DialogsManagerState>('modalsManager', initialState);

export const $dialogOpened = $modalsManager.map((state) => state.dialogOpened);

export const openDialog = createEvent();

export const closeDialog = createEvent();
