import 'intersection-observer';
import {Ref, useCallback, useRef} from 'react';

type Props = {
  onLoad: () => void;
  options?: IntersectionObserverInit;
};

type Return<R> = {
  cancelAutoLoad: () => void;
  load: () => void;
  ref: Ref<R>;
};

const defaultOptions = {threshold: 1};

export function useScrollLoader<R extends Element>({onLoad, options}: Props): Return<R> {
  const unobserve = useRef<() => void>();
  const autoLoad = useRef<boolean>(false);
  const loadFn = useRef(onLoad);
  loadFn.current = onLoad;

  const load = useCallback(() => {
    autoLoad.current = true;
    loadFn.current();
  }, []);

  const cancelAutoLoad = useCallback(() => {
    autoLoad.current = false;
  }, []);

  const setRef = useCallback(
    (node: R | null) => {
      if (unobserve.current !== undefined) {
        unobserve.current();
        unobserve.current = undefined;
      }

      if (node) {
        const observerOptions = {...defaultOptions, ...options};
        const observer = new IntersectionObserver((entryList) => {
          if (entryList[0]?.isIntersecting && autoLoad.current) {
            loadFn.current();
          }
        }, observerOptions);

        observer.observe(node);
        unobserve.current = () => {
          observer.disconnect();
        };
      }
    },
    [options],
  );

  return {
    cancelAutoLoad,
    load,
    ref: setRef,
  };
}
