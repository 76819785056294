import {createCommunicationStore} from 'lib/communication';

const {
  $store: $referralDialog,
  openDialog: openReferralDialog,
  closeDialog: closeReferralDialog,
  sendRequestFx: sendPageReferralDataFx,
} = createCommunicationStore('referral');

export {$referralDialog, openReferralDialog, closeReferralDialog, sendPageReferralDataFx};
