import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import classNamesBind from 'classnames/bind';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {SelectInterfaceContext} from './contexts';
import styles from './index.module.scss';
import {Menu} from './Menu';
import {SelectOption, SelectOptionProps} from './SelectOption';
import {SelectTestId, SelectSize, SelectInterface, SelectChangeHandler, SelectChangeData} from './types';

export type {SelectChangeHandler, SelectChangeData};
export {SelectOption} from './SelectOption';

const cn = classNamesBind.bind(styles);

export type SelectProps<Value> = TestIdProp<SelectTestId> & {
  children: React.ReactElement<SelectOptionProps<Value>> | React.ReactElement<SelectOptionProps<Value>>[];
  defaultValue?: Value;
  disabled?: boolean;
  error?: ExtMessageDescriptor;
  label?: ExtMessageDescriptor;
  menuMinWidth?: number;
  name?: string;
  onChange?: SelectChangeHandler<Value>;
  onMenuOpen?: () => void;
  placeholder?: ExtMessageDescriptor;
  renderValue?: (value: Value | undefined) => React.ReactNode;
  size?: SelectSize;
  value?: Value;
};
/**
 * TODO: https://joom-team.atlassian.net/browse/PRO-5944
 * @deprecated use `import { Select } from "uikit/SelectMui";` instead
 */
export function Select<Value>(props: SelectProps<Value>): React.ReactElement {
  const {
    children,
    defaultValue,
    disabled,
    error,
    label,
    menuMinWidth,
    name,
    onChange,
    onMenuOpen,
    placeholder,
    renderValue,
    size = 'medium',
    testId,
    value,
  } = props;
  const intl = useIntl();
  const actionRef = useRef(null);
  const [opened, setOpened] = useState(false);
  const [internalValue, setInternalValue] = useState<Value | undefined>(() => value ?? defaultValue);
  const internalValueRef = useRef(internalValue);
  internalValueRef.current = internalValue;

  const notEmpty = Boolean(value);
  const withError = Boolean(error);
  const withLabel = Boolean(label);

  const inputError = buildMessage(intl, error);
  const inputLabel = buildMessage(intl, label);
  const inputPlaceholder = size === 'small' && !placeholder ? inputLabel : buildMessage(intl, placeholder);
  const labelVisible = inputLabel || inputError;

  const handleActionClick = useCallback(() => {
    setOpened(true);
  }, []);

  const handleOptionsClose = useCallback(() => {
    setOpened(false);
  }, []);

  useUpdateEffect(() => {
    if (opened && onMenuOpen) {
      onMenuOpen();
    }
  }, [opened]);

  useUpdateEffect(() => {
    setInternalValue(value);
  }, [value]);

  useUpdateEffect(() => {
    if (disabled) {
      setOpened(false);
    }
  }, [disabled]);

  const context = useMemo<SelectInterface<Value>>(
    () => ({
      isSelected: (checkedValue) => checkedValue === internalValueRef.current,
      setValue: (newValue: Value | undefined) => {
        setOpened(false);
        setInternalValue(newValue);
        if (onChange && newValue !== undefined) {
          onChange({name, value: newValue});
        }
      },
      size,
    }),
    [size, onChange, name],
  );

  let display;

  if (renderValue) {
    if (internalValue !== undefined) {
      display = renderValue(internalValue);
    }
  } else {
    let displayMatched = false;

    for (const child of React.Children.toArray(children)) {
      if (React.isValidElement<SelectOptionProps<Value>>(child) && child.type === SelectOption) {
        if (child.props.value === internalValue) {
          displayMatched = true;
          if (React.isValidElement(child.props.children)) {
            display = React.cloneElement(child.props.children);
          } else {
            display = child.props.children;
          }
        }
      }
    }

    if (!displayMatched && internalValue !== undefined) {
      display = internalValue;
    }
  }

  return (
    <label
      className={cn(styles.select, `size-${size}`, {
        empty: !notEmpty,
        notEmpty,
        opened,
        withError,
        withLabel,
      })}
      data-test-id={testId}
    >
      <button className={styles.action} disabled={disabled} onClick={handleActionClick} ref={actionRef} type='button'>
        <div className={styles.value}>{(display as React.ReactNode) || inputPlaceholder}</div>
      </button>
      {labelVisible && <div className={styles.label}>{inputError || inputLabel}</div>}
      {opened && (
        <Menu minWidth={menuMinWidth} onClose={handleOptionsClose} targetRef={actionRef} testId={testId?.menu}>
          <SelectInterfaceContext.Provider value={context}>{children}</SelectInterfaceContext.Provider>
        </Menu>
      )}
    </label>
  );
}
