import {CartPricesResponse} from 'lib/cart/types';
import {Client} from 'lib/client';
import {DeliveryChannelType} from 'lib/deliveryChannel/types';
import {extractPayload} from 'lib/transport/utils';

export type Payload = {
  channelType: DeliveryChannelType;
  ids: string[]; // cartIds
};

export type ResponseBody = {
  payload: CartPricesResponse;
};

export function loadCartPrices(client: Client, payload: Payload): Promise<CartPricesResponse> {
  return client.mainApi.post<ResponseBody>('cart/prices', {body: payload}).then(extractPayload);
}
