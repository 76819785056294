import {Client} from 'lib/client';
import {CommunicationReason, CommunicationRequest, CommunicationResponse} from 'lib/communication/types';
import {cleanPhone} from 'lib/data/phone';
import {isFormValidationError} from 'lib/errors/guards';
import {formValidationError} from 'lib/form/types';
import {isTransportResponse} from 'lib/transport/types';
import {parseUtmParameters} from 'lib/utm';
import {UtmParameters} from 'lib/utm/types';

type RequestBody = Omit<CommunicationRequest, 'reason'> & {
  phone: string;
  promocode?: string;
  utmLabels?: UtmParameters;
  websiteForm: CommunicationReason;
};

type ResponseBody = {
  payload: CommunicationResponse;
};

export async function addInteraction(client: Client, params: CommunicationRequest): Promise<CommunicationResponse> {
  const utmParameters = parseUtmParameters(client.cookies.utmParameters);
  const body: RequestBody = {
    campaign: params.campaign,
    comment: params.comment,
    companyName: params.companyName,
    email: params.email,
    firstName: params.firstName,
    hasLegalEntity: params.hasLegalEntity,
    hasProductImport: params.hasProductImport,
    importProducts: params.importProducts,
    phone: cleanPhone(params.phone),
    promocode: params.promocode,
    radarPossessionType: params.radarPossessionType,
    source: params.source,
    type: params.type,
    websiteForm: params.reason,
  };

  if (utmParameters) {
    body.utmLabels = utmParameters;
  }

  return client.mainApi
    .post<ResponseBody>('interactions/add', {body})
    .then(({body: {payload}}) => payload)
    .catch((error) => {
      if (isTransportResponse(error) && isFormValidationError(error.body)) {
        const {fieldValidationErrors} = error.body.payload;
        const errorMap = fieldValidationErrors.reduce<Record<string, string>>((result, item) => {
          // eslint-disable-next-line no-param-reassign
          result[item.field] = item.message;
          return result;
        }, {});

        throw formValidationError(errorMap);
      }

      throw error;
    });
}
