import {DataState} from '@joomcode/deprecated-utils/dataState';
import {loadSampleExistence} from 'api/sample/loadSampleExistence';
import {createClientEffect, createStore} from 'lib/effector';
import {createRegistrationRequiredEffect} from 'models/registrationCallback/utils';

type SampleExistenceState = {
  loading: boolean;
  loadingError?: unknown;
  sampleExistence?: boolean;
  state: DataState;
};

const initialState: SampleExistenceState = {
  loading: false,
  state: DataState.IDLE,
};

export const $sampleExistence = createStore('sampleExistence', initialState);

export const loadSampleExistenceFx = createClientEffect(loadSampleExistence);

export const loadSampleExistenceWithAuthFx = createRegistrationRequiredEffect(loadSampleExistenceFx);
