import {Provider as EffectorProvider} from 'effector-react/ssr';
import React from 'react';
import {StateManagerContext} from './contexts';
import {StateManager} from './StateManager';

type Props = {
  children: React.ReactNode;
  value: StateManager;
};

export function StateManagerProvider({children, value}: Props): React.ReactElement {
  return (
    <StateManagerContext.Provider value={value}>
      <EffectorProvider value={value.scope}>{children}</EffectorProvider>
    </StateManagerContext.Provider>
  );
}
