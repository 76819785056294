"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentryBreadcrumbsProvider = void 0;
const sentry_1 = require("../sentry");
const Provider_1 = require("../providers/Provider");
const utils_1 = require("../utils");
class SentryBreadcrumbsProvider extends Provider_1.Provider {
    constructor(sentry, config) {
        super(config);
        this.sentry = sentry;
    }
    processInternal({ name, level, meta, args }) {
        let message;
        const maybeEcsInputMessage = args[0];
        if (maybeEcsInputMessage && typeof maybeEcsInputMessage === 'object') {
            message = maybeEcsInputMessage.message || '';
        }
        else {
            message = args.map(utils_1.stringify).join(' ');
        }
        this.sentry.addBreadcrumb({
            level: sentry_1.levelMapping[level.name],
            category: name,
            message,
            data: meta,
            timestamp: Date.now(),
        });
    }
}
exports.SentryBreadcrumbsProvider = SentryBreadcrumbsProvider;
