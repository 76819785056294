import {defineMessages} from 'react-intl';
import {FieldName} from './types';

export const fieldMessages = defineMessages<FieldName>({
  categoryIds: {
    defaultMessage: 'Product categories',
    description: 'Self Service Registation form for self-service: field label',
  },
  cnpj: {
    defaultMessage: 'Company’s Tax Identification Number (CNPJ)',
    description: 'Self Service Registation form for self-service: field label',
  },
  companyEmployeesNumber: {
    defaultMessage: 'Number of employees',
    description: 'Self Service Registation form for self-service: field label',
  },
  companyName: {
    defaultMessage: 'Your company’s name',
    description: 'Self Service Registation form for self-service: field label',
  },
  companyOperationFields: {
    defaultMessage: 'Company’s field of operation',
    description: 'Self Service Registation form for self-service: field label',
  },
  companyRole: {
    defaultMessage: 'Your role in the company',
    description: 'Self Service Registation form for self-service: field label',
  },
  companyTurnoverRangeLevel: {
    defaultMessage: 'Annual turnover{currency, select, undefined{} other{, {currency}}}',
    description: 'Self Service Registation form for self-service: field label',
  },
  companyWebsite: {
    defaultMessage: 'Company’s website (optional)',
    description: 'Self Service Registation form for self-service: field label',
  },
  email: {
    defaultMessage: 'E-mail',
    description: 'Self Service Registation form for self-service: field label',
  },
  firstName: {
    defaultMessage: 'Name',
    description: 'Self Service Registation form for self-service: field label',
  },
  hasProductImport: {
    defaultMessage: 'Does your company already carry out imports?',
    description: 'Self Service Registation form for self-service: field label',
  },
});

export const messages = defineMessages({
  register: {
    defaultMessage: 'Register',
    description: 'Registration dialog submit button',
  },
});
