import {DataState} from '@joomcode/deprecated-utils/dataState';
import {loadImagesTranslations} from 'api/product/loadImagesTranslations';
import {translateImages} from 'api/product/translateImages';
import {createStore, createClientEffect} from 'lib/effector';
import {ProductTranslations, ProductTranslationsTarget} from 'lib/product/types';
import {createRegistrationRequiredEffect} from 'models/registrationCallback/utils';

export type ProductTranslationsState = {
  dataByTarget: ProductTranslations;
  productId?: string;
  requestStateByTarget: Record<ProductTranslationsTarget, DataState>;
  stateByTarget: Record<ProductTranslationsTarget, DataState>;
};

const initialState: ProductTranslationsState = {
  dataByTarget: {},
  requestStateByTarget: {
    [ProductTranslationsTarget.DESCRIPTION]: DataState.IDLE,
    [ProductTranslationsTarget.GALLERY]: DataState.IDLE,
  },
  stateByTarget: {
    [ProductTranslationsTarget.DESCRIPTION]: DataState.IDLE,
    [ProductTranslationsTarget.GALLERY]: DataState.IDLE,
  },
};

export const $productTranslations = createStore('productTranslations', initialState);

export const loadImagesTranslationsFx = createClientEffect(loadImagesTranslations);

export const translateImagesFx = createClientEffect(translateImages);

export const translateImagesWithAuthFx = createRegistrationRequiredEffect(translateImagesFx, {
  skipSelfServiceData: true,
});
