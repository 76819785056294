import {parse as parseCookie, serialize as serializeCookie} from 'cookie';
import {ServerResponse} from 'http';
import {isNumber} from 'lib/guards';
import {cookieManagerLogger} from 'lib/logger';
import {parse as parseSetCookieHeader, splitCookiesString} from 'set-cookie-parser';
import {CookieParams, CookieOptions, CookieValue, ChangesMap, ValuesMap} from './types';
import {normalizeSameSite, serializeValuesMap} from './utils';

const PATH = '/';
const DAY = 24 * 60 * 60;
const YEAR = 365 * DAY;

export class CookieManager {
  private readonly _source: ValuesMap | undefined;

  private readonly _changes: ChangesMap | undefined;

  language: CookieValue;

  siteLeaveDialog: CookieValue<'shown'>;

  utmParameters: CookieValue;

  promocode: CookieValue;

  analyticsAuthDialogShownByScroll: CookieValue;

  constructor(source?: string) {
    if (__SERVER__) {
      this._source = parseCookie(source || '');
      this._changes = {};
    }

    this.defineCookie({
      alias: 'language',
      httpOnly: true,
      maxAge: 10 * YEAR,
      name: 'NEXT_LOCALE',
    });
    this.defineCookie({maxAge: 7 * DAY, name: 'siteLeaveDialog'});
    this.defineCookie({maxAge: 7 * DAY, name: 'utmParameters'});
    this.defineCookie({maxAge: 100 * DAY, name: 'promocode'});
    this.defineCookie({maxAge: DAY, name: 'analyticsAuthDialogShownByScroll'});
  }

  private get source(): ValuesMap {
    if (__SERVER__) {
      if (!this._source) {
        throw new Error('Cookie source must be defined');
      }

      return this._source;
    }

    return parseCookie(typeof document !== 'undefined' ? document.cookie : '');
  }

  private get changes(): ChangesMap {
    if (__SERVER__) {
      if (!this._changes) {
        throw new Error('Cookie changes must be defined');
      }

      return this._changes;
    }

    throw new Error('Server only method');
  }

  applyToResponse(res: ServerResponse): void {
    if (__SERVER__) {
      const cookieList = Object.entries(this.changes).map(([name, {value, options}]) => {
        return serializeCookie(name, value || '', options);
      });

      res.setHeader('set-cookie', cookieList);
    }
  }

  enhanceFromSetCookieHeader(input: string | string[] | null | undefined): void {
    if (__SERVER__) {
      try {
        const parsedCookieSet = parseSetCookieHeader(splitCookiesString(input ?? undefined));

        for (const {name, value, sameSite, ...options} of parsedCookieSet) {
          this.changes[name] = {
            options: {
              ...options,
              sameSite: normalizeSameSite(sameSite as string),
            },
            value,
          };
        }
      } catch (e) {
        cookieManagerLogger.fatal('SetCookieHeader parsing error', e);
      }

      return;
    }

    throw new Error('Server only method');
  }

  serializeValues(): string {
    if (__SERVER__) {
      const actualCookies: ValuesMap = {...this.source};

      for (const key of Object.keys(this.changes)) {
        const cookieItem = this.changes[key];

        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#attributes
        if (
          (isNumber(cookieItem.options.maxAge) && cookieItem.options.maxAge <= 0) ||
          (cookieItem.options.expires && cookieItem.options.expires.getTime() <= Date.now())
        ) {
          delete actualCookies[key];
        } else {
          actualCookies[key] = cookieItem.value ?? '';
        }
      }

      return serializeValuesMap(actualCookies);
    }

    if (typeof document !== 'undefined') {
      return document.cookie;
    }

    throw new Error('Can not serialize nothing');
  }

  defineCookie(props: CookieParams): void {
    const {name, alias, maxAge, httpOnly = false, readOnly = false, resetOnly = false} = props;

    Object.defineProperty(this, alias || name, {
      get(): string | undefined {
        if (__SERVER__) {
          if (this.changes[name]) {
            return this.changes[name].value;
          }
        }

        if (__CLIENT__ && httpOnly) {
          throw new Error('Access to httpOnly cookie on client is denied');
        }

        return this.source[name];
      },

      set(value: string | undefined): void {
        if (readOnly) {
          throw new Error(`Cookie ${name} is readOnly`);
        }

        if (resetOnly && value !== undefined) {
          throw new Error(`Cookie ${name} is resetOnly`);
        }

        let options: CookieOptions;

        if (value === undefined) {
          options = {
            expires: new Date(0),
            path: PATH,
          };
        } else {
          options = {
            httpOnly,
            maxAge,
            path: PATH,
          };
        }

        if (__SERVER__) {
          this.changes[name] = {
            options,
            value,
          };

          return;
        }

        if (typeof document !== 'undefined') {
          document.cookie = serializeCookie(name, value || '', options);
        }
      },
    } as PropertyDescriptor & ThisType<this>);
  }
}
