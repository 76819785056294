import {isNotNullish, isNullish} from '@joomcode/deprecated-utils/function';
import React from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {messages} from './messages';
import {FormattedRangeMessages} from './types';

type Options<T> = {
  from?: T;
  isEqual: (from: T, to: T) => boolean;
  rangeMessages?: FormattedRangeMessages;
  render: (value: T) => string;
  to?: T;
};

export function formatRange<T>(
  {from, to, render, isEqual, rangeMessages = messages}: Options<T>,
  intl: IntlShape,
): string {
  if (isNullish(from) && isNotNullish(to)) {
    return intl.formatMessage(rangeMessages.rangeTo, {to: render(to)});
  }

  if (isNotNullish(from) && isNullish(to)) {
    return intl.formatMessage(rangeMessages.rangeFrom, {from: render(from)});
  }

  if (isNotNullish(from) && isNotNullish(to)) {
    if (isEqual(from, to)) {
      return intl.formatMessage(rangeMessages.equal, {equal: render(from)});
    }

    return intl.formatMessage(rangeMessages.range, {from: render(from), to: render(to)});
  }

  return '';
}

export function FormattedRange<T>({...props}: Options<T>): React.ReactElement {
  const intl = useIntl();

  return <>{formatRange(props, intl)}</>;
}
