import classNamesBind from 'classnames/bind';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import {DialogBack} from '../DialogBack';
import {DialogClose} from '../DialogClose';
import {DialogTestId} from '../types';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

type Props = TestIdProp<DialogTestId> & {
  children?: React.ReactNode;
  sticky?: boolean;
  title?: ExtMessageDescriptor;
  withBackButton?: boolean;
  withCloseButton?: boolean;
};

export function DialogHeader({
  title,
  testId,
  children,
  sticky = false,
  withBackButton = false,
  withCloseButton = false,
}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={cn('header', {sticky, withBackButton, withCloseButton})} data-test-id={testId?.header}>
      {withBackButton && <DialogBack testId={testId?.backButton} />}
      {withCloseButton && <DialogClose testId={testId?.closeButton} />}
      {title && <div className={styles.title}>{buildMessage(intl, title)}</div>}
      {children}
    </div>
  );
}
