import {MaskItemInterface, MaskItemConfig} from './types';

export class MaskItem implements MaskItemInterface {
  private config: MaskItemConfig;

  private value: string | undefined;

  constructor(config: MaskItemConfig) {
    this.config = {...config};
    this.value = this.config.value;
  }

  clear(): void {
    if (this.config.editable) {
      this.value = undefined;
    }
  }

  test(value: string): boolean {
    if (this.config.editable) {
      return this.config.test.test(value);
    }

    return false;
  }

  isFilled(): boolean {
    if (this.isEditable()) {
      return Boolean(this.value);
    }

    return true;
  }

  isEmpty(): boolean {
    return !this.isFilled();
  }

  setValue(value: string): void {
    if (this.config.editable) {
      if (this.config.test.test(value)) {
        this.value = value;
      }
    }
  }

  isEditable(): boolean {
    return this.config.editable;
  }

  getMaskValue(): string {
    if (this.config.editable) {
      return this.config.fallback;
    }

    return this.config.value;
  }

  toString(): string {
    if (this.config.editable) {
      return this.value || this.config.fallback;
    }

    return this.config.value;
  }
}
