import {Category, RootCategory} from 'lib/category/types';
import {Client} from 'lib/client';

export type Payload = {
  categoryId?: string;
  levels?: number;
  parentLevels?: number;
};

export function loadCategoriesHierarchy<R extends Category | RootCategory>(
  client: Client,
  payload: Payload,
): Promise<R> {
  return client.mainApi.get<{payload: R}>('categoriesHierarchy', {query: payload}).then(({body}) => body.payload);
}
