import {Client} from 'lib/client';
import {cleanPhone} from 'lib/data/phone';
import {User} from 'lib/user/types';
import {PayloadWithUser} from './types';
import {extractUser, handleCredentialsValidationError} from './utils';

type SignUpCredential = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  source: string;
};

function prepareCredentials(credential: SignUpCredential): SignUpCredential {
  return {
    ...credential,
    email: credential.email.trim(),
    firstName: credential.firstName.trim(),
    lastName: credential.lastName.trim(),
    phone: cleanPhone(credential.phone),
  };
}

export function signUpByEmail(client: Client, credential: SignUpCredential): Promise<User> {
  return client.mainApi
    .post<PayloadWithUser>('auth/email/signUp', {body: prepareCredentials(credential)})
    .then(extractUser)
    .catch((error) => {
      handleCredentialsValidationError(error);
      throw error;
    });
}
