import {sample} from 'effector';
import {
  $companyAnnualTurnoverCollection,
  loadCompanyAnnualTurnoverFx,
  resetCompanyAnnualTurnover,
  loadCompanyAnnualTurnover,
} from './index';

$companyAnnualTurnoverCollection
  .on(loadCompanyAnnualTurnoverFx, (state) => ({
    ...state,
    loading: true,
    loadingError: undefined,
  }))
  .on(loadCompanyAnnualTurnoverFx.done, (state, {result, params}) => ({
    ...state,
    byId: {[params.countryCode]: result},
    loading: false,
  }))
  .on(loadCompanyAnnualTurnoverFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }))
  .reset(resetCompanyAnnualTurnover);

sample({
  clock: loadCompanyAnnualTurnover,
  target: loadCompanyAnnualTurnoverFx,
});
