import {guard, sample} from 'effector';
import {User} from 'lib/user/types';
import {authSuccess} from 'models/auth';
import {loadCategoryFx, resetCategory} from 'models/category';
import {$similarProducts, loadSimilarProductsFx} from 'models/product/similarProducts';
import {updateUserFx} from 'models/ssRegistrationDialog';
import {$user} from 'models/user';
import {
  openProductDestinationDialog,
  closeProductDestinationDialog,
  $productDestinationDialogOpened,
} from './destination';
import {$product, $productActionSubmitted, loadProductFx, resetProduct, ProductState, reloadProductFx} from './product';

import './translations/init';
import './prices/init';
import './deliveryDate/init';

$product
  .on(loadProductFx, (state) => ({
    ...state,
    loading: true,
    loadingError: undefined,
    product: undefined,
  }))
  .on([loadProductFx.doneData, reloadProductFx.doneData], (state, product) => ({
    ...state,
    loading: false,
    product,
  }))
  .on(loadProductFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }))
  .reset(resetProduct);

$productActionSubmitted.reset(resetProduct);

$similarProducts
  .on(loadSimilarProductsFx, (state, {productId}) => ({
    loading: true,
    loadingError: undefined,
    productId,
    products: undefined,
  }))
  .on(loadSimilarProductsFx.done, (state, {result, params}) =>
    params.productId === state.productId
      ? {
          ...state,
          items: result,
          loading: false,
        }
      : state,
  )
  .on(loadProductFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }))
  .reset(resetProduct);

$productDestinationDialogOpened
  .on(openProductDestinationDialog, () => true)
  .on(closeProductDestinationDialog, () => false);

sample({
  fn: ({category}) => ({
    categoryId: category!.id,
    parentLevels: -1,
  }),
  source: guard({
    filter: (product) => Boolean(product.category),
    source: loadProductFx.doneData,
  }),
  target: loadCategoryFx,
});

guard({
  filter: (product) => !product.category,
  source: loadProductFx.doneData,
  target: resetCategory,
});

sample({
  clock: [updateUserFx.doneData, authSuccess],
  filter([{product, loading}, user]: [ProductState, User]) {
    return !loading && Boolean(product);
  },
  source: [$product, $user],
  target: reloadProductFx,
});
