import type {Client} from 'lib/client';
import {processAuthorization} from 'lib/transport/utils';
import {Transport} from './Transport';
import {TransportRequestMethod, TransportRequestOptions, TransportResponse} from './types';

export class ApiTransport extends Transport {
  private client: Client;

  constructor(client: Client) {
    super({
      baseUrl: client.endpoints.mainApi,
      clientAddress: client.clientAddress,
      cookies: client.cookies,
      origin: client.config.origin,
      ssrId: client.ssrId,
      userAgent: client.userAgent,
    });

    this.client = client;
  }

  async request<ResponseBody>(
    method: TransportRequestMethod,
    path: string,
    options: TransportRequestOptions = {},
  ): Promise<TransportResponse<ResponseBody>> {
    return processAuthorization(this.client, () => super.request<ResponseBody>(method, path, options));
  }
}
