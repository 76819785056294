import {useCallbackWithoutArgs} from 'lib/hooks/useCallbackWithoutArgs';
import {TestIdProp} from 'lib/testing/types';
import React, {useContext} from 'react';
import {HandlersContext} from '../contexts';
import {BackHandler} from '../types';
import styles from './index.module.scss';

type Props = TestIdProp & {
  onClick?: BackHandler;
};

export function DialogBack({onClick, testId}: Props): React.ReactElement {
  const {onBack} = useContext(HandlersContext);
  const handleClick = useCallbackWithoutArgs(onClick || onBack);

  return <button className={styles.backButton} data-test-id={testId} onClick={handleClick} type='button' />;
}
