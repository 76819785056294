import {IntlShape, MessageDescriptor} from 'react-intl';

type EnumFormatter = (enumKey: string | number, intl: IntlShape) => string;

/**
 * An utility that creates enum messages formatter.
 *
 * @example
 * ```
 * enum BankTransferAccount {
 *   JOOM_PRO = 'joompro',
 * }
 *
 * const messages = defineMessages<BankTransferAccount>({
 *   [BankTransferAccount.JOOM_PRO]: {
 *     defaultMessage: 'JoomPro',
 *     description: 'Bank transfer account name',
 *   },
 * });
 *
 * const formatBankTransferAccount = getEnumFormatter(messages);
 *
 * formatBankTransferAccount('joompro', intl) // → JoomPro
 * formatBankTransferAccount('other', intl) // → other
 * ```
 */
export function getEnumFormatter(enumMessages: Record<number | string, MessageDescriptor>): EnumFormatter {
  return function formatEnum(enumKey: string | number, intl: IntlShape): string {
    const message = enumMessages[enumKey];
    return message ? intl.formatMessage(message) : `${enumKey}`;
  };
}
