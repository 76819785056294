import {useDefaultValidator, useFormMessages} from 'lib/finalForm/hooks';
import {BaseFieldProps} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {Field, FieldRenderProps} from 'react-final-form';
import {Input, InputType, InputTestId, InputSize, InputFocusHandler, InputBlurHandler} from 'uikit/Input';

export type InputFieldTestId = InputTestId;

type Props = BaseFieldProps &
  TestIdProp<InputFieldTestId> & {
    dark?: boolean;
    fullSize?: boolean;
    max?: number;
    min?: number;
    onBlur?: InputBlurHandler;
    onFocus?: InputFocusHandler;
    size?: InputSize;
    type?: InputType;
  };

/**
 * TODO: https://joom-team.atlassian.net/browse/PRO-5944
 * @deprecated use `import { InputField } from "components/FinalForm/InputMuiField";` instead
 */
export function InputField({
  size = 'medium',
  type = 'text',
  testId,
  name,
  label,
  required,
  readOnly,
  placeholder,
  validate,
  onBlur,
  onFocus,
  dark,
  fullSize,
  min,
  max,
  ...props
}: Props): React.ReactElement {
  const messages = useFormMessages();
  const requiredMessage = props.requiredMessage || messages[name]?.required;

  const inputLabel = label || messages[name]?.label;
  const inputPlaceholder = placeholder || messages[name]?.placeholder;

  const handleValidate = useDefaultValidator({
    required,
    requiredMessage,
    validate,
  });

  return (
    <Field name={name} validate={handleValidate}>
      {({input, meta}: FieldRenderProps<string>) => {
        const handleBlur: InputBlurHandler = (event) => {
          input.onBlur(event);
          if (onBlur) {
            onBlur(event);
          }
        };

        // TODO: PRO-1572 New forms
        /* eslint-disable react/jsx-no-bind */
        return (
          <Input
            dark={dark}
            error={getErrorMessage(meta)}
            fullSize={fullSize}
            label={inputLabel}
            max={max}
            min={min}
            name={input.name}
            onBlur={handleBlur}
            onChange={input.onChange}
            onFocus={onFocus}
            placeholder={inputPlaceholder}
            readOnly={readOnly}
            size={size}
            testId={testId}
            type={type}
            value={input.value}
          />
        );
        /* eslint-enable react/jsx-no-bind */
      }}
    </Field>
  );
}
