import {AdminUser} from 'lib/adminUser/types';
import {Client} from 'lib/client';

type AdminUserResponse = {payload: AdminUser};

type Params = {
  id: string;
};

export function loadAdminUser(client: Client, {id}: Params): Promise<AdminUser> {
  return client.mainApi.post<AdminUserResponse>('adminUser', {body: {id}}).then(({body: {payload}}) => payload);
}
