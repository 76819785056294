import {FormTexts} from 'components/Form/types';
import {messages as emailMessages} from 'lib/form/messages/email';
import {messages as firstNameMessages} from 'lib/form/messages/firstName';
import {messages as phoneMessages} from 'lib/form/messages/phone';

export const labels = {
  email: emailMessages.label,
  firstName: firstNameMessages.label,
  phone: phoneMessages.label,
};

export const placeholders = {
  email: emailMessages.placeholder,
  firstName: firstNameMessages.placeholder,
  phone: phoneMessages.placeholder,
};

export const fieldErrors = {
  email: emailMessages.error,
  firstName: firstNameMessages.error,
  phone: phoneMessages.error,
};

export const formTexts: FormTexts = {
  fieldErrors,
  labels,
  placeholders,
};
