import {loadAdminUser} from 'api/adminUser/loadAdminUser';
import {AdminUser} from 'lib/adminUser/types';
import {createStore, createClientEffect} from 'lib/effector';

type AdminUserState = {
  adminUser?: AdminUser;
  loading: boolean;
  loadingError?: Error;
};

const initialState = {
  loading: false,
};

export const $adminUser = createStore<AdminUserState>('adminUser', initialState);

export const loadAdminUserFx = createClientEffect(loadAdminUser);
