import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  button: {
    defaultMessage: 'Recover password',
    description: 'Password recovery form: "Recover password" button',
  },
  rememberPassword: {
    defaultMessage: 'Remember your password?',
    description: 'Password recovery form: button “Remember your password?',
  },
  subtitle: {
    defaultMessage: 'Please specify the email address that you used to log in to the service',
    description: 'Password recovery form: subheading',
  },
  title: {
    defaultMessage: 'Password recovery',
    description: 'Password recovery form: header',
  },
});
