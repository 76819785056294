import {Definition} from './common';
import {MaskItem} from './MaskItem';
import {MaskItemConfig, MaskManagerInterface} from './types';

export type Options = {
  fallback: string;
  initialValue?: string;
};

export class MaskManager implements MaskManagerInterface {
  private options: Options;

  readonly items: MaskItem[];

  private definitionMap: Record<string, Definition | undefined> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '*': new Definition('*', /./),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    0: new Definition('0', /\d/),
    a: new Definition('a', /\w/),
  };

  constructor(mask: string, options: Options) {
    this.options = options;
    this.items = mask.split('').map((char) => {
      return new MaskItem(this.getConfig(char));
    });

    if (options.initialValue) {
      this.setValue(options.initialValue);
    }
  }

  getItems(): MaskItem[] {
    return this.items;
  }

  /**
   * @example for date format __/__/____
   * 1. |__/__/____ -> setValue(1234) -> |12/34/____
   * 2. 12/__/|____ -> setValue(2021) -> 20/21/|____
   * 3. |12/__/____ -> setValue(20)   -> |20/__/____
   * 4. |02/12/2020 -> setValue(20)   -> |20/__/____
   */
  setValue(value: string): void {
    const chars = value.split('').reverse();

    let char: string | undefined;
    this.items.forEach((item) => {
      item.clear();

      if (chars.length && item.isEditable()) {
        char = chars.pop();

        // Try to find char which passed test
        while (char && !item.test(char)) {
          char = chars.pop();
        }

        if (char) {
          item.setValue(char);
        }
      }
    });
  }

  clear(): void {
    this.items.forEach((item: MaskItem) => item.clear());
  }

  getConfig(char: string): MaskItemConfig {
    const definition = this.definitionMap[char];

    if (definition) {
      return {
        editable: true,
        fallback: this.options.fallback,
        test: definition.regexp,
      };
    }

    return {
      editable: false,
      value: char,
    };
  }
}
