import {loadSimilarProducts} from 'api/product/loadSimilarProducts';
import {ContentList} from 'lib/contentList/types';
import {createStore, createClientEffect} from 'lib/effector';

export type SimilarProductsState = {
  items?: ContentList;
  loading: boolean;
  loadingError?: unknown;
  productId?: string;
};

const initialProductState = {
  loading: false,
};

export const $similarProducts = createStore<SimilarProductsState>('similarProducts', initialProductState);

export const loadSimilarProductsFx = createClientEffect(loadSimilarProducts);
