import {CommunicationDialog, CommunicationDialogTestId} from 'components/CommunicationDialog';
import {CommunicationParams, CommunicationReason} from 'lib/communication/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {messages} from './messages';

export type PartnersConsultationDialogTestId = CommunicationDialogTestId;

type Props = TestIdProp<PartnersConsultationDialogTestId> & {
  error?: unknown;
  onClose(): void;
  onSubmit(formData: CommunicationParams): void;
  onSuccess?: () => void;
  processing?: boolean;
  reason?: CommunicationReason;
  success?: boolean;
};

export function PartnersConsultationDialog({
  error,
  onClose,
  onSubmit,
  onSuccess,
  processing,
  success,
  testId,
  reason,
}: Props): React.ReactElement {
  return (
    <CommunicationDialog<CommunicationParams>
      error={error}
      illustration='/illustrations/consultation.svg'
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      processing={processing}
      reason={reason}
      section='partnersConsultationDialog'
      subtitle={messages.subtitle}
      success={success}
      testId={testId}
      title={messages.title}
    />
  );
}
