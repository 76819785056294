import TextField, {TextFieldProps} from '@mui/material/TextField';
import React from 'react';

export type InputProps = Omit<TextFieldProps, 'variant' | 'error'> & {
  error?: string;
};

/**
 * For style ovverides check /src/styles/mui.scss
 */
export function Input({error, helperText, ...props}: InputProps): React.ReactElement {
  return (
    <TextField
      {...props}
      error={Boolean(error)}
      helperText={error || helperText}
      value={props.value ?? ''}
      variant='outlined'
    />
  );
}
