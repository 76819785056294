import {Reducer} from 'components/Layout/Reducer';
import {ToasterContext} from 'lib/toaster';
import {ToasterInterface, ToastData} from 'lib/toaster/types';
import React, {useCallback, useMemo, useState} from 'react';
import uuid from 'uuid/v4';
import styles from './index.module.scss';
import {ToastInternal} from './ToastInternal';

type Props = {
  children: React.ReactNode;
};

type ToastState = {
  data: ToastData;
  id: string;
};

export function Toaster({children}: Props): React.ReactElement {
  const [currentToast, setCurrentToast] = useState<ToastState | undefined>();

  const toasterInterface = useMemo<ToasterInterface>(
    () => ({
      closeAll: () => setCurrentToast(undefined),
      notify: (data: ToastData) => setCurrentToast({data, id: uuid()}),
    }),
    [],
  );

  const handleToastClose = useCallback(() => {
    setCurrentToast(undefined);
  }, []);

  return (
    <ToasterContext.Provider value={toasterInterface}>
      {children}
      {currentToast && (
        <div className={styles.layout}>
          <Reducer>
            <div className={styles.container}>
              <div className={styles.toaster}>
                <ToastInternal data={currentToast.data} key={currentToast.id} onClose={handleToastClose} />
              </div>
            </div>
          </Reducer>
        </div>
      )}
    </ToasterContext.Provider>
  );
}
