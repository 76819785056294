import {LoadCartItemsPayload} from 'api/cart/loadCartItems';
import {sample} from 'effector';
import {loadCartItemsFx} from 'models/cart';
import {createDealInternalFx} from '.';

sample({
  clock: createDealInternalFx.done,
  fn: (): LoadCartItemsPayload => ({channelType: 'sea'}),
  target: loadCartItemsFx,
});
