import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  subtitle: {
    defaultMessage: "We will contact you and tell you about the partner's offer",
    description: 'Home page. Affiliate program consultation dialog: text',
  },
  title: {
    defaultMessage: 'A special offer from a partner',
    description: 'Home page. Affiliate program consultation dialog: header',
  },
});
