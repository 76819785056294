import {$blogArticle, loadBlogAricleFx, resetBlogAricle} from './index';

$blogArticle
  .on(loadBlogAricleFx, (state) => ({
    ...state,
    loading: true,
    loadingError: undefined,
  }))
  .on(loadBlogAricleFx.doneData, (state, blogArticle) => ({
    ...state,
    blogArticle,
    loading: false,
  }))
  .on(loadBlogAricleFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }))
  .reset(resetBlogAricle);
