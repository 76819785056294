import {loadPrices, ProductPricesPayload} from 'api/product/loadPrices';
import {attach} from 'effector';
import {createClientEffect, createEvent, createStore} from 'lib/effector';
import {ParamsWithRequestId} from 'lib/effector/types';
import {ProductPrices} from 'lib/product/types';
import uuid from 'uuid';

type ProductPricesState = {
  loading: boolean;
  loadingError?: unknown;
  prices?: ProductPrices;
  requestId?: string;
};

export const $productPrices = createStore<ProductPricesState>('productPrices', {loading: false});

export const resetProductPrices = createEvent();

export const loadProductPricesWithReqIdFx = createClientEffect<
  ParamsWithRequestId<ProductPricesPayload>,
  ProductPrices
>(loadPrices);

export const loadProductPricesFx = attach({
  effect: loadProductPricesWithReqIdFx,
  mapParams: (params: ProductPricesPayload) => {
    return {
      ...params,
      requestId: uuid(),
    };
  },
});
