import {AuthViewName, AuthByPhoneViewName} from 'components/Auth/types';
import {authSuccess} from '../index';
import {
  $authPage,
  changeAuthPageView,
  changeAuthPagePhoneView,
  authPageSignInByEmailFx,
  authPageSignUpByEmailFx,
  authPageRequestRecoveryFx,
  authPagePasswordChangeFx,
  authPageSendOtpFx,
  authPageSignInByPhoneFx,
} from './index';

$authPage
  .on(changeAuthPageView, (state, view) => ({
    ...state,
    passwordChangeError: undefined,
    passwordChanged: undefined,
    requestRecoveryError: undefined,
    sendOtpError: undefined,
    signInError: undefined,
    signUpError: undefined,
    view,
  }))
  .on(changeAuthPagePhoneView, (state, phoneView) => ({
    ...state,
    passwordChangeError: undefined,
    passwordChanged: undefined,
    phoneView,
    requestRecoveryError: undefined,
    sendOtpError: undefined,
    signInError: undefined,
    signUpError: undefined,
  }))
  .reset([authSuccess]);

$authPage
  .on(authPageSignInByEmailFx, (state) => ({
    ...state,
    signInError: undefined,
    signInPending: true,
  }))
  .on(authPageSignInByEmailFx.doneData, (state) => ({
    ...state,
    signInPending: false,
    successView: state.view,
  }))
  .on(authPageSignInByEmailFx.failData, (state, error) => ({
    ...state,
    signInError: error,
    signInPending: false,
  }));

$authPage
  .on(authPageSignUpByEmailFx, (state) => ({
    ...state,
    signUpError: undefined,
    signUpPending: true,
  }))
  .on(authPageSignUpByEmailFx.doneData, (state) => ({
    ...state,
    signUpPending: false,
    successView: state.view,
  }))
  .on(authPageSignUpByEmailFx.failData, (state, error) => ({
    ...state,
    signUpError: error,
    signUpPending: false,
  }));

// request recovery email
$authPage
  .on(authPageRequestRecoveryFx, (state) => ({
    ...state,
    requestRecoveryError: undefined,
    requestRecoveryPending: true,
  }))
  .on(authPageRequestRecoveryFx.done, (state, {params}) => ({
    ...state,
    requestRecoveryEmail: params.email,
    requestRecoveryPending: false,
    view: AuthViewName.PASSWORD_RECOVERY_SENT,
  }))
  .on(authPageRequestRecoveryFx.failData, (state, error) => ({
    ...state,
    requestRecoveryError: error,
    requestRecoveryPending: false,
  }));

// password change
$authPage
  .on(authPagePasswordChangeFx, (state) => ({
    ...state,
    passwordChangeError: undefined,
    passwordChangePending: true,
    passwordChanged: undefined,
  }))
  .on(authPagePasswordChangeFx.doneData, (state) => ({
    ...state,
    passwordChangePending: false,
    passwordChanged: true,
  }))
  .on(authPagePasswordChangeFx.failData, (state, error) => ({
    ...state,
    passwordChangeError: error,
    passwordChangePending: false,
  }));

$authPage
  .on(authPageSendOtpFx, (state) => ({
    ...state,
    sendOtpError: undefined,
    sendOtpLoading: true,
  }))
  .on(authPageSendOtpFx.done, (state, {params: sendOtpData, result: {retryAfterMs}}) => ({
    ...state,
    phoneView: AuthByPhoneViewName.OTP,
    retryAfterMs,
    sendOtpData,
    sendOtpLoading: false,
  }))
  .on(authPageSendOtpFx.failData, (state, error) => ({
    ...state,
    sendOtpError: error,
    sendOtpLoading: false,
  }));

$authPage
  .on(authPageSignInByPhoneFx, (state) => ({
    ...state,
    signInError: undefined,
    signInPending: true,
  }))
  .on(authPageSignInByPhoneFx.doneData, (state) => ({
    ...state,
    signInPending: false,
  }))
  .on(authPageSignInByPhoneFx.failData, (state, error) => ({
    ...state,
    signInError: error,
    signInPending: false,
  }));
