"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNode = void 0;
const isNode = () => {
    try {
        return Object.prototype.toString.call(globalThis.process) === '[object process]';
    }
    catch (ex) {
        return false;
    }
};
exports.isNode = isNode;
