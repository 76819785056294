import {addPartnersCredit} from 'api/interactions/addPartnersCredit';
import {createClientEffect, createEvent, createStore} from 'lib/effector';

export type PaymentsPartnersState = {
  error?: unknown;
  opened: boolean;
  processing?: boolean;
  success?: boolean;
};

export const $paymentsPartnersDialog = createStore<PaymentsPartnersState>('paymentsPartners', {opened: false});

export const openPaymentsPartnersDialog = createEvent();

export const closePaymentsPartnersDialog = createEvent();

export const sendPagePaymentsPartnersDataFx = createClientEffect(addPartnersCredit);
