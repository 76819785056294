import {CountryCode} from '@joomcode/deprecated-utils/countries';
import {
  CompanyAnnualTurnoverByCountryCode,
  loadCompanyAnnualTurnover as loadCompanyAnnualTurnoverApi,
} from 'api/companyAnnualTurnover';
import {createStore, createClientEffect, createEvent} from 'lib/effector';

type CompanyAnnualTurnoverState = {
  byId: CompanyAnnualTurnoverByCountryCode;
  loading: boolean;
  loadingError?: Error;
};

const initialCompanyAnnualTurnoverState: CompanyAnnualTurnoverState = {
  byId: {},
  loading: false,
};

export const $companyAnnualTurnoverCollection = createStore<CompanyAnnualTurnoverState>(
  'companyAnnualTurnover',
  initialCompanyAnnualTurnoverState,
);

export const loadCompanyAnnualTurnoverFx = createClientEffect(loadCompanyAnnualTurnoverApi);

export const loadCompanyAnnualTurnover = createEvent<{countryCode: CountryCode}>();

export const resetCompanyAnnualTurnover = createEvent();
