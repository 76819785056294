import {useDefaultValidator, useFormMessages} from 'lib/finalForm/hooks';
import {BaseFieldProps} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import React, {useCallback} from 'react';
import {useField} from 'react-final-form';
import {Select, SelectProps, SelectChangeHandler} from 'uikit/Select';

export * from 'uikit/Select';

export type SelectFieldProps<Value> = Omit<BaseFieldProps<Value>, 'value'> & SelectProps<Value>;

/**
 * TODO: https://joom-team.atlassian.net/browse/PRO-5944
 * @deprecated use `import { SelectField } from "components/FinalForm/SelectMuiField";` instead
 */
export function SelectField<Value>({name, required, validate, ...props}: SelectFieldProps<Value>): React.ReactElement {
  const messages = useFormMessages();
  const requiredMessage = props.requiredMessage || messages[name]?.required;
  const label = props.label || messages[name]?.label;
  const placeholder = props.placeholder || messages[name]?.placeholder;

  const handleValidate = useDefaultValidator({
    required,
    requiredMessage,
    validate,
  });
  const {input, meta} = useField(name, {validate: handleValidate});
  const onChange: SelectChangeHandler<Value> = useCallback(({value}) => input.onChange(value), [input.onChange]);

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      error={getErrorMessage(meta)}
      label={label}
      name={input.name}
      onChange={onChange}
      placeholder={placeholder}
      value={input.value}
    />
  );
}
