import {loadBlogArticle} from 'api/blogArticle/loadBlogArticle';
import {BlogArticle} from 'lib/blogArticle/types';
import {createStore, createEvent, createClientEffect} from 'lib/effector';

type BlogArticleState = {
  blogArticle?: BlogArticle;
  loading: boolean;
  loadingError?: Error;
};

const initialBlogAricleState = {
  loading: false,
};

export const $blogArticle = createStore<BlogArticleState>('blogArticle', initialBlogAricleState);

export const loadBlogAricleFx = createClientEffect(loadBlogArticle);

export const resetBlogAricle = createEvent();
