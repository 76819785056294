import {PhoneField as PhoneFieldBase} from 'components/Form';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {InputTestId} from 'uikit/Input';

export type PhoneFieldTestId = InputTestId;

type Props = TestIdProp<PhoneFieldTestId> & {
  autoFocus?: boolean;
  required?: boolean;
};

export function PhoneField({required, testId, autoFocus}: Props): React.ReactElement {
  return <PhoneFieldBase autoFocus={autoFocus} name='phone' required={required} testId={testId} />;
}
