import {assertNever} from '@joomcode/deprecated-utils/types';
import {isArray, isNumber} from 'lib/guards';
import {searchLogger} from 'lib/logger';
import {
  CheckboxFilter,
  CheckboxFilterValue,
  Filter,
  FilterKind,
  FilterList,
  FilterValueList,
  MoneyRangeFilter,
  MoneyRangeFilterValue,
  SearchTagFilter,
  SearchTagFilterValue,
  TreeFilter,
  TreeFilterItem,
  TreeFilterItemList,
  TreeFilterValue,
} from './types';

export function forEachTreeFilterItem(treeFilter: TreeFilter, cb: (item: TreeFilterItem, depth: number) => void): void {
  const forEach = (items: TreeFilterItemList, depth: number) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      cb(item, depth);

      if (isArray(item.items)) {
        forEach(item.items, depth + 1);
      }
    }
  };

  forEach(treeFilter.items, 0);
}

function applyTreeFilterItemSelection(treeItem: TreeFilterItem, value: boolean): TreeFilterItem {
  // eslint-disable-next-line no-param-reassign
  treeItem.selected = value;

  if (isArray(treeItem.items)) {
    treeItem.items.forEach((item) => applyTreeFilterItemSelection(item, value));
  }

  return treeItem;
}

export function applyTreeFilterSelection(treeFilter: TreeFilter, itemId: string, value: boolean): TreeFilter {
  const applySelection = (items: TreeFilterItemList): boolean => {
    let itemsSelected = true;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.id === itemId) {
        if (Boolean(item.selected) !== value) {
          applyTreeFilterItemSelection(item, value);
        }
      } else if (isArray(item.items)) {
        const childrenSelected = applySelection(item.items);

        if (Boolean(item.selected) !== childrenSelected) {
          item.selected = childrenSelected;
        }
      }

      if (!item.selected) {
        itemsSelected = false;
      }
    }

    return itemsSelected;
  };

  applySelection(treeFilter.items);

  return treeFilter;
}

export function unselectTreeFilter(treeFilter: TreeFilter): TreeFilter {
  treeFilter.items.forEach((item) => applyTreeFilterItemSelection(item, false));

  return treeFilter;
}

export function filterTreeFilterItems(
  treeFilter: TreeFilter,
  cb: (item: TreeFilterItem, depth: number) => unknown,
): TreeFilterItemList {
  const result: TreeFilterItemList = [];
  const resultMap: Record<string, number> = {};

  const filterItems = (items: TreeFilterItemList, depth: number) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.id in resultMap) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (cb(item, depth)) {
        result.push(item);
        resultMap[item.id] = result.length - 1;
      } else if (isArray(item.items)) {
        filterItems(item.items, depth + 1);
      }
    }
  };

  filterItems(treeFilter.items, 0);

  return result;
}

export function filterAppliedTreeFilterItems(treeFilter: TreeFilter): TreeFilterItemList {
  return filterTreeFilterItems(treeFilter, (item) => item.applied);
}

export function filterSelectedTreeFilterItems(treeFilter: TreeFilter): TreeFilterItemList {
  return filterTreeFilterItems(treeFilter, (item) => item.selected);
}

export function resetTreeFilter(treeFilter: TreeFilter): void {
  unselectTreeFilter(treeFilter);
}

export function resetCheckboxFilter(checkboxFilter: CheckboxFilter): void {
  if (checkboxFilter.applied) {
    // eslint-disable-next-line no-param-reassign
    checkboxFilter.checked = !checkboxFilter.appliedValue?.checked;
  } else {
    // eslint-disable-next-line no-param-reassign
    checkboxFilter.checked = false;
  }
}

export function changeMoneyRangeFilter(
  filter: MoneyRangeFilter,
  changes: Pick<MoneyRangeFilter, 'currency' | 'max' | 'min'>,
): void {
  /* eslint-disable no-param-reassign */
  if ('currency' in changes) {
    filter.currency = changes.currency;
  }

  if ('min' in changes) {
    filter.min = changes.min;
  }

  if ('max' in changes) {
    filter.max = changes.max;
  }
  /* eslint-enable no-param-reassign */
}

export function resetMoneyRangeFilter(filter: MoneyRangeFilter): void {
  /* eslint-disable no-param-reassign */
  filter.currency = undefined;
  filter.min = undefined;
  filter.max = undefined;
  /* eslint-enable no-param-reassign */
}

export function resetSearchTagFilter(filter: SearchTagFilter): void {
  /* eslint-disable no-param-reassign */
  filter.items = [];
  /* eslint-enable no-param-reassign */
}

export function resetFilter(filter: Filter): void {
  switch (filter.kind) {
    case FilterKind.CHECKBOX:
      resetCheckboxFilter(filter);
      break;

    case FilterKind.MONEY_RANGE:
      resetMoneyRangeFilter(filter);
      break;

    case FilterKind.TREE:
      resetTreeFilter(filter);
      break;

    case FilterKind.SEARCH_TAG:
      resetSearchTagFilter(filter);
      break;

    default:
      searchLogger.error('Unsupported FilterKind', filter);
      assertNever(filter);
  }
}

export function resetFilters(filterList: FilterList): void {
  filterList.forEach(resetFilter);
}

export function extractCheckboxFilterValue(filter: CheckboxFilter): CheckboxFilterValue {
  return {
    checked: filter.checked,
    id: filter.id,
    type: filter.type,
  };
}

export function extractMoneyRangeFilterValue(filter: MoneyRangeFilter): MoneyRangeFilterValue {
  return {
    currency: filter.currency,
    id: filter.id,
    max: filter.max,
    min: filter.min,
    type: filter.type,
  };
}

export function extractTreeFilterValue(filter: TreeFilter): TreeFilterValue {
  const value: TreeFilterValue = {
    id: filter.id,
    items: [],
    type: filter.type,
  };

  filterTreeFilterItems(filter, (item) => {
    if (item.selected) {
      value.items.push({id: item.id});
      return true;
    }

    return false;
  });

  return value;
}

export function extractSearchTagFilterValue(filter: SearchTagFilter): SearchTagFilterValue {
  return {
    id: filter.id,
    items: filter.items.map((item) => ({
      id: item.id,
    })),
    type: filter.type,
  };
}

export function extractFilterValueList(filtersList: FilterList): FilterValueList {
  return filtersList.reduce<FilterValueList>((result, filter) => {
    switch (filter.kind) {
      case FilterKind.CHECKBOX: {
        if (filter.applied) {
          if (filter.checked === Boolean(filter.appliedValue?.checked)) {
            result.push(extractCheckboxFilterValue(filter));
          }
        } else if (filter.checked) {
          result.push(extractCheckboxFilterValue(filter));
        }

        break;
      }

      case FilterKind.MONEY_RANGE: {
        if (isNumber(filter.max) || isNumber(filter.min)) {
          result.push(extractMoneyRangeFilterValue(filter));
        }

        break;
      }

      case FilterKind.TREE: {
        const value = extractTreeFilterValue(filter);

        if (value.items.length) {
          result.push(value);
        }

        break;
      }

      case FilterKind.SEARCH_TAG: {
        if (filter.applied) {
          const value = extractSearchTagFilterValue(filter);

          if (value.items.length) {
            result.push(value);
          }
        }

        break;
      }

      default:
        searchLogger.error('Unsupported FilterKind', filter);
        assertNever(filter);
    }

    return result;
  }, []);
}

export function packFilterValueForAnalytics(filter: Filter): string {
  switch (filter.kind) {
    case FilterKind.CHECKBOX: {
      return `bool:${filter.checked}`;
    }

    case FilterKind.MONEY_RANGE: {
      const {min, max} = filter;
      return `range:${isNumber(min) ? min : ''},${isNumber(max) ? max : ''}`;
    }

    case FilterKind.TREE: {
      const filterValue = extractTreeFilterValue(filter);
      const values = filterValue.items.map((item) => item.id);
      return `list:${values.join(',')}`;
    }

    case FilterKind.SEARCH_TAG: {
      const filterValue = extractSearchTagFilterValue(filter);
      const values = filterValue.items.map((item) => item.id);
      return `searchTag:${values.join(',')}`;
    }

    default: {
      searchLogger.error('Unsupported FilterKind', filter);
      assertNever(filter);
    }
  }

  return '';
}
