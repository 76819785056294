import {defineMessage, defineMessages} from 'react-intl';

export const messages = {
  error: defineMessages({
    email: {
      defaultMessage: 'Invalid email',
      description: 'General field "Email". Error for invalid value',
    },
    required: {
      defaultMessage: 'Enter your email',
      description: 'General field "Email". Error for empty value',
    },
  }),

  label: defineMessage({
    defaultMessage: 'Email',
    description: 'General field "Email". Signature',
  }),

  placeholder: defineMessage({
    defaultMessage: 'Enter your email',
    description: 'General field "Email". Clue',
  }),
};
