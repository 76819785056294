import {MaskInterface, MaskUtilsInterface} from './types';

export class MaskUtils implements MaskUtilsInterface {
  private mask: MaskInterface;

  constructor(mask: MaskInterface) {
    this.mask = mask;
  }

  substring(start: number, end?: number): string {
    const parts = this.mask.getItems().slice(start, end);

    return parts
      .reduce<string[]>((result, item) => {
        if (item.isEditable() && item.isFilled()) {
          result.push(item.toString());
        }

        return result;
      }, [])
      .join('');
  }

  toString(): string {
    const {alwaysShowMask} = this.mask.getOptions();
    const items = this.mask.getItems();
    const result: string[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (!alwaysShowMask) {
        // Break loop at the first editable and empty item.
        if (item.isEditable() && item.isEmpty()) {
          break;
        }
      }

      result.push(item.toString());
    }

    return result.join('');
  }

  toRawString(): string {
    return this.mask
      .getItems()
      .reduce<string[]>((raw, item) => {
        if (item.isEditable() && item.isFilled()) {
          raw.push(item.toString());
        }

        return raw;
      }, [])
      .join('');
  }

  getMask(): string {
    return this.mask
      .getItems()
      .reduce<string[]>((mask, item) => {
        mask.push(item.getMaskValue());

        return mask;
      }, [])
      .join('');
  }

  isEmpty(): boolean {
    return !this.toRawString().length;
  }
}
