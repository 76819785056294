import {ErrorMessage} from 'components/ErrorMessage';
import {Form, InputField} from 'components/Form';
import {isFormValidationError} from 'lib/form/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {Button, ButtonTestId} from 'uikit/Button';
import type {InputTestId} from 'uikit/Input';
import {formTexts} from '../messages';
import {SubAction, SubActionTestId} from '../SubAction';
import {ViewChangeHandler, AuthViewName, SubmitHandler, RequestRecoveryCredential} from '../types';
import {View, ViewActions, ViewFields, ViewHeader, ViewExtraContent, ViewError, ViewFormGeneralError} from '../View';
import {messages} from './messages';

export type PasswordRecoveryTestId = {
  form: {
    email: InputTestId;
    submit: ButtonTestId;
  };
  rememberPasswordAction: SubActionTestId;
};

type Props = TestIdProp<PasswordRecoveryTestId> & {
  error?: unknown;
  onSubmit: SubmitHandler<RequestRecoveryCredential>;
  onViewChange: ViewChangeHandler;
  pending?: boolean;
};

export function PasswordRecoveryView({onSubmit, onViewChange, pending, error, testId}: Props): React.ReactElement {
  const handleRememberClick = useCallback(() => {
    onViewChange(AuthViewName.SIGN_IN);
  }, [onViewChange]);

  const validationError = isFormValidationError(error) ? error : undefined;

  return (
    <View testId={testId}>
      <ViewHeader image='/illustrations/lock.svg' subtitle={messages.subtitle} title={messages.title} />
      <Form error={validationError} onSubmit={onSubmit} testId={testId?.form} texts={formTexts}>
        <ViewFields>
          <InputField autoComplete='email' name='email' required testId={testId?.form.email} type='email' />
        </ViewFields>
        <ViewActions>
          <Button
            color='black'
            disabled={pending}
            label={messages.button}
            loading={pending}
            testId={testId?.form.submit}
            type='submit'
          />
        </ViewActions>
        <ViewFormGeneralError />
      </Form>
      {Boolean(error && !validationError) && (
        <ViewError>
          <ErrorMessage error={error} />
        </ViewError>
      )}
      <ViewExtraContent>
        <SubAction
          label={messages.rememberPassword}
          onClick={handleRememberClick}
          testId={testId?.rememberPasswordAction}
        />
      </ViewExtraContent>
    </View>
  );
}
