import {loadPromotion as loadPromotionBase} from 'api/promotions/loadPromotion';
import {Client} from 'lib/client';
import {ProductLite} from 'lib/productLite/types';
import {extractProductItems} from './utils';

export type MainPromotionParams = {
  count?: number;
  id: string;
};

export type MainPromotionResponse = {
  items: ProductLite[];
  nextPageToken?: string;
  promotionId: string;
  title?: string;
};

export function loadPromotion(client: Client, params: MainPromotionParams): Promise<MainPromotionResponse> {
  return loadPromotionBase(client, params).then((result) => ({
    ...result,
    items: extractProductItems(result.items),
  }));
}
