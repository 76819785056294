import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  dealInProgressSubTitle: {
    defaultMessage: 'To order the product, please fill out all registration fields',
    description: 'Registration dialog sub title',
  },
  step0SubTitle: {
    defaultMessage: "You don't have an account yet. Complete a quick registration to start using the service.",
    description: 'Registration dialog sub title',
  },
  step0Title: {
    defaultMessage: 'Tell us about yourself!',
    description: 'Registration dialog title',
  },
  step1SubTitle: {
    defaultMessage:
      'Add information about your product categories and field of operation so we can provide the best products tailored to your interests.',
    description: 'Registration dialog sub title',
  },
  step1Title: {
    defaultMessage: 'You have successfully registered!',
    description: 'Registration dialog title',
  },
});
