import {AuthOrigin, AuthViewName, AuthByPhoneViewName} from 'lib/auth/types';
import React from 'react';
import type {ButtonTestId} from 'uikit/Button';
import type {PasswordRecoveryTestId} from './PasswordRecoveryView';
import type {SignInByPhoneTestId} from './SignInByPhone';
import type {SignInByEmailTestId} from './SignInView';
import type {SignUpByEmailTestId} from './SignUpView';

export {AuthOrigin, AuthViewName, AuthByPhoneViewName, AuthSuccessExtraViewName} from 'lib/auth/types';

export type SignInByEmailCredential = {
  email: string;
  password: string;
};

export type SendOtpFormData = {
  email?: string;
  firstName?: string;
  phone: string;
};

export type SignInByPhoneCredential = {
  email?: string;
  firstName?: string;
  origin: AuthOrigin;
  otp: string;
  phone: string;
};

export type SignUpByEmailCredential = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  source: string;
};

export type RequestRecoveryCredential = {
  email: string;
};

export type RequestPasswordChangeCredential = {
  password: string;
  token: string;
};

export type FormData = Record<string, unknown>;

export type ViewChangeHandler = (viewName: AuthViewName) => void;

export type SubmitHandler<T = FormData> = (data: T) => void;

export type CloseHandler = () => void;

export type AuthViewsTestId = {
  passwordRecovery: PasswordRecoveryTestId;
  passwordRecoverySent: unknown;
  recoverySuccessSentAction: ButtonTestId;
  signInByEmail: SignInByEmailTestId;
  signInByPhone: SignInByPhoneTestId;
  signUpByEmail: SignUpByEmailTestId;
};

export type AuthPhoneProps = {
  onPhoneViewChange: (view: AuthByPhoneViewName) => void;
  onSendOtp: (payload: SendOtpFormData) => void;
  onSignInByPhone: (credential: SignInByPhoneCredential) => void;
  origin?: AuthOrigin;
  phoneView?: AuthByPhoneViewName;
  retryAfterMs?: number;
  sendOtpData?: SendOtpFormData;
  sendOtpError?: unknown;
  sendOtpLoading?: boolean;
  withBackButton?: boolean;
};

export type AuthProps = AuthPhoneProps & {
  onRequestRecovery: (credential: RequestRecoveryCredential) => void;
  onSignInByEmail: (credential: SignInByEmailCredential) => void;
  onSignUpByEmail: (credential: SignUpByEmailCredential) => void;
  onViewChange: ViewChangeHandler;
  requestRecoveryEmail?: string;
  requestRecoveryError?: unknown;
  requestRecoveryPending?: boolean;
  requestRecoverySentAction?: React.ReactNode;
  signInError?: unknown;
  signInPending?: boolean;
  signUpError?: unknown;
  signUpPending?: boolean;
  view: AuthViewName;
};
