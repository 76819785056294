import {requestPasswordChange} from 'api/auth/requestPasswordChange';
import {requestPasswordRecovery} from 'api/auth/requestPasswordRecovery';
import {sendOtp} from 'api/auth/sendOtp';
import {signInByEmail} from 'api/auth/signInByEmail';
import {signInByPhone} from 'api/auth/signInByPhone';
import {signInByToken} from 'api/auth/signInByToken';
import {signOut} from 'api/auth/signOut';
import {signUpByEmail} from 'api/auth/signUpByEmail';
import {merge} from 'effector';
import {createClientEffect} from 'lib/effector';

export const signInByEmailFx = createClientEffect(signInByEmail);

export const signUpByEmailFx = createClientEffect(signUpByEmail);

export const signInByPhoneFx = createClientEffect(signInByPhone);

export const signOutFx = createClientEffect(signOut);

export const sendOtpFx = createClientEffect(sendOtp);

export const requestRecoveryFx = createClientEffect(requestPasswordRecovery);

export const requestPasswordChangeFx = createClientEffect(requestPasswordChange);

export const signInByTokenFx = createClientEffect(signInByToken);

export const authSuccess = merge([
  signInByEmailFx.doneData,
  signInByTokenFx.doneData,
  signUpByEmailFx.doneData,
  signInByPhoneFx.doneData,
  requestPasswordChangeFx.doneData,
]);
