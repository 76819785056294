import AutocompleteBase, {AutocompleteProps as AutocompletePropsBase} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import ChevronDownIcon from 'icons/chevron-down.svg';
import {FieldValue} from 'lib/form/types';
import React from 'react';

export type AutocompleteProps<
  Value extends FieldValue,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompletePropsBase<Value, Multiple, DisableClearable, FreeSolo>,
  'error' | 'variant' | 'renderInput' | 'IconComponent' | 'openOnFocus' | 'autoSelect'
> & {
  error?: string;
  helperText?: string;
  label: string;
  placeholder?: string;
};

export function Autocomplete<
  Value extends FieldValue,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({label, placeholder, error, helperText, ...props}: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>) {
  return (
    <AutocompleteBase
      {...props}
      autoSelect
      openOnFocus
      popupIcon={<ChevronDownIcon />}
      /* eslint-disable react/jsx-no-bind */
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {props.loading ? <CircularProgress color='inherit' size={16} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={Boolean(error)}
            helperText={error || helperText}
            label={label}
            placeholder={placeholder}
            variant='outlined'
          />
        );
      }}
    />
  );
}
