import {getServerRuntimeConfig} from 'lib/appConfig';
import {Endpoints} from './types';

export function getEndpoints(baseUrl?: URL): Endpoints {
  let localApi;
  let mainApi;

  if (__SERVER__) {
    const config = getServerRuntimeConfig();
    localApi = config.localApi;
    mainApi = config.mainApi;
  } else {
    if (!baseUrl) {
      throw new Error('The base url must be defined');
    }

    localApi = new URL('/api', baseUrl).toString();
    mainApi = new URL('/main-api', baseUrl).toString();
  }

  return {localApi, mainApi};
}
