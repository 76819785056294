import {
  allSettled,
  serialize,
  scopeBind,
  fork,
  Scope,
  Unit,
  Store,
  Effect,
  EffectParams,
  EffectResult,
  EffectError,
} from 'effector';
import {Client} from 'lib/client';
import {Language} from 'lib/language/types';
import {appDomain, $appGetClient, $appGetLanguage, $appGetAnalytics, $appGetCookieManager} from './domain';

type SerializedState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [sid: string]: any;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
type Done<Fx extends Effect<any, any, any>> = {
  status: 'done';
  value: EffectResult<Fx>;
};
type Fail<Fx extends Effect<any, any, any>> = {
  status: 'fail';
  value: EffectError<Fx>;
};
export type DispatchResult<Fx extends Effect<any, any, any>> = Done<Fx> | Fail<Fx>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export class StateManager {
  client: Client;

  scope: Scope;

  language: Language;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({client, language}: {client: Client; language: Language}, sourceValues?: Record<string, any>) {
    this.client = client;
    this.language = language;

    const values = {
      ...sourceValues,
      [$appGetLanguage.sid!]: () => this.language,
      [$appGetClient.sid!]: () => this.client,
      [$appGetAnalytics.sid!]: () => this.client.analytics,
      [$appGetCookieManager.sid!]: () => this.client.cookies,
    };

    this.scope = fork(appDomain, {values});
  }

  getState<T>(store: Store<T>): T {
    return this.scope.getState<T>(store);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  async dispatch<Fx extends Effect<any, any, any>>(unit: Fx, params: EffectParams<Fx>): Promise<DispatchResult<Fx>>;

  async dispatch<Fx extends Effect<void, any, any>>(unit: Fx): Promise<DispatchResult<Fx>>;

  async dispatch<T>(unit: Unit<T>, params: T): Promise<void>;

  async dispatch<T>(unit: Unit<T>, params?: T): Promise<void>;

  async dispatch(unit: Unit<void>): Promise<void>;

  async dispatch(unit: Unit<any>, params?: any): Promise<any> {
    if (typeof window === 'undefined') {
      return allSettled(unit, {params, scope: this.scope});
    }
    try {
      const fn = scopeBind(unit as any, {scope: this.scope});
      return await fn(params);
    } catch (e) {
      return undefined;
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  serialize(): SerializedState {
    const options = {
      ignore: [$appGetClient, $appGetLanguage, $appGetAnalytics, $appGetCookieManager],
      onlyChanges: true,
    };
    return serialize(this.scope, options);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  toJSON(): SerializedState {
    return this.serialize();
  }
}
