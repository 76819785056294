import {InputField} from 'components/Form';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {InputTestId} from 'uikit/Input';

export type FirstNameFieldTestId = InputTestId;

type Props = TestIdProp<FirstNameFieldTestId> & {
  required?: boolean;
};

export function FirstNameField({required, testId}: Props): React.ReactElement {
  return <InputField name='firstName' required={required} testId={testId} type='text' />;
}
