import * as api from 'api/promocode';
import {$appGetCookieManager, createClientEffect, createEvent, createStore} from 'lib/effector';
import {PromocodeGetter} from 'lib/promocode/types';

export const $promocodeTopBannerVisible = createStore<boolean>('promocodeTopBannerVisible', false);

export const $getCurrentPromocode = $appGetCookieManager.map<PromocodeGetter>(
  (getCookieManager) => () => getCookieManager().promocode,
);

export const validatePromocodeFx = createClientEffect(api.validatePromocode);

export const validateAndApplyPromocode = createEvent<string>();

export const closeTopBanner = createEvent();
