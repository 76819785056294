import {sample} from 'effector';
import {isGtmEnabled, extractErrorType} from 'lib/analytics/utils';
import {$appGetAnalytics} from 'lib/effector';
import {analyticsDataLayer} from 'lib/effector/analytics';
import './dialog/init';
import './page/init';
import {authSuccess, sendOtpFx, signInByPhoneFx, signOutFx} from './index';

sample({
  clock: authSuccess,
  fn: (getAnalytics, user) => {
    if (!user.anonymous && !isGtmEnabled(user)) {
      getAnalytics().disableGtm();
    }
  },
  source: $appGetAnalytics,
});

signOutFx.done.watch(() => window.location.reload());

analyticsDataLayer(signInByPhoneFx, () => ({event: 'signInRequest', type: 'phone'}));
analyticsDataLayer(signInByPhoneFx.doneData, (user) => ({event: 'signInSuccess', type: 'phone', user_id: user.id}));
analyticsDataLayer(signInByPhoneFx.failData, (error) => ({
  error: extractErrorType(error),
  event: 'signInFail',
  type: 'phone',
}));

analyticsDataLayer(sendOtpFx, () => ({event: 'signInOtpRequest', type: 'phone'}));
analyticsDataLayer(sendOtpFx.done, () => ({event: 'signInOtpRequestSuccess', type: 'phone'}));
analyticsDataLayer(sendOtpFx.failData, (error) => ({
  error: extractErrorType(error),
  event: 'signInOtpRequestFail',
  type: 'phone',
}));
