import {Oops} from 'components/Oops';
import React from 'react';
import {DialogContent} from 'uikit/Dialog';

export function ErrorView(): React.ReactElement {
  return (
    <DialogContent>
      <Oops />
    </DialogContent>
  );
}
