import classNamesBind from 'classnames/bind';
import React from 'react';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

type Props = {
  children: React.ReactNode;
  textAlign?: 'left' | 'center' | 'right';
};

export function ViewExtraContent({children, textAlign = 'left'}: Props): React.ReactElement {
  return <div className={cn('extraContent', `${textAlign}Align`)}>{children}</div>;
}
