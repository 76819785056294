import {ExtMessageDescriptor} from 'lib/intl/types';
import {validatePhone as validatePhoneBase} from 'lib/validation';
import {defineMessage} from 'react-intl';

const errorMessage = defineMessage({
  defaultMessage: 'Incorrect phone number',
  description: 'Error for invalid phone number',
});

export function validatePhone(phone: unknown): ExtMessageDescriptor | undefined {
  return validatePhoneBase(phone) === 'phone' ? errorMessage : undefined;
}
