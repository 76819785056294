import {FormTexts} from 'components/Form/types';
import {ErrorCode} from 'lib/validation';
import {defineMessages, defineMessage} from 'react-intl';

export const messages = defineMessages({
  subtitle: {
    defaultMessage: 'Our partner will contact you and tell you about financing',
    description: 'Home page. Financing Advice Dialog: Text',
  },
  title: {
    defaultMessage: 'Financing application',
    description: 'Home page. Funding Advice Dialog: Header',
  },
});

export const labels = {
  comment: defineMessage({
    defaultMessage: 'Comment',
    description: 'Field: "Comment"',
  }),
  name: defineMessage({
    defaultMessage: 'First Name',
    description: 'Field: "Name"',
  }),
  taxpayerNumber: defineMessage({
    defaultMessage: 'INN',
    description: 'Field: "TIN"',
  }),
};

export const placeholders = {
  comment: defineMessage({
    defaultMessage: 'Enter a comment',
    description: 'Field hint: "Comment"',
  }),
  name: defineMessage({
    defaultMessage: 'First name required',
    description: 'Field hint: "Name"',
  }),
  taxpayerNumber: defineMessage({
    defaultMessage: 'Enter your INN',
    description: 'Hint for the field: "TIN"',
  }),
};

export const fieldErrors = {
  taxpayerNumber: {
    [ErrorCode.NON_NUMERIC]: defineMessage({
      defaultMessage: 'INN should only consist of numbers',
      description: 'Error for the field: "TIN"',
    }),
    [ErrorCode.MIN_LENGTH]: defineMessage({
      defaultMessage: 'The INN should only consist of 10 or 12 characters',
      description: 'Length error for field: "TIN"',
    }),
  },
};

export const formTexts: FormTexts = {
  fieldErrors,
  labels,
  placeholders,
};
