import {PaymentsConsultationDialog as PaymentsConsultationDialogComponent} from 'components/PaymentsConsultationDialog';
import {useEvent, useStore} from 'lib/effector';
import {useFormSuccessEvent} from 'lib/hooks';
import {
  $paymentsConsultationDialog,
  closePaymentsConsultationDialog,
  sendPagePaymentsConsultationDataFx,
} from 'models/paymentsConsultation';
import React from 'react';
import {rootTestId} from 'testId';

export function PaymentsConsultationDialog(): React.ReactElement | null {
  const dialogState = useStore($paymentsConsultationDialog);
  const handleClose = useEvent(closePaymentsConsultationDialog);
  const handleSubmit = useEvent(sendPagePaymentsConsultationDataFx);
  const {onSuccess} = useFormSuccessEvent();

  if (!dialogState.opened) {
    return null;
  }

  return (
    <PaymentsConsultationDialogComponent
      error={dialogState.error}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      processing={dialogState.processing}
      reason={dialogState.reason}
      success={dialogState.success}
      testId={rootTestId?.consultationDialog}
    />
  );
}
