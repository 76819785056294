import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$searchPromotion, loadSearchPromotionFx, resetSearchPromotion} from '.';

$searchPromotion.reset(resetSearchPromotion);
$searchPromotion
  .on(loadSearchPromotionFx, (store) => ({
    ...store,
    dataState: DataState.LOADING,
  }))
  .on(loadSearchPromotionFx.doneData, (store, data) => ({
    ...store,
    data,
    dataState: DataState.LOADED,
  }))
  .on(loadSearchPromotionFx.failData, (store, error) => ({
    ...store,
    dataState: DataState.FAILED,
  }));
