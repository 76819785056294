import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import type {Client} from 'lib/client';
import {Devicevar, Devicevars, DevicevarsInfo} from 'lib/devicevars/types';
import {extractPayload} from 'lib/transport/utils';

type PreferencesBody = {
  payload: {
    configId: string;
    devicevars?: Devicevars;
  };
};

export function startSession(client: Client): Promise<DevicevarsInfo> {
  const supportedDevicevars = getEnumValues(Devicevar);

  return client.mainApi
    .post<PreferencesBody>('device/startSession', {body: {supportedDevicevars}})
    .then(extractPayload)
    .then(({configId, devicevars = {}}) => ({configId, devicevars}));
}
