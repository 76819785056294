import {isRecord, isString} from 'lib/guards';

export type RgbColor = [number, number, number];

export type HexColor = string;

export type HslColor = [number, number, number];

export type NamedColor = {
  name?: string;
  rgb?: HexColor;
};

export type Color = HexColor | NamedColor;

export type Colors = Color[];

export function isNamedColor(color: unknown): color is NamedColor {
  return isRecord(color) && isString(color.name);
}
