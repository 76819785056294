import {createCommunicationStore} from 'lib/communication';

const {
  $store: $loyaltyConsultationDialog,
  openDialog: openLoyaltyConsultationDialog,
  closeDialog: closeLoyaltyConsultationDialog,
  sendRequestFx: sendPageLoyaltyConsultationDataFx,
} = createCommunicationStore('loyaltyConsultation');

export {
  $loyaltyConsultationDialog,
  openLoyaltyConsultationDialog,
  closeLoyaltyConsultationDialog,
  sendPageLoyaltyConsultationDataFx,
};
