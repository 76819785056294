import {Client} from 'lib/client';
import {User} from 'lib/user/types';
import {PayloadWithUser} from './types';
import {extractUser} from './utils';

type SignInCredential = {
  email: string;
  password: string;
};

export function signInByEmail(client: Client, credential: SignInCredential): Promise<User> {
  return client.mainApi.post<PayloadWithUser>('auth/email/signIn', {body: credential}).then(extractUser);
}
