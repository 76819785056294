import {isRecord, isString} from 'lib/guards';
import {commonLogger} from 'lib/logger';
import {UtmParameters, UtmParameter} from './types';

const UTM_PARAMETER_LIST: UtmParameter[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export function extractUtmParameters(source?: Record<string, unknown>): UtmParameters | undefined {
  const result: UtmParameters = {};
  let count = 0;

  if (isRecord(source)) {
    UTM_PARAMETER_LIST.forEach((parameter) => {
      const value = source[parameter];
      if (isString(value) && value) {
        result[parameter] = value;
        count++;
      }
    });
  }

  return count ? result : undefined;
}

export function stringifyUtmParameters(params?: UtmParameters): string | undefined {
  return params !== undefined ? JSON.stringify(params) : undefined;
}

export function parseUtmParameters(source?: string): UtmParameters | undefined {
  if (isString(source)) {
    try {
      const data = JSON.parse(source);
      return extractUtmParameters(data);
    } catch (e) {
      commonLogger.warn('Utm parsing error', e);
    }
  }

  return undefined;
}
