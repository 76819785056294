import {InputField} from 'components/Form';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {InputTestId} from 'uikit/Input';

export type EmailFieldTestId = InputTestId;

type Props = TestIdProp<EmailFieldTestId> & {
  required?: boolean;
};

export function EmailField({required, testId}: Props): React.ReactElement {
  return <InputField name='email' required={required} testId={testId} type='email' />;
}
