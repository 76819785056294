import {isString} from 'lib/guards';
import {isImageBundle, makeSrcSet} from 'lib/image';
import {ImageBundle} from 'lib/image/types';
import {commonLogger} from 'lib/logger';
import {TestIdProp} from 'lib/testing/types';
import React, {useMemo} from 'react';
import {prepareSizes} from './utils';

export type ImageTestId = unknown;

export type ImageLoading = 'lazy' | 'eager';

type ImgAttributes = Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet' | 'sizes'>;

type Props = TestIdProp<ImageTestId> & {
  alt?: string;
  className?: string;
  loading?: ImageLoading;
  onLoad?: () => void;
} & (
    | {
        sizes?: undefined;
        src?: string;
      }
    | {
        sizes: number | string;
        src?: string | ImageBundle;
      }
  );

export const Image = React.forwardRef<HTMLImageElement, Props>(function Image(
  {alt, className, loading, sizes, src, testId, onLoad},
  ref,
): React.ReactElement {
  const imgAttributes = useMemo<ImgAttributes>(() => {
    const attributes: ImgAttributes = {};

    if (isString(src)) {
      attributes.src = src;
    } else if (isImageBundle(src)) {
      if (src.images.length <= 1) {
        attributes.src = src.images[0]?.url;
      } else {
        if (__DEVELOPMENT__) {
          if (!sizes) {
            commonLogger.error('Image: sizes must be defined when src is ImageBundle');
          }
        }

        attributes.sizes = prepareSizes(sizes);
        attributes.srcSet = makeSrcSet(src);
        attributes.src = src.images[0].url;
      }
    }

    return attributes;
  }, [src, sizes]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <img
      alt={alt}
      className={className}
      data-test-id={testId}
      loading={loading}
      onLoad={onLoad}
      ref={ref}
      {...imgAttributes}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
});
