import {getEnumFormatter} from '@joomcode/deprecated-utils/intl/getEnumFormatter';
import {defineMessages} from 'react-intl';
import {CompanyRole} from '.';

export const messages = defineMessages<CompanyRole>({
  [CompanyRole.CEO]: {
    defaultMessage: 'CEO',
    description: 'User company role label',
  },
  [CompanyRole.COMMERCIAL_DIRECTOR]: {
    defaultMessage: 'Commercial Director',
    description: 'User company role label',
  },
  [CompanyRole.MARKETER]: {
    defaultMessage: 'Marketer',
    description: 'User company role label',
  },
  [CompanyRole.OWNER]: {
    defaultMessage: 'Owner',
    description: 'User company role label',
  },
  [CompanyRole.PROCUREMENT_MANAGER]: {
    defaultMessage: 'Procurement Manager',
    description: 'User company role label',
  },
  [CompanyRole.PURCHASER]: {
    defaultMessage: 'Purchaser',
    description: 'User company role label',
  },
});

export const formatCompanyRole = getEnumFormatter(messages);
