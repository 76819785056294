import {Client} from 'lib/client';
import {ProductTranslationsTarget, ProductTranslations} from 'lib/product/types';
import {loadImagesTranslations} from './loadImagesTranslations';

type TranslateRequestParams = {
  productId: string;
  target: ProductTranslationsTarget;
};

type TranslateResponse = {
  payload: ProductTranslations;
};

export function translateImages(client: Client, params: TranslateRequestParams): Promise<ProductTranslations> {
  return client.mainApi
    .post<TranslateResponse>('products/translateImages', {body: params})
    .then(() => loadImagesTranslations(client, params));
}
