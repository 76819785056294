import {ExtMessageDescriptor} from 'lib/intl/types';
import React from 'react';
import {useFormStateContext} from '../contexts';

export type FormGeneralErrorProps = {
  children: (generalMessage: ExtMessageDescriptor) => React.ReactElement;
};

export function FormGeneralError({children}: FormGeneralErrorProps): React.ReactElement | null {
  const {generalError} = useFormStateContext();
  return generalError ? children(generalError) : null;
}
