import {identity} from '@joomcode/deprecated-utils/function';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {loadZipCodeSuggest, City} from 'api/address';
import {AutocompleteField} from 'components/FinalForm/AutocompleteField';
import {useClient} from 'lib/client';
import {useDebounceEffect} from 'lib/hooks';
import {useState, useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  disabled?: boolean;
  label: string;
  name: string;
  required?: boolean;
};

export const ZipCodeField = ({name, label, disabled, required}: Props) => {
  const intl = useIntl();
  const menu = usePopupState(false);
  const loading = useBooleanState(false);
  const [inputValue, setInputValue] = useState('');

  const [options, setOptions] = useState<readonly City[]>([]);

  const client = useClient();
  const inputValueCleaned = useMemo(() => inputValue.trim(), [inputValue]);

  useDebounceEffect(
    () => {
      if (inputValueCleaned === '') {
        setOptions((arr) => (arr.length === 0 ? arr : []));
        return;
      }
      loading.setTrue();
      loadZipCodeSuggest(client, {limit: 30, query: inputValueCleaned})
        .then((data) => {
          setOptions(data);
        })
        .finally(() => {
          loading.setFalse();
        });
    },
    [inputValueCleaned],
    400,
  );

  const zipCodeToCity: Map<string, City> = useMemo(() => new Map(options.map((item) => [item.zip, item])), [options]);

  const zipCodes = useMemo(() => options.map((item) => item.zip), [options]);

  const getOptionLabel = useCallback(
    (zipCode: string) => {
      const city = zipCodeToCity.get(zipCode);
      if (!city) {
        return '';
      }
      return `${city.zip}, ${city.name}, ${city.state}`;
    },
    [zipCodeToCity],
  );

  const onInputChange = useCallback(
    (_: unknown, newInputValue: string) => setInputValue(newInputValue),
    [setInputValue],
  );

  return (
    <AutocompleteField
      disabled={disabled}
      filterOptions={identity}
      getOptionLabel={getOptionLabel}
      label={label}
      loading={loading.value}
      name={name}
      noOptionsText={intl.formatMessage(messages.noOptionsText)}
      onClose={menu.close}
      onInputChange={onInputChange}
      onOpen={menu.open}
      open={menu.isOpen && Boolean(inputValueCleaned)}
      options={zipCodes}
      placeholder={intl.formatMessage(messages.placeholder)}
      required={required}
    />
  );
};
