import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$cartPrices, loadCartPricesWithReqIdFx} from './index';

$cartPrices
  .on(loadCartPricesWithReqIdFx, (state, params) => ({
    ...state,
    dataState: DataState.LOADING,
    requestId: params.requestId,
  }))
  .on(loadCartPricesWithReqIdFx.done, (state, {result, params}) => {
    if (params.requestId === state.requestId) {
      return {
        ...state,
        data: result,
        dataState: DataState.LOADED,
      };
    }

    return state;
  })
  .on(loadCartPricesWithReqIdFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));
