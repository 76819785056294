import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$productTranslations, loadImagesTranslationsFx, translateImagesFx} from './index';
import {getUpdatedStateByTarget} from './utils';

$productTranslations
  .on(loadImagesTranslationsFx, (state, params) => ({
    ...state,
    productId: params.productId,
    stateByTarget: getUpdatedStateByTarget(state.stateByTarget, DataState.LOADING, params.target),
  }))
  .on(loadImagesTranslationsFx.done, (state, {params, result}) => {
    if (state.productId === params.productId) {
      return {
        ...state,
        dataByTarget: {
          ...state.dataByTarget,
          ...result,
        },
        stateByTarget: getUpdatedStateByTarget(state.stateByTarget, DataState.LOADED, params.target),
      };
    }

    return state;
  })
  .on(loadImagesTranslationsFx.fail, (state, {params}) => {
    if (state.productId === params.productId) {
      return {
        ...state,
        stateByTarget: getUpdatedStateByTarget(state.stateByTarget, DataState.FAILED, params.target),
      };
    }

    return state;
  });

$productTranslations
  .on(translateImagesFx, (state, params) => ({
    ...state,
    requestStateByTarget: {
      ...state.requestStateByTarget,
      [params.target]: DataState.LOADING,
    },
  }))
  .on(translateImagesFx.done, (state, {params, result}) => ({
    ...state,
    dataByTarget: {
      ...state.dataByTarget,
      ...result,
    },
    requestStateByTarget: {
      ...state.requestStateByTarget,
      [params.target]: DataState.LOADED,
    },
  }))
  .on(translateImagesFx.fail, (state, {params}) => ({
    ...state,
    requestStateByTarget: {
      ...state.requestStateByTarget,
      [params.target]: DataState.FAILED,
    },
  }));
