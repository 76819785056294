import {loadProducts} from 'api/main/loadProducts';
import {loadPromotion, MainPromotionResponse} from 'api/main/loadPromotion';
import {createStore, createClientEffect} from 'lib/effector';
import {ProductLite} from 'lib/productLite/types';

export type MainPromotionsState = {
  byId: Record<string, MainPromotionResponse>;
  byIdLoading: Record<string, boolean>;
  byIdLoadingError: Record<string, Error>;
};

export const initialPromotionsState: MainPromotionsState = {
  byId: {},
  byIdLoading: {},
  byIdLoadingError: {},
};

export const $products = createStore<ProductLite[]>('mainProducts', []);

export const $promotions = createStore<MainPromotionsState>('mainPromotions', initialPromotionsState);

export const loadPromotionFx = createClientEffect(loadPromotion);

export const loadProductsFx = createClientEffect(loadProducts);
