import {isRecord, isString} from 'lib/guards';
import {Version} from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */

function normalizeKey(key: string): string {
  return `jb2b.${key}`;
}

function checkStorage(storage: unknown) {
  if (!storage) {
    return false;
  }

  const test = normalizeKey('test');
  try {
    (storage as Storage).setItem(test, test);
    (storage as Storage).removeItem(test);
    return true;
  } catch (ex) {
    return false;
  }
}

function getLocalStorage(): Storage | undefined {
  try {
    if (typeof window !== 'undefined' && checkStorage(window.localStorage)) {
      return window.localStorage;
    }
  } catch (e) {
    // do nothing
  }

  return undefined;
}

function getSessionStorage(): Storage | undefined {
  try {
    if (typeof window !== 'undefined' && checkStorage(window.sessionStorage)) {
      return window.sessionStorage;
    }
  } catch (e) {
    // do nothing
  }

  return undefined;
}

function serializeValue(value: any, version?: Version): string {
  return JSON.stringify({$val: value, $ver: version});
}

function parseValue<V>(source: any, version?: Version): V | undefined {
  if (isString(source)) {
    try {
      const record = JSON.parse(source);
      if (isRecord(record) && '$val' in record) {
        if ((version && record.$ver === version) || !(version || record.$ver)) {
          return record.$val as V;
        }
      }
    } catch (e) {
      // do nothing
    }
  }

  return undefined;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
class VStorage {
  private readonly nativeStorage: Storage | undefined;

  private readonly syntheticStorage: Map<string, string> | undefined;

  constructor(source: Storage | undefined) {
    if (source) {
      this.nativeStorage = source;
    } else {
      this.syntheticStorage = new Map<string, string>();
    }
  }

  getItem<V>(key: string, version?: Version): V | undefined {
    const normalizedKey = normalizeKey(key);
    let value;

    if (this.nativeStorage) {
      value = this.nativeStorage.getItem(normalizedKey);
    } else if (this.syntheticStorage) {
      value = this.syntheticStorage.get(normalizedKey);
    } else {
      throw new Error('No store defined');
    }

    return parseValue<V>(value, version);
  }

  setItem(key: string, value: any, version?: Version): void {
    const normalizedKey = normalizeKey(key);
    const packedValue = serializeValue(value, version);

    if (this.nativeStorage) {
      this.nativeStorage.setItem(normalizedKey, packedValue);
    } else if (this.syntheticStorage) {
      this.syntheticStorage.set(normalizedKey, packedValue);
    } else {
      throw new Error('No store defined');
    }
  }

  removeItem(key: string): void {
    const normalizedKey = normalizeKey(key);

    if (this.nativeStorage) {
      this.nativeStorage.removeItem(normalizedKey);
    } else if (this.syntheticStorage) {
      this.syntheticStorage.delete(normalizedKey);
    } else {
      throw new Error('No store defined');
    }
  }

  clear(): void {
    if (this.nativeStorage) {
      this.nativeStorage.clear();
    } else if (this.syntheticStorage) {
      this.syntheticStorage.clear();
    } else {
      throw new Error('No store defined');
    }
  }
}

export const localStorage = new VStorage(getLocalStorage());
export const sessionStorage = new VStorage(getSessionStorage());
