import {ValidateHandler} from 'lib/finalForm/types';
import {useField, FieldRenderProps} from 'react-final-form';

export type UseRadioProps<V extends string> = {
  name: string;
  validate?: ValidateHandler;
  value: V;
};

type ReturnShape<V extends string> = {
  inputProps: FieldRenderProps<V>['input'];
};

export function useRadio<V extends string>({name, value, validate}: UseRadioProps<V>): ReturnShape<V> {
  const {input} = useField<V>(name, {
    type: 'radio',
    validate,
    value,
  });
  return {
    inputProps: input,
  };
}
