import {isNullish} from '@joomcode/deprecated-utils/function';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import {ErrorCode} from '../types';

const PROTOCOL = '(?:(?:[a-z]+:)?//)';
const AUTH = '(?:\\S+(?::\\S*)?@)?';
const HOST = '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)';
const DOMAIN = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
const TLD = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?';
const PORT = '(?::\\d{2,5})?';
const PATH = '(?:[/?#][^\\s"]*)?';
const REGEX = `(?:${PROTOCOL}|www\\.)?${AUTH}(?:localhost|${HOST}${DOMAIN}${TLD})${PORT}${PATH}`;

// TODO: fast copypaste of joom-form/src/validation/validators/url/validator.ts Will be fixed in PRO-5370

export function validateUrl(value: unknown): ErrorCode.URL | undefined {
  if (isNullish(value) || (typeof value === 'string' && isBlank(value))) {
    return ErrorCode.URL;
  }

  if (typeof value !== 'string' || !new RegExp(`(?:^${REGEX}$)`, 'i').test(value)) {
    return ErrorCode.URL;
  }
  return undefined;
}
