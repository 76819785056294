import {TransportRequestHeaders, JoomProApplicationsHeaders} from './types';

export function convertResponseHeaders(headers: Headers): Record<string, string> {
  const resultHeaders: Record<string, string> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key of headers.keys()) {
    resultHeaders[key] = headers.get(key)!;
  }

  return resultHeaders;
}

export function addCookieHeader(cookie: string, headers: TransportRequestHeaders = {}): TransportRequestHeaders {
  if (cookie) {
    // eslint-disable-next-line no-param-reassign
    headers.cookie = cookie;
  }

  return headers;
}

export function getSsrHeaderName(): string {
  if (__SERVER__) {
    return 'joom-ssr-id';
  }

  throw new Error('Can not get header name');
}

export function addSsrHeader(ssrId: string, headers: TransportRequestHeaders = {}): TransportRequestHeaders {
  if (__SERVER__) {
    // eslint-disable-next-line no-param-reassign
    headers[getSsrHeaderName()] = ssrId;
  }

  return headers;
}

export function addUserAgentHeader(userAgent: string, headers: TransportRequestHeaders = {}): TransportRequestHeaders {
  if (__SERVER__) {
    // eslint-disable-next-line no-param-reassign
    headers['user-agent'] = userAgent;
  }

  return headers;
}

export function addXffHeader(xff: string, headers: TransportRequestHeaders = {}): TransportRequestHeaders {
  if (__SERVER__) {
    // eslint-disable-next-line no-param-reassign
    headers['x-forwarded-for'] = xff;
  }

  return headers;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function addXJoomProApplicationHeader(headers: TransportRequestHeaders = {}): TransportRequestHeaders {
  // eslint-disable-next-line no-param-reassign
  headers['X-Joompro-Application'] = JoomProApplicationsHeaders.JOOM_PRO;

  return headers;
}
