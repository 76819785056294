import classNamesBind from 'classnames/bind';
import {isString} from 'lib/guards';
import {useInput} from 'lib/input';
import {InputProps as CommonInputProps} from 'lib/input/types';
import {buildMessage} from 'lib/intl';
import {TestIdProp} from 'lib/testing/types';
import React, {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, forwardRef} from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type {InputType} from 'lib/input/types';

export type InputSize = 'small' | 'medium' | 'large' | 'x-large';

export type InputTextAlign = 'left' | 'right' | 'center';

export type InputBlurHandler = FocusEventHandler<HTMLInputElement>;

export type InputFocusHandler = FocusEventHandler<HTMLInputElement>;

export type InputChangeHandler = ChangeEventHandler<HTMLInputElement>;

export type InputKeyboardHandler = KeyboardEventHandler<HTMLInputElement>;

export type InputTestId = {
  input: unknown;
  label: unknown;
};

export type InputProps = CommonInputProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  block?: boolean;
  dark?: boolean;
  error?: string | MessageDescriptor;
  fullSize?: boolean;
  label?: string | MessageDescriptor;
  onBlur?: InputBlurHandler;
  onFocus?: InputFocusHandler;
  onKeyDown?: InputKeyboardHandler;
  placeholder?: string | MessageDescriptor;
  readOnly?: boolean;
  size?: InputSize;
  textAlign?: InputTextAlign;
  withMaskPlaceholder?: boolean;
};

type Props = InputProps & TestIdProp<InputTestId>;

/**
 * TODO: https://joom-team.atlassian.net/browse/PRO-5944
 * @deprecated use `import { Input } from "uikit/InputMui";` instead
 */
export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    block = false,
    error,
    label,
    onChange,
    onKeyDown,
    placeholder,
    withMaskPlaceholder,
    size = 'medium',
    testId,
    value,
    textAlign,
    dark,
    fullSize,
    ...inputProps
  } = props;

  const intl = useIntl();
  const input = useInput(props);
  const notEmpty = Boolean(input.value);
  const withError = Boolean(error);
  const withLabel = Boolean(label);

  const inputError = buildMessage(intl, error, inputProps);
  const inputLabel = buildMessage(intl, label);
  const inputPlaceholder = size === 'small' && !placeholder ? inputLabel : buildMessage(intl, placeholder);
  const maskPlaceholderVisible = notEmpty && withMaskPlaceholder && inputPlaceholder;
  const labelVisible = inputLabel || inputError;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div
      className={cn('inputWrap', `size-${size}`, {
        block,
        dark,
        empty: !notEmpty,
        fullSize,
        notEmpty,
        withError,
        withLabel,
      })}
      data-test-id={testId}
    >
      {maskPlaceholderVisible && isString(input.value) && (
        <div className={styles.maskPlaceholder}>
          <span className={styles.head}>{input.value}</span>
          <span className={styles.tail}>{inputPlaceholder.substr(input.value.length)}</span>
        </div>
      )}
      <input
        ref={ref}
        {...inputProps}
        {...input.nativeProps}
        className={cn(styles.input, `text-align-${textAlign}`)}
        data-test-id={testId?.input}
        onKeyDown={onKeyDown}
        placeholder={inputPlaceholder}
      />
      <div className={styles.border} />
      {labelVisible && (
        <div className={styles.label} data-test-id={testId?.label}>
          {inputError || inputLabel}
        </div>
      )}
    </div>
  );
});
