import {Category} from 'lib/category/types';
import {ImageBundle} from 'lib/image/types';
import {MediaItems} from 'lib/media/types';
import {ObfuscatedCount} from 'lib/obfuscatedCount/types';
import {Currency, Money} from 'lib/pricing/types';
import {SearchTag} from 'lib/searchTag/types';
import {ProductVariantList} from './variants';

export type ProductGallery = MediaItems;

export type ProductPricingQuantity = number[];

export enum ProductCertification {
  CERTIFICATION = 'certification',
  DECLARATION = 'declaration',
  NONE = 'none',
  REJECTION_LETTER = 'rejection-letter',
  STATE_REGISTRATION = 'state-registration',
  UNKNOWN = 'unknown',
}

export enum ProductBadge {
  HOT_SALE = 'hotSale',
  MERCADO_BEST_SELLER = 'mercadoBestSeller',
}

export type ProductPricePerBatch = {
  extraCharge?: {
    percent: number;
    total: Money;
  };
  pieces: number;
  price: Money;
  total: Money;
};

export type ProductWbInfo = {
  link: string;
  price: Money;
};

export type ProductAttribute = {
  key: string;
  value: string;
};

export type ProductVideo = {
  videoUrl: string;
};

export type Product = {
  analyticProductId?: string;
  attributes?: ProductAttribute[];
  badges?: ProductBadge[];
  category?: Category;
  certificationSchemes: ProductCertification[];
  currency?: Currency;
  description: string;
  engDescription: string;
  engName: string;
  examplesOfOffers?: ProductPricePerBatch[];
  favorite?: boolean;
  gallery?: ProductGallery;
  hasDetailedSizeTables?: boolean;
  id: string;
  inStock: boolean;
  mainImage?: ImageBundle;
  marginPercent?: number;
  maxSampleQuantity: number;
  minTotalPrice?: Money;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  priceExtended10000USD?: ProductPricePerBatch;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  priceExtended3000USD?: ProductPricePerBatch;
  pricingCurrency: string;
  pricingQuantityDistribution?: ProductPricingQuantity;
  rating?: number;
  reviewsCount?: ObfuscatedCount;
  searchTags?: SearchTag[];
  sellerPrice?: Money;
  variants?: ProductVariantList;
  videos?: ProductVideo[];
  wbInfo?: ProductWbInfo;
};

// additional type that is used for calculations
export type PriceInterval = {
  index: number;
  price: {
    max: number;
    min: number;
  };
  quantity: {
    max?: number;
    min: number;
  };
};

export type PriceIntervals = PriceInterval[];
