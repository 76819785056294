import {isRecord, isString} from 'lib/guards';
import {isTransportResponse} from 'lib/transport/types';

export function extractErrorType(error: unknown): string {
  if (error instanceof Error) {
    return 'generic';
  }

  if (isTransportResponse(error)) {
    if (isRecord(error.body) && isString(error.body.type)) {
      return error.body.type;
    }
    return `http${error.status}`;
  }

  if (isRecord(error) && isString(error.type)) {
    return error.type;
  }

  return 'unknown';
}
