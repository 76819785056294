import {config} from 'config';
import {useLanguage} from 'lib/language/hooks';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';

export type SupportEmailTestId = unknown;

type Props = TestIdProp<SupportEmailTestId> & {
  children?: React.ReactNode;
  className?: string;
  email?: string;
};

export function SupportEmail({children, email: emailFromProps, className, testId}: Props): React.ReactElement {
  const language = useLanguage();
  const emailFromConfig = config.supportEmail[language];
  const email = emailFromProps || emailFromConfig;

  return (
    <a className={className} data-test-id={testId} href={`mailto:${email}`} rel='noopener noreferrer' target='_blank'>
      {children || email}
    </a>
  );
}
