import {useContext} from 'react';
import {DevicevarsContext} from './contexts';
import {Devicevars} from './types';

export function useDevicevars(): Devicevars {
  const context = useContext(DevicevarsContext);

  if (!context) {
    throw new Error('Devicevars root object must be defined');
  }

  return context;
}
