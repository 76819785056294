import {useClient} from 'lib/client';
import React, {useMemo} from 'react';
import {DevicevarsContext} from './contexts';
import {Devicevars} from './types';

type Props = {
  children: React.ReactNode;
};

export function DevicevarsProvider({children}: Props): React.ReactElement {
  const client = useClient();

  const devicevars = useMemo<Devicevars>(
    () => client.config.devicevarsInfo?.devicevars || {},
    [client.config.devicevarsInfo?.devicevars],
  );

  return <DevicevarsContext.Provider value={devicevars}>{children}</DevicevarsContext.Provider>;
}
