import {sample} from 'effector';
import {globalSendFullCommunicationRequestFx} from 'lib/communication';
import {$appGetCookieManager} from 'lib/effector';
import {$promocodeTopBannerVisible, validateAndApplyPromocode, validatePromocodeFx, closeTopBanner} from './index';

sample({
  clock: validateAndApplyPromocode,
  filter: (getCookieManager, promocode) => !!promocode && getCookieManager().promocode !== promocode,
  fn: (getCookieManager, promocode) => promocode,
  source: $appGetCookieManager,
  target: validatePromocodeFx,
});

$promocodeTopBannerVisible.on(closeTopBanner, (state) => false).on(validatePromocodeFx.done, (state) => true);

$appGetCookieManager.on(validatePromocodeFx.done, (getCookieManager, {params: promocode}) => {
  // eslint-disable-next-line no-param-reassign
  getCookieManager().promocode = promocode;
  return getCookieManager;
});

sample({
  clock: globalSendFullCommunicationRequestFx.done,
  filter: (getCookieManager, {params}) => getCookieManager().promocode === params.promocode,
  fn: () => undefined,
  source: $appGetCookieManager,
  target: closeTopBanner,
});
