import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {Client} from 'lib/client';
import {
  convertOriginalContentList,
  createTopProductsBannerItem,
  hasTopProducts,
  isContentListProductItem,
} from 'lib/contentList';
import {ContentList, OriginalContentList} from 'lib/contentList/types';
import {convertOriginalFiltersAndSorting, convertToOriginalSimpleFilterList} from 'lib/search';
import {
  OriginalFilterType,
  OriginalSearchRequestBody,
  OriginalSearchResponseBody,
  SearchParams,
  SearchResponseBody,
} from 'lib/search/types';

export function searchProducts(client: Client, params: SearchParams): Promise<SearchResponseBody<ContentList>> {
  const body: OriginalSearchRequestBody = {
    appearance: {
      productColumns: 12,
    },
    count: 36,
    searchResultsUniqId: params.searchResultsUniqId,
  };

  if (params.phrase) {
    body.query = params.phrase;
  }

  if (isNotNullish(params.count)) {
    body.count = params.count;
  }

  if (params.pageToken) {
    body.pageToken = params.pageToken;
  }

  if (params.sorting) {
    body.sorting = params.sorting;
  }

  if (params.filters) {
    body.filters = convertToOriginalSimpleFilterList(params.filters);
  }

  if (params.categoryId) {
    if (!body.filters?.some((item) => item.id === 'categoryId')) {
      if (!body.filters) {
        body.filters = [];
      }

      body.filters.push({
        id: 'categoryId',
        value: {
          items: [{id: params.categoryId}],
          type: OriginalFilterType.CATEGORIES,
        },
      });
    }
  }

  if (params.storeId) {
    if (!body.filters?.some((item) => item.id === 'storeId')) {
      if (!body.filters) {
        body.filters = [];
      }

      body.filters.push({
        id: 'storeId',
        value: {
          items: [{id: params.storeId}],
          type: OriginalFilterType.STORES,
        },
      });
    }
  }

  const isImageSearch = Boolean(params.imageId);
  if (isImageSearch) {
    body.embeddingId = params.imageId;
  }

  return client.mainApi
    .post<OriginalSearchResponseBody<OriginalContentList>>(isImageSearch ? 'search/byImage' : 'search/content', {body})
    .then(({body: {payload}}) => {
      const items = convertOriginalContentList(payload.items);
      const enhancedItems = items.map((item) => {
        if (isContentListProductItem(item)) {
          // eslint-disable-next-line no-param-reassign
          item.content.searchResultUniqId = payload.searchResultsUniqId;
        }

        return item;
      });

      if (items.length) {
        if (!body.pageToken && hasTopProducts(items)) {
          items.unshift(createTopProductsBannerItem());
        }
      }

      return {
        ...convertOriginalFiltersAndSorting(payload),
        filename: payload.filename,
        items: enhancedItems,
        nextPageToken: payload.nextPageToken,
        searchResultsUniqId: payload.searchResultsUniqId,
      };
    });
}
