import {Image, ImageTestId} from 'components/Image';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export type SplashContentTestId = {
  content: unknown;
  image: ImageTestId;
  message: unknown;
  title: unknown;
};

type Props = TestIdProp<SplashContentTestId> & {
  children?: React.ReactNode;
  image?: string;
  message?: ExtMessageDescriptor;
  messageValues?: Record<string, unknown>;
  title?: ExtMessageDescriptor;
};

export function SplashContent({children, image, message, messageValues, testId, title}: Props): React.ReactElement {
  const intl = useIntl();
  const compiledTitle = buildMessage(intl, title);
  const compiledMessage = buildMessage(intl, message, messageValues);

  return (
    <div className={styles.splashContent} data-test-id={testId}>
      {image && <Image className={styles.image} src={image} testId={testId?.image} />}
      {compiledTitle && (
        <div className={styles.title} data-test-id={testId?.title}>
          {compiledTitle}
        </div>
      )}
      {compiledMessage && (
        <div className={styles.message} data-test-id={testId?.message}>
          {compiledMessage}
        </div>
      )}
      {children && (
        <div className={styles.content} data-test-id={testId?.content}>
          {children}
        </div>
      )}
    </div>
  );
}
