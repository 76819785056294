import {CartItem, CartItemSelectedVariant, CartItemType} from 'lib/cart/types';
import {Client} from 'lib/client';
import {DeliveryChannelType} from 'lib/deliveryChannel/types';
import {extractPayload} from 'lib/transport/utils';

type AddCartPayload = {
  channelType: DeliveryChannelType;
  productId: string;
  selectedVariantItems: CartItemSelectedVariant[];
  type: CartItemType;
};

type ResponseBody = {
  payload: CartItem;
};

export function addCartItem(client: Client, params: AddCartPayload): Promise<CartItem> {
  const body: AddCartPayload = {
    ...params,
    // backend doesn't allow zero quantity
    selectedVariantItems: params.selectedVariantItems?.filter((variant) => variant.quantity > 0),
  };
  return client.mainApi
    .post<ResponseBody>('cart/add', {
      body,
    })
    .then(extractPayload);
}
