import {assertNever} from '@joomcode/deprecated-utils/types';
import {Category} from 'lib/category/types';
import {isNamedColor} from 'lib/color/types';
import {
  CheckboxFilter,
  FilterKind,
  FilterList,
  FilterType,
  FilterValueList,
  SearchTagFilter,
  MoneyRangeFilter,
  SortingItem,
  SortingList,
  TreeFilter,
  TreeFilterItem,
  TreeFilterValue,
  CheckboxFilterValue,
  MoneyRangeFilterValue,
} from 'lib/filtersAndSorting/types';
import {isArray} from 'lib/guards';
import {searchLogger} from 'lib/logger';
import keyBy from 'lodash/keyBy';
import {
  isOriginalFilter,
  OriginalFilter,
  OriginalFilterCheckboxValue,
  OriginalFilterColorItem,
  OriginalFilterColorValue,
  OriginalFilterList,
  OriginalFilterMoneyRangeValue,
  OriginalFilterSimpleValue,
  OriginalFilterTreeItem,
  OriginalFilterTreeValue,
  OriginalFilterType,
  OriginalSortingList,
  OriginalSimpleFilter,
  OriginalSimpleFilterList,
  OriginalFilterSearchTagValue,
  OriginalFilterTreeSimpleValue,
  OriginalFilterStoreSimpleValue,
  OriginalFilterColorSimpleValue,
  OriginalFilterCheckboxSimpleValue,
  OriginalFilterMoneyRangeSimpleValue,
} from '../types';

export function convertOriginalSorting(
  available?: OriginalSortingList,
  applied?: OriginalSortingList,
): SortingList | undefined {
  if (!isArray(available)) {
    return undefined;
  }

  const appliedMap = applied ? keyBy(applied, (item) => item.fieldName) : undefined;

  return available.map<SortingItem>((item) => {
    let itemApplied: boolean;

    if (appliedMap) {
      itemApplied = Boolean(item.fieldName in appliedMap && appliedMap[item.fieldName].order === item.order);
    } else {
      itemApplied = item.default;
    }

    return {
      ...item,
      applied: itemApplied,
      selected: itemApplied,
    };
  });
}

export function convertOriginalCheckboxFilter(
  {id, name, value, disabled}: OriginalFilter<OriginalFilterCheckboxValue>,
  appliedFilter?: OriginalFilter<OriginalFilterCheckboxValue>,
): CheckboxFilter {
  let appliedValue;
  let checked;

  if (appliedFilter) {
    checked = Boolean(appliedFilter.value.checked);
    appliedValue = {checked};
  } else {
    checked = Boolean(value.checked);
  }

  return {
    applied: Boolean(appliedValue),
    appliedValue,
    checked,
    disabled,
    id,
    kind: FilterKind.CHECKBOX,
    name,
    type: FilterType.CHECKBOX,
  };
}

export function convertOriginalCheckboxSimpleFilter({
  id,
  value,
}: OriginalSimpleFilter<OriginalFilterCheckboxSimpleValue>): CheckboxFilterValue {
  return {
    checked: value.checked,
    id,
    type: FilterType.CHECKBOX,
  };
}

export function convertOriginalMoneyRangeFilter(
  {id, name, value, disabled}: OriginalFilter<OriginalFilterMoneyRangeValue>,
  applied?: OriginalFilter<OriginalFilterMoneyRangeValue>,
): MoneyRangeFilter {
  let appliedValue;

  if (applied) {
    const {
      value: {min, max, currency},
    } = applied;
    appliedValue = {currency, max, min};
  }

  return {
    applied: Boolean(appliedValue),
    appliedValue,
    currency: appliedValue ? appliedValue.currency : value.currency,
    disabled,
    id,
    kind: FilterKind.MONEY_RANGE,
    max: appliedValue ? appliedValue.max : value.max,
    min: appliedValue ? appliedValue.min : value.min,
    name,
    type: FilterType.MONEY_RANGE,
  };
}

export function convertOriginalMoneyRangeSimpleFilter({
  id,
  value,
}: OriginalSimpleFilter<OriginalFilterMoneyRangeSimpleValue>): MoneyRangeFilterValue {
  return {
    currency: value.currency,
    id,
    max: value.max,
    min: value.min,
    type: FilterType.MONEY_RANGE,
  };
}

export function convertOriginalTreeFilter(
  {id, name, value, disabled}: OriginalFilter<OriginalFilterTreeValue>,
  appliedFilter?: OriginalFilter<OriginalFilterTreeValue>,
): TreeFilter {
  const appliedIds = (appliedFilter?.value.items || []).reduce((ids, item) => ids.add(item.id), new Set()) || {};

  const convertItems = (items: OriginalFilterTreeItem[], parentSelected: boolean): TreeFilterItem[] =>
    items.map<TreeFilterItem>((item) => {
      const applied = appliedIds.has(item.id);
      const selected = parentSelected || applied;

      return {
        applied,
        count: item.count,
        disabled: item.disabled,
        id: item.id,
        items: item.children ? convertItems(item.children, selected) : undefined,
        name: item.name,
        selected,
      };
    });

  return {
    applied: Boolean(appliedFilter),
    disabled,
    id,
    items: convertItems(value.items || [], false),
    kind: FilterKind.TREE,
    multiple: Boolean(value.supportsMultipleChoice),
    name,
    searchable: true,
    type: FilterType.TREE,
  };
}

export function convertOriginalTreeSimpleFilter({
  id,
  value,
}: OriginalSimpleFilter<OriginalFilterTreeSimpleValue>): TreeFilterValue {
  return {
    id,
    items: value.items.map((item) => ({id: item.id})),
    type: FilterType.TREE,
  };
}

export function convertOriginalColorFilter(
  {id, name, value, disabled}: OriginalFilter<OriginalFilterColorValue>,
  appliedFilter?: OriginalFilter<OriginalFilterColorValue>,
): TreeFilter {
  const appliedItemsMap = appliedFilter ? keyBy(appliedFilter.value.items, (item) => item.id) : {};

  const convertItems = (items: OriginalFilterColorItem[]): TreeFilterItem[] =>
    items.map((item) => {
      const applied = Boolean(appliedItemsMap[item.id]);
      const itemName =
        item.colors
          .reduce<string[]>((result, color) => {
            if (isNamedColor(color) && color.name) {
              result.push(color.name);
            }

            return result;
          }, [])
          .join(' / ') || 'unknown';

      return {
        applied,
        colors: item.colors,
        count: item.count,
        disabled: item.disabled,
        id: item.id,
        name: itemName,
        selected: applied,
      };
    });

  return {
    applied: Boolean(appliedFilter),
    disabled,
    id,
    items: convertItems(value.items || []),
    kind: FilterKind.TREE,
    multiple: Boolean(value.supportsMultipleChoice),
    name,
    searchable: true,
    type: FilterType.COLORS,
  };
}

export function convertOriginalColorSimpleFilter({
  id,
  value,
}: OriginalSimpleFilter<OriginalFilterColorSimpleValue>): TreeFilterValue {
  return {
    id,
    items: value.items.map((item) => ({id: item.id})),
    type: FilterType.COLORS,
  };
}

export function convertOriginalStoreSimpleFilter({
  id,
  value,
}: OriginalSimpleFilter<OriginalFilterStoreSimpleValue>): TreeFilterValue {
  return {
    id,
    items: value.items.map((item) => ({id: item.id})),
    type: FilterType.STORES,
  };
}

export function convertOriginalSearchTagFilter(
  {disabled, id, name}: OriginalFilter<OriginalFilterSearchTagValue>,
  appliedFilter?: OriginalFilter<OriginalFilterSearchTagValue>,
): SearchTagFilter {
  return {
    applied: Boolean(appliedFilter),
    disabled,
    id,
    items: appliedFilter?.value.items ?? [],
    kind: FilterKind.SEARCH_TAG,
    name,
    type: FilterType.SEARCH_TAG,
  };
}

type ConvertOriginalFiltersReturn = {
  categories?: Category[];
  currentCategory?: Category;
  filterList: FilterList;
};

export function convertOriginalFilters(
  available: OriginalFilterList,
  applied?: OriginalFilterList,
): ConvertOriginalFiltersReturn {
  const appliedMap = applied ? keyBy(applied, (item) => item.id) : {};
  const filterList: FilterList = [];
  let categories: Category[] | undefined;
  let currentCategory: Category | undefined;

  available.forEach((item) => {
    if (isOriginalFilter(item, OriginalFilterType.TREE)) {
      filterList.push(
        convertOriginalTreeFilter(item, appliedMap[item.id] as OriginalFilter<OriginalFilterTreeValue> | undefined),
      );
    } else if (isOriginalFilter(item, OriginalFilterType.COLORS)) {
      filterList.push(
        convertOriginalColorFilter(item, appliedMap[item.id] as OriginalFilter<OriginalFilterColorValue> | undefined),
      );
    } else if (isOriginalFilter(item, OriginalFilterType.MONEY_RANGE)) {
      filterList.push(
        convertOriginalMoneyRangeFilter(
          item,
          appliedMap[item.id] as OriginalFilter<OriginalFilterMoneyRangeValue> | undefined,
        ),
      );
    } else if (isOriginalFilter(item, OriginalFilterType.CHECKBOX)) {
      filterList.push(
        convertOriginalCheckboxFilter(
          item,
          appliedMap[item.id] as OriginalFilter<OriginalFilterCheckboxValue> | undefined,
        ),
      );
    } else if (isOriginalFilter(item, OriginalFilterType.CATEGORIES)) {
      categories = item.value.items;
    } else if (isOriginalFilter(item, OriginalFilterType.SEARCH_TAG)) {
      filterList.push(
        convertOriginalSearchTagFilter(
          item,
          appliedMap[item.id] as OriginalFilter<OriginalFilterSearchTagValue> | undefined,
        ),
      );
    }
  });

  if (appliedMap.categoryId && isOriginalFilter(appliedMap.categoryId, OriginalFilterType.CATEGORIES)) {
    currentCategory = appliedMap.categoryId.value.items?.[0];
  }

  return {categories, currentCategory, filterList};
}

export function convertToOriginalSimpleFilterList(filters: FilterValueList): OriginalSimpleFilterList {
  return filters.reduce<OriginalSimpleFilterList>((result, filter) => {
    let value: OriginalFilterSimpleValue | undefined;

    switch (filter.type) {
      case FilterType.TREE: {
        value = {
          items: filter.items,
          type: OriginalFilterType.TREE,
        };
        break;
      }

      case FilterType.MONEY_RANGE: {
        value = {
          currency: filter.currency,
          max: filter.max,
          min: filter.min,
          type: OriginalFilterType.MONEY_RANGE,
        };
        break;
      }

      case FilterType.CHECKBOX: {
        value = {
          checked: filter.checked,
          type: OriginalFilterType.CHECKBOX,
        };
        break;
      }

      case FilterType.COLORS: {
        value = {
          items: filter.items,
          type: OriginalFilterType.COLORS,
        };
        break;
      }

      case FilterType.STORES: {
        value = {
          items: filter.items,
          type: OriginalFilterType.STORES,
        };
        break;
      }

      case FilterType.SEARCH_TAG: {
        value = {
          items: filter.items,
          type: OriginalFilterType.SEARCH_TAG,
        };
        break;
      }

      default:
        searchLogger.error('Unsupported FilterType', filter);
        assertNever(filter);
    }

    if (value) {
      result.push({id: filter.id, value});
    }

    return result;
  }, []);
}
