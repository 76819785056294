import {SuggestContext} from 'lib/finalForm/contexts';
import {SuggestState} from 'lib/finalForm/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useMemo} from 'react';
import {Form, Props as FormProps} from '../Form';

type Props<Values> = TestIdProp & FormProps<Values> & SuggestState;

export function FormSuggest<Values>({
  loading,
  values,
  className,
  messages = {},
  initialValues,
  suggestions,
  children,
  errors,
  testId,
  onSubmit,
  onSuggest,
  onSelectSuggest,
  renderSuggestLabel,
}: Props<Values>): React.ReactElement {
  const suggestProps: SuggestState = useMemo(
    () => ({
      onSelectSuggest,
      onSuggest,
      renderSuggestLabel,
      suggestions,
    }),
    [onSuggest, suggestions, onSelectSuggest, renderSuggestLabel],
  );

  return (
    <SuggestContext.Provider value={suggestProps}>
      <Form
        className={className}
        errors={errors}
        initialValues={initialValues}
        loading={loading}
        messages={messages}
        onSubmit={onSubmit}
        testId={testId}
        values={values}
      >
        {children}
      </Form>
    </SuggestContext.Provider>
  );
}
