import uuid from 'uuid';
import {ItemContentType} from '../types';
import {TopProductsBannerItemCreator} from './types';

export const createTopProductsBannerItem: TopProductsBannerItemCreator = () => {
  return {
    content: undefined,
    id: uuid(),
    type: ItemContentType.TOP_PRODUCTS_BANNER,
  };
};
