import {AnalyticsEvent} from 'events/index';
import type {Client} from 'lib/client';
import {SECOND} from 'lib/datetime';
import {isFunction} from 'lib/guards';
import {analyticsLogger} from 'lib/logger';
import {safeDumpTransportResponse} from 'lib/logger/converters';
import {getPageNameByPathname} from 'lib/router';
import {isTransportResponse} from 'lib/transport/types';
import uuid from 'uuid/v4';
import {SendEventOptions} from '../types';

export {isEventsEnabled} from './isEventsEnabled';

type ExtendedAnalyticsEvent = AnalyticsEvent & {
  id: string;
  ts: number;
};

type AnalyticsRequestPayload = {
  configId?: string;
  payload: ExtendedAnalyticsEvent[];
};

const API_PATH = 'events';
const DELAY_MS = 10 * SECOND;
const QUEUE_MAX_SIZE = 10;

export class EventsQueuePlugin {
  private readonly client: Client;

  private beaconEnabled: boolean;

  private queue: ExtendedAnalyticsEvent[];

  private timerId: number | undefined;

  private enabledValue: boolean;

  constructor(client: Client) {
    this.client = client;
    this.beaconEnabled = false;
    this.enabledValue = false;
    this.queue = [];

    if (__CLIENT__) {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          this.beaconEnabled = true;
          this.sendQueue();
        } else {
          this.beaconEnabled = false;
        }
      });
      window.addEventListener('beforeunload', () => {
        const {router} = this.client.config;
        this.push({
          payload: {
            pageName: router && getPageNameByPathname(router.pathname),
            pageUrl: window.location.href,
          },
          type: 'tabClose',
        });

        this.beaconEnabled = true;
        this.sendQueue();
      });
    }
  }

  get enabled(): boolean {
    return this.enabledValue;
  }

  set enabled(value: boolean) {
    this.enabledValue = value;
  }

  push(event: AnalyticsEvent, options: SendEventOptions = {}): void {
    if (!this.enabledValue) {
      return;
    }

    if (__SERVER__) {
      analyticsLogger.error(`Can not push event '${event.type}' on the server`);
      return;
    }

    this.queue.push({
      id: uuid(),
      ts: Date.now(),
      ...event,
    });

    this.scheduleSending();

    if (this.beaconEnabled || options.immediately || this.queue.length >= QUEUE_MAX_SIZE) {
      this.sendQueue();
    }
  }

  private getRequestPayload(): AnalyticsRequestPayload {
    if (!this.client.config.devicevarsInfo?.configId) {
      analyticsLogger.log('No configId while sending analytic events');
    }
    return {
      configId: this.client.config.devicevarsInfo?.configId,
      payload: this.queue,
    };
  }

  private async sendQueueWithBeacon(): Promise<void> {
    const apiPath = this.client.mainApi.getUrl({path: API_PATH}).toString();
    const serializedData = JSON.stringify(this.getRequestPayload());

    let sentWithBeacon = false;
    if (isFunction(navigator.sendBeacon)) {
      try {
        // mobile chrome works weird with application/json and we had a lot of beacon errors in sentry (http://crbug.com/490015)
        // backend parse text/plain correctly, so, we can send events this way
        sentWithBeacon = navigator.sendBeacon(apiPath, serializedData);
      } catch (error) {
        analyticsLogger.error('Error while sending events with beacon', error);
      }
    }

    if (!sentWithBeacon) {
      try {
        const response = await fetch(apiPath, {body: serializedData, keepalive: true, method: 'POST'});
        if (response.status !== 200) {
          throw new Error(`Bad status code ${response.status}`);
        }
      } catch (error) {
        analyticsLogger.error('Error while sending events with keepalive fetch', error);
      }
    }
  }

  private async sendQueueWithTransport(): Promise<void> {
    try {
      await this.client.mainApi.post(API_PATH, {
        body: this.getRequestPayload(),
      });
    } catch (error) {
      analyticsLogger.error(
        'Can not post events queue',
        isTransportResponse(error) ? safeDumpTransportResponse(error) : error,
      );
    }
  }

  private sendQueue(): void {
    if (!this.enabledValue || this.queue.length === 0) {
      return;
    }

    if (this.beaconEnabled) {
      this.sendQueueWithBeacon();
    } else {
      this.sendQueueWithTransport();
    }

    this.queue = [];
  }

  private scheduleSending(): void {
    if (__CLIENT__) {
      window.clearTimeout(this.timerId);
      this.timerId = window.setTimeout(() => this.sendQueue(), DELAY_MS);
    }
  }
}
