import {isRootCategory} from 'lib/category/types';
import {$categories, loadRootCategoriesFx} from './index';

$categories
  .on(loadRootCategoriesFx, (state) => ({
    ...state,
    loading: true,
    loadingError: undefined,
  }))
  .on(loadRootCategoriesFx.doneData, (state, category) => {
    const categories = isRootCategory(category) ? category.children : undefined;

    return {
      ...state,
      categories,
      loading: false,
    };
  })
  .on(loadRootCategoriesFx.failData, (state, error) => {
    return {
      ...state,
      loading: false,
      loadingError: error,
    };
  });
