import {DataState} from '@joomcode/deprecated-utils/dataState';
import {contactUs} from 'api/selfService/contactUs';
import {createStore, createClientEffect, createEvent} from 'lib/effector';
import {ContactUsDestination} from 'lib/selfService/types';
import {createRegistrationRequiredEvent} from 'models/registrationCallback/utils';

type ContactUsDialogState = {
  destination?: ContactUsDestination;
  isOpen: boolean;
  requestError?: unknown;
  requestState: DataState;
  source?: string;
};

const initialState: ContactUsDialogState = {
  isOpen: false,
  requestState: DataState.IDLE,
};

export const $contactUsDialog = createStore<ContactUsDialogState>('contactUsDialog', initialState);

export const openContactUsDialogUnsafe = createEvent<ContactUsDestination | void>();

export const openContactUsDialog = createRegistrationRequiredEvent(openContactUsDialogUnsafe);

export const closeContactUsDialog = createEvent();

export const sendContactUsRequestFx = createClientEffect(contactUs);
