import {checkCnpj} from 'lib/cnpj/checkCnpj';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {defineMessage} from 'react-intl';

const errorMessage = defineMessage({
  defaultMessage: 'Number is incorrect',
  description: 'Error for incorrect cnpj',
});

export function validateCnpj(cnpj: unknown): ExtMessageDescriptor | undefined {
  return checkCnpj(cnpj) ? undefined : errorMessage;
}
