import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$selfServicePromotions, loadMainPromotionFx, resetMainPromotions} from './index';

$selfServicePromotions.reset(resetMainPromotions);
$selfServicePromotions
  .on(loadMainPromotionFx, (state) => ({...state, dataState: DataState.LOADING}))
  .on(loadMainPromotionFx.failData, (state) => ({...state, dataState: DataState.FAILED}))
  .on(loadMainPromotionFx.doneData, (state, result) => ({
    ...state,
    dataState: DataState.LOADED,
    promotions: [...state.promotions, result],
  }));
