import {loadCategory} from 'api/categories';
import {Category} from 'lib/category/types';
import {createStore, createEvent, createClientEffect} from 'lib/effector';

type CategoryState = {
  category?: Category;
  loading: boolean;
  loadingError?: Error;
};

const initialCategoryState = {
  loading: false,
};

export const $category = createStore<CategoryState>('category', initialCategoryState);

export const loadCategoryFx = createClientEffect(loadCategory);

export const resetCategory = createEvent();
