import {Selection} from '../common';
import {InputType} from '../types';

export type Params = {
  selectionAfter: Selection;
  selectionBefore: Selection;
  valueAfter: string;
  valueBefore: string;
};

export function getInputType(params: Params): InputType {
  const {valueAfter, valueBefore, selectionAfter, selectionBefore} = params;

  const {start, end} = selectionBefore;
  const lenDiff = valueBefore.length - valueAfter.length;
  const hasSelection = end - start > 0;

  if (hasSelection) {
    return lenDiff === end - start ? InputType.DELETE_CHAR_BACKWARD : InputType.INSERT;
  }

  if (valueAfter.length > valueBefore.length) {
    return InputType.INSERT;
  }

  if (valueAfter.length < valueBefore.length) {
    if (start === selectionAfter.start) {
      return InputType.DELETE_CHAR_FORWARD;
    }

    return lenDiff > 1 ? InputType.DELETE_CONTENT_BACKWARD : InputType.DELETE_CHAR_BACKWARD;
  }

  return InputType.NONE;
}
