export class Definition {
  readonly char: string;

  readonly regexp: RegExp;

  constructor(char: string, regexp: RegExp) {
    this.char = char;
    this.regexp = regexp;
  }
}
