import {defineMessage, defineMessages} from 'react-intl';

export const messages = {
  error: defineMessages({
    required: {
      defaultMessage: 'Enter your first name',
      description: 'General field "First name". Error for empty value',
    },
  }),

  label: defineMessage({
    defaultMessage: 'First name',
    description: 'General field "First name". Signature',
  }),

  placeholder: defineMessage({
    defaultMessage: 'Enter your first name',
    description: 'General field "First name". Clue',
  }),
};
