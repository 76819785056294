import {PageLeaveDialog as PageLeaveDialogComponent} from 'components/PageLeaveDialog';
import {getMonthFromDate} from 'lib/datetime';
import {useEvent, useStore} from 'lib/effector';
import {useFormSuccessEvent} from 'lib/hooks';
import {$pageLeaveDialog, closePageLeaveDialog, sendPageLeaveDataFx} from 'models/pageLeave';
import {$serverDate} from 'models/serverDate';
import React from 'react';
import {rootTestId} from 'testId';

export function PageLeaveDialog(): React.ReactElement | null {
  const dialogState = useStore($pageLeaveDialog);
  const handleClose = useEvent(closePageLeaveDialog);
  const handleSubmit = useEvent(sendPageLeaveDataFx);
  const serverDate = useStore($serverDate);
  const {onSuccess} = useFormSuccessEvent();

  const currentMonth = getMonthFromDate(new Date(serverDate));

  if (!dialogState.opened) {
    return null;
  }

  return (
    <PageLeaveDialogComponent
      currentMonth={currentMonth}
      error={dialogState.error}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      processing={dialogState.processing}
      reason={dialogState.reason}
      success={dialogState.success}
      testId={rootTestId?.pageLeaveDialog}
    />
  );
}
