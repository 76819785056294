import {isRecord} from 'lib/guards';
import {ErrorType, GeneralError, FormValidationError} from './types';

export function isGeneralError(error: unknown): error is GeneralError {
  return isRecord(error) && error.type === ErrorType.GENERAL;
}

export function isFormValidationError(error: unknown): error is FormValidationError {
  return isRecord(error) && error.type === ErrorType.FORM_VALIDATION;
}
