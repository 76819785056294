import classNamesBind from 'classnames/bind';
import {Portal} from 'components/Portal';
import React, {useEffect} from 'react';
import FocusLock from 'react-focus-lock';
import {RemoveScroll} from 'react-remove-scroll';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  /**
   * Locks focus inside component.
   * See more: https://github.com/theKashey/react-focus-lock
   */
  focusLock?: boolean;
  inPlace?: boolean;
  onClose?: () => void;
  /**
   * Disables scroll outside of component.
   * See more: https://github.com/theKashey/react-remove-scroll
   */
  scrollLock?: boolean;
  transparent?: boolean;
};

export function ModalOverlay({
  inPlace,
  onClose,
  children,
  disabled,
  transparent,
  focusLock = false,
  scrollLock = false,
}: Props): React.ReactElement {
  const active = !disabled;

  useEffect(() => {
    if (!active || !onClose) {
      return undefined;
    }

    const handleKey = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        if (document.activeElement instanceof HTMLElement) {
          // Hack to prevent scrolling after closing in Safari
          document.activeElement.blur();
        }
        onClose();
      }
    };

    window.addEventListener('keydown', handleKey);

    return () => window.removeEventListener('keydown', handleKey);
  }, [active, onClose]);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  const content = (
    <FocusLock disabled={!focusLock}>
      <RemoveScroll enabled={scrollLock}>
        <div className={cn('modalOverlay', {active})}>
          <div className={cn('scrim', {active, transparent})} onClick={onClose} role='button' tabIndex={-1} />
          {children}
        </div>
      </RemoveScroll>
    </FocusLock>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events */

  if (inPlace) {
    return content;
  }

  return <Portal>{content}</Portal>;
}
