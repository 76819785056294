import {$samplePrices, loadSamplePricesWithReqIdFx, resetSamplePrices} from './index';

$samplePrices.reset(resetSamplePrices);
$samplePrices
  .on(loadSamplePricesWithReqIdFx, (state, params) => {
    return {
      ...state,
      loading: true,
      loadingError: undefined,
      requestId: params.requestId,
    };
  })
  .on(loadSamplePricesWithReqIdFx.done, (state, {params, result: prices}) => {
    if (state.requestId === params.requestId) {
      return {
        ...state,
        loading: false,
        prices,
      };
    }

    return state;
  })
  .on(loadSamplePricesWithReqIdFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }));
