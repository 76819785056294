import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export type SubActionTestId = unknown;

type Props = TestIdProp<SubActionTestId> & {
  children?: React.ReactNode;
  label?: ExtMessageDescriptor;
  onClick?: () => void;
};

export function SubAction({children, label, onClick, testId}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <button className={styles.subAction} data-test-id={testId} onClick={onClick} type='button'>
      {buildMessage(intl, label) || children}
    </button>
  );
}
