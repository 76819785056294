export {convertToSearchSuggestion, convertToSearchSuggestionText, convertToSearchSuggestionStore} from './converters';

export {SearchSuggestionKind} from './types';

export type {SearchSuggestion, SearchSuggestionText, SearchSuggestionStore} from './types';

export type {
  OriginalSearchSuggestion,
  OriginalSearchSuggestionText,
  OriginalSearchSuggestionStore,
} from './types/original';
