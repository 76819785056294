import {Client} from 'lib/client';
import {ProductTranslations, ProductTranslationsTarget} from 'lib/product/types';

type TranslationsRequestParams = {
  attempt?: number;
  productId: string;
  target?: ProductTranslationsTarget;
};

type TranslationsResponse = {
  payload: ProductTranslations;
};

export function loadImagesTranslations(
  client: Client,
  {target, ...restParams}: TranslationsRequestParams,
): Promise<ProductTranslations> {
  const body = {
    target: target ?? 'all',
    ...restParams,
  };

  return client.mainApi
    .post<TranslationsResponse>('products/getImagesTranslations', {body})
    .then(({body: {payload}}): ProductTranslations => payload);
}
