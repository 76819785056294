import type {Client} from 'lib/client';
import {Preferences} from 'lib/preferences/types';

type PreferencesBody = {
  payload: Preferences;
};

export function savePreferences(client: Client, preferences: Partial<Preferences>): Promise<Preferences> {
  return client.mainApi
    .put<PreferencesBody>('device/preferences', {body: preferences})
    .then(({body: {payload}}) => payload);
}
