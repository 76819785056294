import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {HOUR} from 'lib/datetime';
import {useTimer} from 'lib/hooks';
import {useCallbackWithoutArgs} from 'lib/hooks/useCallbackWithoutArgs';
import {TestIdProp} from 'lib/testing/types';
import React, {useEffect} from 'react';
import {defineMessages, FormattedMessage, FormattedDate} from 'react-intl';
import {DotsLoading} from 'uikit/DotsLoading';
import {ViewError} from '../../../View';
import styles from './index.module.scss';

function hasHours(value: number): boolean {
  return value >= HOUR;
}

const messages = defineMessages({
  button: {
    defaultMessage: 'Get a new code',
    description: 'Login and registration form. Code entry step: Retrieve code button',
  },
  error: {
    defaultMessage: 'Could not retrieve code. Try again or come back later',
    description: 'Login and registration form. Code entry step: general error',
  },
  hours: {
    defaultMessage: 'You can request a new code in {left}',
    description: 'Login and registration form. Code entry step: remaining time',
  },
  minutes: {
    defaultMessage: 'You can request a new code in <time>{left, time, ::mm:ss}</time>',
    description: 'Login and registration form. Code entry step: remaining time',
  },
});

export type OtpResendTestId = {
  button: unknown;
  text: unknown;
};

type Props = TestIdProp<OtpResendTestId> & {
  awaitingTime: number;
  error?: unknown;
  loading?: boolean;
  onResend(): void;
};

export function OtpResend({awaitingTime, error, testId, onResend, loading}: Props): React.ReactElement {
  const {time, isComplete, start, cancel} = useTimer();
  const handleResend = useCallbackWithoutArgs(onResend);
  const hasError = isNotNullish(error);

  useEffect(() => {
    if (!loading && !hasError) {
      cancel();
      start(awaitingTime);
    }
  }, [loading]);

  const content =
    isComplete || hasError ? (
      <button
        className={styles.button}
        data-test-id={testId?.button}
        disabled={loading}
        onClick={handleResend}
        type='button'
      >
        {loading ? <DotsLoading /> : <FormattedMessage {...messages.button} />}
      </button>
    ) : (
      <span className={styles.text} data-test-id={testId?.text}>
        {hasHours(time) ? (
          <FormattedMessage
            {...messages.hours}
            values={{
              left: (
                <div>
                  <FormattedDate timeStyle='medium' timeZone='UTC' value={time} />
                </div>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            {...messages.minutes}
            values={{
              left: time,
              time: (value: React.ReactNode) => <div>{value}</div>,
            }}
          />
        )}
      </span>
    );

  return (
    <div data-test-id={testId}>
      {content}
      {hasError && <ViewError error={messages.error} />}
    </div>
  );
}
