import {SubmissionErrors, FormApi} from 'final-form';
import {useRef, useCallback, useEffect} from 'react';
import {FormProps} from 'react-final-form';

type SetErrorsHandler = (errors?: SubmissionErrors) => void;
type Props<Values> = {
  errors?: Partial<Record<keyof Values, string>>;
  onSubmit: FormProps<Values>['onSubmit'];
};

type SubmitHandler<Values> = (values: Values, form: FormApi<Values>, callback?: SetErrorsHandler) => void;

export function useSubmitHandler<Values>({onSubmit, errors}: Props<Values>): SubmitHandler<Values> {
  const setErrors = useRef<SetErrorsHandler | undefined>(undefined);

  /**
   * This wrapper need to save callback which sets errors to the form.
   * See third argument in doc: https://final-form.org/docs/react-final-form/types/FormProps#onsubmit
   * After saving this callback, on each change errors object,
   * it will be triggered and sets errors to the form.
   */
  const submitHandler = useCallback<SubmitHandler<Values>>(
    (values, form, callback) => {
      onSubmit(values, form, callback);

      if (callback) {
        setErrors.current = callback;
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    if (setErrors.current) {
      setErrors.current(errors);
    }
  }, [errors]);

  return submitHandler;
}
