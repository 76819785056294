import {TransportResponse} from 'lib/transport/types';
import pick from 'lodash/pick';

export function safeDumpTransportResponse(response: TransportResponse): Record<string, unknown> {
  const typeOfBody = Object.prototype.toString.call(response.body);

  return {
    ...pick(response, ['url', 'method', 'status', 'statusText', 'requestId', 'body.type']),
    typeOfBody,
  };
}
