import {Client} from 'lib/client';
import {DeliveryChannelType} from 'lib/deliveryChannel/types';

type Payload = {
  channelType: DeliveryChannelType;
  ids: string[];
};

type ResponseBody = {
  payload: {
    ids: string[];
  };
};

export function removeCartItems(client: Client, payload: Payload): Promise<string[]> {
  return client.mainApi.post<ResponseBody>('cart/remove', {body: payload}).then(({body}) => body.payload.ids);
}
