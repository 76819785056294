import {AppEnvContext, isEqualAppEnvValues} from 'lib/appEnv';
import {AppEnv as AppEnvType} from 'lib/appEnv/types';
import React, {useContext, useRef} from 'react';

type Props = {
  children: React.ReactNode;
  env?: Partial<AppEnvType>;
};

const INITIAL_APP_ENV: AppEnvType = {
  currentPageName: 'unknown',
};

export function AppEnv({children, env}: Props): React.ReactElement {
  const parentAppEnv = useContext(AppEnvContext);
  const prevEnv = useRef<Partial<AppEnvType> | undefined>(undefined);
  const resultEnv = useRef<AppEnvType | undefined>(undefined);

  if (parentAppEnv === undefined || !isEqualAppEnvValues(env, prevEnv.current)) {
    resultEnv.current = {...INITIAL_APP_ENV, ...parentAppEnv, ...env};
    prevEnv.current = env;
  }

  return <AppEnvContext.Provider value={resultEnv.current}>{children}</AppEnvContext.Provider>;
}
