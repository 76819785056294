import {FieldLabel} from 'components/FieldLabel';
import React from 'react';
import styles from './index.module.scss';
import {RadioFieldProps, RadioField} from './RadioField';

export type RadioItemType<T extends string | number> = Pick<RadioFieldProps<T>, 'value' | 'label'>;

type RadioGroupFieldProps<T extends string | number> = {
  items: RadioItemType<T>[];
  label: string;
  name: string;
  required?: boolean;
};

export function RadioGroupField<T extends string | number>({
  label,
  items,
  name,
  required,
}: RadioGroupFieldProps<T>): React.ReactElement {
  return (
    <FieldLabel label={label}>
      {(props) => (
        <div className={styles.root} id={props.id} role='group'>
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key, react/jsx-props-no-spreading
            <RadioField key={index} name={name} required={required} {...item} />
          ))}
        </div>
      )}
    </FieldLabel>
  );
}
