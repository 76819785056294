import {loadPromotion, PromotionRequestPayload} from 'api/promotions';
import {attach, createEvent} from 'effector';
import {ContentList} from 'lib/contentList/types';
import {alignItemsByDivisor} from 'lib/contentList/utils';
import {createClientEffect, createStore} from 'lib/effector';
import {Promotion} from 'lib/promotion/types';

type PromotionState = {
  items?: ContentList;
  loading: boolean;
  loadingError?: Error;
  nextPageToken?: string;
  promotion?: Promotion;
  request?: PromotionRequestPayload;
  title?: string;
};

export const initialPromotionState: PromotionState = {
  loading: false,
};

export const $promotion = createStore<PromotionState>('promotion', initialPromotionState);

export const $promotionItems = $promotion.map(({items, nextPageToken}) => {
  // Pass all items if it is last page.
  // That means backend have no items to send to client,
  // and we don't need to filter current items manually.
  if (items?.length && nextPageToken !== undefined) {
    return alignItemsByDivisor(items);
  }

  return items;
});

export const resetPromotion = createEvent();

export const loadPromotionFx = createClientEffect(loadPromotion);

export const loadPromotionMoreFx = attach({
  effect: loadPromotionFx,
  mapParams: (params: void, {request, nextPageToken}) => {
    if (!request) {
      throw new Error("Can not load more promotion's items without initial promotion request");
    }
    return {...request, nextPageToken};
  },
  source: $promotion,
});
