import {useBaseUrl} from 'lib/baseUrl';
import {TestIdProp} from 'lib/testing/types';
import NextLink, {LinkProps as NextLinkProps} from 'next/link';
import React, {useMemo} from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';

export type LinkTestId = unknown;

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  Pick<NextLinkProps, 'href' | 'locale' | 'onClick'> &
  TestIdProp<LinkTestId> & {
    children?: React.ReactNode;
    message?: MessageDescriptor;
    tabIndex?: number;
    target?: LinkTarget;
  };

export const Link = React.forwardRef(function Link(
  {children, href, message, testId, target, locale, rel: rawRel, ...anchorProps}: LinkProps,
  ref: React.Ref<HTMLAnchorElement>,
): React.ReactElement {
  const baseUrl = useBaseUrl();
  const rel = useMemo(() => {
    if (rawRel) {
      return rawRel;
    }

    const targetUrl = new URL(href, baseUrl);
    return targetUrl.host !== baseUrl.host ? 'noopener noreferrer' : undefined;
  }, [baseUrl, href, rawRel]);

  return (
    <NextLink
      href={href}
      locale={locale}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...anchorProps}
      data-test-id={testId}
      ref={ref}
      rel={rel}
      target={target}
    >
      {message ? <FormattedMessage {...message} /> : children}
    </NextLink>
  );
});
