import cn from 'classnames';
import {Link} from 'components/Link';
import React from 'react';
import styles from './index.module.scss';
import {PromoLinkProps} from './types';

export const PromoLink = ({iconLeft, theme, underlined, ...restProps}: PromoLinkProps): React.ReactElement => {
  return (
    <Link
      className={cn(styles.link, {
        [styles.underlined]: underlined,
        [styles.themeDefault]: theme === 'default',
        [styles.themeInverted]: theme === 'inverted',
        [styles.themeRed]: theme === 'red',
      })}
      /* eslint-disable react/jsx-props-no-spreading */
      {...restProps}
      /* eslint-enable react/jsx-props-no-spreading */
    >
      {iconLeft && <span className={styles.iconLeft}>{iconLeft}</span>}
      <span className={styles.content}>{restProps.children}</span>
    </Link>
  );
};
