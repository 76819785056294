import {InputBaseComponentProps} from '@mui/material';
import React, {useCallback} from 'react';
import {IMaskInput} from 'react-imask';

export const CnpjInput: React.ElementType<InputBaseComponentProps> = React.forwardRef(
  (props, ref: React.Ref<HTMLInputElement>) => {
    const {children, onChange, ...rest} = props;
    const handleAccept = useCallback(
      (value: string) => {
        onChange?.({target: {value}} as React.ChangeEvent<HTMLInputElement>);
      },
      [onChange],
    );
    return <IMaskInput inputRef={ref} {...rest} mask='00.000.000/0000-00' onAccept={handleAccept} unmask={true} />;
  },
);
