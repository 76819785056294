import {Category} from 'lib/category/types';
import {Client} from 'lib/client';
import {loadCategoriesHierarchy} from './loadCategoriesHierarchy';

type Payload = {
  categoryId: string;
  levels?: number;
  parentLevels?: number;
};

export function loadCategory(client: Client, payload: Payload): Promise<Category> {
  return loadCategoriesHierarchy<Category>(client, payload);
}
