import {isRecord} from 'lib/guards';
import getConfig from 'next/config';
import {AnalyticsGtmMode, ReleaseStage, PublicRuntimeConfig, ServerRuntimeConfig} from './types';

const {publicRuntimeConfig = {}, serverRuntimeConfig = {}} = getConfig() || {};

if (!isRecord(publicRuntimeConfig) || !isRecord(serverRuntimeConfig)) {
  throw new Error('[CONFIG] PublicRuntimeConfig and ServerRuntimeConfig must be objects');
}

export function getPublicRuntimeConfig(): PublicRuntimeConfig {
  return publicRuntimeConfig;
}

export function getServerRuntimeConfig(): ServerRuntimeConfig {
  return serverRuntimeConfig;
}

export function getNormalizedAnalyticsGtmMode(): AnalyticsGtmMode {
  switch (publicRuntimeConfig.analyticsGtmMode) {
    case 'smartEnabled':
      return 'smartEnabled';
    case 'alwaysEnabled':
      return 'alwaysEnabled';
    default:
    // do-nothing
  }

  return 'disabled';
}

export function getNormalizedReleaseStage(): ReleaseStage {
  switch (getPublicRuntimeConfig().releaseStage) {
    case 'development':
      return 'development';
    case 'production':
      return 'production';
    default:
    // do-nothing
  }

  return 'staging';
}
