import {DataState} from '@joomcode/deprecated-utils/dataState';
import {AuthOrigin} from 'components/Auth/types';
import {sample} from 'effector';
import {AuthViewName} from 'lib/auth/types';
import {$appGetLanguage, createEffect} from 'lib/effector';
import {Language} from 'lib/language/types';
import {User} from 'lib/user/types';
import {openAuthDialogInternal, openAuthDialog} from 'models/auth/dialog';
import {callRegistrationCallback, RegistrationQueueState} from 'models/registrationCallback';
import {$registrationCallback} from 'models/registrationCallback';
import {$user} from 'models/user';
import {
  $ssRegistrationDialog,
  $registrationOrigin,
  closeSsRegistrationDialog,
  openSsAuthDialog,
  openSsRegistrationDialog,
  OpenRegistrationDialogPayload,
  openSsRegistrationDialogUnsafe,
  updateUserFx,
  resetDataState,
  setSuccessVisible,
  setRegistrationOrigin,
  RegistrationOriginState,
  cancelSsRegistrationDialog,
} from '.';
import './timeoutedRegistration/init';

$ssRegistrationDialog
  .on(openSsRegistrationDialogUnsafe, (state, data) => ({
    ...state,
    ...data,
    opened: true,
  }))
  .on(resetDataState, (state) => ({
    ...state,
    dataState: DataState.IDLE,
    error: undefined,
  }))
  .on(setSuccessVisible, (state) => ({
    ...state,
    successVisible: true,
  }))
  .reset(closeSsRegistrationDialog);

$ssRegistrationDialog
  .on(updateUserFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(updateUserFx.doneData, (state) => ({
    ...state,
    dataState: DataState.LOADED,
  }))
  .on(updateUserFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));

$registrationOrigin.on(setRegistrationOrigin, (state, origin) => ({
  ...state,
  value: origin,
}));

type OnUserUpdateTargetArgs = {
  registrationCallback: RegistrationQueueState;
  registrationOrigin: RegistrationOriginState;
  user: User;
};

sample({
  clock: updateUserFx.doneData,
  fn: ([registrationCallback, registrationOrigin], user): OnUserUpdateTargetArgs => ({
    registrationCallback,
    registrationOrigin,
    user,
  }),
  source: [$registrationCallback, $registrationOrigin] as const,
  target: createEffect(({registrationCallback, registrationOrigin, user}: OnUserUpdateTargetArgs) => {
    if (!user.isSelfServiceDataFilled) {
      return;
    }

    if (registrationCallback.data) {
      closeSsRegistrationDialog();
      callRegistrationCallback({state: 'filled'});
      return;
    }

    if (registrationOrigin.value !== AuthOrigin.SELF_SERVICE) {
      setSuccessVisible();
    } else {
      closeSsRegistrationDialog();
    }
  }),
});

type OnCancelDialogTargetArgs = {
  registrationCallback: RegistrationQueueState;
};

sample({
  clock: cancelSsRegistrationDialog,
  fn: (registrationCallback): OnCancelDialogTargetArgs => ({registrationCallback}),
  source: $registrationCallback,
  target: createEffect(({registrationCallback}: OnCancelDialogTargetArgs) => {
    closeSsRegistrationDialog();

    if (registrationCallback.data) {
      callRegistrationCallback({state: 'cancel'});
    }
  }),
});

type OnOpenDialogTargetArgs = {
  params: OpenRegistrationDialogPayload | undefined;
  registrationCallback: RegistrationQueueState;
  user: User;
};

sample({
  clock: openSsRegistrationDialog,
  filter([, , getLanguage]) {
    return getLanguage() !== Language.RU;
  },
  fn: ([registrationCallback, user], params): OnOpenDialogTargetArgs => ({
    params,
    registrationCallback,
    user,
  }),
  source: [$registrationCallback, $user, $appGetLanguage] as const,
  target: createEffect(({registrationCallback, user, params}: OnOpenDialogTargetArgs) => {
    if (user.anonymous) {
      openAuthDialogInternal({
        origin: AuthOrigin.SELF_SERVICE,
        view: AuthViewName.SIGN_IN_BY_PHONE,
      });
      return;
    }

    if (user.isSelfServiceDataFilled) {
      if (registrationCallback.data) {
        callRegistrationCallback({state: 'filled'});
        return;
      }
      setSuccessVisible();
      return;
    }
    openSsRegistrationDialogUnsafe(params);
  }),
});

sample({
  clock: openSsAuthDialog,
  fn(registrationOrigin, source) {
    return {
      origin: registrationOrigin.value,
      source,
      view: AuthViewName.SIGN_IN_BY_PHONE,
    };
  },
  source: $registrationOrigin,
  target: openAuthDialog,
});
