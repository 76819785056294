import {config} from 'config';
import {useAsyncValidator, useFormMessages} from 'lib/finalForm/hooks';
import {BaseFieldProps} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import {useLanguage} from 'lib/language/hooks';
import {transformPhoneBeforeInput} from 'lib/mask';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {Field, FieldRenderProps} from 'react-final-form';
import {InputTestId, InputSize, InputFocusHandler, InputBlurHandler} from 'uikit/Input';
import {InputMask} from 'uikit/InputMask';

export type PhoneFieldTestId = InputTestId;

type Props = BaseFieldProps &
  TestIdProp<PhoneFieldTestId> & {
    onBlur?: InputBlurHandler;
    onFocus?: InputFocusHandler;
    size?: InputSize;
  };

export function PhoneField({
  size = 'medium',
  testId,
  name,
  label,
  required,
  readOnly,
  validate,
  onBlur,
  onFocus,
  ...props
}: Props): React.ReactElement {
  const language = useLanguage();
  const messages = useFormMessages();
  const phoneConfig = config.phone[language];
  const requiredMessage = props.requiredMessage || messages[name]?.required;
  const inputLabel = label || messages[name]?.label;

  const handleValidate = useAsyncValidator({required, requiredMessage, validate});
  const handleTransformBeforeInput = useCallback(
    (value: string) => {
      return transformPhoneBeforeInput(value, phoneConfig);
    },
    [phoneConfig],
  );

  return (
    <Field name={name} validate={handleValidate}>
      {({input, meta}: FieldRenderProps<string>) => {
        const handleBlur: InputBlurHandler = (event) => {
          input.onBlur(event);
          if (onBlur) {
            onBlur(event);
          }
        };

        // TODO: PRO-1572 New forms
        /* eslint-disable react/jsx-no-bind */
        return (
          <InputMask
            error={getErrorMessage(meta)}
            label={inputLabel}
            mask={phoneConfig.mask}
            name={input.name}
            onBlur={handleBlur}
            onChange={input.onChange}
            onFocus={onFocus}
            readOnly={readOnly}
            size={size}
            testId={testId}
            transformBeforeInput={handleTransformBeforeInput}
            type='phone'
            value={input.value}
          />
        );
        /* eslint-enable react/jsx-no-bind */
      }}
    </Field>
  );
}
