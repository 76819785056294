const NAME = 'CancelRegistrationError';

export class CancelRegistrationError extends Error {
  public constructor() {
    super(NAME);
    this.name = NAME;
    Object.setPrototypeOf(this, CancelRegistrationError.prototype);
  }
}

export const isCancelRegistrationError = (error?: unknown): error is CancelRegistrationError => {
  return (error as CancelRegistrationError)?.name === NAME;
};
