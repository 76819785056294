import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {defineMessages} from 'react-intl';
import {Tab, Tabs} from 'uikit/Tabs';
import {TabsChangeHandler} from 'uikit/Tabs/types';
import {AuthViewName, ViewChangeHandler} from '../types';

const messages = defineMessages({
  signIn: {
    defaultMessage: 'Sign In',
    description: 'Tabs on the login/registration form: “Login” tab',
  },
  signUp: {
    defaultMessage: 'Sign Up',
    description: 'Tabs on the login/registration form: “Registration” tab',
  },
});

export type SignTabsTestId = {
  signInTab: unknown;
  signUpTab: unknown;
};

type Props = TestIdProp<SignTabsTestId> & {
  onViewChange: ViewChangeHandler;
  view: AuthViewName;
};

export function SignTabs({onViewChange, testId, view}: Props): React.ReactElement {
  const handleChange = useCallback<TabsChangeHandler<AuthViewName>>(
    ({value}) => {
      if (value) {
        onViewChange(value);
      }
    },
    [onViewChange],
  );

  return (
    <Tabs<AuthViewName> onChange={handleChange} testId={testId} value={view}>
      <Tab label={messages.signIn} testId={testId?.signInTab} value={AuthViewName.SIGN_IN} />
      <Tab label={messages.signUp} testId={testId?.signUpTab} value={AuthViewName.SIGN_UP} />
    </Tabs>
  );
}
