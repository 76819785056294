import {ExtMessageDescriptor} from 'lib/intl/types';
import {MessageDescriptor} from 'react-intl';

export function isArray<T>(target: unknown): target is T[] {
  return Array.isArray(target);
}

export function isRecord<K extends string, V>(target: unknown): target is Record<K, V> {
  return typeof target === 'object' && target !== null && !isArray(target);
}

export function isNumber(target: unknown): target is number {
  return typeof target === 'number';
}

export function isBoolean(target: unknown): target is boolean {
  return typeof target === 'boolean';
}

export function isString(target: unknown): target is string {
  return typeof target === 'string';
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(target: unknown): target is Function {
  return typeof target === 'function';
}

export function isMessageDescriptor(target: unknown): target is MessageDescriptor {
  return isRecord(target) && 'defaultMessage' in target;
}

export function isExtMessageDescriptor(target: unknown): target is ExtMessageDescriptor {
  return isString(target) || isMessageDescriptor(target);
}
