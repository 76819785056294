import {config} from 'config';
import {AnalyticsEvent} from 'events/index';
import type {Client} from 'lib/client';
import {EventsQueuePlugin} from './events';
import {GtmPlugin} from './gtm';
import {GtmData} from './gtm/types';
import {AnalyticsInterface, SendEventOptions} from './types';

export class Analytics implements AnalyticsInterface {
  private readonly client: Client;

  private gtm: GtmPlugin;

  private eventsQueue: EventsQueuePlugin;

  constructor(client: Client) {
    this.client = client;
    this.gtm = new GtmPlugin({id: config.gtmId});
    this.eventsQueue = new EventsQueuePlugin(this.client);
  }

  enableGtm = (): void => {
    this.gtm.enabled = true;
  };

  disableGtm = (): void => {
    this.gtm.enabled = false;
  };

  dataLayer = (data: GtmData): void => {
    this.gtm.dataLayer(data);
  };

  enableEvents = (): void => {
    this.eventsQueue.enabled = true;
  };

  disableEvents = (): void => {
    this.eventsQueue.enabled = false;
  };

  sendEvent = (event: AnalyticsEvent, options?: SendEventOptions): void => {
    this.eventsQueue.push(event, options);
  };
}
