import {CartItem} from 'lib/cart/types';
import {Client} from 'lib/client';
import {DeliveryChannelType} from 'lib/deliveryChannel/types';
import {BodyPayloadItems} from 'lib/transport/types';
import {extractPayloadItems} from 'lib/transport/utils';

export type LoadCartItemsPayload = {
  channelType: DeliveryChannelType;
};

export function loadCartItems(client: Client, payload: LoadCartItemsPayload): Promise<CartItem[]> {
  return client.mainApi.post<BodyPayloadItems<CartItem[]>>('cart/list', {body: payload}).then(extractPayloadItems);
}
