import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {isArray} from 'lib/guards';
import {QueryParams} from './types';

export function removeTrailingSlash(url: string): string {
  return url.replace(/\/+$/, '');
}

export function buildCanonicalUrl(baseUrl: URL, path: string, locale?: string, defaultLocale?: string): string {
  const nextPath = removeTrailingSlash(`${locale && locale !== defaultLocale ? `/${locale}` : ''}${path}`);
  const url = new URL(nextPath, baseUrl);
  return url.toString();
}

export function appendQueryToPath(path: string, query?: string): string {
  return query ? `${path}${path.indexOf('?') === -1 ? '?' : '&'}${query}` : path;
}

export function buildQueryString(query?: QueryParams): string {
  if (!query) {
    return '';
  }

  const searchParams = new URLSearchParams();

  for (const key of Object.keys(query)) {
    const rootValue = query[key];
    if (isArray(rootValue)) {
      for (const value of rootValue) {
        if (isNotNullish(value)) {
          searchParams.append(key, String(value));
        }
      }
    } else if (isNotNullish(rootValue)) {
      searchParams.append(key, String(rootValue));
    }
  }

  return searchParams.toString();
}
