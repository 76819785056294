import {getEnumFormatter} from '@joomcode/deprecated-utils/intl/getEnumFormatter';
import {defineMessages} from 'react-intl';
import {CompanyOperationField} from '.';

export const messages = defineMessages<CompanyOperationField>({
  [CompanyOperationField.FACTORY]: {
    defaultMessage: 'Factory',
    description: 'User company operation field label',
  },
  [CompanyOperationField.MARKETPLACE_SELLER]: {
    defaultMessage: 'Marketplace seller',
    description: 'User company operation field label',
  },
  [CompanyOperationField.ONLINE_RETAILER]: {
    defaultMessage: 'Online retailer',
    description: 'User company operation field label',
  },
  [CompanyOperationField.OFFLINE_RETAILER]: {
    defaultMessage: 'Offline retailer',
    description: 'User company operation field label',
  },
  [CompanyOperationField.B2B_RESELLER]: {
    defaultMessage: 'B2B reseller',
    description: 'User company operation field label',
  },
});

export const formatСompanyOperationField = getEnumFormatter(messages);
