import {FormTexts} from 'components/Form/types';
import {messages as messagesEmail} from 'lib/form/messages/email';
import {messages as messagesPhone} from 'lib/form/messages/phone';
import {defineMessages, defineMessage} from 'react-intl';

const labels = {
  comment: defineMessage({
    defaultMessage: 'Comment',
    description: 'Field: "Comment"',
  }),
  companyName: defineMessage({
    defaultMessage: 'Company Name',
    description: 'Field: "Company Name"',
  }),

  description: defineMessage({
    defaultMessage: 'Order Details',
    description: 'Field: "Order information"',
  }),
  email: messagesEmail.label,
  firstName: defineMessage({
    defaultMessage: 'First Name',
    description: 'Field: "First name"',
  }),
  fullName: defineMessage({
    defaultMessage: 'Full Name',
    description: 'Field: "Last name and first name"',
  }),
  phone: messagesPhone.label,
  promocode: defineMessage({
    defaultMessage: 'Promo code',
    description: 'Field: "Promotional code"',
  }),
};

const placeholders = {
  comment: defineMessage({
    defaultMessage: 'Enter comment',
    description: 'Tooltip for the field: "Comment"',
  }),
  companyName: defineMessage({
    defaultMessage: 'Enter company name',
    description: 'Tooltip for the field: "Company Name"',
  }),

  description: defineMessage({
    defaultMessage: 'Enter order details',
    description: 'Tooltip for the field: "Order information"',
  }),
  email: messagesEmail.placeholder,
  firstName: defineMessage({
    defaultMessage: 'Enter your name',
    description: 'Hint for the field: "First name"',
  }),
  fullName: defineMessage({
    defaultMessage: 'Enter your full name',
    description: 'Hint for the field: "Last name and first name"',
  }),
  phone: messagesPhone.placeholder,
  promocode: defineMessage({
    defaultMessage: 'Enter Promo Code',
    description: 'Hint for the field: "Promotional code"',
  }),
};

const fieldErrors = {
  comment: defineMessage({
    defaultMessage: 'Enter comment',
    description: 'Error for an empty input field: "Comment"',
  }),
  companyName: defineMessage({
    defaultMessage: 'Enter company name',
    description: 'Error for an empty input field: "Company Name"',
  }),

  description: defineMessages({
    required: {
      defaultMessage: 'Enter order details',
      description: 'Error for an empty input field: "Order information"',
    },
  }),
  email: messagesEmail.error,
  firstName: defineMessages({
    required: {
      defaultMessage: 'Enter your name',
      description: 'Error for empty input field: "Name"',
    },
  }),
  fullName: defineMessages({
    required: {
      defaultMessage: 'Enter your full name',
      description: 'Error for empty input field: "Name"',
    },
  }),
  phone: messagesPhone.error,
  promocode: defineMessages({
    required: {
      defaultMessage: 'Enter Promo Code',
      description: 'Error for an empty input field: "Promotional code"',
    },
  }),
};

export const formTexts: FormTexts = {
  fieldErrors,
  labels,
  placeholders,
};
