"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentryProvider = void 0;
const sentry_1 = require("../sentry");
const Provider_1 = require("../providers/Provider");
const Level_1 = require("../common/Level");
const utils_1 = require("../utils");
class SentryProvider extends Provider_1.Provider {
    constructor(sentry, config) {
        super(config);
        this.sentry = sentry;
    }
    processInternal({ name, level, args, meta }) {
        if (level.value >= Level_1.Level.ERROR.value) {
            const ecs = args[0];
            if (typeof ecs === 'object') {
                const { error, ...rest } = ecs;
                // eslint-disable-next-line no-underscore-dangle
                const originalError = error?.__originalError;
                if (originalError && originalError instanceof Error) {
                    this.sentry.captureException(originalError, {
                        extra: {
                            ...meta,
                            ...rest,
                        },
                        level: sentry_1.levelMapping[level.name],
                        tags: {
                            from: 'logger',
                            logger: name,
                        },
                    });
                }
            }
        }
        else {
            this.sentry.captureMessage(args.map(utils_1.stringify).join(' '), {
                extra: meta,
                level: sentry_1.levelMapping[level.name],
                tags: {
                    from: 'logger',
                    logger: name,
                },
            });
        }
    }
}
exports.SentryProvider = SentryProvider;
