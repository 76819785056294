import {$productPrices, loadProductPricesWithReqIdFx, resetProductPrices} from './index';

$productPrices.reset(resetProductPrices);
$productPrices
  .on(loadProductPricesWithReqIdFx, (state, params) => {
    return {
      ...state,
      loading: true,
      loadingError: undefined,
      requestId: params.requestId,
    };
  })
  .on(loadProductPricesWithReqIdFx.done, (state, {params, result: prices}) => {
    if (state.requestId === params.requestId) {
      return {
        ...state,
        loading: false,
        prices,
      };
    }

    return state;
  })
  .on(loadProductPricesWithReqIdFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }));
