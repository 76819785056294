import merge from 'lodash/merge';
import {Selection} from './common';
import {MaskItem} from './MaskItem';
import {MaskManager} from './MaskManager';
import {MaskUtils} from './MaskUtils';
import {MaskInterface, MaskOptions} from './types';

type Options = MaskOptions & Required<Pick<MaskOptions, 'fallback' | 'alwaysShowMask'>>;

const defaultOptions: Options = {
  alwaysShowMask: true,
  fallback: '_',
};

export class Mask implements MaskInterface {
  private utils: MaskUtils;

  readonly options: Options;

  private manager: MaskManager;

  private selection: Selection;

  constructor(mask: string, options: MaskOptions = {}) {
    this.options = merge({}, defaultOptions, options);

    this.utils = new MaskUtils(this);
    this.selection = new Selection(0, 0);
    this.manager = new MaskManager(mask, {
      fallback: this.options.fallback,
      initialValue: this.options.initialValue,
    });
  }

  /**
   * Move cursor to the right editable position.
   *
   * @example for date format __/__/____
   * 1. __/__/__|__ -> |__/__/____
   * 2. 12/__/__|__ -> 12/|__/____
   * 3. 12/05|/____ -> 12/05/|____
   * 4. 12/0|5/____ -> 12/0|5/____
   */
  private shiftSelection(): void {
    const items = this.manager.getItems();
    const {start} = this.selection;
    const startItem = items[start];

    if (start > 0) {
      for (let item, i = 0; i < start; i++) {
        item = items[i];

        // Find first empty editable item before cursor
        if (item && item.isEditable() && !item.isFilled()) {
          this.selection.setSelection(i, i);
          return;
        }
      }
    }

    if (startItem && !startItem.isEditable()) {
      if (start < items.length - 1) {
        let rightPosition = start + 1;
        let nextRightItem = items[rightPosition];

        while (nextRightItem && !nextRightItem.isEditable()) {
          rightPosition += 1;
          nextRightItem = items[rightPosition];
        }

        if (nextRightItem) {
          this.selection.setSelection(rightPosition, rightPosition);
        }
      }
    }
  }

  /**
   * Clear current selection.
   *
   * @example for date format __/__/____
   * |12/__|/____ -> |__/__/____
   * |12/|34/5678 -> |34/56/78__
   * |12/__/____ -> |12/__/____
   */
  private clearSelection(): void {
    if (this.selection.hasSelection()) {
      const {start, end} = this.selection;

      const before = this.utils.substring(0, start);
      const after = this.utils.substring(end);

      this.manager.setValue(`${before}${after}`);
      this.setSelection(start);
      this.shiftSelection();
    }
  }

  /**
   * Returns position of cursor for first mask element that editable and empty
   *
   * @example for date format __/__/____
   * __/__/____ -> 0 -> |__/__/____
   * 1_/__/____ -> 1 -> 1|_/__/____
   * 12/__/____ -> 3 -> 12/|__/____
   * 12/34/5678 -> 10 -> 12/34/5678|
   */
  private getFirstEditableSelection(): Selection {
    const items = this.manager.getItems();
    const index = items.findIndex((item) => item.isEditable() && item.isEmpty());
    const start = index > -1 ? index : items.length;

    return new Selection(start, start);
  }

  // Utils methods
  toString(): string {
    return this.utils.toString();
  }

  toRawString(): string {
    return this.utils.toRawString();
  }

  getMask(): string {
    return this.utils.getMask();
  }

  getItems(): MaskItem[] {
    return this.manager.getItems();
  }

  getOptions(): MaskOptions {
    return this.options;
  }

  isEmpty(): boolean {
    return this.utils.isEmpty();
  }

  // Selection methods
  setSelection(start: number, end?: number): void {
    this.selection.setSelection(start, end || start);
  }

  getSelection(): Selection {
    return this.selection;
  }

  resetSelection(): void {
    const {start} = this.getFirstEditableSelection();
    this.setSelection(start);
  }

  // Change methods
  clear(): void {
    this.manager.clear();
  }

  setValue(value: string): void {
    this.manager.setValue(value);
  }

  input(value: string): void {
    this.clearSelection();
    this.shiftSelection();

    const items = this.manager.getItems();
    const {start, end} = this.selection;

    const tail = this.utils.substring(end);
    const chars = value.split('').reverse();
    const tailChars = tail.split('').reverse();

    let nextSelectionStart = start;
    let currentChar;

    items.slice(start).forEach((item) => {
      item.clear();

      // If chars is empty - break cycle.
      if (!chars.length) {
        return;
      }

      if (item.isEditable()) {
        // Take chars from queue
        // until it will be passed a check.
        do {
          currentChar = chars.pop();
        } while (currentChar && !item.test(currentChar));

        // If symbol have been found, he sets at the current position
        // and cursor shifts to one step right.
        if (currentChar) {
          item.setValue(currentChar);
          nextSelectionStart += 1;
        }
      } else {
        nextSelectionStart += 1;
      }
    });

    // Set symbols of the tail same way.
    // Selection doesn't change.
    if (tailChars.length) {
      items.slice(nextSelectionStart).forEach((item) => {
        if (tailChars.length) {
          if (item.isEditable()) {
            do {
              currentChar = tailChars.pop();
            } while (currentChar && !item.test(currentChar));

            if (currentChar) {
              item.setValue(currentChar);
            }
          }
        }
      });
    }

    this.setSelection(nextSelectionStart);
    this.shiftSelection();
  }

  deleteCharBackward(): void {
    if (this.selection.hasSelection()) {
      this.clearSelection();
    } else {
      const items = this.manager.getItems();
      const {start} = this.selection;

      if (start > 0) {
        // Variable `item` stores element that stays from the left of cursor position.
        // Variable `startNext` stores position of this element.
        let item = items[start - 1];
        let startNext = start - 1;

        // If user tries to delete mask element, need to do shift to the left,
        // until variable `item` starts to store filled editable item.
        while (item && (!item.isEditable() || (item.isEditable() && item.isEmpty()))) {
          item = items[startNext - 1];
          startNext -= 1;
        }

        if (item) {
          const after = this.utils.substring(start);
          const before = this.utils.substring(0, startNext);

          this.manager.setValue(`${before}${after}`);
          this.setSelection(startNext);
          this.shiftSelection();
        }
      }
    }
  }

  deleteContentBackward(): void {
    if (this.selection.hasSelection()) {
      this.clearSelection();
    } else {
      const {start} = this.selection;

      if (start > 0) {
        const tail = this.utils.substring(start);

        this.clear();
        this.resetSelection();
        this.manager.setValue(tail);
      }
    }
  }

  deleteCharForward(): void {
    if (this.selection.hasSelection()) {
      this.clearSelection();
    } else {
      const items = this.manager.getItems();
      const {start} = this.selection;
      const lastIndex = items.length - 1;

      if (start < lastIndex) {
        let item = items[start];
        let index = start;

        while (item && !item.isEditable()) {
          item = items[index + 1];
          index += 1;
        }

        if (item) {
          const before = this.utils.substring(0, start);
          const tail = this.utils.substring(index + 1);

          this.manager.setValue(`${before}${tail}`);
          this.setSelection(index);
        }
      }
    }
  }
}
