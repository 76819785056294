import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SelectBase, {SelectProps as SelectPropsBase} from '@mui/material/Select';
import ChevronDownIcon from 'icons/chevron-down.svg';
import {Option, FieldValue} from 'lib/form/types';
import React, {useId} from 'react';

export type SelectProps<T extends FieldValue> = Omit<
  SelectPropsBase<T>,
  'error' | 'variant' | 'IconComponent' | 'open' | 'onOpen' | 'onFocus' | 'onBlur' | 'onClose'
> & {
  error?: string;
  label: string;
  options: Option<T>[];
  placeholder?: string;
};

/**
 * For style ovverides check /src/styles/mui.scss
 */
export function Select<T extends FieldValue>({
  label,
  options,
  error,
  placeholder,
  ...props
}: SelectProps<T>): React.ReactElement {
  const labelId = useId();
  return (
    <FormControl error={Boolean(error)} fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <SelectBase
        {...props}
        IconComponent={ChevronDownIcon}
        label={label}
        labelId={labelId}
        placeholder={placeholder}
        variant='outlined'
      >
        {options.map((item) => (
          <MenuItem key={item.value + item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </SelectBase>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
