import {defineMessages} from 'react-intl';
import {FormattedRangeCases} from './types';

export const messages = defineMessages<FormattedRangeCases>({
  equal: {
    defaultMessage: '{equal}',
    description: 'Formatted Range',
  },
  range: {
    defaultMessage: '{from} – {to}',
    description: 'Formatted Range',
  },
  rangeFrom: {
    defaultMessage: 'Over {from}',
    description: 'Formatted Range',
  },
  rangeTo: {
    defaultMessage: 'Less than {to}',
    description: 'Formatted Range',
  },
});
