import {isMessageDescriptor} from 'lib/guards';
import {IntlShape} from 'react-intl';
import {ExtMessageDescriptor, MessageValues} from './types';

export function buildMessage<V extends MessageValues = MessageValues>(
  intl: IntlShape,
  message: ExtMessageDescriptor | undefined,
  values?: V,
): string | undefined {
  if (isMessageDescriptor(message)) {
    return intl.formatMessage(message, values);
  }

  return message;
}
