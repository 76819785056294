import {Client} from 'lib/client';

export type RequestParams = {
  filename: string;
  image: File;
};

export type ResponsePayload = {
  fileName: string;
  id: string;
};

export function getImageEmbedding(client: Client, params: RequestParams): Promise<ResponsePayload> {
  const formData = new FormData();
  formData.append('image', params.image);
  formData.append('filename', params.filename);

  return client.mainApi
    .post<{payload: ResponsePayload}>('search/getImageEmbedding', {body: formData})
    .then(({body: {payload}}) => payload);
}
