import {ContactUsRequestDialog as ContactUsRequestDialogBase} from 'components/ContactUsRequestDialog';
import {useEvent, useStore} from 'lib/effector';
import {$contactUsDialog, sendContactUsRequestFx, closeContactUsDialog} from 'models/selfService/contactUs';
import React from 'react';

export function ContactUsRequestDialog(): React.ReactElement | null {
  const {destination, isOpen, requestState} = useStore($contactUsDialog);
  const onCloseContactUsDialog = useEvent(closeContactUsDialog);
  const sendContactUsRequest = useEvent(sendContactUsRequestFx);

  if (isOpen) {
    return (
      <ContactUsRequestDialogBase
        onClose={onCloseContactUsDialog}
        onSubmit={(data) => sendContactUsRequest({destination, ...data})}
        requestState={requestState}
      />
    );
  }

  return null;
}
