import {getPublicRuntimeConfig, getNormalizedAnalyticsGtmMode, getNormalizedReleaseStage} from 'lib/appConfig';
import {AppConfig} from 'lib/appConfig/types';
import {Language} from 'lib/language/types';

const publicRuntimeConfig = getPublicRuntimeConfig();

const locales: AppConfig['locales'] = {
  [Language.RU]: {
    name: 'Русский',
    region: 'RU',
  },
  [Language.EN]: {
    name: 'English',
    region: 'US',
  },
  [Language.PT_BR]: {
    name: 'Português',
    region: 'BR',
  },
};

const phone: AppConfig['phone'] = {
  [Language.RU]: {
    codes: ['7', '8'],
    mask: '+7 (000) 000-00-00',
    placeholder: '+79123456789',
  },
  [Language.EN]: {
    codes: ['7', '8'],
    mask: '+7 (000) 000-00-00',
    placeholder: '+79123456789',
  },
  [Language.PT_BR]: {
    codes: ['55'],
    mask: '+55 (00) 00000-0000',
    nsnLength: [9, 10],
    placeholder: '+5510912345678',
  },
};

const supportEmail: AppConfig['supportEmail'] = {
  [Language.RU]: 'russia.support@joom.pro',
  [Language.EN]: 'support@joom.pro',
  [Language.PT_BR]: 'suporte.brasil@joom.pro',
};

const marketingEmail: AppConfig['marketingEmail'] = {
  [Language.PT_BR]: 'marketing.brasil@joom.pro',
};

const supportAnalyticsEmail: AppConfig['supportAnalyticsEmail'] = 'analytics@joom.pro';

const supportPhone: AppConfig['supportPhone'] = {
  [Language.RU]: '+7 (905) 727 97 58',
  [Language.EN]: '+7 (905) 727 97 58',
  [Language.PT_BR]: '+7 (905) 727 97 58',
};

const i18nLocaleByLanguage: AppConfig['i18nLocaleByLanguage'] = {
  [Language.RU]: 'ru-RU',
  [Language.EN]: 'en-US',
  [Language.PT_BR]: 'pt-BR',
};

export const config: AppConfig = {
  allowedDomains: ['joom.pro', 'electronics.joom.pro', 'joompro.ru', 'joomplace.com.br'],
  analyticsGtmMode: getNormalizedAnalyticsGtmMode(),
  appVersion: publicRuntimeConfig.appVersion,
  defaultCurrency: 'USD',
  defaultLocale: Language.EN,
  defaultOrigin: publicRuntimeConfig.defaultOrigin,
  gaId: 'UA-79371846-4',
  gtmId: 'GTM-MFMWJLG',
  i18nLocaleByLanguage,
  locales,
  marketingEmail,
  phone,
  releaseStage: getNormalizedReleaseStage(),
  supportAnalyticsEmail,
  supportEmail,
  supportPhone,
  testIdEnabled: publicRuntimeConfig.testIdEnabled,
};
