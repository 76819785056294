import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

type Props = TestIdProp & {
  children?: React.ReactNode;
  error?: ExtMessageDescriptor;
};

export function ViewError({children, error, testId}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.error} data-test-id={testId}>
      {children || buildMessage(intl, error)}
    </div>
  );
}
