import {TestIdProp} from 'lib/testing/types';
import React, {useState, useCallback, useRef, forwardRef} from 'react';
import styles from './index.module.scss';

const EMPTY_STRING = '';
const NON_DIGIT_REGEXP = /[^\d]/g;

export type OtpInputTestId = unknown;

type Props = TestIdProp<OtpInputTestId> & {
  disabled?: boolean;
  maxLength: number;
  onSubmit(otp: string): void;
};

export const OtpInput = forwardRef<HTMLInputElement, Props>(
  ({onSubmit, disabled, testId, maxLength}: Props, ref): React.ReactElement => {
    const [value, setValue] = useState(EMPTY_STRING);
    const valueRef = useRef<string>(EMPTY_STRING);
    valueRef.current = value;

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextValue = e.currentTarget.value.replace(NON_DIGIT_REGEXP, EMPTY_STRING).substr(0, maxLength);

        setValue(nextValue);

        if (nextValue.length === maxLength) {
          if (valueRef.current !== nextValue) {
            onSubmit(nextValue);
          }
        }
      },
      [onSubmit, maxLength],
    );

    return (
      <input
        className={styles.input}
        data-test-id={testId}
        disabled={disabled}
        onChange={handleChange}
        ref={ref}
        type='text'
        value={value}
      />
    );
  },
);
