import {DataState} from '@joomcode/deprecated-utils/dataState';
import * as api from 'api/orders';
import {createClientEffect, createStore} from 'lib/effector';
import {Order} from 'lib/ordersList/types';

export type OrderListState = {
  data: Order[];
  dataState: DataState;
  error?: Error;
};

export const $orders = createStore<OrderListState>('orders', {
  data: [],
  dataState: DataState.IDLE,
});

export const loadOrdersListFx = createClientEffect(api.loadOrdersList);
