import {$searchSuggestions, searchPhraseSuggestFx, resetSuggest} from './index';

$searchSuggestions
  .on(searchPhraseSuggestFx, (state) => ({
    ...state,
    loading: true,
  }))
  .on(searchPhraseSuggestFx.done, (state, {params, result}) => ({
    ...state,
    loading: false,
    searchSuggestions: result.items,
    searchSuggestionsPhrase: params,
  }))
  .on(searchPhraseSuggestFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }))
  .reset(resetSuggest);
