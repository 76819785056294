import uuid from 'uuid';
import {ItemContentType} from '../types';
import {GoToCatalogCardItemCreator} from './types';

export const createGoToCatalogCardItem: GoToCatalogCardItemCreator = () => {
  return {
    content: undefined,
    id: uuid(),
    type: ItemContentType.GO_TO_CATALOG_CARD,
  };
};
