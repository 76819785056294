import {isString} from 'lib/guards';
import {buildMessage} from 'lib/intl';
import {ElementSize} from 'lib/types/ElementSize';
import React, {useContext, useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Textarea} from 'uikit/Textarea';
import {FormTextsContext, useFormStateContext, useFormActionsContext} from '../contexts';
import {TextareaField as TextareaFieldProps} from '../types';

type Props = TextareaFieldProps & {
  noresize?: boolean;
  rows?: number;
  size?: ElementSize;
};

export function TextareaField(props: Props): React.ReactElement {
  const {size = 'medium', rows, noresize, testId, ...textareaFieldProps} = props;
  const {name, minLength, maxLength} = textareaFieldProps;

  const intl = useIntl();
  const texts = useContext(FormTextsContext);
  const {errors, values} = useFormStateContext();
  const {addField, removeField, onFieldBlur, onFieldChange} = useFormActionsContext();

  const value = isString(values[name]) ? values[name] : '';
  const error = buildMessage(intl, errors[name]);
  const label = buildMessage(intl, texts?.labels?.[name]);
  const placeholder = buildMessage(intl, texts?.placeholders?.[name]);

  useEffect(() => {
    addField(textareaFieldProps);

    return () => {
      removeField(textareaFieldProps);
    };
  }, []);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      onFieldBlur({name: e.target.name});
    },
    [onFieldBlur],
  );

  const handleInputChange = useCallback(
    ({target}: React.ChangeEvent<HTMLTextAreaElement>) => {
      onFieldChange({
        name: target.name,
        value: target.value,
      });
    },
    [onFieldChange],
  );

  return (
    <Textarea
      disabled={props.disabled}
      error={error}
      label={label}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      onBlur={handleBlur}
      onChange={handleInputChange}
      onFocus={textareaFieldProps.onFocus}
      placeholder={placeholder}
      rows={rows}
      size={size}
      testId={testId}
      value={value as string}
    />
  );
}
