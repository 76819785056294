import {createContext, useContext} from 'react';
import {FormActions, FormState, FormTexts} from './types';

export const FormStateContext = createContext<FormState | undefined>(undefined);

export const FormActionsContext = createContext<FormActions | undefined>(undefined);

export const FormTextsContext = createContext<FormTexts | undefined>(undefined);

export function useFormStateContext(): FormState {
  const formState = useContext(FormStateContext);

  if (formState) {
    return formState;
  }

  throw new Error('FormStateContext must be provided');
}

export function useFormActionsContext(): FormActions {
  const formActions = useContext(FormActionsContext);

  if (formActions) {
    return formActions;
  }

  throw new Error('FormActionsContext must be provided');
}
