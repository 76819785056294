import {loadUser} from 'api/loadUser';
import {createStore, createClientEffect} from 'lib/effector';
import {SYNTHETIC_USER} from 'lib/user';
import {User} from 'lib/user/types';

export const $user = createStore<User>('user', SYNTHETIC_USER);
export const $userAnonymous = $user.map((user) => user.anonymous);
export const $userNotAnonymous = $user.map((user) => !user.anonymous);
export const $isUserRegistrationCompleted = $user.map((user) => user.isSelfServiceDataFilled);

export const loadUserFx = createClientEffect(loadUser);
