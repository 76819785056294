import {Client} from 'lib/client';
import {convertOriginalContentList} from 'lib/contentList';
import {createGoToCatalogCardItem} from 'lib/contentList/creators/createGoToCatalogCardItem';
import {Promotion} from 'lib/promotion/types';
import {DEFAULT_PROMOTION_ITEMS_PER_PAGE} from './constants';
import {loadPromotionByAlias} from './loadPromotionByAlias';
import {loadPromotionById} from './loadPromotionById';
import {PromotionRequestPayload} from './types';

const GO_TO_CATALOG_CARD_POSITION = 12;

export async function loadPromotion(client: Client, requestPayload: PromotionRequestPayload): Promise<Promotion> {
  const {count = DEFAULT_PROMOTION_ITEMS_PER_PAGE, nextPageToken} = requestPayload;
  const result =
    'alias' in requestPayload
      ? await loadPromotionByAlias(client, {alias: requestPayload.alias, count, nextPageToken})
      : await loadPromotionById(client, {count, id: requestPayload.id, nextPageToken});

  const {payload} = result.body;
  const items = convertOriginalContentList(payload.items);

  // TODO: PRO-7445 remove after backend support
  if (!payload.nextPageToken) {
    if (items.length < GO_TO_CATALOG_CARD_POSITION) {
      items.splice(GO_TO_CATALOG_CARD_POSITION / 2, 0, createGoToCatalogCardItem());
    } else {
      items.splice(GO_TO_CATALOG_CARD_POSITION, 0, createGoToCatalogCardItem());
    }
  }

  return {
    alias: payload.alias,
    items,
    nextPageToken: payload.nextPageToken,
    promotionId: payload.id,
    title: payload.title,
  };
}
