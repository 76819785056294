import {defineMessages} from 'react-intl';

export const generalMessages = defineMessages({
  no: {
    defaultMessage: 'No',
    description: 'General translation, may be used anywhere',
  },
  tryAgain: {
    defaultMessage: 'Try again',
    description: '[button] General translation, may be used anywhere',
  },
  update: {
    defaultMessage: 'Update',
    description: '[button] General translation, may be used anywhere',
  },
  yes: {
    defaultMessage: 'Yes',
    description: 'General translation, may be used anywhere',
  },
});
