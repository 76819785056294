import {assertNever} from '@joomcode/deprecated-utils/types';
import {Form} from 'components/Form';
import {FormTexts} from 'components/Form/types';
import {PolicyLine as DefaultPolicyLine} from 'components/PolicyLine';
import {FormValidationError} from 'lib/form/types';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {PromocodeContext} from 'lib/promocode';
import {TestIdProp} from 'lib/testing/types';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import React, {useContext, useMemo} from 'react';
import {ButtonTestId} from 'uikit/Button';
import {DialogContent} from 'uikit/Dialog';
import {
  PhoneField,
  EmailField,
  FirstNameField,
  FullNameField,
  DescriptionField,
  PromocodeField,
  CommentField,
  CompanyNameField,
} from '../Fields';
import {
  FormData,
  SubmitHandler,
  FormViewField,
  FormViewFields,
  PhoneFieldTestId,
  EmailFieldTestId,
  FirstNameFieldTestId,
  FullNameFieldTestId,
  DescriptionFieldTestId,
  PromocodeFieldTestId,
  CommentFieldTestId,
  CompanyNameFieldTestId,
} from '../types';
import {ButtonSubmit, ButtonSubmitTestId} from './ButtonSubmit';
import {Header} from './Header';
import styles from './index.module.scss';
import {formTexts as defaultFormTexts} from './messages';

type FormTestId = {
  comment: CommentFieldTestId;
  companyName: CompanyNameFieldTestId;
  description: DescriptionFieldTestId;
  email: EmailFieldTestId;
  firstName: FirstNameFieldTestId;
  fullName: FullNameFieldTestId;
  phone: PhoneFieldTestId;
  promocode: PromocodeFieldTestId;
  submit: ButtonTestId;
};

export type FormViewTestId = {
  form: FormTestId;
  submit: ButtonSubmitTestId;
};

type Props<Data extends FormData> = TestIdProp<FormViewTestId> & {
  children?: React.ReactNode;
  error?: FormValidationError;
  fields?: FormViewFields;
  formTexts?: FormTexts;
  illustration?: string;
  onSubmit: SubmitHandler<Data>;
  policyLine?: React.ReactNode;
  processing?: boolean;
  subtitle?: ExtMessageDescriptor | React.ReactNode;
  title?: ExtMessageDescriptor;
};

function getComponentByField(field: FormViewField, testId?: FormViewTestId): React.ReactElement | void {
  switch (field) {
    case 'comment':
      return <CommentField key={field} testId={testId?.form.comment} />;
    case 'companyName':
      return <CompanyNameField key={field} testId={testId?.form.companyName} />;
    case 'email':
      return <EmailField key={field} required testId={testId?.form.email} />;
    case 'phone':
      return <PhoneField autoFocus key={field} required testId={testId?.form.phone} />;
    case 'firstName':
      return <FirstNameField key={field} required testId={testId?.form.firstName} />;
    case 'fullName':
      return <FullNameField key={field} required testId={testId?.form.fullName} />;
    case 'description':
      return <DescriptionField key={field} required testId={testId?.form.description} />;
    case 'promocode':
      return <PromocodeField key={field} testId={testId?.form.promocode} />;
    default:
      return assertNever(field);
  }
}

export function FormView<Data extends FormData>({
  error,
  children,
  formTexts,
  illustration,
  onSubmit,
  processing,
  subtitle,
  policyLine,
  title,
  testId,
  fields = ['phone', 'promocode'],
}: Props<Data>): React.ReactElement {
  const {promocodeAvailable} = useContext(PromocodeContext);
  const formTextsMerged = useMemo<FormTexts>(() => merge({}, defaultFormTexts, formTexts), [formTexts]);
  const uniqFields = useMemo(
    () => uniq(fields).filter((field) => (field === 'promocode' ? promocodeAvailable : true)),
    [fields, promocodeAvailable],
  );
  const hasIllustration = Boolean(illustration);

  return (
    <DialogContent noTopSpacing={!hasIllustration}>
      <Header illustration={illustration} subtitle={subtitle} title={title} />
      <Form<Data>
        className={styles.form}
        error={error}
        onSubmit={onSubmit}
        testId={testId?.form}
        texts={formTextsMerged}
      >
        {children || uniqFields.map((uniqField) => getComponentByField(uniqField, testId) as React.ReactNode)}
        <div className={styles.actions}>
          <ButtonSubmit loading={processing} testId={testId?.submit} />
        </div>
        {policyLine ?? <DefaultPolicyLine />}
      </Form>
    </DialogContent>
  );
}
