import {Oops} from 'components/Oops';
import {generalMessages} from 'lib/i18n/generalMessages';
import React from 'react';
import {Button} from 'uikit/Button';
import {DialogContent} from 'uikit/Dialog';
import styles from './index.module.scss';

type Props = {
  onTryAgainClick?: () => void;
};

export function DialogErrorContent({onTryAgainClick}: Props) {
  return (
    <DialogContent>
      <Oops />
      <div className={styles.buttonContainer}>
        <Button color='black' label={generalMessages.tryAgain} onClick={onTryAgainClick} size='large' type='button' />
      </div>
    </DialogContent>
  );
}
