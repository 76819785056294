import {ContentListGoToCatalogCardItem} from './goToCatalogCard';
import {ContentListProductItem} from './product';
import {ContentListRfqBannerItem} from './rfqBanner';
import {ContentListSearchResultFeedbackBannerItem} from './searchResultFeedbackBanner';
import {ContentListSearchSuggestionItem} from './searchSuggestion';
import {ContentListSignUpBannerItem} from './signUpBanner';
import {ContentListTopProductsBannerItem} from './topProductsBanner';
import {ContentListUniqueSellingPointBannerItem} from './uniqueSellingPointBanner';

export * from './common';
export * from './original';

export type {ContentListProductItem} from './product';
export type {ContentListSearchSuggestionItem} from './searchSuggestion';
export type {ContentListUniqueSellingPointBannerItem} from './uniqueSellingPointBanner';
export type {ContentListTopProductsBannerItem} from './topProductsBanner';
export type {ContentListRfqBannerItem} from './rfqBanner';
export type {ContentListSignUpBannerItem} from './signUpBanner';
export type {ContentListGoToCatalogCardItem} from './goToCatalogCard';
export type {ContentListSearchResultFeedbackBannerItem} from './searchResultFeedbackBanner';

export type ContentListItem =
  | ContentListProductItem
  | ContentListSearchSuggestionItem
  | ContentListUniqueSellingPointBannerItem
  | ContentListSearchResultFeedbackBannerItem
  | ContentListTopProductsBannerItem
  | ContentListGoToCatalogCardItem
  | ContentListSignUpBannerItem
  | ContentListRfqBannerItem;

export type ContentList = ContentListItem[];
