import {isRecord} from 'lib/guards';
import {FilterValue, FilterType} from './types';

type FilterValueByType<T, V> = V extends {type: T} ? V : never;

export function isFilterValue<V extends FilterValue, T extends FilterType = FilterType>(
  filter: unknown,
  type: T,
): filter is FilterValueByType<T, V> {
  return isRecord(filter) && filter.type === type;
}
