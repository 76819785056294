import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  noOptionsText: {
    defaultMessage: 'No zip-codes found',
    description: 'Text in options list in zip-code autocomplete component while list is empty',
  },
  placeholder: {
    defaultMessage: 'Start typing city or zip-code',
    description: 'Placeholder in zip-code autocomplete component',
  },
});
