import {assertNever} from '@joomcode/deprecated-utils/types';
import {convertOriginalStoreSimpleFilter} from '../utils/converters';
import {SearchSuggestion, SearchSuggestionKind, SearchSuggestionText, SearchSuggestionStore} from './types';
import {OriginalSearchSuggestion, OriginalSearchSuggestionText, OriginalSearchSuggestionStore} from './types/original';

export function convertToSearchSuggestionStore(original: OriginalSearchSuggestionStore): SearchSuggestionStore {
  return {
    id: original.id,
    kind: original.kind,
    searchParams: {
      filters: original.query.filters.map((filter) => {
        return convertOriginalStoreSimpleFilter(filter);
      }),
      phrase: original.query.query,
    },
    title: original.title,
  };
}

export function convertToSearchSuggestionText(original: OriginalSearchSuggestionText): SearchSuggestionText {
  return {
    id: original.id,
    kind: original.kind,
    searchParams: {
      phrase: original.query.query,
    },
    title: original.title,
  };
}

export function convertToSearchSuggestion(original: OriginalSearchSuggestion): SearchSuggestion | undefined {
  const {kind} = original;

  switch (kind) {
    case SearchSuggestionKind.STORE:
      return convertToSearchSuggestionStore(original);
    case SearchSuggestionKind.TEXT:
      return convertToSearchSuggestionText(original);
    default:
      assertNever(kind);
      return undefined;
  }
}
