export function isRecord<K extends string, V = unknown>(target: unknown): target is Record<K, V> {
  return typeof target === 'object' && target !== null && !Array.isArray(target);
}

export function isNumber(target: unknown): target is number {
  return typeof target === 'number';
}

export function isBoolean(target: unknown): target is boolean {
  return typeof target === 'boolean';
}

export function isString(target: unknown): target is string {
  return typeof target === 'string';
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(target: unknown): target is Function {
  return typeof target === 'function';
}

export function isArray<T>(target: unknown): target is T[] {
  return Array.isArray(target);
}
