import {Language} from 'lib/language/types';
import {CountryCode} from '../types';

export function localeToCountryCode(locale?: string): CountryCode {
  switch (locale) {
    case Language.PT_BR:
      return CountryCode.BR;
    case Language.RU:
      return CountryCode.RU;
    default:
      return CountryCode.US;
  }
}
