import classNamesBind from 'classnames/bind';
import {Link, LinkProps} from 'components/Link';
import {makeGridStyle} from 'lib/grid';
import {GridProps} from 'lib/grid/types';
import {isMessageDescriptor} from 'lib/guards';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type ButtonColor = 'black' | 'white' | 'gray' | 'accent' | 'outline-white' | 'outline-black' | 'transparent';

export type ButtonShape = 'classic' | 'round' | 'circle';

export type ButtonSize = 'small' | 'medium' | 'large' | 'x-large';

export type ButtonTestId = unknown;

export type ButtonSpecificProps = Pick<JSX.IntrinsicElements['button'], 'type' | 'onClick'>;

export type LinkSpecificProps = {
  type: 'link';
} & Pick<LinkProps, 'href' | 'onClick' | 'target' | 'rel'>;

export type ButtonProps = (ButtonSpecificProps | LinkSpecificProps) &
  GridProps &
  TestIdProp<ButtonTestId> & {
    /**
     * Отображается только если нет label
     * Обычно текст, который отображаеться в кнопке, но можно засунуть и компонент
     */
    children?: React.ReactNode;
    /**
     * Цвет кнопки
     * @defaultValue `gray`
     */
    color?: ButtonColor;
    /**
     * Отвечает за состояния дизейбл
     */
    disabled?: boolean;
    /**
     * Иконка слева от текста кнопки (по факту принимает любой компонент)
     */
    iconLeft?: React.ReactNode;
    /**
     * Иконка справа от текста кнопки (по факту принимает любой компонент)
     */
    iconRight?: React.ReactNode;
    /**
     * Текст кнопки который может быть как строкой так и MessageDescriptor с фолбеком на children
     */
    label?: string | MessageDescriptor;
    loading?: boolean;
    /**
     * Задает форму кнопки
     * @defaultValue `classic`
     */
    shape?: ButtonShape;
    /**
     * Вместо текста показывает галочку (типо как принято/нажато)
     */
    showCheck?: boolean;
    /**
     * Размер кнопки
     * @defaultValue `medium`
     */
    size?: ButtonSize;
    /**
     * Порядок переключения табом на странице
     */
    tabIndex?: number;
  };

// TODO: PRO-2801 add posibility to change size of the button via mixins.
// It needs for controlling visibility in mobile layouts.
// Nowadays we cannot change size of the button through css,
// it controlled by poperty "size".
export const Button = React.forwardRef(function Button(
  props: ButtonProps,
  ref: React.Ref<HTMLElement>,
): React.ReactElement {
  const {
    children,
    color = 'gray',
    iconLeft,
    iconRight,
    label,
    loading,
    shape = 'classic',
    size = 'medium',
    tabIndex,
    testId,
    showCheck,
  } = props;
  const style = makeGridStyle(props);
  const disabled = props.disabled || loading;

  const className = cn(
    'button',
    `color-${color}`,
    `shape-${shape}`,
    `size-${size}`,
    iconLeft || iconRight ? 'with-icon' : undefined,
    disabled ? 'disabled' : undefined,
    !children && !label ? 'icon-only' : undefined,
  );

  const content = (
    <>
      <span className={cn('content', {'content-hidden': showCheck || loading})}>
        {iconLeft && <span className={cn('icon', 'icon-left')}>{iconLeft}</span>}
        <span className={styles.label}>
          {isMessageDescriptor(label) ? <FormattedMessage {...label} /> : label || children}
        </span>
        {iconRight && <span className={cn('icon', 'icon-right')}>{iconRight}</span>}
      </span>
      {loading && <span className={cn(styles.fullIcon, styles.loading)} />}
      {showCheck && <span className={cn(styles.fullIcon, styles.showCheck)} />}
    </>
  );

  if (props.type === 'link') {
    return (
      <Link
        className={className}
        data-test-id={testId}
        href={props.href}
        onClick={props.onClick}
        ref={ref as React.Ref<HTMLAnchorElement>}
        rel={props.rel}
        style={style}
        tabIndex={tabIndex}
        target={props.target}
      >
        {content}
      </Link>
    );
  }

  /* eslint-disable react/button-has-type */
  return (
    <button
      className={className}
      data-test-id={testId}
      disabled={disabled || loading}
      onClick={props.onClick}
      ref={ref as React.Ref<HTMLButtonElement>}
      style={style}
      tabIndex={tabIndex}
      type={props.type || 'button'}
    >
      {content}
    </button>
  );
  /* eslint-enable react/button-has-type */
});
