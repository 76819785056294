import './adminUser/init';
import './auth/init';
import './consultation/init';
import './categoriesTree/init';
import './categories/init';
import './category/init';
import './main/init';
import './search/init';
import './user/init';
import './product/init';
import './modalsManager/init';
import './paymentsPartners/init';
import './promotion/init';
import './serverDate/init';
import './promocode/init';
import './ssRegistrationDialog/init';
import './blogArticle/init';
import './blogArticles/init';
import './deliveryChannel/init';
import './companyAnnualTurnover/init';
import './deal/init';
import './cart/init';
import './orders/init';
import './registrationCallback/init';
import './selfService/init';
import './sample/init';
