import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  subtitle: {
    // eslint-disable-next-line max-len
    defaultMessage:
      'Let us help you search for the perfect product and the best discounts on delivery (valid through {month})',
    description: 'PageLeaveDialog: window subtitle in January',
  },
  title: {
    defaultMessage: "Couldn't find what you were looking for?",
    description: 'PageLeaveDialog: window title',
  },
});
