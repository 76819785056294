import {isRecord, isString} from 'lib/guards';
import {
  ItemContentType,
  ContentListProductItem,
  OriginalContentListItem,
  OriginalContentListProductItem,
  OriginalContentListSearchSuggestionItem,
  OriginalContentListUniqueSellingPointBannerItem,
  OriginalContentListRfqBannerItem,
} from './types';

export function isOriginalContentListItem<T extends ItemContentType>(
  item: unknown,
  type?: T,
): item is OriginalContentListItem {
  if (isRecord(item) && isString(item.id)) {
    if (type) {
      return Boolean(isRecord(item.content) && item.content[type]);
    }

    return true;
  }

  return false;
}

export function isOriginalSearchSuggestionItem(item: unknown): item is OriginalContentListSearchSuggestionItem {
  return isOriginalContentListItem(item, ItemContentType.SEARCH_SUGGESTION);
}

export function isOriginalContentListProductItem(item: unknown): item is OriginalContentListProductItem {
  return isOriginalContentListItem(item, ItemContentType.PRODUCT);
}

export function isOriginalRfqBannerItem(item: unknown): item is OriginalContentListRfqBannerItem {
  return isOriginalContentListItem(item, ItemContentType.RFQ_BANNER);
}

export function isOriginalSignUpBannerItem(item: unknown): item is OriginalContentListRfqBannerItem {
  return isOriginalContentListItem(item, ItemContentType.SIGN_UP_BANNER);
}

export function isOriginalUniqueSellingPointBannerItem(
  item: unknown,
): item is OriginalContentListUniqueSellingPointBannerItem {
  return isOriginalContentListItem(item, ItemContentType.UNIQUE_SELLING_POINT_BANNER);
}

export function isContentListProductItem(item: unknown): item is ContentListProductItem {
  return isRecord(item) && item.type === ItemContentType.PRODUCT;
}
