import {CommunicationDialog, CommunicationDialogTestId} from 'components/CommunicationDialog';
import {PhoneField, PromocodeField} from 'components/CommunicationDialog/Fields';
import {FormViewFields} from 'components/CommunicationDialog/types';
import {InputField, TextareaField} from 'components/Form';
import {CommunicationParams, CommunicationReason} from 'lib/communication/types';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import {validateNumeric} from 'lib/validation';
import React from 'react';
import {messages, formTexts} from './messages';

export type PaymentsPartnersDialogTestId = CommunicationDialogTestId;

type FormData = {
  comment?: string;
  name?: string;
  phone: string;
  promocode?: string;
  taxpayerNumber?: string;
};

type Props = TestIdProp<PaymentsPartnersDialogTestId> & {
  error?: unknown;
  onClose(): void;
  onSubmit(formData: FormData): void;
  onSuccess?: () => void;
  processing?: boolean;
  reason?: CommunicationReason;
  success?: boolean;
};

const MAX_TAXPAYER_FIELD_LENGTH = 12;
const MIN_TAXPAYER_FIELD_LENGTH = 10;
const RENDER_FIELDS: FormViewFields = [];

export function PaymentsPartnersDialog({
  error,
  onClose,
  onSubmit,
  onSuccess,
  processing,
  success,
  testId,
}: Props): React.ReactElement {
  const language = useLanguage();
  return (
    <CommunicationDialog<CommunicationParams>
      error={error}
      fields={RENDER_FIELDS}
      formTexts={formTexts}
      illustration={
        language === Language.RU ? '/illustrations/financial-consultation-ru.svg' : '/illustrations/consultation.svg'
      }
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      processing={processing}
      section='paymentsPartnersDialog'
      subtitle={messages.subtitle}
      success={success}
      successIllustration={
        language === Language.RU ? '/illustrations/financial-consultation-success-ru.svg' : undefined
      }
      testId={testId}
      title={messages.title}
    >
      <InputField name='name' type='text' />
      <InputField
        maxLength={MAX_TAXPAYER_FIELD_LENGTH}
        minLength={MIN_TAXPAYER_FIELD_LENGTH}
        name='taxpayerNumber'
        type='text'
        validator={validateNumeric}
      />
      <PhoneField autoFocus required />
      <TextareaField name='comment' />
      <PromocodeField />
    </CommunicationDialog>
  );
}
