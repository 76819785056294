import classNamesBind from 'classnames/bind';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type DotsLoadingTestId = unknown;

type Props = TestIdProp<DotsLoadingTestId> & {
  color?: 'inherit' | 'black' | 'gray';
};

export function DotsLoading({color = 'inherit', testId}: Props): React.ReactElement {
  return (
    <span className={cn('loading', `color-${color}`)} data-test-id={testId}>
      <span className={styles.inner} />
    </span>
  );
}
