import {getOwnEnumerableNonArrayIndexKeys} from './objectKeys';

export type EnumValue<E> = E[keyof E];

export function getEnumValues<E extends Record<string, unknown>>(enumInstance: E): EnumValue<E>[] {
  return getOwnEnumerableNonArrayIndexKeys(enumInstance).map((key: keyof E) => enumInstance[key]);
}

type HashMapOf<E> = {[key in keyof E]: EnumValue<E>};

export function convertEnumToObject<E extends Record<string, unknown>>(enumInstance: E): HashMapOf<E> {
  return getOwnEnumerableNonArrayIndexKeys(enumInstance).reduce<HashMapOf<E>>((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = enumInstance[key];
    return result;
  }, {} as HashMapOf<E>);
}

export function isEnumValue<E extends Record<string, unknown>>(enumInstance: E, value: unknown): value is EnumValue<E> {
  return getEnumValues(enumInstance).includes(value as E[keyof E]);
}
