import {CurrencyCode} from '@joomcode/deprecated-utils/currency';
import {formatRange} from 'components/FormattedRange/FormattedRange';
import {IntlShape} from 'react-intl';
import {CompanyAnnualTurnoverRange} from '.';

export const formatAnnualTurnoverRange = (
  {range}: CompanyAnnualTurnoverRange,
  currency: CurrencyCode,
  intl: IntlShape,
): string => {
  return formatRange(
    {
      from: range.min,
      isEqual: (min, max) => min === max,
      render: (value) => intl.formatNumber(value, {currency, maximumFractionDigits: 0, style: 'currency'}),
      to: range.max,
    },
    intl,
  );
};
