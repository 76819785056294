import classNamesBind from 'classnames/bind';
import {TestIdProp} from 'lib/testing/types';
import React, {ChangeEventHandler} from 'react';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type CheckboxChangeHandler = ChangeEventHandler<HTMLInputElement>;

export type CheckboxSize = 'small' | 'medium';

export type CheckboxTestId = unknown;

type CheckboxProps = TestIdProp<CheckboxTestId> &
  Pick<JSX.IntrinsicElements['input'], 'checked' | 'name' | 'value' | 'disabled'> & {
    onChange?: CheckboxChangeHandler;
    size?: CheckboxSize;
  };

export function Checkbox({size = 'medium', testId, ...checkboxProps}: CheckboxProps): React.ReactElement {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <input {...checkboxProps} className={cn('checkbox', `size-${size}`)} data-test-id={testId} type='checkbox' />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}
