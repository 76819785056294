import {Client} from 'lib/client';
import {BodyPayload, TransportResponse} from 'lib/transport/types';
import {PromotionResponsePayload, PromotionByIdRequestPayload} from './types';

export async function loadPromotionById(
  client: Client,
  {id, count, nextPageToken}: PromotionByIdRequestPayload,
): Promise<TransportResponse<BodyPayload<PromotionResponsePayload>>> {
  return client.mainApi.post<BodyPayload<PromotionResponsePayload>>('promo/get', {
    body: {count, id, pageToken: nextPageToken},
  });
}
