import classNamesBind from 'classnames/bind';
import {useCallbackWithoutArgs} from 'lib/hooks';
import {ModalsManagerContext} from 'lib/modalsManager';
import {TestIdProp} from 'lib/testing/types';
import React, {useMemo, useContext, useEffect} from 'react';
import {ModalOverlay} from 'uikit/ModalOverlay';
import {HandlersContext} from './contexts';
import {DialogBack} from './DialogBack';
import {DialogClose} from './DialogClose';
import styles from './index.module.scss';
import {ClosableProps, BackProps, DialogTestId} from './types';

export {DialogActions} from './DialogActions';
export {DialogClose} from './DialogClose';
export {DialogContent} from './DialogContent';
export {DialogHeader} from './DialogHeader';

const cn = classNamesBind.bind(styles);

export type DialogProps = ClosableProps &
  BackProps &
  TestIdProp<DialogTestId> & {
    children: React.ReactNode;
    fullscreen?: 'mobile';
    width?: number | string;
  };

export function Dialog({
  testId,
  children,
  fullscreen,
  onBack,
  onClose,
  width,
  withCloseButton = false,
  withBackButton = false,
}: DialogProps): React.ReactElement {
  const dialogMethods = useContext(ModalsManagerContext);
  const surfaceStyle = {width};

  useEffect(() => {
    dialogMethods.dialogDidOpen();

    return () => {
      dialogMethods.dialogWillClose();
    };
  }, [dialogMethods]);

  const rootClassName = cn('dialog', fullscreen ? `fullscreen-${fullscreen}` : undefined);

  const handleClose = useCallbackWithoutArgs(onClose);
  const handleBack = useCallbackWithoutArgs(onBack);

  const handlersContextValue = useMemo(() => {
    return {
      onBack: handleBack,
      onClose: handleClose,
    };
  }, [handleClose, handleBack]);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <ModalOverlay focusLock onClose={handleClose} scrollLock>
      <HandlersContext.Provider value={handlersContextValue}>
        <div className={rootClassName} data-test-id={testId}>
          <div
            className={styles.scrim}
            data-test-id={testId?.scrim}
            onClick={handleClose}
            role='button'
            tabIndex={-1}
          />
          <div className={styles.surfaceWrap}>
            <div className={styles.surface} role='dialog' style={surfaceStyle}>
              {withBackButton && (
                <div className={styles.backButtonWrap}>
                  <DialogBack testId={testId?.backButton} />
                </div>
              )}
              {withCloseButton && (
                <div className={styles.closeButtonWrap}>
                  <DialogClose testId={testId?.closeButton} />
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </HandlersContext.Provider>
    </ModalOverlay>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events */
}
