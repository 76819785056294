import {createDeal as apiCreateDeal} from 'api/deal';
import {createClientEffect} from 'lib/effector';
import {createRegistrationRequiredEffect} from 'models/registrationCallback/utils';

export const createDealInternalFx = createClientEffect(apiCreateDeal);
export const createDealFx = createRegistrationRequiredEffect(createDealInternalFx, {
  opendialogPayload: {
    showCheckoutPausedMessage: true,
  },
});
