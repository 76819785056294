import {Image} from 'components/Image';
import {isMessageDescriptor} from 'lib/guards';
import {ImageBundle} from 'lib/image/types';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

type Props = {
  actions?: React.ReactNode;
  image?: string | ImageBundle;
  imageSize?: string | number;
  subtitle?: ExtMessageDescriptor | React.ReactNode;
  title?: ExtMessageDescriptor | React.ReactNode;
};

export function DialogContentHeader({image, title, subtitle, imageSize = 200}: Props): React.ReactElement {
  const intl = useIntl();
  return (
    <div className={styles.header}>
      {image && (
        <div className={styles.image}>
          <Image className={styles.image} sizes={imageSize} src={image} />
        </div>
      )}
      {title && <div className={styles.title}>{isMessageDescriptor(title) ? buildMessage(intl, title) : title}</div>}
      {subtitle && (
        <div className={styles.subtitle}>{isMessageDescriptor(subtitle) ? buildMessage(intl, subtitle) : subtitle}</div>
      )}
    </div>
  );
}
