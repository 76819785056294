"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.levelMapping = void 0;
exports.levelMapping = {
    /* eslint-disable @typescript-eslint/naming-convention */
    OFF: 'info',
    FATAL: 'fatal',
    ERROR: 'error',
    WARN: 'warning',
    INFO: 'info',
    DEBUG: 'debug',
    TRACE: 'info',
    ALL: 'info',
    /* eslint-enable @typescript-eslint/naming-convention */
};
