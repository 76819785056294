import type {CookieManager} from 'lib/cookie';
import {isRecord} from 'lib/guards';
import {QueryParams} from 'lib/url/types';

export type Endpoints = {
  localApi: string;
  mainApi: string;
};

export type TransportOptions = {
  baseUrl: string;
  clientAddress?: string;
  cookies?: CookieManager;
  origin: string;
  ssrId?: string;
  userAgent?: string;
};

export type TransportRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type TransportRequestHeaders = Record<string, string>;

export type TransportRequestOptions = {
  body?: Record<string, unknown> | FormData | unknown[];
  headers?: TransportRequestHeaders;
  query?: QueryParams;
};

export type TransportRequestInit = {
  body?: BodyInit;
  headers: TransportRequestHeaders;
  method: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TransportResponse<Body = any> = {
  body: Body;
  kind: 'TransportResponse';
  method?: string;
  requestId?: string;
  status: number;
  statusText?: string;
  url?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTransportResponse<Body = any>(data: unknown): data is TransportResponse<Body> {
  return isRecord(data) && data.kind === 'TransportResponse';
}

export type BodyPayload<Payload> = {
  payload: Payload;
};

export type BodyPayloadItems<Items> = BodyPayload<{items: Items}>;

export enum JoomProApplicationsHeaders {
  ANALYTICS = 'Analytics',
  JOOM_PRO = 'JoomPro',
}
