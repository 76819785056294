import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';

export enum CompanyOperationField {
  B2B_RESELLER = 'b2bReseller',
  FACTORY = 'factory',
  MARKETPLACE_SELLER = 'marketplaceSeller',
  OFFLINE_RETAILER = 'offlineRetailer',
  ONLINE_RETAILER = 'onlineRetailer',
}

export const companyOperationFields = getEnumValues(CompanyOperationField);

export const isCompanyOperationField = (value: unknown): value is CompanyOperationField => {
  return companyOperationFields.includes(value as CompanyOperationField);
};
