import {InputField} from 'components/FinalForm/InputMuiField';
import React from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const CompanyWebsite = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  return <InputField label={intl.formatMessage(fieldMessages.companyWebsite)} name={fieldName} required={required} />;
};
