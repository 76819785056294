import {BlogArticleLite} from 'lib/blogArticle/types';
import {localeToCountryCode} from 'lib/blogArticle/utils';
import {Client} from 'lib/client';

type BlogArticlesResponse = {payload: {items: BlogArticleLite[]}};

export type BlogArticlesPayload = {
  limit?: number;
  locale?: string;
  tagsAllOf?: string[];
  tagsOneOf?: string[];
};

export function loadBlogArticles(client: Client, payload: BlogArticlesPayload): Promise<BlogArticleLite[]> {
  const {locale, limit = 100, ...restPayload} = payload;

  return client.mainApi
    .post<BlogArticlesResponse>('blog/article/list', {
      body: {
        filter: {...restPayload, country: localeToCountryCode(locale)},
        limit,
        sorting: {date: 'desc'},
      },
    })
    .then(({body}) => body.payload.items);
}
