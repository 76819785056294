import {DialogContentHeader} from 'components/DialogContent';
import React from 'react';
import {DialogContent} from 'uikit/Dialog';
import {messages} from './messages';

export function SuccessView(): React.ReactElement {
  return (
    <DialogContent>
      <DialogContentHeader
        image='/illustrations/successDialog.png'
        imageSize={200}
        subtitle={messages.successSubtitle}
        title={messages.successTitle}
      />
    </DialogContent>
  );
}
