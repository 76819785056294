import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {useKeyboardActiveIndex} from 'lib/hooks';
import {TestIdProp} from 'lib/testing/types';
import React, {useEffect, useCallback} from 'react';
import {Input, InputProps, InputTestId} from 'uikit/Input';
import {ModalOverlay} from 'uikit/ModalOverlay';
import styles from './index.module.scss';
import {OptionItem, OptionItemTestId} from './OptionItem';

export type InputAutocompleteTestId = {
  input: InputTestId;
  option: OptionItemTestId;
};

type Props<Option> = TestIdProp<InputAutocompleteTestId> &
  InputProps & {
    onSelect(option: Option): void;
    options?: Option[];
    renderOptionLabel(option: Option): React.ReactNode;
  };

const DEFAULT_OPTIONS: unknown[] = [];

export function InputAutocomplete<Option>({
  options = DEFAULT_OPTIONS as Option[],
  testId,
  onSelect,
  renderOptionLabel,
  ...inputProps
}: Props<Option>): React.ReactElement {
  const {value: optionsVisible, setTrue: showOptions, setFalse: hideOptions} = useBooleanState(false);

  const selectActive = useCallback(
    (currentActiveIndex: number) => {
      if (options[currentActiveIndex]) {
        onSelect(options[currentActiveIndex]);
        hideOptions();
      }
    },
    [onSelect, hideOptions, options],
  );

  const {reset, activeIndex, handleKeyDown} = useKeyboardActiveIndex({
    lastIndex: options.length - 1,
    onEnter: selectActive,
  });

  const hideOptionsAndRest = useCallback(() => {
    hideOptions();
    reset();
  }, [hideOptions, reset]);

  const handleSelect = useCallback(
    (option: Option) => {
      onSelect(option);
      hideOptionsAndRest();
    },
    [hideOptionsAndRest, onSelect],
  );

  const handleBlur = useCallback(() => {
    if (optionsVisible && options.length) {
      if (inputProps.value && options[activeIndex]) {
        handleSelect(options[activeIndex]);
      } else {
        hideOptionsAndRest();
      }
    }
  }, [options, activeIndex, optionsVisible, inputProps.value, hideOptionsAndRest, handleSelect]);

  useEffect(() => {
    if (!optionsVisible && options.length && inputProps.value) {
      showOptions();
    }

    if (optionsVisible && (!options.length || !inputProps.value)) {
      hideOptionsAndRest();
    }
  }, [options]);

  return (
    /* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
    <div className={styles.container} data-test-id={testId}>
      <Input {...inputProps} autoComplete='off' onBlur={handleBlur} onKeyDown={handleKeyDown} testId={testId?.input} />
      {optionsVisible && (
        <ModalOverlay inPlace onClose={hideOptions} transparent>
          <div className={styles.options}>
            {options.map((option: Option, index) => (
              <OptionItem<Option>
                active={activeIndex === index}
                item={option}
                key={index}
                onSelect={handleSelect}
                testId={testId?.option}
              >
                {renderOptionLabel(option)}
              </OptionItem>
            ))}
          </div>
        </ModalOverlay>
      )}
    </div>
  );
}
