import {assertNever} from '@joomcode/deprecated-utils/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {SendOtpFormData, SignInByPhoneCredential, AuthByPhoneViewName, AuthOrigin} from '../types';
import {Header, HeaderTestId} from './Header';
import {OtpView, OtpViewTestId} from './OtpView';
import {PhoneView, PhoneViewTestId} from './PhoneView';

export type SignInByPhoneTestId = {
  header: HeaderTestId;
  otpView: OtpViewTestId;
  phoneView: PhoneViewTestId;
};

type Props = TestIdProp<SignInByPhoneTestId> & {
  disablePhoneMask?: boolean;
  onBack?(): void;
  onSendOtp(payload: SendOtpFormData): void;
  onSubmit(credential: SignInByPhoneCredential): void;
  origin?: AuthOrigin;
  retryAfterMs?: number;
  sendOtpData?: SendOtpFormData;
  sendOtpError?: unknown;
  sendOtpLoading?: boolean;
  signInError?: unknown;
  signInLoading?: boolean;
  view?: AuthByPhoneViewName;
  withBackButton?: boolean;
};

export function SignInByPhone({
  origin = AuthOrigin.GENERAL,
  testId,
  view,
  retryAfterMs = 0,
  sendOtpLoading,
  withBackButton,
  sendOtpData,
  sendOtpError,
  signInLoading,
  signInError,
  disablePhoneMask,
  onSendOtp,
  onSubmit,
  onBack,
}: Props): React.ReactElement {
  const backButtonVisible = withBackButton && view === AuthByPhoneViewName.OTP;
  const handleSubmit = useCallback(
    (otp: string) => {
      if (sendOtpData) {
        onSubmit({
          origin,
          otp,
          ...sendOtpData,
        });
      }
    },
    [onSubmit, origin, sendOtpData],
  );

  const handleResendOtp = useCallback(() => {
    if (sendOtpData) {
      onSendOtp(sendOtpData);
    }
  }, [onSendOtp, sendOtpData]);

  let content: React.ReactNode = null;
  if (view) {
    switch (view) {
      case AuthByPhoneViewName.PHONE:
        content = (
          <PhoneView
            onSubmit={onSendOtp}
            sendOtpData={sendOtpData}
            sendOtpError={sendOtpError}
            sendOtpLoading={sendOtpLoading}
            testId={testId?.phoneView}
          />
        );
        break;
      case AuthByPhoneViewName.OTP:
        content = (
          <OtpView
            onResend={handleResendOtp}
            onSubmit={handleSubmit}
            phone={sendOtpData?.phone}
            retryAfterMs={retryAfterMs}
            sendOtpError={sendOtpError}
            sendOtpLoading={sendOtpLoading}
            signInError={signInError}
            signInLoading={signInLoading}
            testId={testId?.otpView}
          />
        );
        break;
      default:
        assertNever(view);
        break;
    }
  }

  return (
    <div data-test-id={testId}>
      {backButtonVisible && <Header onClick={onBack} testId={testId?.header} />}
      {content}
    </div>
  );
}
