import {Client} from 'lib/client';
import {BodyPayloadItems} from 'lib/transport/types';
import {extractPayloadItems} from 'lib/transport/utils';

export type City = {
  name: string;
  state: string;
  zip: string;
};

type ZipCodeSuggestInput = {
  limit: number;
  query: string;
};

export function loadZipCodeSuggest(client: Client, params: ZipCodeSuggestInput): Promise<City[]> {
  return client.mainApi
    .post<BodyPayloadItems<City[]>>('address/city/suggest', {body: params})
    .then(extractPayloadItems);
}
