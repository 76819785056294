import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, ButtonTestId} from 'uikit/Button';
import {DotsLoading, DotsLoadingTestId} from 'uikit/DotsLoading';

export type ButtonSubmitTestId = {
  button: ButtonTestId;
  loading: DotsLoadingTestId;
};

type Props = TestIdProp<ButtonSubmitTestId> & {
  loading?: boolean;
};

export function ButtonSubmit({loading = false, testId}: Props): React.ReactElement {
  return (
    <Button color='black' disabled={loading} loading={loading} size='large' testId={testId?.button} type='submit'>
      {loading ? (
        <DotsLoading testId={testId?.loading} />
      ) : (
        <FormattedMessage defaultMessage='Send' description='General communication form submit button' />
      )}
    </Button>
  );
}
