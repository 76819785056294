import {CookieManager} from 'lib/cookie';
import {HOUR, MINUTE} from 'lib/datetime';
import {localStorage} from 'lib/storage';

let mouseleaveCallback: () => unknown;

const STORAGE_KEY = 'pageLeave.lastSessionStartTime';
const STORAGE_KEY_VERSION = 1;
const EXPIRE_SESSION_TIME = HOUR;
const TIME_BEFORE_OPEN = 2 * MINUTE;

function startPageLeaveDialogTimer(): void {
  const lastSessionStartTime = localStorage.getItem<number>(STORAGE_KEY, STORAGE_KEY_VERSION);
  const now = Date.now();
  if (!lastSessionStartTime || now - lastSessionStartTime > EXPIRE_SESSION_TIME) {
    localStorage.setItem(STORAGE_KEY, now, STORAGE_KEY_VERSION);
  }
}

function isReadyToOpenPageLeaveDialog(cookies: CookieManager): boolean {
  if (cookies.siteLeaveDialog) {
    return false;
  }
  const lastSessionStartTime = localStorage.getItem<number>(STORAGE_KEY, STORAGE_KEY_VERSION);
  const now = Date.now();
  return Boolean(lastSessionStartTime && now - lastSessionStartTime > TIME_BEFORE_OPEN);
}

export function setPageLeaveDialogHandlers(cookies: CookieManager, callback: () => unknown): void {
  if (!cookies.siteLeaveDialog) {
    startPageLeaveDialogTimer();
    if (mouseleaveCallback) {
      document.documentElement.removeEventListener('mouseleave', mouseleaveCallback);
    }
    mouseleaveCallback = () => {
      if (isReadyToOpenPageLeaveDialog(cookies)) {
        callback();
      }
    };
    document.documentElement.addEventListener('mouseleave', mouseleaveCallback);
  }
}

export function removePageLeaveDialogHandlers(): void {
  if (mouseleaveCallback) {
    document.documentElement.removeEventListener('mouseleave', mouseleaveCallback);
  }
}
