import {DialogContentHeader} from 'components/DialogContent';
import {Form, PhoneField} from 'components/Form';
import {PolicyLine} from 'components/PolicyLine';
import {isFormValidationError} from 'lib/errors/guards';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useRef, useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {Button, ButtonTestId} from 'uikit/Button';
import {InputTestId} from 'uikit/Input';
import {SendOtpFormData} from '../../types';
import {View, ViewError, ViewFields, ViewActions, ViewExtraContent} from '../../View';
import {formTexts} from '../messages';

const messages = defineMessages({
  button: {
    defaultMessage: 'Get code',
    description: 'Login and registration form: button to receive code',
  },
  error: {
    defaultMessage: 'Check for errors and try again',
    description: 'Login and registration form: standard error',
  },
  title: {
    defaultMessage: 'Enter your phone number',
    description: 'Login and registration form: header',
  },
});

export type PhoneViewTestId = {
  input: InputTestId;
  policy: unknown;
  submit: ButtonTestId;
};

type Props = TestIdProp<PhoneViewTestId> & {
  onSubmit(data: SendOtpFormData): void;
  sendOtpData?: SendOtpFormData;
  sendOtpError?: unknown;
  sendOtpLoading?: boolean;
};

function getErrorMessage(error: unknown): ExtMessageDescriptor | undefined {
  if (!isFormValidationError(error)) {
    return messages.error;
  }

  return undefined;
}

export function PhoneView({onSubmit, testId, sendOtpData, sendOtpError, sendOtpLoading}: Props): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <View>
      <DialogContentHeader image='/illustrations/auth/enterPhone.png' imageSize={200} title={messages.title} />
      <Form<SendOtpFormData> error={sendOtpError} onSubmit={onSubmit} texts={formTexts}>
        <ViewFields>
          <PhoneField
            disabled={sendOtpLoading}
            name='phone'
            ref={inputRef}
            required
            size='large'
            testId={testId?.input}
            value={sendOtpData?.phone}
          />
        </ViewFields>
        <ViewActions>
          <Button
            color='black'
            disabled={sendOtpLoading}
            label={messages.button}
            loading={sendOtpLoading}
            testId={testId?.submit}
            type='submit'
          />
        </ViewActions>
      </Form>
      {sendOtpError ? <ViewError error={getErrorMessage(sendOtpError)} /> : null}
      <ViewExtraContent>
        <span data-test-id={testId?.policy}>
          <PolicyLine />
        </span>
      </ViewExtraContent>
    </View>
  );
}
