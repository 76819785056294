import {CountryCode} from '@joomcode/deprecated-utils/countries';
import {Client} from 'lib/client';
import {CompanyAnnualTurnover} from 'lib/companyAnnualTurnover';
import {extractPayload} from 'lib/transport/utils';

export type CompanyAnnualTurnoverByCountryCode = Partial<Record<CountryCode, CompanyAnnualTurnover>>;

export function loadCompanyAnnualTurnover(
  client: Client,
  {countryCode}: {countryCode: CountryCode},
): Promise<CompanyAnnualTurnover> {
  return client.mainApi
    .get<{payload: CompanyAnnualTurnover}>('company/turnoverRanges', {query: {countryCode}})
    .then(extractPayload);
}
