import {useEvent, useStore} from 'lib/effector';
import {$getCurrentPromocode, $promocodeTopBannerVisible, closeTopBanner} from 'models/promocode';

export type PromocodeStateShape = {
  close(): void;
  value: string | null;
};

export const usePromocodeState = (): PromocodeStateShape => {
  const visible = useStore($promocodeTopBannerVisible);
  const getPromocode = useStore($getCurrentPromocode);
  const close = useEvent(closeTopBanner);
  const promocode = getPromocode();

  return {
    close,
    value: visible && promocode ? promocode : null,
  };
};
