import {CSSProperties} from 'react';
import {GridProps} from '../types';

export function makeGridStyle(
  {gridArea, gridColumn, gridRow}: GridProps,
  style: CSSProperties = {},
): CSSProperties | undefined {
  if (gridArea !== undefined) {
    // eslint-disable-next-line no-param-reassign
    style.gridArea = gridArea;
  }

  if (gridColumn !== undefined) {
    // eslint-disable-next-line no-param-reassign
    style.gridColumn = gridColumn;
  }

  if (gridRow !== undefined) {
    // eslint-disable-next-line no-param-reassign
    style.gridRow = gridRow;
  }

  return style;
}
