import {Effect, Event, Store, EffectError, EffectResult, EffectParams} from 'effector';
import {createStore} from '../domain';

type SimpleActionStore<Data, Params, Error> = {
  data?: Data;
  error?: Error;
  params?: Params;
  pending: boolean;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export function createSimpleActionStore<
  Clock extends Effect<any, any, any>,
  ClockParams extends EffectParams<Clock>,
  ClockResult extends EffectResult<Clock>,
  ClockError extends EffectError<Clock>,
  Map extends (payload: ClockResult) => any,
  Data extends ReturnType<Map>,
>({
  sid,
  initialData,
  clock,
  map,
  reset,
}: {
  clock: Clock;
  initialData?: Data;
  map: Map;
  reset?: Event<void>;
  sid: string;
}): Store<SimpleActionStore<Data, ClockParams, ClockError>> {
  const $store = createStore<SimpleActionStore<Data, ClockParams, ClockError>>(sid, {
    data: initialData,
    pending: false,
  });

  $store
    .on(clock, (state, params) => ({
      ...state,
      error: undefined,
      params,
      pending: true,
    }))
    .on(clock.done, (state, {params, result: data}) => {
      if (params !== state.params) {
        return state;
      }

      return {
        data: map(data),
        pending: false,
      };
    })
    .on(clock.fail, (state, {params, error}) => {
      if (params !== state.params) {
        return state;
      }

      return {
        ...state,
        error,
        pending: false,
      };
    });

  if (reset) {
    $store.reset(reset);
  }

  return $store;
}
