import {convertToSearchSuggestion} from 'lib/search/suggest';
import {ItemContentType} from '../types';
import {SearchSuggestionItemCreator} from './types';

export const createSearchSuggestionItem: SearchSuggestionItemCreator = (original) => {
  const searchSuggestion = convertToSearchSuggestion(original.content.searchSuggestion);
  return searchSuggestion
    ? {
        content: searchSuggestion,
        id: original.id,
        type: ItemContentType.SEARCH_SUGGESTION,
      }
    : undefined;
};
