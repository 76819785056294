import {SortingOrder} from 'lib/filtersAndSorting/types/sorting';
import {SortingItem, SortingItemValue, SortingList, SortingValueList} from './types';

export function isSortingOrder(order: unknown): order is SortingOrder {
  return order === 'asc' || order === 'desc';
}

export function applySortingSelection(
  sortingList: SortingList,
  {fieldName, order}: Pick<SortingItem, 'fieldName' | 'order'>,
): void {
  sortingList.forEach((item) => {
    // eslint-disable-next-line no-param-reassign
    item.selected = Boolean(item.fieldName === fieldName && item.order === order);
  });
}

export function resetSorting(sortingList: SortingList): void {
  sortingList.forEach((item) => {
    // eslint-disable-next-line no-param-reassign
    item.selected = item.default;
  });
}

export function extractSortingItemValue(sortingItem: SortingItem): SortingItemValue {
  return {
    fieldName: sortingItem.fieldName,
    order: sortingItem.order,
  };
}

export function extractSortingValueList(sortingList: SortingList): SortingValueList {
  return sortingList.reduce<SortingValueList>((result, item) => {
    if (item.selected && !item.default) {
      result.push(extractSortingItemValue(item));
    }

    return result;
  }, []);
}
