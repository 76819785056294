import {Image} from 'components/Image';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {DialogContent} from 'uikit/Dialog';
import styles from './index.module.scss';

const messages = defineMessages({
  subtitle: {
    defaultMessage: 'Our specialist will respond to you as soon as possible',
    description: 'Contact us success dialog: subtitle',
  },
  title: {
    defaultMessage: 'Request has been received',
    description: 'Contact us success dialog: title',
  },
});

export function SuccessView(): React.ReactElement {
  const intl = useIntl();

  return (
    <>
      <DialogContent>
        <div className={styles.figure}>
          <Image className={styles.image} loading='lazy' src='/illustrations/consultation-success.svg' />
        </div>
        <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
        <div className={styles.subtitle}>{intl.formatMessage(messages.subtitle)}</div>
      </DialogContent>
    </>
  );
}
