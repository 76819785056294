import React, {useCallback, useState} from 'react';
import {useBooleanState} from './useBooleanState';

export type PopupState<Payload = void> = {
  isOpen: boolean;
  payload?: Payload;
  open(): void;
  close(): void;
  toggle(): void;
  openWithPayload(payload: Payload): void;
};

export function usePopupState<Payload = void>(initiallyOpen = false): PopupState<Payload> {
  const [payload, setPayload] = useState<Payload>();
  const booleanState = useBooleanState(initiallyOpen);

  const handleOpenWithPayload = useCallback(
    (data: Payload) => {
      setPayload(data);
      booleanState.setTrue();
    },
    [booleanState.setTrue],
  );

  const handleClose = useCallback(() => {
    setPayload(undefined);
    booleanState.setFalse();
  }, [booleanState.setFalse]);

  return {
    payload,
    isOpen: booleanState.value,
    openWithPayload: handleOpenWithPayload,
    close: handleClose,
    open: booleanState.setTrue,
    toggle: booleanState.toggle,
  };
}

type Props<Payload> = {
  children: (state: PopupState<Payload>) => React.ReactNode;
  initiallyOpen?: boolean;
};

export function PopupState<Payload = void>({children, initiallyOpen}: Props<Payload>) {
  const popupState = usePopupState<Payload>(initiallyOpen);

  return children(popupState);
}
