import {formValidationError} from 'lib/form/types';
import {isRecord} from 'lib/guards';
import {isTransportResponse, TransportResponse} from 'lib/transport/types';
import {User} from 'lib/user/types';
import {PayloadWithUser} from './types';

export const extractUser = ({body: {payload}}: TransportResponse<PayloadWithUser>): User => payload.user;

export const extractBody = <T>(result: TransportResponse<{payload: T}>): {payload: T} => result.body;

export type EmailAuthCredentialsValidationError = {
  payload: {
    fieldsValidationMessages: Record<string, string>;
    generalFieldMessage?: string;
  };
  type: 'emailauth.credentials_validation_failed';
};

function isEmailAuthCredentialsValidationError(error: unknown): error is EmailAuthCredentialsValidationError {
  return (
    isRecord(error) &&
    error.type === 'emailauth.credentials_validation_failed' &&
    // todo: PRO-194 we are waiting for backend format in /auth/email/recoverPassword
    isRecord(error.payload) &&
    'fieldsValidationMessages' in error.payload
  );
}

export function handleCredentialsValidationError(error: unknown): void {
  if (isTransportResponse(error) && isEmailAuthCredentialsValidationError(error.body)) {
    throw formValidationError(error.body.payload.fieldsValidationMessages);
  }
}
