import {useContext} from 'react';
import {ClientContext} from './contexts';
import type {Client} from './index';

export function useClient(): Client {
  const client = useContext(ClientContext);

  if (!client) {
    throw new Error('Can not use hook useClient when client is not defined');
  }

  return client;
}
