import {SECOND} from 'lib/datetime/constants';
import {useRef, useCallback, useState, useEffect} from 'react';

const FINISH_TIME_VALUE = 0;

export type Options = {
  onEnd?(): void;
  timerStep?: number;
};

export type ReturnShape = {
  cancel(): void;
  inProgress: boolean;
  isComplete: boolean;
  pause(): void;
  resume(): void;
  start(awaitingTime: number): void;
  time: number;
};

export function useTimer({onEnd, timerStep = SECOND}: Options = {}): ReturnShape {
  const [time, setTime] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const inProgressRef = useRef<boolean>(false);
  const intervalRef = useRef<number>(0);
  const startRef = useRef<number>(0);
  const timeRef = useRef<number>(0);
  const onEndRef = useRef<typeof onEnd>(onEnd);

  inProgressRef.current = inProgress;
  timeRef.current = time;
  onEndRef.current = onEnd;

  const pause = useCallback(() => {
    if (inProgressRef.current && timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      setInProgress(false);
    }
  }, []);

  const intervalHandler = useCallback(() => {
    startRef.current = Date.now();

    return function insideHandler() {
      const now = Date.now();
      const timeLeft = now - startRef.current;
      // overtime is a time passed after interval.
      // E.g. interval is 1000ms, and passed 1005ms,
      // that means overtime equals to 1005ms - 1000ms = 5ms
      const overtime = timeLeft - intervalRef.current;

      // If overtime more than timerStep, was a freeze (alert, heavy calculations, etc)
      // E.g. timerStep is 1000ms, after freeze passed 3500ms
      // that means overtime is equal to 2500ms (3500ms - 1000ms).
      // Need to handle this case separately.
      if (overtime > timerStep) {
        const timeLeftRatio = Math.floor(timeLeft / timerStep);

        // E.g. passed 3500ms, overtime 2500ms, time 10,000ms
        // next interval is 500ms (3000ms - 2500ms)
        // next time is 7000 (10,000ms - 3000ms)
        intervalRef.current = timerStep * timeLeftRatio - overtime;
        timeRef.current -= timerStep * timeLeftRatio;
      } else {
        // By default we need to sync timer with real time.
        // E.g. timerStep is 1000ms, passed 1005ms, overtime 5ms
        // that means next interval will be 995ms (1000ms - 5ms)
        intervalRef.current = timerStep - overtime;
        timeRef.current -= timerStep;
      }

      if (timeRef.current > 0) {
        setTime(timeRef.current);
        startRef.current = now;
        timeoutIdRef.current = setTimeout(insideHandler, intervalRef.current);
      } else {
        setIsComplete(true);
        setTime(FINISH_TIME_VALUE);
        pause();

        if (onEndRef.current) {
          onEndRef.current();
        }
      }
    };
  }, [pause, timerStep]);

  const resume = useCallback(() => {
    if (!inProgressRef.current) {
      setInProgress(true);

      intervalRef.current = timerStep;
      timeoutIdRef.current = setTimeout(intervalHandler(), timerStep);
    }
  }, [intervalHandler, timerStep]);

  const start = useCallback(
    (awaitingTime: number) => {
      if (!inProgressRef.current) {
        if (awaitingTime <= 0) {
          setIsComplete(true);
          setTime(FINISH_TIME_VALUE);
          pause();
        } else {
          setTime(awaitingTime);
          resume();
        }
      }
    },
    [resume, intervalHandler],
  );

  const cancel = useCallback(() => {
    pause();
    setIsComplete(false);
  }, [pause]);

  useEffect(() => cancel, []);

  return {
    cancel,
    inProgress,
    isComplete,
    pause,
    resume,
    start,
    time,
  };
}
