import {TestIdProp} from 'lib/testing/types';
import {isTransportResponse} from 'lib/transport/types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {messages} from './messages';

export type ErrorMessageTestId = {
  requestId: unknown;
};

type Props = TestIdProp<ErrorMessageTestId> & {
  children?: React.ReactNode;
  className?: string;
  error?: unknown;
  noRequestId?: boolean;
};

export function ErrorMessage({children, className, error, noRequestId, testId}: Props): React.ReactElement {
  const requestId = isTransportResponse(error) ? error.requestId : undefined;

  return (
    <span className={className} data-test-id={testId}>
      {children || <FormattedMessage {...messages.title} />}
      {!noRequestId && requestId && (
        <>
          <br />
          <span data-test-id={testId?.requestId}>ID: {requestId}</span>
        </>
      )}
    </span>
  );
}
