import {useContext} from 'react';
import {AppEnvContext} from './context';
import type {AppEnv} from './types';

export function useAppEnv(): AppEnv {
  const appEnv = useContext(AppEnvContext);

  if (!appEnv) {
    throw Error('AppEnvContext must be defined');
  }

  return appEnv;
}
