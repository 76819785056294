import {DataState} from '@joomcode/deprecated-utils/dataState';
import {ErrorMessage} from 'components/ErrorMessage';
import {TextareaField} from 'components/FinalForm';
import React from 'react';
import {Form} from 'react-final-form';
import {defineMessages, useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import {DialogContent, DialogHeader} from 'uikit/Dialog';
import {FormData} from '../types';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Send request',
    description: '[button] Contact us dialog: button',
  },
  label: {
    defaultMessage: 'Enter your query of interest, and we will get in touch with you',
    description: 'Contact us dialog: field comment label',
  },
  placeholder: {
    defaultMessage: 'Enter your request',
    description: 'Contact us dialog: field comment placeholder',
  },
  title: {
    defaultMessage: 'Contact us',
    description: 'Contact us dialog: title',
  },
});

export type Props = {
  onSubmit(formData: FormData): void;
  requestState: DataState;
};

const INTIAL_TEXTAREA_ROWS = 4;

export function ContentView({onSubmit, requestState}: Props): React.ReactElement {
  const intl = useIntl();
  const loading = requestState === DataState.LOADING;
  const failed = requestState === DataState.FAILED;

  return (
    <>
      <DialogHeader title={intl.formatMessage(messages.title)} />
      <DialogContent noTopSpacing>
        <Form onSubmit={onSubmit}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className={styles.label}>{intl.formatMessage(messages.label)}</div>
              <div className={styles.field}>
                <TextareaField
                  autoFocus
                  disabled={loading}
                  name='text'
                  placeholder={intl.formatMessage(messages.placeholder)}
                  rows={INTIAL_TEXTAREA_ROWS}
                />
              </div>
              <div className={styles.actions}>
                <Button color='black' loading={loading} type='submit'>
                  {intl.formatMessage(messages.button)}
                </Button>
              </div>
              {failed && <ErrorMessage className={styles.error} />}
            </form>
          )}
        </Form>
      </DialogContent>
    </>
  );
}
