import {useMemo} from 'react';

type UseNeighborsIndexesResult = {
  getIndexes: (index: number) => {left: number; right: number};
  getLeftIndex: (index: number) => number;
  getRightIndex: (index: number) => number;
};

export function useNeighborsIndexes(lastIndex: number): UseNeighborsIndexesResult {
  return useMemo(() => {
    const getLeftIndex = (idx: number) => (idx > 0 ? idx - 1 : lastIndex);
    const getRightIndex = (idx: number) => (idx < lastIndex ? idx + 1 : 0);
    const getIndexes = (index: number) => ({
      left: getLeftIndex(index),
      right: getRightIndex(index),
    });

    return {getIndexes, getLeftIndex, getRightIndex};
  }, [lastIndex]);
}
