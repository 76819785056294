import {$promotion, loadPromotionFx, resetPromotion} from '.';

$promotion.reset(resetPromotion);
$promotion
  .on(loadPromotionFx, (store, request) => ({
    ...store,
    items: request.nextPageToken ? store.items || [] : [],
    loading: true,
    loadingError: undefined,
    request,
  }))
  .on(loadPromotionFx.doneData, (store, data) => ({
    ...store,
    items: [...(store.items || []), ...data.items],
    loading: false,
    nextPageToken: data.nextPageToken,
    promotion: data,
    title: data.title,
  }))
  .on(loadPromotionFx.failData, (store, error) => ({
    ...store,
    loading: false,
    loadingError: error,
  }));
