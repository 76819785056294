import {AuthOrigin} from 'components/Auth/types';
import {Client} from 'lib/client';
import {User, UserUpdatePayload} from 'lib/user/types';

type ResponseBody = {
  payload: User;
};

export function updateUser(client: Client, body: UserUpdatePayload & {origin: AuthOrigin}): Promise<User> {
  return client.mainApi
    .post<ResponseBody>('/users/update', {
      body,
    })
    .then(({body: {payload}}) => payload);
}
