/**
 * Checks that all possible cases are handled.
 *
 * @example
 * declare const state: 'error' | 'success';
 *
 * switch (state) {
 *   case 'error': {
 *     break;
 *   }
 *
 *   default: {
 *     // 'success' case is unhandled
 *     // TypeScript error: Argument of type '"success"' is not assignable to parameter of type 'never'.
 *     assertNever(state);
 *   }
 * }
 *
 */
export function assertNever(x: never): void {} // eslint-disable-line @typescript-eslint/no-unused-vars
