import classNamesBind from 'classnames/bind';
import {isMessageDescriptor} from 'lib/guards';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useContext} from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import {TabSelectContext} from './contexts';
import styles from './index.module.scss';
import {TabValue} from './types';

const cn = classNamesBind.bind(styles);

export type TabProps<V = TabValue> = TestIdProp & {
  active?: boolean;
  children?: React.ReactNode;
  index?: number;
  label?: string | MessageDescriptor;
  tabIndex?: number;
  value?: V;
};

export function Tab<V = TabValue>({
  active,
  children,
  index = 0,
  label,
  tabIndex = -1,
  testId,
  value,
}: TabProps<V>): React.ReactElement {
  const handleSelect = useContext(TabSelectContext);

  const handleClick = useCallback(() => {
    if (!active) {
      handleSelect(index, value);
    }
  }, [active, handleSelect, index, value]);

  return (
    <button
      className={cn('tab', {active})}
      data-test-id={testId}
      onClick={handleClick}
      tabIndex={tabIndex}
      type='button'
    >
      {isMessageDescriptor(label) ? <FormattedMessage {...label} /> : label || children}
    </button>
  );
}
