import {getEnumFormatter} from '@joomcode/deprecated-utils/intl/getEnumFormatter';
import {defineMessages} from 'react-intl';
import {CompanyEmployeesNumber} from '.';

export const messages = defineMessages<CompanyEmployeesNumber>({
  [CompanyEmployeesNumber.BETWEEN_0_AND_2]: {
    defaultMessage: '0 - 2',
    description: 'User company employees number',
  },
  [CompanyEmployeesNumber.BETWEEN_3_AND_10]: {
    defaultMessage: '3 - 10',
    description: 'User company employees number',
  },
  [CompanyEmployeesNumber.BETWEEN_11_AND_50]: {
    defaultMessage: '11 - 50',
    description: 'User company employees number',
  },
  [CompanyEmployeesNumber.BETWEEN_51_AND_200]: {
    defaultMessage: '51 - 200',
    description: 'User company employees number',
  },
  [CompanyEmployeesNumber.GREATER_THAN_201]: {
    defaultMessage: '201 +',
    description: 'User company employees number',
  },
});

export const formatEmployeesNumber = getEnumFormatter(messages);
