import {$adminUser, loadAdminUserFx} from './index';

$adminUser
  .on(loadAdminUserFx, (state) => ({
    ...state,
    loading: true,
    loadingError: undefined,
  }))
  .on(loadAdminUserFx.doneData, (state, adminUser) => ({
    ...state,
    adminUser,
    loading: false,
  }))
  .on(loadAdminUserFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }));
