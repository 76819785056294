import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useValueRef} from '@joomcode/deprecated-utils/react/useValueRef';
import React, {useMemo} from 'react';
import {Dialog} from 'uikit/Dialog';
import {ContentView} from './Content';
import {SuccessView} from './Success';
import {FormData} from './types';

export type Props = {
  onClose(): void;
  onSubmit(formData: FormData): void;
  requestState: DataState;
};

export function ContactUsRequestDialog({onClose, onSubmit, requestState}: Props): React.ReactElement {
  const onSubmitRef = useValueRef(onSubmit);
  const content = useMemo(() => {
    switch (requestState) {
      case DataState.LOADED:
        return <SuccessView />;
      default:
        return <ContentView onSubmit={onSubmitRef.current} requestState={requestState} />;
    }
  }, [requestState]);

  return (
    <Dialog onClose={onClose} width='420px' withCloseButton>
      {content}
    </Dialog>
  );
}
