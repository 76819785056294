import {Client} from 'lib/client';
import {BodyPayload, TransportResponse} from 'lib/transport/types';
import {PromotionResponsePayload, PromotionByAliasRequestPayload} from './types';

export function loadPromotionByAlias(
  client: Client,
  {alias, count, nextPageToken}: PromotionByAliasRequestPayload,
): Promise<TransportResponse<BodyPayload<PromotionResponsePayload>>> {
  return client.mainApi.post<BodyPayload<PromotionResponsePayload>>('promo/getByAlias', {
    body: {alias, count, pageToken: nextPageToken},
  });
}
