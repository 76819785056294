import {Selection} from '../common/Selection';

export type Params = {
  selectionBefore: Selection;
  valueAfter: string;
  valueBefore: string;
};

export function getInserted({valueAfter, valueBefore, selectionBefore}: Params): string {
  // Handle browser's autofill behaviour.
  if (selectionBefore.start > 0) {
    const headAfter = valueAfter.substr(0, selectionBefore.start);
    const headBefore = valueBefore.substr(0, selectionBefore.start);

    if (headAfter !== headBefore) {
      return valueAfter;
    }
  }

  const selectionDiff = selectionBefore.end - selectionBefore.start;
  const lengthDiff = valueAfter.length - valueBefore.length;

  return valueAfter.substr(selectionBefore.start, selectionDiff + lengthDiff);
}
