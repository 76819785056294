import {useDefaultValidator} from 'lib/finalForm/hooks';
import {BaseFieldProps} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useField, UseFieldConfig} from 'react-final-form';
import {useIntl} from 'react-intl';
import {Input, InputProps} from 'uikit/InputMui';

type Props<Value extends string | number> = BaseFieldProps<Value> &
  InputProps &
  Pick<UseFieldConfig<Value | undefined>, 'parse'> & {onlyPositiveIntNumber?: boolean};

const parseToNumber = (value: string): number | undefined => {
  return Number.isNaN(parseFloat(value)) ? undefined : parseFloat(value);
};

export function InputField<Value extends string | number>({
  name,
  required,
  validate,
  requiredMessage,
  onlyPositiveIntNumber,
  ...props
}: Props<Value>): React.ReactElement {
  const intl = useIntl();
  const handleValidate = useDefaultValidator<string | number>({
    required,
    requiredMessage,
    validate,
  });
  const {input, meta} = useField(name, {
    parse: props.parse ?? (props.type === 'number' ? parseToNumber : undefined),
    validate: handleValidate,
  });
  const error = buildMessage(intl, getErrorMessage(meta));

  const preventSymbolsForNumber: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+' || e.key === '.') {
      e.preventDefault();
    }
  };

  // TODO: убрать onKeyDown после таски https://joom-team.atlassian.net/browse/PRO-7585
  return (
    <Input
      inputProps={{...props.inputProps, min: props?.inputProps?.min || onlyPositiveIntNumber ? 0 : undefined}}
      onKeyDown={onlyPositiveIntNumber && props.type === 'number' ? preventSymbolsForNumber : undefined}
      {...props}
      {...input}
      error={error}
    />
  );
}
