import {isPhoneRateLimitExceededError} from 'lib/auth';
import {Client} from 'lib/client';
import {cleanPhone} from 'lib/data/phone';
import {isFormValidationError} from 'lib/errors/guards';
import {isTransportResponse} from 'lib/transport/types';

type ResponseBody = {
  payload: {
    retryAfterMs: number;
  };
};

export type Request = {
  email?: string;
  firstName?: string;
  phone: string;
};

export type Response = {
  retryAfterMs: number;
};

export function sendOtp(client: Client, {phone, email, firstName}: Request): Promise<Response> {
  return client.mainApi
    .post<ResponseBody>('auth/phoneV2/sendOtp', {
      body: {email, firstName, phone: cleanPhone(phone)},
    })
    .then(({body: {payload}}) => ({retryAfterMs: payload.retryAfterMs}))
    .catch((error) => {
      if (isTransportResponse(error)) {
        if (error.status === 500 && isPhoneRateLimitExceededError(error.body)) {
          return {retryAfterMs: error.body.payload?.retryAfterMs || 0};
        }

        if (error.status === 400 && isFormValidationError(error.body)) {
          throw error.body;
        }
      }

      throw error;
    });
}
