import {createDataStore} from '@joomcode/joompro-core';
import {loadCategoriesTree} from 'api/categories';
import {RootCategory} from 'lib/category/types';
import {createClientEffect} from 'lib/effector';

export const [$categoriesTree, categoriesTreeBinders] = createDataStore<RootCategory>(
  {data: {}},
  {name: 'categoriesTree'},
);

export const loadCategoriesTreeFx = createClientEffect(loadCategoriesTree);
