import {animate, ANIMATION_MAX_PROGRESS, DEFAULT_DURATION} from 'lib/animation';
import {Animation} from 'lib/animation/types';
import React, {useCallback, useEffect, useRef} from 'react';

export type ScrollToOptions = {
  duration?: number;
  force?: boolean;
  left?: number;
  smooth?: boolean;
  top?: number;
};

type ScrollTo = (options: ScrollToOptions) => void;

type ScrollIntoViewOptions = {
  duration?: number;
  force?: boolean;
  smooth?: boolean;
};

type ScrollIntoView = (target: HTMLElement, options?: ScrollIntoViewOptions) => void;

type IsScrollBusy = () => boolean;

type ScrollHook = {
  isScrollBusy: IsScrollBusy;
  scrollIntoView: ScrollIntoView;
  scrollTo: ScrollTo;
};

// Данный хук должен гарантировать неизменность возвращаемой функции
export function useScroll(viewRef: React.RefObject<HTMLElement>): ScrollHook {
  // eslint-disable-next-line no-magic-numbers
  const animation = useRef<Animation>();
  const busy = useRef<boolean>(false);

  useEffect(() => (): void => animation.current?.cancel(), []);

  const isBusy: IsScrollBusy = useCallback(() => busy.current, []);

  const scrollTo = useCallback<ScrollTo>(
    ({left, top, force = false, duration = DEFAULT_DURATION, smooth = false}) => {
      if (!viewRef.current || (!force && isBusy())) {
        return;
      }

      animation.current?.cancel();

      const view = viewRef.current;
      const maxLeft = view.scrollWidth - view.clientWidth;
      const maxTop = view.scrollHeight - view.clientHeight;
      const targetLeft = typeof left === 'number' ? Math.min(left, maxLeft) : view.scrollLeft;
      const targetTop = typeof top === 'number' ? Math.min(top, maxTop) : view.scrollTop;

      if (smooth) {
        const {scrollLeft: startLeft, scrollTop: startTop} = view;
        const deltaLeft = targetLeft - startLeft;
        const deltaTop = targetTop - startTop;

        busy.current = true;

        animation.current = animate((progress) => {
          view.scrollLeft = startLeft + deltaLeft * progress;
          view.scrollTop = startTop + deltaTop * progress;

          if (progress === ANIMATION_MAX_PROGRESS) {
            busy.current = false;
          }
        }, duration);
      } else {
        view.scrollLeft = targetLeft;
        view.scrollTop = targetTop;
      }
    },
    [isBusy, viewRef],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollIntoView = useCallback<ScrollIntoView>(
    (target, {duration = DEFAULT_DURATION, force = false, smooth = false} = {}) => {
      if (!viewRef.current || (!force && isBusy())) {
        return;
      }

      const view = viewRef.current;
      const {borderLeftWidth, borderTopWidth} = window.getComputedStyle(view);
      const viewBorderLeftWidth = parseInt(borderLeftWidth, 10);
      const viewBorderTopWidth = parseInt(borderTopWidth, 10);
      const {
        clientHeight: viewHeight,
        clientWidth: viewWidth,
        scrollLeft: viewScrollLeft,
        scrollTop: viewScrollTop,
      } = view;
      const viewRect = view.getBoundingClientRect();
      const viewLeft = viewRect.left + viewBorderLeftWidth;
      const viewRight = viewLeft + viewWidth;
      const viewTop = viewRect.top + viewBorderTopWidth;
      const viewBottom = viewTop + viewHeight;

      const {
        left: targetLeft,
        top: targetTop,
        height: targetHeight,
        width: targetWidth,
      } = target.getBoundingClientRect();
      const targetRight = targetLeft + targetWidth;
      const targetBottom = targetTop + targetHeight;

      let left = viewScrollLeft;
      let top = viewScrollTop;
      let needScrollLeft = true;
      let needScrollTop = true;

      if (viewLeft > targetLeft || targetWidth > viewWidth) {
        left -= viewLeft - targetLeft;
      } else if (viewRight < targetRight) {
        left += targetRight - viewRight;
      } else {
        needScrollLeft = false;
      }

      if (viewTop > targetTop || targetHeight > viewHeight) {
        top -= viewTop - targetTop;
      } else if (viewBottom < targetBottom) {
        top += targetBottom - viewBottom;
      } else {
        needScrollTop = false;
      }

      if (needScrollLeft || needScrollTop) {
        scrollTo({
          duration,
          left,
          smooth,
          top,
        });
      }
    },
    [isBusy, scrollTo, viewRef],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isScrollBusy: isBusy,
    scrollIntoView,
    scrollTo,
  };
}
