import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {isString} from '@joomcode/deprecated-utils/guards';
import {produce} from 'immer';
import {isFormValidationError as isFormValidationErrors} from 'lib/errors/guards';
import {isFormValidationError} from 'lib/form/types';
import {isFunction, isNumber} from 'lib/guards';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {validateEmail, validateMaxLength, validateMinLength, validatePhone, validateRequired} from 'lib/validation';
import {Field, FieldBlurEvent, FieldChangeEvent, FieldsValues, FormProps, FormState, FormTexts} from './types';
import {isFieldCheckable, isTypeCheckable} from './utils';

type BlurAction = {
  event: FieldBlurEvent;
  type: 'blur';
};

type ChangeAction = {
  event: FieldChangeEvent;
  type: 'change';
};

type SubmitAction = {
  type: 'submit';
};

type InitFieldAction = {
  field: Field;
  type: 'initField';
};

type DestroyFieldAction = {
  field: Field;
  type: 'destroyField';
};

export type ReducerAction = BlurAction | ChangeAction | SubmitAction | InitFieldAction | DestroyFieldAction;

export function initFormState<V extends FieldsValues>({texts}: Pick<FormProps<V>, 'texts'>): FormState<V> {
  return {
    errors: {},
    fields: [],
    submit: 0,
    texts,
    values: {} as V,
  };
}

function getErrorByCode(fieldName: string, code: string, texts?: FormTexts): ExtMessageDescriptor {
  const fieldError = texts?.fieldErrors?.[fieldName]?.[code];

  if (!fieldError) {
    return 'unknown';
  }

  return fieldError;
}

function validateField(field: Field, {errors, texts, values}: FormState): undefined | string {
  if (isFieldCheckable(field)) {
    //
  } else {
    const value = values[field.name];
    let errorCode;

    if (!errorCode && field.required) {
      errorCode = validateRequired(value);
    }

    // The check `isNotNullish` cuts off fields with values `null` and `undefined`.
    // Such values should be checked by function `validateRequired`.
    if (!errorCode && isNotNullish(value)) {
      // If value is a string and length of the string is equal to 0
      // this is empty string that doesn't need to be validated.
      // Such values should be checked by `validateRequired` also.
      if (!(isString(value) && value.length === 0)) {
        if (!errorCode && isNumber(field.minLength)) {
          errorCode = validateMinLength(field.minLength, value);
        }

        if (!errorCode && isNumber(field.maxLength)) {
          errorCode = validateMaxLength(field.maxLength, value);
        }

        if (!errorCode && field.type === 'email') {
          errorCode = validateEmail(value);
        }

        if (!errorCode && field.type === 'phone') {
          errorCode = validatePhone(value);
        }
      }
    }

    if (!errorCode && field.equalTo) {
      const targetValue = values[field.equalTo];
      errorCode = value !== targetValue ? 'equalTo' : '';
    }

    if (!errorCode && isFunction(field.validator)) {
      errorCode = field.validator(value);
    }

    if (errorCode) {
      // eslint-disable-next-line no-param-reassign
      errors[field.name] = getErrorByCode(field.name, errorCode, texts);
    }

    return errorCode;
  }

  return undefined;
}

export const formReducerMethods = {
  addField: (state: FormState, field: Field): FormState =>
    produce(state, (draft) => {
      draft.fields.push(field);

      if (isFieldCheckable(field)) {
        //
      } else {
        draft.values[field.name] = field.value;
      }
    }),

  applyError: (state: FormState, error: unknown): FormState =>
    produce(state, (draft) => {
      if (isFormValidationErrors(error)) {
        error.payload.fieldValidationErrors.forEach((item) => {
          draft.errors[item.field] = item.message;
        });
      } else if (isFormValidationError(error)) {
        Object.keys(error.messages).forEach((fieldName) => {
          draft.errors[fieldName] = error.messages[fieldName];
        });
        if (error.generalMessage) {
          draft.generalError = error.generalMessage;
        }
      }
    }),

  onFieldBlur: (state: FormState, event: FieldBlurEvent): FormState =>
    produce(state, (draft) => {
      const {name, type} = event;

      if (isTypeCheckable(type)) {
        //
      } else {
        const field = state.fields.find((item) => item.name === name);

        if (field) {
          validateField(field, draft);
        }
      }
    }),

  onFieldChange: (state: FormState, event: FieldChangeEvent): FormState =>
    produce(state, (draft) => {
      const {name, type, value} = event;

      if (isTypeCheckable(type)) {
        //
      } else {
        draft.values[name] = value;
        delete draft.errors[name];
      }
    }),

  removeField: (state: FormState, field: Field): FormState =>
    produce(state, (draft) => {
      if (isFieldCheckable(field)) {
        //
      } else {
        draft.fields = state.fields.filter((item) => field.name !== item.name);
        delete draft.values[field.name];
      }
    }),

  reset: (state: FormState): FormState => ({
    ...initFormState({texts: state.texts}),
    fields: state.fields,
  }),

  submit: (state: FormState): FormState =>
    produce(state, (draft) => {
      let submit = true;

      draft.fields.forEach((field) => {
        const errorCode = validateField(field, draft);
        submit = submit && !errorCode;
      });
      draft.submit += Number(submit);
      draft.generalError = undefined;
    }),
};
