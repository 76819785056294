import {refreshOrCreate, RefreshOrCreateParams} from 'api/device/refreshOrCreate';
import type {Client} from 'lib/client';
import {Preferences} from 'lib/preferences/types';
import {Transport} from './Transport';

export class TokensTransport extends Transport {
  private client: Client;

  constructor(client: Client) {
    super({
      baseUrl: client.endpoints.mainApi,
      clientAddress: client.clientAddress,
      cookies: client.cookies,
      origin: client.config.origin,
      ssrId: client.ssrId,
      userAgent: client.userAgent,
    });

    this.client = client;
  }

  refreshOrCreate(preferences?: Preferences): ReturnType<typeof refreshOrCreate> {
    const params: RefreshOrCreateParams = {
      sysInfo: {
        osType: this.client.platform.osName,
      },
    };

    if (preferences) {
      params.preferences = preferences;
    }

    return refreshOrCreate(this.client, params);
  }
}
