import {messages} from 'components/ErrorMessage/messages';
import {SplashContent, SplashContentTestId} from 'components/SplashContent';
import {SupportEmail} from 'components/SupportEmail';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';

export type OopsTestId = SplashContentTestId;

type Props = TestIdProp<OopsTestId>;

export function Oops({testId}: Props): React.ReactElement {
  const email = <SupportEmail />;

  return (
    <SplashContent
      image='/illustrations/oops.svg'
      message={messages.message}
      messageValues={{email}}
      testId={testId}
      title={messages.title}
    />
  );
}
