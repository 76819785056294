import {useEvent, useStore} from 'lib/effector';
import omit from 'lodash/omit';
import {registerUserFx} from 'models/registrationCallback/registrationEvent';
import {$userAnonymous} from 'models/user';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

const PRELIMINARY_AUTH_PARAMETR = 'withPreliminaryAuth';

export const usePreliminaryAuth = () => {
  const router = useRouter();

  const userAnonymous = useStore($userAnonymous);

  const registerEvent = useEvent(registerUserFx);

  useEffect(() => {
    const originRouterPush = router.push;

    router.push = (...args) =>
      // catch the error which we throw when prevented route
      originRouterPush(...args).catch((e) => {
        if (e.cause !== 'authRouteError') {
          throw e;
        }
        return false;
      });
  }, [router]);

  useEffect(() => {
    const routerChangeHandler = (url: string) => {
      const urlObj = new URL(url, window.location.origin);
      if (!urlObj.searchParams.has(PRELIMINARY_AUTH_PARAMETR) || !userAnonymous) {
        router.events.emit('routeChangeComplete');
      } else {
        urlObj.searchParams.delete(PRELIMINARY_AUTH_PARAMETR);
        registerEvent().finally(() => router.push(`${urlObj.toString()}`));
        router.events.emit('routeChangeError');

        // throw an error to prevent route
        throw new Error("Don't worry just a prevent route cause need registration ", {cause: 'authRouteError'});
      }
    };

    router.events.on('routeChangeStart', routerChangeHandler);
    return () => {
      router.events.off('routeChangeStart', routerChangeHandler);
    };
  }, [userAnonymous]);

  useEffect(() => {
    if (typeof router.query[PRELIMINARY_AUTH_PARAMETR] === 'string' && !userAnonymous) {
      router.replace({pathname: router.pathname, query: omit(router.query, PRELIMINARY_AUTH_PARAMETR)}, undefined, {
        shallow: true,
      });
    }
  }, [router, userAnonymous]);
};
