import {isArray} from 'lib/guards';
import {
  OriginalFilter,
  OriginalFilterTreeItem,
  OriginalFilterTreeItemList,
  OriginalFilterTreeValue,
  FiltersAndSorting,
  OriginalFiltersAndSorting,
} from '../types';
import {convertOriginalFilters, convertOriginalSorting} from './converters';

export function convertOriginalFiltersAndSorting({
  appliedFilters,
  appliedSorting,
  availableFilters,
  availableSorting,
}: OriginalFiltersAndSorting): FiltersAndSorting {
  const result: FiltersAndSorting = {};

  if (Array.isArray(availableSorting)) {
    result.sortingList = convertOriginalSorting(availableSorting, appliedSorting);
  }

  if (Array.isArray(availableFilters)) {
    Object.assign(result, convertOriginalFilters(availableFilters, appliedFilters));
  }

  return result;
}

export function filterOriginalTreeFilterItems(
  treeFilter: OriginalFilter<OriginalFilterTreeValue>,
  cb: (item: OriginalFilterTreeItem, depth: number) => unknown,
): OriginalFilterTreeItemList {
  const result: OriginalFilterTreeItemList = [];
  const resultMap: Record<string, number> = {};

  const filterItems = (items: OriginalFilterTreeItemList | undefined, depth: number) => {
    if (!isArray(items)) {
      return;
    }

    for (const item of items) {
      if (item.id in resultMap) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (cb(item, depth)) {
        result.push(item);
        resultMap[item.id] = result.length - 1;
      } else {
        filterItems(item.children, depth + 1);
      }
    }
  };

  filterItems(treeFilter.value.items, 0);

  return result;
}
