import {useCallbackWithoutArgs} from 'lib/hooks';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TELEGRAM_URL} from 'lib/router/constants';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Button} from 'uikit/Button';
import {DialogContent} from 'uikit/Dialog';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Follow',
    description: 'CommunicationDialog: Button on success',
  },
  message: {
    defaultMessage: 'We will contact you within 1 business day.',
    description: 'CommunicationDialog: Message if successful',
  },
  ok: {
    defaultMessage: 'OK',
    description: 'CommunicationDialog: Button on success',
  },
  social: {
    defaultMessage: 'Follow us on Telegram to keep up with our news and hot product selections.',
    description: 'CommunicationDialog: Message if successful',
  },
  title: {
    defaultMessage: 'Thank you for contacting us',
    description: 'CommunicationDialog: Header if successful',
  },
});

export type SuccessViewTestId = {
  button: unknown;
  image: unknown;
  message: unknown;
  social: unknown;
  title: unknown;
};

type Props = TestIdProp<SuccessViewTestId> & {
  onOk: () => void;
  successIllustration?: string;
};

export function SuccessView({onOk, testId, successIllustration}: Props): React.ReactElement {
  const language = useLanguage();
  const landingsMode = language !== Language.RU && language !== Language.EN;
  const showFooter = !landingsMode;
  const showOkButton = landingsMode;
  const handleClick = useCallbackWithoutArgs(onOk);

  return (
    <DialogContent testId={testId}>
      <div className={styles.content}>
        <img
          alt='Illustration of dialog'
          className={styles.image}
          data-test-id={testId?.image}
          src={successIllustration || '/illustrations/consultation.svg'}
        />
        <div className={styles.text}>
          <div className={styles.title} data-test-id={testId?.title}>
            <FormattedMessage {...messages.title} />
          </div>
          <div className={styles.message} data-test-id={testId?.message}>
            <FormattedMessage {...messages.message} />
          </div>
          {showOkButton && (
            <Button color='black' onClick={handleClick} size='large'>
              <FormattedMessage {...messages.ok} />
            </Button>
          )}
        </div>
        {showFooter && (
          <div className={styles.footer}>
            <div className={styles.social} data-test-id={testId?.social}>
              <FormattedMessage {...messages.social} />
            </div>
            <Button
              color='outline-white'
              href={TELEGRAM_URL.joomPro}
              iconLeft={<span className={styles.icon} />}
              onClick={handleClick}
              shape='round'
              target='_blank'
              testId={testId?.button}
              type='link'
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </div>
        )}
      </div>
    </DialogContent>
  );
}
