import {Client} from 'lib/client';
import {DeliveryChannelType} from 'lib/deliveryChannel/types';
import {ProductPrices} from 'lib/product/types';

type SelectedVariant = {
  quantity: number;
  variantId: string;
};

export type ProductPricesPayload = {
  items: SelectedVariant[];
  productId: string;
};

type ProductPricesRequest = ProductPricesPayload & {
  channelType: DeliveryChannelType;
};

export type ProductPricesResponse = {
  payload: ProductPrices;
};

export function loadPrices(client: Client, request: ProductPricesPayload): Promise<ProductPrices> {
  const body: ProductPricesRequest = {
    ...request,
    channelType: 'sea',
  };

  return client.mainApi.post<ProductPricesResponse>('products/prices', {body}).then(({body: {payload}}) => payload);
}
