import {Key} from '@joomcode/deprecated-utils/keyboard/keys';
import {useNeighborsIndexes} from 'lib/hooks/useNeighborsIndexes';
import React, {useState, useCallback, useRef, KeyboardEventHandler} from 'react';

type Props = {
  lastIndex: number;
  onEnter(activeIndex: number): void;
};

type UseKeyboardActiveIndexResult = {
  activeIndex: number;
  handleKeyDown: KeyboardEventHandler;
  reset: () => void;
};

export function useKeyboardActiveIndex({lastIndex, onEnter}: Props): UseKeyboardActiveIndexResult {
  const {getLeftIndex, getRightIndex} = useNeighborsIndexes(lastIndex);
  const [activeIndex, setActiveIndex] = useState(-1);

  // Need to not reinitialise handleKeyDown callback
  // on each update of itemIndex value.
  const activeIndexRef = useRef<number>(activeIndex);
  activeIndexRef.current = activeIndex;

  const reset = useCallback(() => {
    setActiveIndex(-1);
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case Key.ARROW_DOWN:
          event.preventDefault();
          setActiveIndex(getRightIndex);
          break;
        case Key.ARROW_UP:
          event.preventDefault();
          setActiveIndex(getLeftIndex);
          break;
        case Key.ENTER:
          event.preventDefault();
          setActiveIndex(-1);

          if (onEnter) {
            onEnter(activeIndexRef.current);
          }

          break;
        default:
        // do nothing
      }
    },
    [onEnter, getRightIndex, getLeftIndex],
  );

  return {activeIndex, handleKeyDown, reset};
}
