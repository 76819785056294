import classNamesBind from 'classnames/bind';
import React from 'react';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

type Props = {
  children: React.ReactNode;
  sticky?: boolean;
};

export function DialogActions({children, sticky = false}: Props): React.ReactElement {
  return <div className={cn('actions', {sticky})}>{children}</div>;
}
