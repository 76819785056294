import {assertNever} from '@joomcode/deprecated-utils/types';
import {authLogger} from 'lib/logger';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {PasswordRecoverySentView} from './PasswordRecoverySentView';
import {PasswordRecoveryView} from './PasswordRecoveryView';
import {SignInByPhone} from './SignInByPhone';
import {SignInView} from './SignInView';
import {SignUpView} from './SignUpView';
import {AuthProps, AuthViewName, AuthByPhoneViewName, AuthViewsTestId} from './types';

type Props = AuthProps & TestIdProp<AuthViewsTestId>;

export function Auth({
  testId,
  onSignInByEmail,
  onSignUpByEmail,
  onSignInByPhone,
  onRequestRecovery,
  onSendOtp,
  onViewChange,
  onPhoneViewChange,
  signInError,
  signInPending,
  signUpError,
  signUpPending,
  sendOtpData,
  sendOtpError,
  sendOtpLoading,
  requestRecoveryError,
  requestRecoveryPending,
  requestRecoveryEmail,
  requestRecoverySentAction,
  origin,
  view,
  phoneView,
  retryAfterMs,
  withBackButton,
}: Props): React.ReactElement | null {
  const handleBack = useCallback(() => {
    onPhoneViewChange(AuthByPhoneViewName.PHONE);
  }, [onPhoneViewChange]);

  let content = null;
  switch (view) {
    case AuthViewName.SIGN_IN_BY_PHONE: {
      content = (
        <SignInByPhone
          onBack={handleBack}
          onSendOtp={onSendOtp}
          onSubmit={onSignInByPhone}
          origin={origin}
          retryAfterMs={retryAfterMs}
          sendOtpData={sendOtpData}
          sendOtpError={sendOtpError}
          sendOtpLoading={sendOtpLoading}
          signInError={signInError}
          signInLoading={signInPending}
          testId={testId?.signInByPhone}
          view={phoneView}
          withBackButton={withBackButton}
        />
      );
      break;
    }

    case AuthViewName.SIGN_IN: {
      content = (
        <SignInView
          error={signInError}
          onSubmit={onSignInByEmail}
          onViewChange={onViewChange}
          pending={signInPending}
          testId={testId?.signInByEmail}
        />
      );
      break;
    }

    case AuthViewName.SIGN_UP: {
      content = (
        <SignUpView
          error={signUpError}
          onSubmit={onSignUpByEmail}
          onViewChange={onViewChange}
          pending={signUpPending}
          testId={testId?.signUpByEmail}
        />
      );
      break;
    }

    case AuthViewName.PASSWORD_RECOVERY: {
      content = (
        <PasswordRecoveryView
          error={requestRecoveryError}
          onSubmit={onRequestRecovery}
          onViewChange={onViewChange}
          pending={requestRecoveryPending}
          testId={testId?.passwordRecovery}
        />
      );
      break;
    }

    case AuthViewName.PASSWORD_RECOVERY_SENT: {
      if (requestRecoveryEmail) {
        content = (
          <PasswordRecoverySentView email={requestRecoveryEmail} testId={testId?.passwordRecoverySent}>
            {requestRecoverySentAction}
          </PasswordRecoverySentView>
        );
      }
      break;
    }

    default:
      assertNever(view);
      authLogger.error(`View '${view}' is not implemented`);
  }

  return content;
}
