import {searchPhraseSuggest} from 'api/search/searchPhraseSuggest';
import {attach} from 'effector';
import {createStore, createClientEffect, createEvent} from 'lib/effector';
import {SearchSuggestion} from 'lib/search/suggest';

type SearchPhraseSuggestState = {
  loading: boolean;
  searchSuggestions?: SearchSuggestion[];
  searchSuggestionsPhrase?: string;
};

export const initialState: SearchPhraseSuggestState = {
  loading: false,
  searchSuggestions: [],
  searchSuggestionsPhrase: '',
};

export const $searchSuggestions = createStore<SearchPhraseSuggestState>('searchSuggestions', initialState);

export const resetSuggest = createEvent();

export const searchPhraseSuggestFx = attach({
  effect: createClientEffect(searchPhraseSuggest),
  mapParams: (query: string) => ({query}),
});
