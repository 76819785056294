import {Image} from 'components/Image';
import {isMessageDescriptor} from 'lib/guards';
import {ImageBundle} from 'lib/image/types';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export const HINT_IMAGES = {
  done: '/illustrations/externalDone.svg',
  lock: '/illustrations/externalLock.svg',
};

type Props = {
  hintImage?: string | ImageBundle;
  image?: string | ImageBundle;
  subtitle?: ExtMessageDescriptor | React.ReactNode;
  title?: ExtMessageDescriptor | React.ReactNode;
};

export function ViewHeader({image, hintImage, title, subtitle}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.header}>
      <div className={styles.images}>
        {hintImage && image && <Image className={styles.hintImage} sizes={40} src={hintImage} />}
        {image && <Image className={styles.image} sizes={120} src={image} />}
      </div>
      {title && <div className={styles.title}>{isMessageDescriptor(title) ? buildMessage(intl, title) : title}</div>}
      {subtitle && (
        <div className={styles.subtitle}>{isMessageDescriptor(subtitle) ? buildMessage(intl, subtitle) : subtitle}</div>
      )}
    </div>
  );
}
