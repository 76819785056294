import {CnpjInput} from 'components/FinalForm/CnpjInput';
import {InputField} from 'components/FinalForm/InputMuiField';
import {validateCnpj} from 'lib/finalForm/validators';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const Cnpj = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  return (
    <InputField
      InputProps={{inputComponent: CnpjInput}}
      label={intl.formatMessage(fieldMessages.cnpj)}
      name={fieldName}
      required={required}
      validate={validateCnpj}
    />
  );
};
