import {Mask} from 'lib/mask';
import {PhoneConfig} from 'lib/phone/types';
import {RE_NON_DIGIT} from 'lib/validation';

export function transformPhoneBeforeInput(value: string, config: PhoneConfig): string {
  const cleared = value.replace(RE_NON_DIGIT, '');
  const code = config.codes.find((codeItem) => cleared.startsWith(codeItem));

  if (code) {
    const maskItems = new Mask(config.mask).getItems();

    const phoneLength = maskItems.reduce((len, maskItem) => len + Number(maskItem.isEditable()), code.length);

    const nsnLength = config.nsnLength ?? [phoneLength];

    const isValidOutput = nsnLength.reduce((result, length) => {
      return result || cleared.length >= length;
    }, false);

    if (isValidOutput) {
      return cleared.substring(code.length);
    }
  }

  return value;
}
