import {useDefaultValidator, useFormMessages} from 'lib/finalForm/hooks';
import {BaseFieldProps} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {Field, FieldRenderProps} from 'react-final-form';
import {Textarea, TextareaTestId, TextareaBlurHandler, TextareaFocusHandler} from 'uikit/Textarea';

export type TextareaFieldTestId = TextareaTestId;

type Props = BaseFieldProps &
  TestIdProp<TextareaFieldTestId> & {
    autoFocus?: boolean;
    disabled?: boolean;
    onBlur?: TextareaBlurHandler;
    onFocus?: TextareaFocusHandler;
    rows?: number;
  };

/**
 * TODO: https://joom-team.atlassian.net/browse/PRO-5944
 * @deprecated use `import { InputField } from "components/FinalForm/InputMuiField";` with multiline prop instead
 */
export function TextareaField({
  autoFocus,
  testId,
  name,
  label,
  required,
  disabled,
  readOnly,
  placeholder,
  validate,
  onBlur,
  onFocus,
  id,
  rows,
  ...props
}: Props): React.ReactElement {
  const messages = useFormMessages();
  const requiredMessage = props.requiredMessage || messages[name]?.required;
  const labelValue = label || messages[name]?.label;
  const placeholderValue = placeholder || messages[name]?.placeholder;

  const handleValidate = useDefaultValidator({required, requiredMessage, validate});

  return (
    <Field name={name} validate={handleValidate}>
      {({input, meta}: FieldRenderProps<string>) => {
        const handleBlur: TextareaBlurHandler = (event) => {
          input.onBlur(event);
          if (onBlur) {
            onBlur(event);
          }
        };

        // TODO: PRO-1572 New forms
        /* eslint-disable react/jsx-no-bind */
        return (
          <Textarea
            autoFocus={autoFocus}
            disabled={disabled}
            error={getErrorMessage(meta)}
            id={id}
            label={labelValue}
            name={input.name}
            onBlur={handleBlur}
            onChange={input.onChange}
            onFocus={onFocus}
            placeholder={placeholderValue}
            readOnly={readOnly}
            rows={rows}
            testId={testId}
            value={input.value}
          />
        );
        /* eslint-enable react/jsx-no-bind */
      }}
    </Field>
  );
}
