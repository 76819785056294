export enum AuthPhoneErrorType {
  RATE_LIMITED = 'phoneservice.rate_limited',
}

export enum AuthViewName {
  PASSWORD_RECOVERY = 'passwordRecovery',
  PASSWORD_RECOVERY_SENT = 'passwordRecoverySent',
  SIGN_IN = 'signIn',
  SIGN_IN_BY_PHONE = 'signInByPhone',
  SIGN_UP = 'signUp',
}

export enum AuthOrigin {
  GENERAL = 'general',
  ORDER_CHECKOUT = 'orderCheckOut',
  SAMPLE_ORDER_CHECKOUT = 'sampleOrderCheckOut',
  SELF_SERVICE = 'selfService',
}

export enum AuthByPhoneViewName {
  OTP = 'otp',
  PHONE = 'phone',
}

export enum AuthSuccessExtraViewName {
  ALREADY_SIGN_IN = 'alreadySignIn',
}

export type AuthPhoneRateLimitExceededError = {
  message?: string;
  payload?: {
    retryAfterMs: number;
  };
  type: AuthPhoneErrorType;
};
