import {ConsultationDialog as ConsultationDialogComponent} from 'components/ConsultationDialog';
import {getMonthFromDate} from 'lib/datetime';
import {useEvent, useStore} from 'lib/effector';
import {useFormSuccessEvent} from 'lib/hooks';
import {$consultationDialog, closeConsultationDialog, sendPageConsultationDataFx} from 'models/consultation';
import {$serverDate} from 'models/serverDate';
import React from 'react';
import {rootTestId} from 'testId';

export function ConsultationDialog(): React.ReactElement | null {
  const dialogState = useStore($consultationDialog);
  const handleClose = useEvent(closeConsultationDialog);
  const handleSubmit = useEvent(sendPageConsultationDataFx);
  const serverDate = useStore($serverDate);
  const {onSuccess} = useFormSuccessEvent();

  const currentMonth = getMonthFromDate(new Date(serverDate));

  if (!dialogState.opened) {
    return null;
  }

  return (
    <ConsultationDialogComponent
      currentMonth={currentMonth}
      error={dialogState.error}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      processing={dialogState.processing}
      reason={dialogState.reason}
      section={dialogState.section}
      success={dialogState.success}
      testId={rootTestId?.consultationDialog}
    />
  );
}
