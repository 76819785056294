import {CommunicationDialog, CommunicationDialogTestId} from 'components/CommunicationDialog';
import {FormViewFields} from 'components/CommunicationDialog/types';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {messages} from './messages';
import {ReferralData, SubmitHandler} from './types';

export type ConsultationDialogTestId = CommunicationDialogTestId;

type Props = TestIdProp<ConsultationDialogTestId> & {
  error?: unknown;
  onClose: () => void;
  onSubmit: SubmitHandler;
  onSuccess?: () => void;
  processing?: boolean;
  section: string;
  success?: boolean;
};

const FORM_FIELDS: FormViewFields = ['phone', 'promocode'];

export function ReferralDialog({
  error,
  onClose,
  onSubmit,
  onSuccess,
  processing,
  success,
  testId,
  section,
}: Props): React.ReactElement {
  const language = useLanguage();
  return (
    <CommunicationDialog<ReferralData>
      error={error}
      fields={FORM_FIELDS}
      illustration={language === Language.RU ? '/illustrations/consultation-ru.svg' : '/illustrations/consultation.svg'}
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      processing={processing}
      section={section}
      subtitle={messages.subtitle}
      success={success}
      successIllustration={language === Language.RU ? '/illustrations/consultation-success-ru.svg' : undefined}
      testId={testId}
      title={messages.title}
    />
  );
}
