import {WizardReturnShape, Wizard} from 'components/Wizard';
import React from 'react';
import {useIntl} from 'react-intl';
import {stepToFields, renderField} from './config';
import styles from './index.module.scss';
import {messages} from './messages';
import {SubmitHandler, FormData} from './types';

type Props = {
  initialValues: FormData;
  onSubmit: SubmitHandler;
  wizard: WizardReturnShape;
};

export function FormView({onSubmit, wizard, initialValues}: Props): React.ReactElement {
  const intl = useIntl();
  return (
    <Wizard
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...wizard}
      initialValues={initialValues}
      onNext={onSubmit}
      onSubmit={onSubmit}
      submitButton={intl.formatMessage(messages.register)}
    >
      {stepToFields.map((fieldNames, step) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Wizard.Step className={styles.form} key={step}>
            {fieldNames.map((fieldName) => renderField({fieldName}))}
          </Wizard.Step>
        );
      })}
    </Wizard>
  );
}
