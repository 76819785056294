import {Expandable} from '@joomcode/deprecated-utils/types';
import {$blogArticles, loadBlogArticlesFx, BlogArticlesState} from './index';
import {toKey} from './utils';

$blogArticles
  .on(loadBlogArticlesFx, (state, params): BlogArticlesState => {
    const key = toKey(params);
    return {
      ...state,
      byKey: {
        ...state.byKey,
        [key]: {
          ...((state.byKey as Expandable<BlogArticlesState['byKey']>)[key] || {}),
          loading: true,
          loadingError: undefined,
        },
      },
    };
  })
  .on(loadBlogArticlesFx.done, (state, {params, result}): BlogArticlesState => {
    const key = toKey(params);
    return {
      ...state,
      byKey: {
        ...state.byKey,
        [key]: {
          ...((state.byKey as Expandable<BlogArticlesState['byKey']>)[key] || {}),
          ...((state as Expandable<BlogArticlesState>)[key] || {}),
          data: result,
          loading: false,
        },
      },
    };
  })
  .on(loadBlogArticlesFx.fail, (state, {params, error}): BlogArticlesState => {
    const key = toKey(params);
    return {
      ...state,
      byKey: {
        ...state.byKey,
        [key]: {
          ...((state.byKey as Expandable<BlogArticlesState['byKey']>)[key] || {}),
          loading: false,
          loadingError: error,
        },
      },
    };
  });
