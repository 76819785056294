import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Payload, loadCartPrices} from 'api/cart/loadCartPrices';
import {attach} from 'effector';
import {CartPricesResponse} from 'lib/cart/types';
import {createClientEffect, createStore} from 'lib/effector';
import {ParamsWithRequestId} from 'lib/effector/types';
import uuid from 'uuid';

export type CartPricesState = {
  data: CartPricesResponse | undefined;
  dataState: DataState;
  error?: Error;
  requestId?: string;
};

export const $cartPrices = createStore<CartPricesState>('cartPrices', {
  data: undefined,
  dataState: DataState.IDLE,
});

export const loadCartPricesWithReqIdFx = createClientEffect<ParamsWithRequestId<Payload>, CartPricesResponse>(
  loadCartPrices,
);

export const loadCartPricesFx = attach({
  effect: loadCartPricesWithReqIdFx,
  mapParams: (params: Payload) => {
    return {
      ...params,
      requestId: uuid(),
    };
  },
});
