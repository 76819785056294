import {DataState} from '@joomcode/deprecated-utils/dataState';
import {CartItemVariant} from 'lib/cart';
import {$cart, loadCartItemsFx, addCartItemFx, removeCartItemsFx, updateCartItemFx, updateCartItem} from './index';
import './itemsCount/init';
import './prices/init';

$cart
  .on(loadCartItemsFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(loadCartItemsFx.doneData, (state, response) => ({
    ...state,
    data: response,
    dataState: DataState.LOADED,
  }))
  .on(loadCartItemsFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));

$cart
  .on(addCartItemFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(addCartItemFx.doneData, (state, response) => ({
    ...state,
    data: [response, ...state.data],
    dataState: DataState.LOADED,
  }))
  .on(addCartItemFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));

$cart
  .on(removeCartItemsFx, (state, payload) => {
    const data = state.data.filter((item) => !payload.ids.includes(item.id));
    return {
      ...state,
      data,
      dataState: DataState.LOADING,
    };
  })
  .on(removeCartItemsFx.doneData, (state, response) => {
    const data = state.data.filter((item) => !response.includes(item.id));
    return {
      ...state,
      data,
      dataState: DataState.LOADED,
    };
  })
  .on(removeCartItemsFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));

$cart
  .on(updateCartItem, (state, payload) => {
    const index = state.data.findIndex((item) => item.id === payload.id);
    if (index === -1) {
      return state;
    }

    const newData = [...state.data];
    const cartItem = newData[index];
    const variantToQuantity = new Map<string, number>(
      payload.selectedVariantItems.map((item) => [item.variantId, item.quantity]),
    );

    const variants: CartItemVariant[] = cartItem.variants.map((item) => {
      return {
        ...item,
        quantity: variantToQuantity.get(item.id) || item.quantity,
      };
    });

    const newCartItem = {...cartItem, variants};
    newData.splice(index, 1, newCartItem);

    return {
      ...state,
      data: newData,
      dataState: DataState.LOADING,
    };
  })
  .on(updateCartItemFx.doneData, (state, response) => {
    const newData = [...state.data];
    const index = state.data.findIndex((item) => item.id === response.id);
    if (index === -1) {
      newData.push(response);
    } else {
      newData.splice(index, 1, response);
    }

    return {
      ...state,
      data: newData,
      dataState: DataState.LOADED,
    };
  })
  .on(updateCartItemFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));
