import {ReferralDialog as ReferralDialogComponent} from 'components/ReferralDialog';
import {useEvent, useStore} from 'lib/effector';
import {useFormSuccessEvent} from 'lib/hooks';
import {$referralDialog, closeReferralDialog, sendPageReferralDataFx} from 'models/referral';
import React from 'react';
import {rootTestId} from 'testId';

export function ReferralDialog(): React.ReactElement | null {
  const dialogState = useStore($referralDialog);
  const handleClose = useEvent(closeReferralDialog);
  const handleSubmit = useEvent(sendPageReferralDataFx);
  const {onSuccess} = useFormSuccessEvent();

  if (!dialogState.opened) {
    return null;
  }

  return (
    <ReferralDialogComponent
      error={dialogState.error}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      processing={dialogState.processing}
      section={dialogState.section}
      success={dialogState.success}
      testId={rootTestId?.consultationDialog}
    />
  );
}
