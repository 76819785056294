import {BlogArticle} from 'lib/blogArticle/types';
import {localeToCountryCode} from 'lib/blogArticle/utils';
import {Client} from 'lib/client';

type BlogArticleResponse = {payload: BlogArticle};

export type BlogArticlePayload = {
  locale: string | undefined;
  slug: string;
};

export function loadBlogArticle(client: Client, {locale, ...restPayload}: BlogArticlePayload): Promise<BlogArticle> {
  const body = {...restPayload, country: localeToCountryCode(locale)};
  return client.mainApi.post<BlogArticleResponse>('blog/article/get', {body}).then(({body: {payload}}) => payload);
}
