import {getImageEmbedding} from 'api/search/getImageEmbedding';
import {searchProducts} from 'api/search/searchProducts';
import {attach} from 'effector';
import {Category} from 'lib/category/types';
import {alignItemsByDivisor} from 'lib/contentList';
import {ContentList} from 'lib/contentList/types';
import {createStore, createClientEffect, createEvent} from 'lib/effector';
import {FilterList, SortingList} from 'lib/filtersAndSorting/types';
import {SearchParams} from 'lib/search/types';

type SearchState = {
  categories?: Category[];
  currentCategory?: Category;
  filename?: string;
  filterList?: FilterList;
  imageId?: string;
  items?: ContentList;
  loading: boolean;
  loadingError?: Error;
  nextPageToken?: string;
  request?: SearchParams;
  searchResultsUniqId?: string;
  sortingList?: SortingList;
};

export const initialSearchState: SearchState = {
  loading: false,
};

export const $search = createStore<SearchState>('search', initialSearchState);

export const $searchPhrase = createStore<string>('searchPhrase', '');

export const $searchItems = $search.map((state: SearchState) => {
  if (state.items?.length && state.nextPageToken !== undefined) {
    return alignItemsByDivisor(state.items);
  }

  // Pass all items if it is last page.
  // That means backend have no items to send to client,
  // and we don't need to filter current items manually.
  return state.items;
});

export const loadFx = createClientEffect(searchProducts);

export const getImageEmbeddingFx = createClientEffect(getImageEmbedding);

export const loadMoreFx = attach({
  effect: loadFx,
  mapParams: (_: void, {request, nextPageToken: pageToken, searchResultsUniqId}) => ({
    ...request,
    pageToken,
    searchResultsUniqId,
  }),
  source: $search,
});

export const setSearchPhrase = createEvent<string>();

export const resetSearchPhrase = createEvent();
