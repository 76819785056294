import {ItemContentType} from '../types';
import {UniqueSellingPointBannerItemCreator} from './types';

export const createUniqueSellingPointItem: UniqueSellingPointBannerItemCreator = (original) => {
  return {
    content: undefined,
    id: original.id,
    type: ItemContentType.UNIQUE_SELLING_POINT_BANNER,
  };
};
