import {loadAsyncScript} from 'lib/dom';
import {isArray} from 'lib/guards';
import {analyticsLogger} from 'lib/logger';
import {GtmData} from './types';

type GtmPluginOptions = {
  id: string;
};

export class GtmPlugin {
  private readonly id: string;

  private started: boolean;

  private enabledValue: boolean;

  constructor(options: GtmPluginOptions) {
    this.id = options.id;
    this.started = false;
    this.enabledValue = false;
  }

  private init() {
    this.started = true;

    if (__SERVER__) {
      return;
    }

    if (!isArray(window.dataLayer)) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      event: 'gtm.js',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'gtm.start': Date.now(),
    });

    loadAsyncScript(`//www.googletagmanager.com/gtm.js?id=${this.id}&l=dataLayer`).catch((error) => {
      analyticsLogger.error('GTM: loading is failed', error);
    });
  }

  get enabled(): boolean {
    return this.enabledValue;
  }

  set enabled(value: boolean) {
    this.enabledValue = value;

    if (value && !this.started) {
      this.init();
    }
  }

  dataLayer(event: GtmData): void {
    if (__SERVER__) {
      analyticsLogger.warn("GTM: can't send event on the server");
      return;
    }

    if (!this.enabledValue) {
      return;
    }

    try {
      if ('ecommerce' in event) {
        // Clear the previous ecommerce object
        // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm&hl=ru#clear_the_ecommerce_object
        window.dataLayer.push({ecommerce: null});
      }
      window.dataLayer.push(event);
    } catch (error) {
      analyticsLogger.error('GTM: send event error', {error, event});
    }
  }
}
