import {sample} from 'effector';
import {createEffect} from 'lib/effector';
import {
  $registrationCallback,
  addRegistrationCallback,
  callRegistrationCallback,
  cleanRegistrationCallback,
  Callback,
  CallbackState,
} from '.';

$registrationCallback.on(addRegistrationCallback, (state, data) => ({...state, data})).reset(cleanRegistrationCallback);

sample({
  clock: callRegistrationCallback,
  fn: ({data: callback}, {state}) => ({callback, state}),
  source: $registrationCallback,
  target: createEffect(({callback, state}: {callback: Callback | undefined; state: CallbackState}) => {
    if (callback) {
      callback({state});
      cleanRegistrationCallback();
    }
  }),
});
