import 'intersection-observer';
import {MutableRefObject, RefObject, useEffect, useRef} from 'react';

type PreviewHandler = () => void;
type Options = Omit<IntersectionObserverInit, 'root'>;
type ObserverRef = MutableRefObject<IntersectionObserver | undefined>;

function destroyObserverRef(observerRef: ObserverRef): void {
  if (observerRef.current) {
    observerRef.current.disconnect();
    // eslint-disable-next-line no-param-reassign
    observerRef.current = undefined;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function useElementPreview<TElement extends Element>(
  target: RefObject<TElement> | MutableRefObject<TElement>,
  onPreview: PreviewHandler,
  {threshold = 1, rootMargin}: Options = {},
): void {
  const observer = useRef<IntersectionObserver>();
  const observingTarget = useRef<TElement>();
  const handlePreview = useRef<PreviewHandler>();
  handlePreview.current = onPreview;

  useEffect(() => {
    if (!target.current) {
      destroyObserverRef(observer);
      observingTarget.current = undefined;
    } else if (target.current instanceof Element && target.current !== observingTarget.current) {
      if (!observer.current) {
        observer.current = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              destroyObserverRef(observer);

              if (handlePreview.current) {
                handlePreview.current();
              }
            }
          },
          {rootMargin, threshold},
        );
      } else {
        observer.current.disconnect();
      }

      observingTarget.current = target.current;
      observer.current.observe(observingTarget.current);
    }
  });

  useEffect(
    () => () => {
      observingTarget.current = undefined;
      handlePreview.current = undefined;
      destroyObserverRef(observer);
    },
    [],
  );
}
