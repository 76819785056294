import {DependencyList, useCallback, useEffect, useMemo, useState} from 'react';
import {useIsFirstRender} from './useIsFirstRender';

/* eslint-disable @typescript-eslint/no-explicit-any */

type Method<State, Params = any> = ((currentState: State) => State) | ((currentState: State, params: Params) => State);

type SmartReducerMethods<State> = {
  [methodName: string]: Method<State, any>;
};

type ExtractState<Methods extends SmartReducerMethods<any>> =
  Methods extends SmartReducerMethods<infer State> ? State : never;

type WrapMethod<M extends Method<any, any>> = M extends (...args: infer Args) => infer Return
  ? Args['length'] extends 2
    ? (params: Args[1]) => Return
    : 1 | 2 extends Args['length']
      ? (params?: Args[1]) => Return
      : () => Return
  : never;

export type WrappedMethods<Methods extends SmartReducerMethods<any>> = {
  [K in keyof Methods]: WrapMethod<Methods[K]>;
};

type ResetStateHandler = () => void;

export function useSmartReducer<Methods extends SmartReducerMethods<any>, State = ExtractState<Methods>>(
  methods: Methods,
  initializer: (prevState?: State) => State,
  deps: DependencyList,
): [State, WrappedMethods<Methods>, ResetStateHandler] {
  const [state, setState] = useState<State>(() => initializer());
  const isFirstRun = useIsFirstRender();

  useEffect(() => {
    if (!isFirstRun()) {
      setState(() => initializer(state));
    }
  }, deps);

  const wrappedMethods = useMemo(() => {
    const resultMethods: Record<string, unknown> = {};

    Object.entries(methods).forEach(([name, method]) => {
      resultMethods[name] = (params: any) => setState((currentState) => method(currentState, params));
    });

    return resultMethods as WrappedMethods<Methods>;
  }, []);

  const reset = useCallback(() => {
    setState(initializer());
  }, [initializer]);

  return [state, wrappedMethods, reset];
}

/* eslint-enable @typescript-eslint/no-explicit-any */
