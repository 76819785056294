import {isRecord} from 'lib/guards';

export type FormValidationError = {
  generalMessage?: string;
  kind: 'FormValidationError';
  messages: {
    [fieldName: string]: string;
  };
};

export function isFormValidationError(error: unknown): error is FormValidationError {
  return isRecord(error) && error.kind === 'FormValidationError';
}

export function formValidationError(messages: Record<string, string>, generalMessage?: string): FormValidationError {
  return {
    generalMessage,
    kind: 'FormValidationError',
    messages,
  };
}

export type Option<T> = {
  label: string;
  value: T;
};

export type FieldValue = string | number | readonly string[] | undefined;
