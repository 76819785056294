import {createDataStore} from '@joomcode/joompro-core';
import {loadDeliveryChannels} from 'api/deliveryChannel/loadDeliveryChannels';
import {DeliveryChannelInfo} from 'lib/deliveryChannel/types';
import {createClientEffect, createEvent} from 'lib/effector';

export const [$deliveryChannels, deliveryChannelsBinders] = createDataStore<DeliveryChannelInfo[]>(
  {data: []},
  {name: 'deliveryChannels'},
);

export const loadDeliveryChannelsFx = createClientEffect(loadDeliveryChannels);

export const resetDeliveryChannels = createEvent();
