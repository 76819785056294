import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';

export enum CompanyRole {
  CEO = 'ceo',
  COMMERCIAL_DIRECTOR = 'commercialDirector',
  MARKETER = 'marketer',
  OWNER = 'owner',
  PROCUREMENT_MANAGER = 'procurementManager',
  PURCHASER = 'purchaser',
}

export const companyRoles = getEnumValues(CompanyRole);

export const isCompanyRole = (value: unknown): value is CompanyRole => {
  return companyRoles.includes(value as CompanyRole);
};
