import {SelectField} from 'components/FinalForm/SelectMuiField';
import {COMPANY_EMPLOYEES_NUMBERS_ORDER} from 'lib/user/companyEmployeesNumber';
import {formatEmployeesNumber} from 'lib/user/companyEmployeesNumber/messages';
import React from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const CompanyEmployeesNumber = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  return (
    <SelectField
      label={intl.formatMessage(fieldMessages.companyEmployeesNumber)}
      name={fieldName}
      options={COMPANY_EMPLOYEES_NUMBERS_ORDER.map((value) => ({
        label: formatEmployeesNumber(value, intl),
        value,
      }))}
      required={required}
    />
  );
};
