import {PromoLink} from 'components/PromoLink';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {reverseUrl} from 'lib/router';
import React, {useCallback} from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import styles from './index.module.scss';

export type Props = {
  message: MessageDescriptor;
};

export function PolicyLineBase({message}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handlePolicyClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'body',
        source: appEnv.currentPageName,
      },
      type: 'policyClick',
    });
  }, [analytics, appEnv]);

  return (
    <div className={styles.policy}>
      <FormattedMessage
        {...message}
        values={{
          link: (content: React.ReactNode) => (
            <PromoLink
              href={reverseUrl.policy()}
              onClick={handlePolicyClick}
              target='_blank'
              theme='default'
              underlined
            >
              {content}
            </PromoLink>
          ),
        }}
      />
    </div>
  );
}
