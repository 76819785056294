import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import {View, ViewActions, ViewHeader} from '../View';
import {messages} from './messages';

export type PasswordRecoverySentTestId = unknown;

type Props = TestIdProp<PasswordRecoverySentTestId> & {
  children?: React.ReactNode;
  email: string;
};

export function PasswordRecoverySentView({email, children, testId}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <View testId={testId}>
      <ViewHeader
        image='/illustrations/sent.svg'
        subtitle={intl.formatMessage(messages.successSubtitle, {email: <b>{email}</b>})}
      />
      {children && <ViewActions>{children}</ViewActions>}
    </View>
  );
}
