import {isString} from 'lib/guards';
import {RE_PHONE, RE_NON_DIGIT} from '../regexps';
import {ErrorCode} from '../types';

export function validatePhone(phone: unknown): ErrorCode.PHONE | undefined {
  if (isString(phone) && phone) {
    const normalizedPhone = phone.replace(RE_NON_DIGIT, '');

    if (RE_PHONE.test(normalizedPhone)) {
      return undefined;
    }
  }

  return ErrorCode.PHONE;
}
