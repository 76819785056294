import classNamesBind from 'classnames/bind';
import {TestIdProp, TestItemProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {dataTestItem} from 'testId';
import {useSelectInterface} from '../hooks';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type SelectOptionTestId = unknown;

export type SelectOptionProps<V> = TestIdProp<SelectOptionTestId> &
  TestItemProp & {
    children?: React.ReactNode;
    value?: V;
  };

export function SelectOption<V>({children, testId, testItem, value}: SelectOptionProps<V>): React.ReactElement {
  const {size, isSelected, setValue} = useSelectInterface<V>();
  const selected = isSelected(value);

  const handleClick = useCallback(() => {
    setValue(value);
  }, [value, setValue]);

  return (
    <button
      aria-selected={selected}
      className={cn(styles.option, `size-${size}`, {selected})}
      data-test-id={testId}
      data-test-item={dataTestItem(testItem)}
      onClick={handleClick}
      role='option'
      type='button'
    >
      <div>{children}</div>
    </button>
  );
}
