import {useContext} from 'react';
import {SelectInterfaceContext} from './contexts';
import {SelectInterface} from './types';

export function useSelectInterface<Value>(): SelectInterface<Value> {
  const selectInterface = useContext(SelectInterfaceContext);

  if (!selectInterface) {
    throw new Error('Select interface must be defined');
  }

  return selectInterface;
}
