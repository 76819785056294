import {$modalsManager, closeDialog, openDialog} from './index';

$modalsManager
  .on(openDialog, (state) => ({
    ...state,
    dialogOpened: true,
  }))
  .on(closeDialog, (state) => ({
    ...state,
    dialogOpened: false,
  }));
