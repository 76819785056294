import {RadioGroupField, RadioItemType} from 'components/FinalForm';
import {generalMessages} from 'lib/i18n/generalMessages';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps, HasProductImport as HasProductImportType} from '../../types';

export const HasProductImport = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();

  const items: RadioItemType<HasProductImportType>[] = useMemo(
    () => [
      {
        label: intl.formatMessage(generalMessages.yes),
        value: 'true',
      },
      {
        label: intl.formatMessage(generalMessages.no),
        value: 'false',
      },
    ],
    [intl],
  );

  return (
    <RadioGroupField
      items={items}
      label={intl.formatMessage(fieldMessages.hasProductImport)}
      name={fieldName}
      required={required}
    />
  );
};
