import {Client} from 'lib/client';
import {Product} from 'lib/product/types';

type ProductRequest = {
  productId: string;
};

type ProductResponse = {
  payload: Product;
};

export function loadProduct(client: Client, {productId}: ProductRequest): Promise<Product> {
  return client.mainApi.post<ProductResponse>(`products/${productId}`).then(({body: {payload}}): Product => payload);
}
