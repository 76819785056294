import React from 'react';
import {CategoryIds} from './fields/CategoryIds';
import {Cnpj} from './fields/Cnpj';
import {CompanyAnnualTurnoverRangeLevelField} from './fields/CompanyAnnualTurnover';
import {CompanyEmployeesNumber} from './fields/CompanyEmployeesNumber';
import {CompanyName} from './fields/CompanyName';
import {CompanyOperationField} from './fields/CompanyOperationField';
import {CompanyRole} from './fields/CompanyRole';
import {CompanyWebsite} from './fields/CompanyWebsite';
import {Email} from './fields/Email';
import {FirstName} from './fields/FirstName';
import {HasProductImport} from './fields/HasProductImport';
import {FieldName, FieldProps} from './types';

export const requiredFields = new Set<FieldName>([
  'firstName',
  'email',
  'companyTurnoverRangeLevel',
  'companyEmployeesNumber',
  'companyOperationFields',
  'categoryIds',
  'cnpj',
]);

// One FieldName should be used one time, otherwise fix logic
export const stepToFields: FieldName[][] = [
  ['firstName', 'email', 'companyEmployeesNumber', 'companyTurnoverRangeLevel'],
  ['categoryIds', 'companyOperationFields', 'cnpj'],
];

type FieldRenderer = (data: FieldProps) => React.ReactElement;

const renderFieldCollection: Record<FieldName, FieldRenderer> = {
  categoryIds: (props) => <CategoryIds key={props.fieldName} {...props} />,
  cnpj: (props) => <Cnpj key={props.fieldName} {...props} />,
  companyEmployeesNumber: (props) => <CompanyEmployeesNumber key={props.fieldName} {...props} />,
  companyName: (props) => <CompanyName key={props.fieldName} {...props} />,
  companyOperationFields: (props) => <CompanyOperationField key={props.fieldName} {...props} />,
  companyRole: (props) => <CompanyRole key={props.fieldName} {...props} />,
  companyTurnoverRangeLevel: (props) => <CompanyAnnualTurnoverRangeLevelField key={props.fieldName} {...props} />,
  companyWebsite: (props) => <CompanyWebsite key={props.fieldName} {...props} />,
  email: (props) => <Email key={props.fieldName} {...props} />,
  firstName: (props) => <FirstName key={props.fieldName} {...props} />,
  hasProductImport: (props) => <HasProductImport key={props.fieldName} {...props} />,
};

type RenderFieldProps = Omit<FieldProps, 'required'>;

export const renderField = ({fieldName}: RenderFieldProps): React.ReactNode => {
  const required = requiredFields.has(fieldName);
  return renderFieldCollection[fieldName]({fieldName, required});
};
