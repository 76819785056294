import {$category, loadCategoryFx, resetCategory} from './index';

$category
  .on(loadCategoryFx, (state) => ({
    ...state,
    loading: true,
    loadingError: undefined,
  }))
  .on(loadCategoryFx.doneData, (state, category) => ({
    ...state,
    category,
    loading: false,
  }))
  .on(loadCategoryFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }))
  .reset(resetCategory);
