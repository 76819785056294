import {AuthViewName, AuthByPhoneViewName, AuthOrigin, SendOtpFormData} from 'components/Auth/types';
import {attach, merge} from 'effector';
import {createEvent, createStore} from 'lib/effector';
import {requestRecoveryFx, signInByEmailFx, signUpByEmailFx, signInByPhoneFx, sendOtpFx} from '../index';

type AuthDialogPhoneState = {
  phoneView: AuthByPhoneViewName;
  retryAfterMs?: number;
  sendOtpData?: SendOtpFormData;
  sendOtpError?: unknown;
  sendOtpLoading?: boolean;
};

type AuthDialogState = AuthDialogPhoneState & {
  origin: AuthOrigin;
  requestRecoveryEmail?: string;
  requestRecoveryError?: unknown;
  requestRecoveryPending?: boolean;
  signInError?: unknown;
  signInPending?: boolean;
  signUpError?: unknown;
  signUpPending?: boolean;
  source?: string;
  view?: AuthViewName;
  visible: boolean;
};

type ChangeViewEvent = AuthViewName;

export type OpenDialogEvent = {
  origin: AuthOrigin;
  redirectUri?: string;
  source?: string;
  view: AuthViewName;
};

const initialState: AuthDialogState = {
  origin: AuthOrigin.GENERAL,
  phoneView: AuthByPhoneViewName.PHONE,
  visible: false,
};

export type AuthRedirectUriState = {
  value?: string;
};

export const $authDialog = createStore<AuthDialogState>('authDialog', initialState);

export const $authRedirectUri = createStore<AuthRedirectUriState>('authRedirectUri', {});

export const resetAuthRedirectUri = createEvent();

export const changeAuthDialogView = createEvent<ChangeViewEvent>();

export const changeAuthPhoneDialogView = createEvent<AuthByPhoneViewName>();

export const openAuthDialog = createEvent<OpenDialogEvent>();

export const openAuthDialogInternal = createEvent<OpenDialogEvent>();

export const closeAuthDialog = createEvent();

export const authByDialogCanceled = merge([closeAuthDialog]);

export const authDialogSignInByEmailFx = attach({effect: signInByEmailFx});

export const authDialogSignUpByEmailFx = attach({effect: signUpByEmailFx});

export const authDialogRequestRecoveryFx = attach({effect: requestRecoveryFx});

export const authDialogSignInByPhoneFx = attach({effect: signInByPhoneFx});

export const authDialogSendOtpFx = attach({effect: sendOtpFx});
