import {serialize as serializeCookie} from 'cookie';
import {CookieSameSite, ValuesMap} from './types';

const VALUES_SEPARATOR = '; ';

export function normalizeSameSite(sameSite?: string): CookieSameSite {
  const lowerCaseSameSite = sameSite?.toLowerCase();
  return lowerCaseSameSite === 'lax' || lowerCaseSameSite === 'strict' || lowerCaseSameSite === 'none'
    ? lowerCaseSameSite
    : undefined;
}

export function serializeValuesMap(values: ValuesMap): string {
  const result = [];

  for (const key of Object.keys(values)) {
    result.push(serializeCookie(key, values[key]));
  }

  return result.join(VALUES_SEPARATOR);
}
