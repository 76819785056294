import {FormTexts} from 'components/Form/types';
import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  email: {
    defaultMessage: 'Email',
    description: 'Field: "Email"',
  },
  firstName: {
    defaultMessage: 'First Name',
    description: 'Field: "Name"',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Field: "Last name"',
  },
  password: {
    defaultMessage: 'Password',
    description: 'Field: "Password"',
  },
  phone: {
    defaultMessage: 'Phone',
    description: 'Field: "Phone"',
  },
  repeatPassword: {
    defaultMessage: 'Repeat your password',
    description: 'Field: "Repeat password"',
  },
});

export const placeholders = defineMessages({
  email: {
    defaultMessage: 'Enter your email',
    description: 'Field hint: "E-mail"',
  },
  firstName: {
    defaultMessage: 'First name required',
    description: 'Field hint: "Name"',
  },
  lastName: {
    defaultMessage: 'Last name required',
    description: 'Field hint: "Last name"',
  },
  password: {
    defaultMessage: 'Enter your password',
    description: 'Hint for the field: "Password"',
  },
  phone: {
    defaultMessage: '+74950000000',
    description: 'Hint for the field: "Phone"',
  },
  repeatPassword: {
    defaultMessage: 'Enter your password again',
    description: 'Field hint: "Repeat password"',
  },
});

export const fieldErrors = {
  email: defineMessages({
    email: {
      defaultMessage: 'Invalid email',
      description: 'Field error text',
    },
    required: {
      defaultMessage: 'Enter your email',
      description: 'Field error text',
    },
  }),
  firstName: defineMessages({
    required: {
      defaultMessage: 'Enter your first name',
      description: 'Field error text',
    },
  }),
  lastName: defineMessages({
    required: {
      defaultMessage: 'Enter your last name',
      description: 'Field error text',
    },
  }),
  password: defineMessages({
    minLength: {
      defaultMessage: 'The password must be at least {minLength, plural, one {# character} other {# characters}} long',
      description: 'Field error text',
    },
    required: {
      defaultMessage: 'Enter your password',
      description: 'Field error text',
    },
  }),
  phone: defineMessages({
    phone: {
      defaultMessage: 'Incorrect phone number',
      description: 'Field error text',
    },
    required: {
      defaultMessage: 'Enter your phone number',
      description: 'Field error text',
    },
  }),
  repeatPassword: defineMessages({
    equalTo: {
      defaultMessage: "Passwords don't match",
      description: 'Field error text',
    },
    required: {
      defaultMessage: 'Enter your password again',
      description: 'Field error text',
    },
  }),
};

export const formTexts: FormTexts = {
  fieldErrors,
  labels,
  placeholders,
};
