import {ExtMessageDescriptor} from 'lib/intl/types';
import {validateEmail as validateEmailBase} from 'lib/validation';
import {defineMessage} from 'react-intl';

const errorMessage = defineMessage({
  defaultMessage: 'Incorrect email',
  description: 'Error for incorrect email. mail',
});

export function validateEmail(email: unknown): ExtMessageDescriptor | undefined {
  return validateEmailBase(email) === 'email' ? errorMessage : undefined;
}
