import {Client} from 'lib/client';

type ResponseBody = {
  payload: {
    count: number;
  };
};

export function loadCartItemsCount(client: Client): Promise<number> {
  return client.mainApi.post<ResponseBody>('cart/getItemsCount').then(({body: {payload}}) => payload.count);
}
