import {combine} from 'effector';
import {createCommunicationStore} from 'lib/communication';
import {$modalsManager} from 'models/modalsManager';
import {$userAnonymous} from 'models/user';

const {
  $store: $pageLeaveDialog,
  openDialog: openPageLeaveDialog,
  closeDialog: closePageLeaveDialog,
  sendRequestFx: sendPageLeaveDataFx,
} = createCommunicationStore('pageLeave');

export const $canOpenPageLeaveDialog = combine(
  $pageLeaveDialog,
  $modalsManager,
  $userAnonymous,
  ({opened: pageLeaveDialogOpened}, {dialogOpened: otherDialogOpened}, userAnonymous) =>
    !pageLeaveDialogOpened && !otherDialogOpened && userAnonymous,
);

export {$pageLeaveDialog, openPageLeaveDialog, closePageLeaveDialog, sendPageLeaveDataFx};
