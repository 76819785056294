import {createCommunicationStore} from 'lib/communication';

const {
  $store: $partnersConsultationDialog,
  openDialog: openPartnersConsultationDialog,
  closeDialog: closePartnersConsultationDialog,
  sendRequestFx: sendPagePartnersConsultationDataFx,
} = createCommunicationStore('partnersConsultation');

export {
  $partnersConsultationDialog,
  openPartnersConsultationDialog,
  closePartnersConsultationDialog,
  sendPagePartnersConsultationDataFx,
};
