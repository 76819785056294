import {useDefaultValidator} from 'lib/finalForm/hooks';
import {BaseFieldProps} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import {FieldValue} from 'lib/form/types';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useField} from 'react-final-form';
import {useIntl} from 'react-intl';
import {Select, SelectProps} from 'uikit/SelectMui';

export * from 'uikit/Select';

export type SelectFieldProps<T extends FieldValue> = BaseFieldProps<T> & Omit<SelectProps<T>, 'value' | 'setValue'>;

export function SelectField<T extends FieldValue>({
  name,
  required,
  validate,
  requiredMessage,
  label,
  placeholder,
  ...props
}: SelectFieldProps<T>): React.ReactElement {
  const intl = useIntl();
  const handleValidate = useDefaultValidator({
    required,
    requiredMessage,
    validate,
  });
  const {input, meta} = useField(name, {validate: handleValidate});
  const error = buildMessage(intl, getErrorMessage(meta));
  return <Select {...props} {...input} error={error} label={label} placeholder={placeholder} />;
}
