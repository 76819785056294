import {isString} from 'lib/guards';
import isEmpty from 'lodash/isEmpty';

const firstCheckDigitWeights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
const secondCheckDigitsWeights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

const getWeightedSum = (digits: number[], weights: number[]): number => {
  let result = 0;
  for (let i = 0; i < weights.length; i++) {
    result += digits[i] * weights[i];
  }
  return result;
};

const getCheckDigit = (weightedSum: number) => {
  const value = weightedSum % 11;

  if (value < 2) {
    return 0;
  }
  return 11 - value;
};

// CNPJ validation algorithm
// https://pt.wikipedia.org/wiki/Cadastro_Nacional_da_Pessoa_Jur%C3%ADdica#Pseudoc%C3%B3digo
export const checkCnpj = (value: unknown): boolean => {
  if (!isString(value)) {
    return false;
  }
  if (isEmpty(value) || value.trim() === '') {
    return false;
  }
  const values = value.trim().match(/\d/g);
  if (!values) {
    return false;
  }
  const digits = values.map(Number);

  if (digits.length !== 14) {
    return false;
  }
  if (digits.every((digit) => digit === 0)) {
    return false;
  }

  const firstCheckDigit = getCheckDigit(getWeightedSum(digits, firstCheckDigitWeights));
  const secondCheckDigit = getCheckDigit(getWeightedSum(digits, secondCheckDigitsWeights));

  // eslint-disable-next-line eqeqeq
  if (firstCheckDigit != digits[12] || secondCheckDigit != digits[13]) {
    return false;
  }
  return true;
};
