import {InputField} from 'components/Form';
import {PromocodeContext} from 'lib/promocode';
import {TestIdProp} from 'lib/testing/types';
import React, {useContext} from 'react';
import {InputTestId} from 'uikit/Input';

export type PromocodeFieldTestId = InputTestId;

type Props = TestIdProp<PromocodeFieldTestId> & {
  required?: boolean;
};

export function PromocodeField({required, testId}: Props): React.ReactElement {
  const {getCurrentPromocode} = useContext(PromocodeContext);
  const currentPromocode = getCurrentPromocode();

  return <InputField name='promocode' required={required} testId={testId} type='text' value={currentPromocode} />;
}
