import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$catalogPromotions, loadCatalogPromotionFx, resetCatalogPromotions} from './index';

$catalogPromotions.reset(resetCatalogPromotions);
$catalogPromotions
  .on(loadCatalogPromotionFx, (state) => ({...state, dataState: DataState.LOADING}))
  .on(loadCatalogPromotionFx.failData, (state) => ({...state, dataState: DataState.FAILED}))
  .on(loadCatalogPromotionFx.doneData, (state, result) => ({
    ...state,
    dataState: DataState.LOADED,
    promotions: [...state.promotions, result],
  }));
