import {DataState} from '@joomcode/deprecated-utils/dataState';
import {loadPromotion} from 'api/promotions';
import {createEvent} from 'effector';
import {createClientEffect, createStore} from 'lib/effector';
import {Promotion} from 'lib/promotion/types';

type SearchPromotionState = {
  data?: Promotion;
  dataState: DataState;
};

export const initialState: SearchPromotionState = {
  dataState: DataState.IDLE,
};

export const $searchPromotion = createStore('searchPromotion', initialState);

export const resetSearchPromotion = createEvent();

export const loadSearchPromotionFx = createClientEffect(loadPromotion);
