import React, {useMemo} from 'react';
import uuid from 'uuid/v4';
import styles from './index.module.scss';

type Props = {
  children: (renderProps: {id: string}) => React.ReactNode;
  label: React.ReactNode;
};

export const FieldLabel = ({label, children}: Props): React.ReactElement => {
  const id = useMemo(() => uuid(), []);
  return (
    <>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      {children({id})}
    </>
  );
};
