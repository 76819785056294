import * as Sentry from '@sentry/react';
import {getPublicRuntimeConfig} from 'lib/appConfig';
import {ReleaseStage} from 'lib/appConfig/types';

const mapEnv = (env: ReleaseStage) => {
  switch (env) {
    case 'development':
      return 'development';
    case 'staging':
      return 'stage';
    case 'production':
      return 'production';
    default:
      return 'development';
  }
};

export const initSentry = (): void => {
  const config = getPublicRuntimeConfig();

  Sentry.init({
    dsn: 'https://e5c75350d28dbb3268b7be2bd772cf83@sentry.joom.it/22',
    environment: mapEnv(config.releaseStage),
    ignoreErrors: [
      // All errors that occur in scripts loaded from other domains.
      // @see https://sentry.io/answers/script-error/
      'Script error.',
    ],
    release: config.buildCalVer,
    tracesSampleRate: 0.1,
  });
};
