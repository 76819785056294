import {sample} from 'effector';
import {CommunicationReason} from 'lib/communication/types';
import {isFormValidationError} from 'lib/form/types';
import {checkConsultationDialog, openConsultationDialog} from '.';
import {DIALOG_PARAM_NAME, DialogCommand} from './constants';
import {
  $consultationDirect,
  closeDialog,
  openFailDialog,
  openSuccessDialog,
  sendDirectConsultationData,
  sendDirectConsultationDataFx,
} from './direct';

$consultationDirect
  .on(sendDirectConsultationData, (state, {source}) => ({
    ...state,
    error: undefined,
    source,
  }))
  .on(sendDirectConsultationDataFx, (state) => ({
    ...state,
    processing: true,
  }))
  .on(openSuccessDialog, (state) => ({
    ...state,
    opened: true,
    processing: false,
    success: true,
  }))
  .on(openFailDialog, (state, error) => ({
    ...state,
    error,
    opened: !isFormValidationError(error),
    processing: false,
    success: false,
  }))
  .reset(closeDialog);

sample({
  clock: sendDirectConsultationData,
  fn: (sourceData, clockData) => ({
    phone: clockData.phone,
    reason: sourceData.reason,
  }),
  source: $consultationDirect,
  target: sendDirectConsultationDataFx,
});

sample({
  filter() {
    const url = new URL(window.location.href);
    const dialogCmd = url.searchParams.get(DIALOG_PARAM_NAME);
    const shouldOpen = dialogCmd === DialogCommand.OPEN;

    if (shouldOpen) {
      url.searchParams.delete(DIALOG_PARAM_NAME);
      window.history.replaceState(null, '', url);
    }

    return shouldOpen;
  },
  fn: () => ({
    reason: CommunicationReason.CONSULTATION_CLAIM,
    section: 'url',
  }),
  source: checkConsultationDialog,
  target: openConsultationDialog,
});
