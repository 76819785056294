import {Client} from 'lib/client';
import {CommunicationResponse} from 'lib/communication/types';
import {cleanPhone} from 'lib/data/phone';
import {isFormValidationError} from 'lib/errors/guards';
import {formValidationError} from 'lib/form/types';
import {isTransportResponse} from 'lib/transport/types';
import {parseUtmParameters} from 'lib/utm';
import {UtmParameters} from 'lib/utm/types';

export type Params = {
  comment?: string;
  name?: string;
  phone: string;
  promocode?: string;
  taxpayerNumber?: string;
};

type RequestBody = Params & {
  utmLabels?: UtmParameters;
};

type ResponseBody = {
  payload: CommunicationResponse;
};

export function addPartnersCredit(client: Client, params: Params): Promise<CommunicationResponse> {
  const utmParameters = parseUtmParameters(client.cookies.utmParameters);
  const body: RequestBody = {...params, phone: cleanPhone(params.phone)};

  if (utmParameters) {
    body.utmLabels = utmParameters;
  }

  return client.mainApi
    .post<ResponseBody>('interactions/partners/addCredit', {body})
    .then(({body: {payload}}) => payload)
    .catch((error) => {
      if (isTransportResponse(error) && isFormValidationError(error.body)) {
        const {fieldValidationErrors} = error.body.payload;
        const errorMap = fieldValidationErrors.reduce<Record<string, string>>((result, item) => {
          // eslint-disable-next-line no-param-reassign
          result[item.field] = item.message;
          return result;
        }, {});

        throw formValidationError(errorMap);
      }

      throw error;
    });
}
