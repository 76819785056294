import omit from 'lodash/omit';
import {InputType} from '../types';
import {getInputType, getInserted} from '../utils';
import {Selection} from './Selection';

export type ChangeDetailsOptions = {
  selectionAfter: Selection;
  selectionBefore: Selection;
  valueAfter: string;
  valueBefore: string;
};

export class ChangeDetails {
  readonly inputType: InputType;

  readonly insertedValue: string;

  constructor(options: ChangeDetailsOptions) {
    this.inputType = getInputType(options);
    this.insertedValue = getInserted(omit(options, 'selectionAfter'));
  }
}
