import {Client} from 'lib/client';
import {messageError} from 'lib/errors/messageError/types';
import {isTransportResponse} from 'lib/transport/types';
import {User} from 'lib/user/types';
import {PayloadWithUser} from './types';
import {extractUser} from './utils';

export type SignInByTokenCredential = {
  loginToken: string;
};

export function signInByToken(client: Client, {loginToken}: SignInByTokenCredential): Promise<User> {
  return client.mainApi
    .post<PayloadWithUser>('auth/admin/signIn', {body: {loginToken}})
    .then(extractUser)
    .catch((error) => {
      // TODO: PRO-620 force exception convention and use `kind` instead of `body`.`message`
      if (isTransportResponse(error) && error.body.message) {
        throw messageError(error.body.message);
      }
      throw error;
    });
}
