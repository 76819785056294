import {DataState} from '@joomcode/deprecated-utils/dataState';
import {loadPromotion} from 'api/promotions';
import {attach} from 'effector';
import {createStore, createClientEffect, createEvent} from 'lib/effector';
import {Promotion} from 'lib/promotion/types';

type PromotionsState = {
  dataState: DataState;
  promotions: Promotion[];
};

const intialState: PromotionsState = {
  dataState: DataState.IDLE,
  promotions: [],
};

export const $selfServicePromotions = createStore('selfServicePromotions', intialState);

export const resetMainPromotions = createEvent();

export const loadMainPromotionFx = attach({
  effect: createClientEffect(loadPromotion),
  mapParams: (id: string) => {
    return {id};
  },
});
