export enum FilterType {
  CATEGORIES = 'categories',
  CHECKBOX = 'checkbox',
  COLORS = 'colors',
  MONEY_RANGE = 'moneyRange',
  SEARCH_TAG = 'searchTag',
  STORES = 'stores',
  TREE = 'tree',
}

export enum FilterKind {
  CHECKBOX = 'checkbox',
  MONEY_RANGE = 'moneyRange',
  SEARCH_TAG = 'searchTag',
  TREE = 'tree',
}
