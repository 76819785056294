import {AuthOrigin} from 'components/Auth/types';
import {Client} from 'lib/client';
import {cleanPhone} from 'lib/data/phone';
import {User} from 'lib/user/types';
import {parseUtmParameters} from 'lib/utm';
import {UtmParameters} from 'lib/utm/types';
import {PayloadWithUser} from './types';
import {extractUser} from './utils';

type SignInCredential = {
  email?: string;
  firstName?: string;
  origin: AuthOrigin;
  otp: string;
  phone: string;
};

type RequestBody = SignInCredential & {
  utmLabels?: UtmParameters;
};

export function signInByPhone(client: Client, {email, origin, otp, phone, firstName}: SignInCredential): Promise<User> {
  const utmParameters = parseUtmParameters(client.cookies.utmParameters);

  const body: RequestBody = {
    email,
    firstName,
    origin,
    otp,
    phone: cleanPhone(phone),
  };

  if (utmParameters) {
    body.utmLabels = utmParameters;
  }

  return client.mainApi
    .post<PayloadWithUser>('auth/phoneV2/signIn', {
      body,
    })
    .then(extractUser);
}
