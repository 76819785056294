import {AutocompleteMultiField} from 'components/FinalForm/AutocompleteMultiField';
import {companyOperationFields} from 'lib/user/companyOperationField';
import {formatСompanyOperationField} from 'lib/user/companyOperationField/messages';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const CompanyOperationField = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  const getOptionLabel = useCallback((item: string) => formatСompanyOperationField(item, intl), [intl]);
  return (
    <AutocompleteMultiField
      disableClearable
      freeSolo
      getOptionLabel={getOptionLabel}
      label={intl.formatMessage(fieldMessages.companyOperationFields)}
      name={fieldName}
      options={companyOperationFields}
      required={required}
    />
  );
};
