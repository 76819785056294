import {useMemo, useState} from 'react';

type VisibilityState = {
  hide: () => void;
  show: () => void;
  toggle: () => void;
  visible: boolean;
};

export function useVisibilityState(initialVisible: boolean): VisibilityState {
  const [visible, setVisible] = useState(initialVisible);

  const methods = useMemo(
    () => ({
      hide: () => setVisible(false),
      show: () => setVisible(true),
      toggle: () => setVisible((prevVisible) => !prevVisible),
    }),
    [],
  );

  return {...methods, visible};
}
