import {useMemo, useState} from 'react';

type BooleanState = {
  setFalse(): void;
  setTrue(): void;
  toggle(): void;
  value: boolean;
};

export function useBooleanState(initialState: boolean | (() => boolean)): BooleanState {
  const [value, setValue] = useState(initialState);

  return useMemo(
    () => ({
      setFalse: () => setValue(false),
      setTrue: () => setValue(true),
      value,
      toggle: () => setValue((isExpanded) => !isExpanded),
    }),
    [value],
  );
}
