import {createForm, FormApi} from 'final-form';
import {MessagesContext} from 'lib/finalForm/contexts';
import {useSubmitHandler, useExternalValues, useExternalLoading} from 'lib/finalForm/hooks';
import {setSubmitting} from 'lib/finalForm/mutators';
import {MessagesFormState} from 'lib/finalForm/types';
import {TestIdProp} from 'lib/testing/types';
import React, {MutableRefObject, useMemo} from 'react';
import {Form as FormBase, FormProps} from 'react-final-form';

export type Props<Values> = FormProps<Values> &
  TestIdProp & {
    className?: string;
    errors?: Partial<Record<keyof Values, string>>;
    formApiRef?: MutableRefObject<FormApi<Values> | undefined>;
    loading?: boolean;
    messages?: MessagesFormState;
    values?: Partial<Values>;
  };

export function Form<Values>({
  testId,
  loading,
  className,
  values,
  formApiRef,
  messages = {},
  initialValues,
  children,
  errors,
  onSubmit,
  decorators,
}: Props<Values>): React.ReactElement {
  const handleSubmit = useSubmitHandler<Values>({errors, onSubmit});
  const form = useMemo(
    () =>
      createForm<Values>({
        initialValues,
        mutators: {setSubmitting},
        onSubmit: handleSubmit,
      }),
    [handleSubmit],
  );

  useExternalValues(form, values);
  useExternalLoading(form, loading);

  if (formApiRef) {
    // eslint-disable-next-line no-param-reassign
    formApiRef.current = form;
  }

  return (
    <MessagesContext.Provider value={messages}>
      <FormBase<Values> decorators={decorators} form={form} onSubmit={handleSubmit}>
        {(props) => (
          <form action='#' className={className} data-test-id={testId} onSubmit={props.handleSubmit}>
            {/* @ts-expect-error */}
            {children}
          </form>
        )}
      </FormBase>
    </MessagesContext.Provider>
  );
}
