import {Client} from 'lib/client';
import {RadarPossessionType} from 'lib/communication/types';
import {cleanPhone} from 'lib/data/phone';
import {isFormValidationError} from 'lib/errors/guards';
import {formValidationError} from 'lib/form/types';
import {isTransportResponse} from 'lib/transport/types';

export type AddCustomerParams = {
  campaign?: string;
  comment?: string;
  companyName?: string;
  description?: string;
  email?: string;
  firstName?: string;
  hasLegalEntity?: boolean;
  hasProductImport?: boolean;
  hasQualifiedLead?: boolean;
  importProducts?: string;
  lastName?: string;
  moderatorId?: string;
  phone: string;
  radarPossessionType?: RadarPossessionType;
  source: string;
  type: string;
};

type ResponsePayload = {
  friendlyId: string;
  interactionId: string;
  popupRequestId?: string;
};

type ResponseBody = {
  payload: ResponsePayload;
};

const FIELDS_FOR_GENERAL_ERRORS = ['type', 'source', 'campaign', 'moderatorId'];

export function addCustomer(client: Client, params: AddCustomerParams): Promise<ResponsePayload> {
  const body = {...params, phone: cleanPhone(params.phone)};

  return client.mainApi
    .post<ResponseBody>('interactions/addCustomer', {body})
    .then(({body: {payload}}) => payload)
    .catch((error) => {
      if (isTransportResponse(error) && isFormValidationError(error.body)) {
        let generalError;
        const {fieldValidationErrors} = error.body.payload;
        const errorMap = fieldValidationErrors.reduce<Record<string, string>>((result, item) => {
          // eslint-disable-next-line no-param-reassign
          result[item.field] = item.message;
          if (FIELDS_FOR_GENERAL_ERRORS.includes(item.field)) {
            generalError = item.message;
          }
          return result;
        }, {});

        throw formValidationError(errorMap, generalError);
      }

      throw error;
    });
}
