import {Client} from 'lib/client';
import {CommunicationRequest} from 'lib/communication/types';
import {cleanPhone} from 'lib/data/phone';
import {isFormValidationError} from 'lib/errors/guards';

type RequestBody = Pick<CommunicationRequest, 'phone'>;

type ResponseBody = {
  payload: Record<string, never>;
};

export async function validatePhone(client: Client, params: RequestBody): Promise<string | Record<string, never>> {
  const body: RequestBody = {
    phone: cleanPhone(params.phone),
  };

  return client.mainApi
    .post<ResponseBody>('interactions/validatePhone', {body})
    .then(({body: {payload}}) => payload)
    .catch((error) => {
      if (isFormValidationError(error.body)) {
        return error.body.payload.fieldValidationErrors[0].message;
      }

      throw error;
    });
}
