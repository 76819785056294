import type {Selection} from './common';
import type {MaskItem} from './MaskItem';

export enum InputType {
  DELETE_CHAR_BACKWARD = 'deleteCharBackward',
  DELETE_CHAR_FORWARD = 'deleteCharForward',
  DELETE_CONTENT_BACKWARD = 'deleteContentBackward',
  INSERT = 'insert',
  NONE = 'none',
}

export type MaskItemConfig =
  | {
      editable: true;
      fallback: string;
      test: RegExp;
      value?: string;
    }
  | {
      editable: false;
      value: string;
    };

export type MaskOptions = {
  alwaysShowMask?: boolean;
  fallback?: string;
  initialValue?: string;
};

export interface MaskInterface {
  // Change methods
  clear(): void;
  deleteCharBackward(): void;
  deleteCharForward(): void;
  deleteContentBackward(): void;
  getItems(): MaskItem[];
  getMask(): string;
  getOptions(): MaskOptions;
  getSelection(): Selection;
  input(value: string): void;
  // Selection methods
  resetSelection(): void;
  setSelection(start: number, end?: number): void;
  setValue(value: string): void;
  toRawString(): string;
  // Util methods
  toString(): string;
}

export interface MaskItemInterface {
  clear(): void;
  getMaskValue(): string;
  isEditable(): boolean;
  isEmpty(): boolean;
  isFilled(): boolean;
  setValue(value: string): void;
  test(value: string): void;
  toString(): string;
}

export interface MaskUtilsInterface {
  getMask(): string;
  substring(start: number, end?: number): string;
  toRawString(): string;
  toString(): string;
}

export interface MaskManagerInterface {
  clear(): void;
  getConfig(char: string): MaskItemConfig;
  getItems(): MaskItem[];
  setValue(value: string): void;
}
