import {DataState} from '@joomcode/deprecated-utils/dataState';
import {ProductTranslationsTarget} from 'lib/product/types';
import {ProductTranslationsState} from './index';

export function getUpdatedStateByTarget(
  stateByTarget: ProductTranslationsState['stateByTarget'],
  nextState: DataState,
  target?: ProductTranslationsTarget,
) {
  const newStateByTarget: ProductTranslationsState['stateByTarget'] = {...stateByTarget};

  if (target) {
    newStateByTarget[target] = nextState;
  } else {
    for (const key of Object.keys(newStateByTarget) as ProductTranslationsTarget[]) {
      newStateByTarget[key] = nextState;
    }
  }

  return newStateByTarget;
}
