import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$orders, loadOrdersListFx} from './index';

$orders
  .on(loadOrdersListFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(loadOrdersListFx.doneData, (state, response) => ({
    ...state,
    data: response,
    dataState: DataState.LOADED,
  }))
  .on(loadOrdersListFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));
