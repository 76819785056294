export function isNonArrayIndexKey(key: string): boolean {
  // See ECMAScript spec section 15.4 for definition of an array index:
  // http://www.ecma-international.org/ecma-262/5.1/#sec-15.4

  // Parse the key as an integer
  const parsedInt = parseInt(key, 10);

  // If the parsed value is outside of the range of valid values,
  // then it clearly can't be a valid array index.
  // NOTE: Maximum unsigned 32-bit integer = 2^32-1 = 4294967295
  if (parsedInt < 0 || parsedInt >= 4294967295) {
    return true;
  }

  // The parsed value is within the valid range, but the original key is not
  // a valid array index if it is not equal to the string representation of
  // the parsed numeric value.
  return key !== String(parsedInt);
}

type StringKeyOf<T> = Extract<keyof T, string>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getOwnEnumerableNonArrayIndexKeys<T extends Record<string, any>>(obj: T): StringKeyOf<T>[] {
  return Object.getOwnPropertyNames(obj).filter((key) => {
    const isPropertyEnumerable = Object.prototype.propertyIsEnumerable.call(obj, key);
    return isPropertyEnumerable && isNonArrayIndexKey(key);
  }) as StringKeyOf<T>[];
}
