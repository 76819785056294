import {config} from 'config';
import {isString} from 'lib/guards';
import {Language} from './types';

export {getLanguageLongName, getLanguageName, getLanguageShortName} from './languageName';
export type {LanguageNameFormat} from './languageName';

export function getAcceptedLanguage(language: unknown): Language {
  if (isString(language) && config.locales[language as Language]) {
    return language as Language;
  }

  return config.defaultLocale;
}

export function getAcceptedLanguages(): Language[] {
  return Object.keys(config.locales) as Language[];
}

export function isLanguageAccepted(language?: string): language is Language {
  return Boolean(language && config.locales[language as Language]);
}

export function getLanguageRegion(language?: Language | string): string | undefined {
  if (language && isLanguageAccepted(language)) {
    return language.includes('-') ? language : `${language}-${config.locales[language].region}`;
  }

  return undefined;
}

export function getInterfaceLanguages(language: Language): Language[] {
  switch (language) {
    case Language.EN:
    case Language.RU:
      return [];
    default:
      return [];
  }
}
