import {DataState} from '@joomcode/deprecated-utils/dataState';
import {$sampleExistence, loadSampleExistenceFx} from './index';

$sampleExistence
  .on(loadSampleExistenceFx, (state) => {
    return {
      ...state,
      loading: true,
      loadingError: undefined,
      state: DataState.LOADING,
    };
  })
  .on(loadSampleExistenceFx.done, (state, {result: sampleExistence}) => {
    return {
      ...state,
      loading: false,
      sampleExistence,
      state: DataState.LOADED,
    };
  })
  .on(loadSampleExistenceFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
    state: DataState.FAILED,
  }));
