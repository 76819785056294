import {loadProduct} from 'api/product/loadProduct';
import {attach} from 'effector';
import {createStore, createClientEffect, createEvent} from 'lib/effector';
import {Product} from 'lib/product/types';

export type ProductState = {
  loading: boolean;
  loadingError?: unknown;
  product?: Product;
};

const initialProductState = {
  loading: false,
};

export const $product = createStore<ProductState>('product', initialProductState);

// for now, submitted action here is only adding to cart
// but I think in future there will be more actions
export const $productActionSubmitted = createStore('productActionSubmitted', false);

export const loadProductFx = createClientEffect(loadProduct);

export const reloadProductFx = attach({
  effect: createClientEffect(loadProduct),
  mapParams(_, {product}) {
    if (product) {
      return {productId: product.id};
    }

    throw new Error('Product for reloading is not defined');
  },
  source: $product,
});

export const resetProduct = createEvent();
