import {CountryCode} from '@joomcode/deprecated-utils/countries';
import {Client} from 'lib/client';
import {DeliveryChannelInfo} from 'lib/deliveryChannel/types';

type Payload = {
  countryCode: CountryCode;
};

type DeliveryChannelsResponse = {
  payload: DeliveryChannelInfo[];
};

export function loadDeliveryChannels(client: Client, {countryCode}: Payload): Promise<DeliveryChannelInfo[]> {
  return client.mainApi
    .post<DeliveryChannelsResponse>('deliveryChannels/get', {body: {country: countryCode}})
    .then(({body: {payload}}) => payload);
}
