import {LoyaltyConsultationDialog as LoyaltyConsultationDialogComponent} from 'components/LoyaltyConsultationDialog';
import {useEvent, useStore} from 'lib/effector';
import {useFormSuccessEvent} from 'lib/hooks';
import {
  $loyaltyConsultationDialog,
  closeLoyaltyConsultationDialog,
  sendPageLoyaltyConsultationDataFx,
} from 'models/loyaltyConsultation';
import React from 'react';
import {rootTestId} from 'testId';

export function LoyaltyConsultationDialog(): React.ReactElement | null {
  const dialogState = useStore($loyaltyConsultationDialog);
  const handleClose = useEvent(closeLoyaltyConsultationDialog);
  const handleSubmit = useEvent(sendPageLoyaltyConsultationDataFx);
  const {onSuccess} = useFormSuccessEvent();

  if (!dialogState.opened) {
    return null;
  }

  return (
    <LoyaltyConsultationDialogComponent
      error={dialogState.error}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      processing={dialogState.processing}
      reason={dialogState.reason}
      success={dialogState.success}
      testId={rootTestId?.consultationDialog}
    />
  );
}
