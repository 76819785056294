import {isPositiveNumber} from '../math/isPositiveNumber';
import {getEnumValues} from '../ts-enum';
import {AnyObject} from '../types';
import {Decoder, DecoderObject} from './decoder';
import * as Result from './result';

/** See `Decoder.string` */
export const {string} = Decoder;

/** See `Decoder.number` */
export const {number} = Decoder;

/**
 * Decoder that validates the string to match pattern.
 *
 * Examples:
 * ```
 * const regex = /^\d+$/
 * stringPattern(regex).run('123') -> ok
 * stringPattern(regex).run('abc') -> err (doesn't match the regex)
 * stringPattern(regex).run(123) -> err (must be a string)
 * ```
 */
export const stringPattern = (regex: RegExp): Decoder<string> =>
  Decoder.string().where((value) => regex.test(value), `string does not match ${regex} pattern`);

/**
 * Decoder that validates that value is date and converts it to Date.
 *
 * Examples:
 * ```
 * dateFromString().run(1580737346551) -> ok Date("Tue Jun 18 2019 14:26:44 GMT+0300 (Moscow Standard Time)")
 * dateFromString().run('01.01.2000') -> ok Date("Sat Jan 01 2000 00:00:00 GMT+0300 (Moscow Standard Time)")
 * dateFromString().run('abs') -> err (incorrect date format)
 * dateFromString().run(true) -> err (must be a number or string)
 * ```
 */
export const dateFromString = (): Decoder<Date> =>
  Decoder.union(string(), number())
    .where((value) => !Number.isNaN(new Date(value).getTime()), 'incorrect date format')
    .map((value) => new Date(value));

/**
 * Decoder that validates that value is date and converts it to iso string.
 *
 * Examples:
 * ```
 * dateToString().run(1580737346551) -> ok ("2020-02-03T13:42:26.551Z")
 * dateToString().run('01.01.2000') -> ok ("1999-12-31T21:00:00.000Z")
 * dateToString({short: true}).run(1580737346551) -> ok ("2020-02-03")
 * dateToString({short: true}).run('01.01.2000') -> ok ("1999-12-31")
 * dateToString().run('abs') -> err (incorrect date format)
 * dateToString().run(true) -> err (must be a number or string)
 * ```
 */
export const dateToIsoString = ({short}: {short?: boolean} = {}): Decoder<string> =>
  dateFromString().map((date) => (short ? date.toISOString().slice(0, 10) : date.toISOString()));

/**
 * Decoder that validates the string and converts it to timestamp.
 *
 * Examples:
 * ```
 * timestampFromString().run('1560857204608') -> ok (1560857204608)
 * timestampFromString().run('01.01.2000') -> ok (946674000000)
 * timestampFromString().run('abs') -> err (incorrect date format)
 * timestampFromString().run(1560857204608) -> err (must be a string)
 * ```
 */
export const timestampFromString = (): Decoder<number> =>
  Decoder.string()
    .where((value) => !Number.isNaN(Date.parse(value)), 'incorrect date format')
    .map((value) => Date.parse(value));

/**
 * Decoder that validates that number is positive.
 *
 * Examples:
 * ```
 * positiveNumber().run(123) -> ok
 * positiveNumber().run(-123) -> err (expected positive number)
 * positiveNumber().run('123') -> err (must be a number)
 * ```
 */
export const positiveNumber = (): Decoder<number> =>
  Decoder.number().where((n: number) => isPositiveNumber(n), `expected positive number`);

/** See `Decoder.boolean` */
export const {boolean} = Decoder;

/** See `Decoder.anyJson` */
export const {anyJson} = Decoder;

/** See `Decoder.unknownJson` */
export const {unknownJson} = Decoder;

/** See `Decoder.constant` */
export const {constant} = Decoder;

/** See `Decoder.object` */
export const {object} = Decoder;

/** See `Decoder.array` */
export const {array} = Decoder;

/**
 * Decoder for json arrays. Runs `decoder` on each array element, and succeeds
 * with elements that are successfully decoded.
 *
 * Examples:
 * ```
 * const validNumbersDecoder = successesArray(number())
 *
 * validNumbersDecoder.run([1, true, 2, 3, 'five', 4, []])
 * // {ok: true, result: [1, 2, 3, 4]}
 *
 * validNumbersDecoder.run([false, 'hi', {}])
 * // {ok: true, result: []}
 *
 * validNumbersDecoder.run(false)
 * // {ok: false, error: {..., message: "expected an array, got a boolean"}}
 * ```
 */
export const successesArray = <A>(decoder: Decoder<A>): Decoder<A[]> =>
  Decoder.array()
    .map((a: unknown[]) => a.map(decoder.run))
    .map(Result.successes);

/** See `Decoder.tuple` */
export const {tuple} = Decoder;

/** See `Decoder.dict` */
export const {dict} = Decoder;

/** See `Decoder.optional` */
export const {optional} = Decoder;

/** See `Decoder.nullable` */
export const {nullable} = Decoder;

/** See `Decoder.oneOf` */
export const {oneOf} = Decoder;

/** See `Decoder.oneOfEnum` */
export const {oneOfEnum} = Decoder;

/** See `Decoder.union` */
export const {union} = Decoder;

/** See `Decoder.intersection` */
export const {intersection} = Decoder;

/** See `Decoder.withDefault` */
export const {withDefault} = Decoder;

/** See `Decoder.valueAt` */
export const {valueAt} = Decoder;

/** See `Decoder.succeed` */
export const {succeed} = Decoder;

/** See `Decoder.fail` */
export const {fail} = Decoder;

/** See `Decoder.lazy` */
export const {lazy} = Decoder;

/** See `Decoder.timestampToDate` */
export const {timestampToDate} = Decoder;

/**
 * Decoder that validates the value is positive number or string that converts to timestamp
 *
 * Examples:
 * ```
 * timestamp().run('1560857204608') -> ok (1560857204608)
 * timestamp().run('01.01.2000') -> ok (946674000000)
 * timestamp().run('abs') -> err (incorrect date format)
 * timestamp().run(1560857204608) -> ok (1560857204608)
 * timestamp().run(true) -> false (must be a string or number)
 * ```
 */
export const timestamp = (): Decoder<number> => oneOf(timestampFromString(), positiveNumber());

/**
 * Decoder that always succeeds with either the decoded value, or a null
 */
export const withDefaultNull = <A>(decoder: Decoder<A>): Decoder<A | null> => withDefault(null, decoder);

/**
 * Decoder that always succeeds with either the decoded value, or a undefined
 */
export const withDefaultUndefined = <A>(decoder: Decoder<A>): Decoder<A | undefined> => withDefault(undefined, decoder);

/**
 * Decoder for json objects. Succeeds with keys that are successfully decoded.
 *
 * Examples:
 * ```
 * const decoder = successesKeys({rating: number(), text: string()})
 *
 * decoder.run({rating: '123', text: '123'})
 * // {ok: true, result: {text: '123'}}
 *
 * decoder.run({user: 'Vasya'})
 * // {ok: true, result: {}}
 *
 * decoder.run(false)
 * // {ok: false, error: {..., message: "expected an object, got a boolean"}}
 * ```
 */
export const successesKeys = <A extends AnyObject>(decoders: DecoderObject<A>): Decoder<Partial<A>> =>
  Decoder.object().map((obj) =>
    Object.keys(decoders).reduce<Partial<A>>(
      (acc, key) =>
        decoders[key].run(obj[key]).ok
          ? {
              ...acc,
              [key]: obj[key],
            }
          : acc,
      {},
    ),
  );

/**
 * Decoder for which the keys are from enum and the values must be of the same type
 *
 * Example:
 * ```
 * dictEnum(CurrencyCode, number()).run({USD: 12, EUR: 10});
 * // => {ok: true, result: {USD: 12, EUR: 10}}
 *
 * dictEnum(CurrencyCode, number()).run({USD: 12, EUR: '10'});
 * // => {ok: false, , error: {..., message: "expected a number, got a string"}}
 *
 * dictEnum(CurrencyCode, number()).run({USD: 12, WWW: 10});
 * // => {ok: false, , error: {..., message: "unexpected enum value"}}
 * ```
 */
export const dictEnum = <A extends AnyObject, B>(keyEnumInstance: A, valueDecoder: Decoder<B>) => {
  const enumValues = getEnumValues(keyEnumInstance);

  return dict(valueDecoder).where(
    // @ts-expect-error: enums satisfy the PropertyKey constraint by design
    (obj) => Object.keys(obj).every<A>((key) => enumValues.includes(key)),
    `expected keys to be of enum values`,
    // @ts-expect-error: enums satisfy the PropertyKey constraint by design
  ) as Decoder<Record<A, B>>;
};
