import {BreadcrumbItem} from 'components/Breadcrumbs/types';
import {useStore} from 'lib/effector';
import {reverseUrl} from 'lib/router';
import {$category} from 'models/category';
import {useMemo} from 'react';

export function useBreadcrumbItems(withCategory = true): BreadcrumbItem[] {
  const {category} = useStore($category);

  return useMemo(() => {
    const items: BreadcrumbItem[] = [];

    if (withCategory && category) {
      items.push({
        name: category.name,
        payload: {
          categoryId: category.id,
        },
        type: 'category',
        url: reverseUrl.search({categoryId: category.id}),
      });
    }

    if (category?.parents) {
      category.parents.forEach((parent) => {
        const {name, id: categoryId} = parent;
        const url = reverseUrl.search({categoryId});

        items.unshift({
          name,
          payload: {
            categoryId: category.id,
          },
          type: 'category',
          url,
        });
      });
    }

    return items;
  }, [category, withCategory]);
}
