import {LoggerFactory, jsonToLevelConfig} from '@joomcode/joom-logger-next';
import {ConsoleProvider, EcsConsoleProvider, SentryProvider} from '@joomcode/joom-logger-next/providers';
import * as Sentry from '@sentry/react';

export const loggerFactory = new LoggerFactory();
const consoleProvider = new ConsoleProvider(
  jsonToLevelConfig({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '*': 'DEBUG',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Server: 'OFF',
  }),
);
const ecsConsoleProvider = new EcsConsoleProvider(
  jsonToLevelConfig({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '*': 'OFF',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Server: 'INFO',
  }),
);
const sentryProvider = new SentryProvider(
  Sentry,
  jsonToLevelConfig({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '*': 'WARN',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Server: 'OFF',
  }),
);

loggerFactory.addProvider(ecsConsoleProvider);
loggerFactory.addProvider(sentryProvider);
loggerFactory.addProvider(consoleProvider);

export const analyticsLogger = loggerFactory.getLogger('analytics');
export const analyticsEmulatorLogger = loggerFactory.getLogger('analytics emulator');
export const authLogger = loggerFactory.getLogger('auth');
export const authProxyLogger = loggerFactory.getLogger('auth proxy');
export const clientLogger = loggerFactory.getLogger('client');
export const cookieManagerLogger = loggerFactory.getLogger('cookie manager');
export const commonLogger = loggerFactory.getLogger('common');
export const effectLogger = loggerFactory.getLogger('effect');
export const initLogger = loggerFactory.getLogger('init');
export const intlLogger = loggerFactory.getLogger('intl');
export const searchLogger = loggerFactory.getLogger('search');
export const transportLogger = loggerFactory.getLogger('transport');
export const tokensLogger = loggerFactory.getLogger('tokens');
export const jivoLogger = loggerFactory.getLogger('jivo');
