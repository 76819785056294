import {loadBlogArticles} from 'api/blogArticle/loadBlogArticles';
import {BlogArticleLite} from 'lib/blogArticle/types';
import {createStore, createClientEffect} from 'lib/effector';

export type BlogArticlesState = {
  byKey: Record<string, {data?: BlogArticleLite[]; loading: boolean; loadingError?: Error}>;
};

const initialBlogArticlesData: BlogArticlesState = {byKey: {}};

export const $blogArticles = createStore<BlogArticlesState>('blogArticles', initialBlogArticlesData);

export const loadBlogArticlesFx = createClientEffect(loadBlogArticles);
