import type {CookieManager} from 'lib/cookie';
import {isRecord, isArray} from 'lib/guards';
import {transportLogger} from 'lib/logger';
import {buildQueryString} from 'lib/url';
import typeIs from 'type-is';
import {
  addCookieHeader,
  addSsrHeader,
  addUserAgentHeader,
  addXffHeader,
  convertResponseHeaders,
  addXJoomProApplicationHeader,
} from './headers';
import {
  TransportRequestHeaders,
  TransportRequestMethod,
  TransportRequestOptions,
  TransportRequestInit,
  TransportResponse,
  TransportOptions,
} from './types';

type GetUrlParams = {
  path: string;
  query?: TransportRequestOptions['query'];
};

export class Transport {
  readonly baseUrl: string;

  readonly origin: string;

  private readonly cookies: CookieManager | undefined;

  private readonly ssrId: string | undefined;

  private readonly clientAddress: string | undefined;

  private readonly userAgent: string | undefined;

  constructor({baseUrl, origin, clientAddress, cookies, ssrId, userAgent}: TransportOptions) {
    this.baseUrl = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
    this.origin = origin;
    this.clientAddress = clientAddress;
    this.cookies = cookies;
    this.ssrId = ssrId;
    this.userAgent = userAgent;
  }

  async headers(headers: TransportRequestHeaders = {}): Promise<TransportRequestHeaders> {
    let nextHeaders = headers;

    if (__SERVER__) {
      if (this.cookies) {
        nextHeaders = addCookieHeader(this.cookies.serializeValues(), nextHeaders);
      }

      if (this.ssrId) {
        nextHeaders = addSsrHeader(this.ssrId, nextHeaders);
      }

      if (this.userAgent) {
        nextHeaders = addUserAgentHeader(this.userAgent, nextHeaders);
      }

      if (this.clientAddress) {
        nextHeaders = addXffHeader(this.clientAddress, nextHeaders);
      }
    }

    nextHeaders = addXJoomProApplicationHeader(nextHeaders);

    return nextHeaders;
  }

  getUrl({path, query}: GetUrlParams): URL {
    const normalizedPath = path.replace(/^\/+/, '');
    const targetUrl = new URL(normalizedPath, this.baseUrl);

    if (isRecord(query)) {
      targetUrl.search = buildQueryString(query);
    }

    return targetUrl;
  }

  async request<ResponseBody>(
    method: TransportRequestMethod,
    path: string,
    options: TransportRequestOptions = {},
  ): Promise<TransportResponse<ResponseBody>> {
    const {query, body: requestBody, headers: requestExtraHeaders} = options;
    const targetUrl = this.getUrl({path, query});
    const requestHeaders = await this.headers();

    const requestInit: TransportRequestInit = {
      headers: {
        ...requestHeaders,
        ...requestExtraHeaders,
      },
      method,
    };

    if (requestBody instanceof FormData) {
      requestInit.body = requestBody;
    } else if (isRecord(requestBody) || isArray(requestBody)) {
      requestInit.body = JSON.stringify(requestBody);
      requestInit.headers['Content-Type'] = 'application/json';
    }

    if (__SERVER__ && __DEVELOPMENT__) {
      transportLogger.log(`REQUEST ${targetUrl}`, requestInit);
    }

    const response = await fetch(targetUrl.toString(), requestInit);

    if (__SERVER__ && __DEVELOPMENT__) {
      transportLogger.log(`RESPONSE ${response.url}`, {
        headers: convertResponseHeaders(response.headers),
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
      });
    }

    if (__SERVER__ && this.cookies) {
      this.cookies.enhanceFromSetCookieHeader(response.headers.get('set-cookie'));
    }

    let responseBody;

    if (typeIs.is(response.headers.get('content-type') || '', ['json'])) {
      responseBody = await response.json();
    } else {
      responseBody = await response.text();
    }

    const resultResponse: TransportResponse<ResponseBody> = {
      body: responseBody as ResponseBody,
      kind: 'TransportResponse',
      method,
      requestId: response.headers.get('request-id') || undefined,
      status: response.status,
      statusText: response.statusText,
      url: response.url,
    };

    if (response.ok) {
      return resultResponse;
    }

    throw resultResponse;
  }

  get<R>(path: string, options?: TransportRequestOptions): Promise<TransportResponse<R>> {
    return this.request<R>('GET', path, options);
  }

  post<R>(path: string, options?: TransportRequestOptions): Promise<TransportResponse<R>> {
    return this.request<R>('POST', path, options);
  }

  put<R>(path: string, options?: TransportRequestOptions): Promise<TransportResponse<R>> {
    return this.request<R>('PUT', path, options);
  }

  delete<R>(path: string, options?: TransportRequestOptions): Promise<TransportResponse<R>> {
    return this.request<R>('DELETE', path, options);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  toJSON(): undefined {
    return undefined;
  }
}
