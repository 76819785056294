import {Client} from 'lib/client';
import {convertOriginalContentList} from 'lib/contentList';
import {OriginalContentList, ItemContentType} from 'lib/contentList/types';
import {SearchSuggestion, SearchSuggestionKind} from 'lib/search/suggest';

type ResponseBody = {
  payload: {
    items?: OriginalContentList;
    nextPageToken?: string;
  };
};

type Params = {
  count?: number;
  query: string;
  storesCount?: number;
};

type RequestBody = Params;

type SearchPhraseSuggestResult = {
  items: SearchSuggestion[];
  nextPageToken?: string;
};

const DEFAULT_COUNT = 10;
const DEFAULT_STORES_COUNT = 3;

function extractSearchSuggestions(original: OriginalContentList): SearchSuggestion[] {
  return (
    convertOriginalContentList(original)
      .reduce<SearchSuggestion[]>((acc, item) => {
        if (item.type === ItemContentType.SEARCH_SUGGESTION) {
          acc.push(item.content);
        }

        return acc;
      }, [])
      // Place store items at the end of list
      // TODO: fix it after backend change direction
      .sort((a, b) => {
        if (a.kind !== b.kind) {
          return b.kind === SearchSuggestionKind.TEXT ? 1 : -1;
        }

        return 0;
      })
  );
}

const defaultParams: Partial<Params> = {
  count: DEFAULT_COUNT,
  storesCount: DEFAULT_STORES_COUNT,
};

export function searchPhraseSuggest(client: Client, params: Params): Promise<SearchPhraseSuggestResult> {
  const body: RequestBody = {...defaultParams, ...params};

  return client.mainApi.post<ResponseBody>('search/contentList/suggest', {body}).then(({body: {payload}}) => ({
    items: payload.items ? extractSearchSuggestions(payload.items) : [],
    nextPageToken: payload.nextPageToken,
  }));
}
