import {ChangeEventHandler, useCallback, useRef, useState} from 'react';
import {InputMode, InputProps} from '../types';
import {getInputModeByType, normalizeValue} from '../utils';

type UseInputResult<V = string | number> = {
  nativeProps: Pick<InputProps, 'autoComplete' | 'onChange'> & {
    defaultValue: undefined;
    inputMode: InputMode;
    type: 'password' | 'text' | 'number';
    value: V;
  };
  value: V;
};

const getType = (type: string | undefined): 'password' | 'text' | 'number' => {
  if (type === 'password' || type === 'number') {
    return type;
  }
  return 'text';
};

export function useInput(props: InputProps): UseInputResult {
  const propsRef = useRef<InputProps>(props);
  propsRef.current = props;

  // Main idea: use only an external state when the value can become defined
  const controlledOutside = useRef<boolean>(false);
  if (!controlledOutside.current) {
    controlledOutside.current = props.value !== undefined;
  }

  const [internalValue, setInternalValue] = useState<string | number | undefined>(props.value);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    if (!controlledOutside.current) {
      setInternalValue(event.target.value);
    }

    if (propsRef.current.onChange) {
      propsRef.current.onChange(event);
    }
  }, []);

  const value = normalizeValue(controlledOutside.current ? props.value : internalValue);

  return {
    nativeProps: {
      autoComplete: props.autoComplete,
      // The guard force override defined defaultValue if it possible
      defaultValue: undefined,

      inputMode: getInputModeByType(props.type),

      onChange: handleChange,

      type: getType(props.type),
      value,
    },
    value,
  };
}
