import {useStore} from 'lib/effector';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {PromocodeContext} from 'lib/promocode';
import {PromocodeContextValue} from 'lib/promocode/types';
import {$getCurrentPromocode} from 'models/promocode';
import React, {useMemo} from 'react';

type Props = {
  children: React.ReactNode;
};

export function PromocodeContextProvider({children}: Props): React.ReactElement {
  const language = useLanguage();
  const getCurrentPromocode = useStore($getCurrentPromocode);
  const value = useMemo<PromocodeContextValue>(
    () => ({
      getCurrentPromocode,
      promocodeAvailable: language === Language.RU || language === Language.EN,
    }),
    [getCurrentPromocode, language],
  );

  return <PromocodeContext.Provider value={value}>{children}</PromocodeContext.Provider>;
}
