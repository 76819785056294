import {Client} from 'lib/client';
import {User} from 'lib/user/types';

type UserResponse = {
  payload: User;
};

export function loadUser(client: Client): Promise<User> {
  return client.mainApi.get<UserResponse>('users/self').then(({body: {payload}}) => payload);
}
