export enum ErrorType {
  FORM_VALIDATION = 'errors.FormValidation',
  GENERAL = 'errors.General',
}

export type GeneralError = {
  payload: {
    message: string;
  };
  type: ErrorType.GENERAL;
};

export type FormValidationError = {
  payload: {
    fieldValidationErrors: {
      field: string;
      message: string;
      type: string;
    }[];
  };
  type: ErrorType.FORM_VALIDATION;
};
