import {type EnsureArrayHasKeys} from '@joomcode/joompro-core/types';

export enum CompanyEmployeesNumber {
  BETWEEN_0_AND_2 = '0-2',
  BETWEEN_11_AND_50 = '11-50',
  BETWEEN_3_AND_10 = '3-10',
  BETWEEN_51_AND_200 = '51-200',
  GREATER_THAN_201 = '201+',
}

export const COMPANY_EMPLOYEES_NUMBERS_ORDER: EnsureArrayHasKeys<CompanyEmployeesNumber> = [
  CompanyEmployeesNumber.BETWEEN_0_AND_2,
  CompanyEmployeesNumber.BETWEEN_3_AND_10,
  CompanyEmployeesNumber.BETWEEN_11_AND_50,
  CompanyEmployeesNumber.BETWEEN_51_AND_200,
  CompanyEmployeesNumber.GREATER_THAN_201,
];
