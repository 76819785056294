import {objectKeys} from '../object/keys';

export function loadAsyncScript(src: string, attributes?: Partial<HTMLScriptElement>): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const sibling = window.document.getElementsByTagName('script')[0];
      const script = window.document.createElement('script');

      if (attributes) {
        objectKeys(attributes).forEach((name) => {
          script.setAttribute(name, attributes[name] as string);
        });
      }

      script.src = src;
      script.async = true;
      script.type = 'text/javascript';
      script.onload = () => setTimeout(resolve, 0);
      script.onerror = reject;

      if (sibling.parentNode) {
        sibling.parentNode.insertBefore(script, sibling);
      }
    } catch (ex) {
      reject(ex);
    }
  });
}
