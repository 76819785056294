import {DataState} from '@joomcode/deprecated-utils/dataState';
import {analyticsDataLayer} from 'lib/effector/analytics';
import {$contactUsDialog, closeContactUsDialog, openContactUsDialogUnsafe, sendContactUsRequestFx} from './index';

$contactUsDialog.reset(closeContactUsDialog);
$contactUsDialog.on(openContactUsDialogUnsafe, (state, payload) => ({
  ...state,
  ...(payload ? {destination: payload} : {}),
  isOpen: true,
}));

$contactUsDialog
  .on(sendContactUsRequestFx, (state) => ({
    ...state,
    requestState: DataState.LOADING,
  }))
  .on(sendContactUsRequestFx.doneData, (state) => ({
    ...state,
    requestState: DataState.LOADED,
  }))
  .on(sendContactUsRequestFx.failData, (state, error) => ({
    ...state,
    requestError: error,
    requestState: DataState.FAILED,
  }));

analyticsDataLayer(sendContactUsRequestFx.doneData, () => ({
  event: 'contactUsSuccess',
}));
