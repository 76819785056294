import {DataState} from '@joomcode/deprecated-utils/dataState';
import {sample} from 'effector';
import {createDealInternalFx} from 'models/deal';
import {$user, $userNotAnonymous} from 'models/user';
import {addCartItemFx, removeCartItemsFx} from '../index';
import {$cartItemsCount, loadCartItemsCountFx} from './index';

$cartItemsCount
  .on(loadCartItemsCountFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(loadCartItemsCountFx.doneData, (state, response) => ({
    ...state,
    data: response,
    dataState: DataState.LOADED,
  }))
  .on(loadCartItemsCountFx.failData, (state, error) => ({
    ...state,
    dataState: DataState.FAILED,
    error,
  }));

sample({
  clock: [$user.map(({id}) => id), addCartItemFx.done, removeCartItemsFx.done, createDealInternalFx.done],
  filter: $userNotAnonymous,
  target: loadCartItemsCountFx,
});
