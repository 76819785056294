import {guard} from 'effector';
import {createSearchResultFeedbackBannerItem} from 'lib/contentList';
import {analyticsDataLayer} from 'lib/effector/analytics';
import './promotions/init';
import './promotion/init';
import './suggest/init';
import {loadFx, $search, $searchPhrase, setSearchPhrase, resetSearchPhrase, getImageEmbeddingFx} from './index';

const DELETE_COUNT_FROM_SEARCH_RESULT = 0;
const INDEX_OF_SEARCH_RESULT_FEEDBACK_BANNER = 12;

$search
  .on(loadFx, (store, request) => ({
    ...store,
    items: request.pageToken ? store.items || [] : [],
    loading: true,
    loadingError: undefined,
    request,
  }))
  .on(loadFx.done, (store, {result: data, params}) => {
    const currentItems = store.items ?? [];
    const nextItems = [...currentItems, ...data.items];

    if (!currentItems.length && nextItems.length && data.searchResultsUniqId && params.imageId) {
      nextItems.splice(
        INDEX_OF_SEARCH_RESULT_FEEDBACK_BANNER,
        DELETE_COUNT_FROM_SEARCH_RESULT,
        createSearchResultFeedbackBannerItem(data.searchResultsUniqId),
      );
    }

    return {
      ...store,
      categories: data.categories,
      currentCategory: data.currentCategory,
      filename: data.filename,
      filterList: data.filterList,
      imageId: params.imageId,
      items: nextItems,
      loading: false,
      nextPageToken: data.nextPageToken,
      searchResultsUniqId: data.searchResultsUniqId,
      sortingList: data.sortingList,
    };
  })
  .on(loadFx.failData, (store, error) => ({
    ...store,
    loading: false,
    loadingError: error,
  }));

$search
  .on(getImageEmbeddingFx, (state, params) => ({
    ...state,
    filename: params.filename,
    items: [],
    loading: true,
  }))
  .on(getImageEmbeddingFx.failData, (state, error) => ({
    ...state,
    loading: false,
    loadingError: error,
  }));

$searchPhrase.on(setSearchPhrase, (state, phrase) => phrase).reset(resetSearchPhrase);

const searchPhraseChanged = guard({
  clock: setSearchPhrase,
  filter: (phrase) => __CLIENT__ && Boolean(phrase),
  source: $searchPhrase,
});

analyticsDataLayer(searchPhraseChanged, (phrase) => ({
  event: 'searchPhrase.change',
  search_term: phrase,
}));
