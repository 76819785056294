import {addInteraction} from 'api/interactions';
import {combine, guard} from 'effector';
import {CommunicationReason} from 'lib/communication/types';
import {createClientEffect, createEvent, createStore} from 'lib/effector';
import {$modalsManager} from 'models/modalsManager';

type ConsultatonState = {
  error?: unknown;
  opened: boolean;
  processing: boolean;
  readonly reason: CommunicationReason;
  source: string;
  success?: boolean;
};

type ConsultationDirectRequest = {
  phone: string;
  source: string;
};

const initialState = {
  opened: false,
  processing: false,
  reason: CommunicationReason.CONSULTATION_CLAIM,
  source: '',
};

export const $consultationDirect = createStore<ConsultatonState>('consultationDirect', initialState);

export const sendDirectConsultationData = createEvent<ConsultationDirectRequest>();

export const sendDirectConsultationDataFx = createClientEffect(addInteraction);

export const closeDialog = createEvent();

export const $canOpenConsultationDirectDialog = combine(
  $consultationDirect,
  $modalsManager,
  ({opened: consultationDirectDialogOpened}, {dialogOpened: otherDialogOpened}) =>
    !consultationDirectDialogOpened && !otherDialogOpened,
);

export const openSuccessDialog = guard({
  filter: $canOpenConsultationDirectDialog,
  source: sendDirectConsultationDataFx.doneData,
});

export const openFailDialog = guard({
  filter: $canOpenConsultationDirectDialog,
  source: sendDirectConsultationDataFx.failData,
});
