import {isRecord} from 'lib/guards';

export type MessageError = {
  kind: 'MessageError';
  message: string;
};

export function isMessageError(error: unknown): error is MessageError {
  return isRecord(error) && error.kind === 'MessageError';
}

export function messageError(message: string): MessageError {
  return {
    kind: 'MessageError',
    message,
  };
}
