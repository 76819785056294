import uuid from 'uuid';
import {ItemContentType} from '../types';
import {SearchResultFeedbackBannerItemCreator} from './types';

export const createSearchResultFeedbackBannerItem: SearchResultFeedbackBannerItemCreator = (
  searchResultsUniqId: string,
) => {
  return {
    content: {
      searchResultsUniqId,
    },
    id: uuid(),
    type: ItemContentType.SEARCH_RESULT_FEEDBACK_BANNER,
  };
};
