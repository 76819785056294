import {TextareaField} from 'components/Form';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {TextareaTestId} from 'uikit/Textarea';

export type DescriptionFieldTestId = TextareaTestId;

type Props = TestIdProp<DescriptionFieldTestId> & {
  required?: boolean;
};

export function DescriptionField({required, testId}: Props): React.ReactElement {
  return <TextareaField name='description' required={required} testId={testId} />;
}
