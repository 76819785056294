import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  message: {
    defaultMessage: 'Please try again or contact our customer support: {email}',
    description: 'Error message: Default text',
  },
  title: {
    defaultMessage: 'Oops! Something went wrong.',
    description: 'Error Message: Default Header',
  },
});
