import {DialogContentHeader} from 'components/DialogContent';
import {SupportEmail} from 'components/SupportEmail';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useRef, useEffect} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {DotsLoading, DotsLoadingTestId} from 'uikit/DotsLoading';
import {View, ViewError, ViewFields, ViewExtraContent} from '../../View';
import styles from './index.module.scss';
import {OtpInput, OtpInputTestId} from './OtpInput';
import {OtpResend, OtpResendTestId} from './OtpResend';

const MAX_CODE_LENGTH = 6;

const messages = defineMessages({
  error: {
    defaultMessage: 'Check for errors and try again',
    description: 'Login and registration form: standard error',
  },
  notReceived: {
    defaultMessage: "Haven't received an SMS? Write to {email}",
    description: 'Login and registration form: notification of agreement with the data processing policy',
  },
  subtitle: {
    defaultMessage: 'The confirmation code has been sent to {phone}',
    description: 'Login and registration form: subtitle',
  },
  title: {
    defaultMessage: 'Insert a code',
    description: 'Login and registration form: header',
  },
});

export type OtpViewTestId = {
  loader: DotsLoadingTestId;
  otpInput: OtpInputTestId;
  otpResend: OtpResendTestId;
  support: unknown;
};

type Props = TestIdProp<OtpViewTestId> & {
  onResend(): void;
  onSubmit(otp: string): void;
  phone?: string;
  retryAfterMs: number;
  sendOtpError?: unknown;
  sendOtpLoading?: boolean;
  signInError?: unknown;
  signInLoading?: boolean;
};

export function OtpView({
  phone,
  testId,
  retryAfterMs,
  onResend,
  onSubmit,
  signInLoading,
  signInError,
  sendOtpLoading,
  sendOtpError,
}: Props): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(
    (otp: string) => {
      onSubmit(otp);
    },
    [onSubmit],
  );

  const handleResendOtp = useCallback(() => {
    onResend();
  }, [onResend]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <View>
      <DialogContentHeader
        image='/illustrations/auth/enterOtp.png'
        imageSize={200}
        subtitle={<FormattedMessage {...messages.subtitle} values={{phone: <div>{phone}</div>}} />}
        title={messages.title}
      />
      <ViewFields>
        <div className={styles.content}>
          <OtpInput
            disabled={signInLoading}
            maxLength={MAX_CODE_LENGTH}
            onSubmit={handleSubmit}
            ref={inputRef}
            testId={testId?.otpInput}
          />
          <OtpResend
            awaitingTime={retryAfterMs}
            error={sendOtpError}
            loading={sendOtpLoading}
            onResend={handleResendOtp}
            testId={testId?.otpResend}
          />
          {signInLoading && (
            <div className={styles.loading}>
              <DotsLoading testId={testId?.loader} />
            </div>
          )}
        </div>
      </ViewFields>
      {signInError ? <ViewError error={messages.error} /> : null}
      <ViewExtraContent textAlign='center'>
        <span className={styles.support} data-test-id={testId?.support}>
          <FormattedMessage {...messages.notReceived} values={{email: <SupportEmail className={styles.email} />}} />
        </span>
      </ViewExtraContent>
    </View>
  );
}
