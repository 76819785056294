import {isString} from 'lib/guards';
import React, {useCallback, useContext, useEffect} from 'react';
import {Select, SelectOption, SelectChangeHandler, SelectChangeData} from 'uikit/Select';
import {FormTextsContext, useFormStateContext, useFormActionsContext} from '../contexts';
import {SelectField as SelectFieldProps} from '../types';

export function SelectField(props: SelectFieldProps): React.ReactElement {
  const {disabled, items, label, name, onChange, placeholder, testId, type, value, size} = props;

  const texts = useContext(FormTextsContext);
  const {onFieldChange, addField, removeField} = useFormActionsContext();
  const {errors, values} = useFormStateContext();

  const handleInputChange = useCallback<SelectChangeHandler<string>>(
    (data: SelectChangeData<string>) => {
      const nextData = {name, value: data.value};
      onFieldChange(nextData);

      if (onChange) {
        onChange(nextData);
      }
    },
    [onFieldChange, onChange, name],
  );

  useEffect(() => {
    addField(props);

    return () => removeField(props);
  }, []);

  useEffect(() => {
    if (name in values && values[name] === value) {
      return;
    }

    onFieldChange({name, type, value: value ?? ''});
  }, [value]);

  let inputValue;

  if (name in values) {
    inputValue = isString(values[name]) ? String(values[name]) : undefined;
  } else {
    inputValue = value;
  }

  const inputError = errors[name];

  return (
    <Select<string>
      disabled={disabled}
      error={inputError}
      label={label || texts?.labels?.[name]}
      name={name}
      onChange={handleInputChange}
      placeholder={placeholder || texts?.placeholders?.[name]}
      size={size}
      testId={testId}
      value={inputValue}
    >
      {items.map(({value: optionValue, label: optionLabel}) => (
        <SelectOption key={optionValue} value={optionValue}>
          {optionLabel ?? optionValue}
        </SelectOption>
      ))}
    </Select>
  );
}
