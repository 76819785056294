import {loadRootCategories} from 'api/categories';
import {Category} from 'lib/category/types';
import {createStore, createClientEffect} from 'lib/effector';

export type CategoriesState = {
  categories?: Category[];
  loading: boolean;
  loadingError?: Error;
};

const initialCategoriesData: CategoriesState = {loading: false};

export const $categories = createStore<CategoriesState>('categories', initialCategoriesData);

export const loadRootCategoriesFx = createClientEffect(loadRootCategories);
