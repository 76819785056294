import {convertOriginalProductLite} from 'lib/productLite';
import {ItemContentType} from '../types';
import {ProductItemCreator} from './types';

export const createProductItem: ProductItemCreator = (original) => {
  return {
    content: convertOriginalProductLite(original.content.product),
    id: original.id,
    type: ItemContentType.PRODUCT,
  };
};
