import {
  $paymentsPartnersDialog,
  openPaymentsPartnersDialog,
  closePaymentsPartnersDialog,
  sendPagePaymentsPartnersDataFx,
} from './index';

$paymentsPartnersDialog
  .on(openPaymentsPartnersDialog, (state) => ({
    ...state,
    opened: true,
  }))
  .on(sendPagePaymentsPartnersDataFx, (state) => ({
    ...state,
    error: undefined,
    processing: true,
    success: false,
  }))
  .on(sendPagePaymentsPartnersDataFx.doneData, (state) => ({
    ...state,
    processing: false,
    success: true,
  }))
  .on(sendPagePaymentsPartnersDataFx.failData, (state, error) => ({
    ...state,
    error,
    processing: false,
  }))
  .reset(closePaymentsPartnersDialog);
