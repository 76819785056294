import {CommunicationDialog, CommunicationDialogTestId} from 'components/CommunicationDialog';
import {CommunicationReason} from 'lib/communication/types';
import {monthMessages} from 'lib/datetime';
import {Month, Case} from 'lib/datetime/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import {PageLeaveData, SubmitHandler} from './types';

export type PageLeaveDialogTestId = CommunicationDialogTestId;

type Props = TestIdProp<PageLeaveDialogTestId> & {
  currentMonth: Month;
  error?: unknown;
  onClose: () => void;
  onSubmit: SubmitHandler;
  onSuccess?: () => void;
  processing?: boolean;
  reason?: CommunicationReason;
  success?: boolean;
};

export function PageLeaveDialog({
  currentMonth,
  error,
  onClose,
  onSubmit,
  onSuccess,
  processing,
  success,
  testId,
  reason,
}: Props): React.ReactElement {
  const intl = useIntl();
  const month = intl.formatMessage(monthMessages[currentMonth], {case: Case.GENETIVE});
  const subtitle = intl.formatMessage(messages.subtitle, {month});

  return (
    <CommunicationDialog<PageLeaveData>
      error={error}
      illustration='/illustrations/larek.svg'
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      processing={processing}
      reason={reason}
      section='pageLeaveDialog'
      subtitle={subtitle}
      success={success}
      testId={testId}
      title={messages.title}
    />
  );
}
