"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcsConsoleProvider = void 0;
const Provider_1 = require("../providers/Provider");
const utils_1 = require("../utils");
class EcsConsoleProvider extends Provider_1.Provider {
    processMessage(ecsInputMessage, meta) {
        return ecsInputMessage;
    }
    processInternal({ name, level, timestamp, meta, args }) {
        if (!globalThis.console) {
            return;
        }
        let ecsInputMessage;
        const maybeEcsInputMessage = args[0];
        if (maybeEcsInputMessage && typeof maybeEcsInputMessage === 'object') {
            ecsInputMessage = maybeEcsInputMessage;
        }
        else {
            ecsInputMessage = {
                message: args.map(utils_1.stringify).join(' '),
            };
        }
        const ecsMessage = {
            ...ecsInputMessage,
            ...(meta.requestId && {
                http: {
                    ...ecsInputMessage.http,
                    request: {
                        ...ecsInputMessage.http?.request,
                        id: meta.requestId,
                    },
                },
            }),
            log: {
                ...ecsInputMessage.log,
                logger: name,
                level: level.name,
            },
            '@timestamp': new Date(timestamp).toISOString(),
        };
        const postprocessedEcsMessage = this.processMessage(ecsMessage, meta);
        globalThis.console.log((0, utils_1.stringify)(postprocessedEcsMessage));
    }
}
exports.EcsConsoleProvider = EcsConsoleProvider;
