import {Client} from 'lib/client';
import {cleanPhone} from 'lib/data/phone';
import {isFormValidationError} from 'lib/errors/guards';
import {formValidationError} from 'lib/form/types';
import {isTransportResponse} from 'lib/transport/types';

type Params = {
  description?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  partnerName: string;
  phone: string;
  promocode?: string;
};

type ResponsePayload = {
  friendlyId: string;
  interactionId: string;
  popupRequestId?: string;
};

type ResponseBody = {
  payload: ResponsePayload;
};

export function addPartnersCustomer(client: Client, params: Params): Promise<ResponsePayload> {
  const body = {...params, phone: cleanPhone(params.phone)};

  return client.mainApi
    .post<ResponseBody>('interactions/partners/addCustomer', {body})
    .then(({body: {payload}}) => payload)
    .catch((error) => {
      if (isTransportResponse(error) && isFormValidationError(error.body)) {
        const {fieldValidationErrors} = error.body.payload;
        const errorMap = fieldValidationErrors.reduce<Record<string, string>>((result, item) => {
          // eslint-disable-next-line no-param-reassign
          result[item.field] = item.message;
          return result;
        }, {});

        throw formValidationError(errorMap);
      }

      throw error;
    });
}
