import {DataState} from '@joomcode/deprecated-utils/dataState';
import * as api from 'api/cart';
import {CartItemUpdatePayload} from 'api/cart/updateCartItem';
import {CartItem} from 'lib/cart/types';
import {createClientEffect, createStore, createEvent} from 'lib/effector';

export type CartState = {
  data: CartItem[];
  dataState: DataState;
  error?: Error;
};

export const $cart = createStore<CartState>('cart', {
  data: [],
  dataState: DataState.IDLE,
});

export const loadCartItemsFx = createClientEffect(api.loadCartItems);

export const addCartItemFx = createClientEffect(api.addCartItem);

export const removeCartItemsFx = createClientEffect(api.removeCartItems);

/**
 * In order to debounce request and immediate optimistic update we use separate
 * - updateCartItemFx for updating data on the server
 * - updateCartItem for updating local state
 */
export const updateCartItemFx = createClientEffect(api.updateCartItem);

export const updateCartItem = createEvent<CartItemUpdatePayload>();
