import {isId} from '@joomcode/deprecated-utils/joomId';
import {isCompanyOperationField} from 'lib/user/companyOperationField';
import {isCompanyRole} from 'lib/user/companyRole';
import {UserUpdatePayload} from 'lib/user/types';
import isEmpty from 'lodash/isEmpty';
import {stepToFields, requiredFields} from './config';
import {FormData} from './types';

export const getStepByFormData = (data: FormData): number | undefined => {
  for (let step = 0; step < stepToFields.length; step++) {
    const stepFieldNames = stepToFields[step];
    for (const fieldName of stepFieldNames) {
      if (requiredFields.has(fieldName) && isEmpty(data[fieldName])) {
        return step;
      }
    }
  }
  return undefined;
};

export const convertUserPayloadToFormData = (payload: UserUpdatePayload): FormData => {
  const companyOperationFields = ((payload.companyOperationFields as string[]) || []).concat(
    payload.companyOperationFieldOtherValues || [],
  );

  const companyRole = payload.companyRole || payload.companyRoleOtherValue;

  const categoryIds = (payload.categoryIds || []).concat(payload.categoryOtherValues || []);

  return {
    ...payload,
    categoryIds,
    companyOperationFields,
    companyRole,
    hasProductImport: payload.hasProductImport ? 'true' : 'false',
  };
};

export const convertFormDataToUserPayload = (data: FormData): UserUpdatePayload => {
  const companyOperationFields = (data.companyOperationFields || []).filter(isCompanyOperationField);
  const companyOperationFieldOtherValues = (data.companyOperationFields || []).filter(
    (item) => !isCompanyOperationField(item),
  );

  const companyRole = isCompanyRole(data.companyRole) ? data.companyRole : '';
  const companyRoleOtherValue = !isCompanyRole(data.companyRole) ? data.companyRole : '';

  const categoryIds = (data.categoryIds || []).filter(isId);
  const categoryOtherValues = (data.categoryIds || []).filter((item) => !isId(item));

  return {
    ...data,
    categoryIds,
    categoryOtherValues,
    companyOperationFieldOtherValues,
    companyOperationFields,
    companyRole,
    companyRoleOtherValue,
    hasProductImport: data.hasProductImport === 'true',
  };
};
