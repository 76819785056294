import type {Client} from 'lib/client';
import {Preferences} from 'lib/preferences/types';
import {BodyPayload} from 'lib/transport/types';
import {extractPayload} from 'lib/transport/utils';

export type RefreshOrCreateParams = {
  preferences?: Preferences;
  sysInfo?: {
    osType?: string;
  };
};

type RefreshOrCreateResult = {
  isBot: boolean;
};

export function refreshOrCreate(client: Client, params: RefreshOrCreateParams): Promise<RefreshOrCreateResult> {
  return client.tokensApi
    .post<BodyPayload<RefreshOrCreateResult>>('device/refreshOrCreate', {body: params})
    .then(extractPayload);
}
