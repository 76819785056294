import type {AppEnv} from './types';

export function isEqualAppEnvValues(envA: Partial<AppEnv> | undefined, envB: Partial<AppEnv> | undefined): boolean {
  if (envA === undefined) {
    return envA === envB;
  }

  if (envB === undefined) {
    return false;
  }

  if (envA === envB) {
    return true;
  }

  const keysA = Object.keys(envA);
  const keysB = Object.keys(envB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (envA[key as keyof AppEnv] !== envB[key as keyof AppEnv]) {
      return false;
    }
  }

  return true;
}
