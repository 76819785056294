import {DataState} from '@joomcode/deprecated-utils/dataState';
import * as api from 'api/cart';
import {createClientEffect, createStore} from 'lib/effector';

export type CartItemsCountState = {
  data: number;
  dataState: DataState;
  error?: Error;
};

export const $cartItemsCount = createStore<CartItemsCountState>('cartItemsCount', {
  data: 0,
  dataState: DataState.IDLE,
});

export const loadCartItemsCountFx = createClientEffect(api.loadCartItemsCount);
