import {IncomingMessage} from 'http';
import {isArray} from 'lib/guards';
import {clientLogger} from 'lib/logger';
import {UAParser} from 'ua-parser-js';

export function getOsName(userAgent?: string): string {
  if (__SERVER__) {
    // eslint-disable-next-line new-cap
    return UAParser(userAgent).os.name?.toLowerCase() ?? '';
  }

  throw new Error('getOsName is not available on client');
}

export function getXff(req: IncomingMessage): string | undefined {
  let originXff = req.headers['x-forwarded-for'];
  originXff = isArray(originXff) ? originXff[0] : originXff;

  if (!originXff) {
    if (!req?.socket?.remoteAddress) {
      clientLogger.warn('Remote address is not defined');
      return undefined;
    }

    originXff = req.socket.remoteAddress;
  }

  return req.socket.localAddress ? `${originXff}, ${req.socket.localAddress}` : originXff;
}
