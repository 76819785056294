import {useContext} from 'react';
import {AnalyticsContext} from './context';
import type {AnalyticsInterface} from './types';

export function useAnalytics(): AnalyticsInterface {
  const analytics = useContext(AnalyticsContext);

  if (!analytics) {
    throw Error('AnalyticsContext must be defined');
  }

  return analytics;
}
