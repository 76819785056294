import {ItemContentType} from '../types';
import {RfqBannerItemCreator} from './types';

export const createRfqBannerItem: RfqBannerItemCreator = (id: string) => {
  return {
    content: undefined,
    id,
    type: ItemContentType.RFQ_BANNER,
  };
};
