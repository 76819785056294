import {RefObject, useEffect, useRef} from 'react';

export type UseIntersectionObserverProps<T, R> = {
  callback: IntersectionObserverCallback;
  disabled?: boolean;
  options?: IntersectionObserverInit;
  rootRef?: RefObject<R>;
  targetRef: RefObject<T>;
};

export function useIntersectionObserver<T extends Element, R extends Element>({
  rootRef,
  targetRef,
  disabled,
  options,
  callback,
}: UseIntersectionObserverProps<T, R>) {
  const observingTargetRef = useRef<T>();
  const observerRef = useRef<IntersectionObserver>();
  const callbackRef = useRef<IntersectionObserverCallback>(callback);
  callbackRef.current = callback;

  useEffect(
    () => () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    },
    [],
  );

  useEffect(() => {
    if (disabled) {
      observerRef.current?.disconnect();
    } else if (observingTargetRef.current) {
      observerRef.current?.observe(observingTargetRef.current);
    }
  }, [disabled]);

  useEffect(() => {
    if (!disabled) {
      const processedOptions = {...options};

      // You may use root via options or via prop rootRef
      // root in options has priority over ref.
      if (!('root' in processedOptions)) {
        if (rootRef && rootRef.current) {
          processedOptions.root = rootRef.current;
        }
      }

      observerRef.current?.disconnect();
      observerRef.current = new IntersectionObserver(callbackRef.current, processedOptions);

      if (observingTargetRef.current) {
        observerRef.current.observe(observingTargetRef.current);
      }
    }
  }, [options?.root, options?.rootMargin, options?.threshold]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    // As we use react we need to handle elements' ref in other way.
    // To see change of targetElement we need always check its ref.
    const isTargetChanged = observingTargetRef.current !== targetRef.current;

    if (!targetRef.current) {
      observerRef.current?.disconnect();
      observingTargetRef.current = undefined;
    } else if (isTargetChanged) {
      if (!observerRef.current) {
        observerRef.current = new IntersectionObserver(callbackRef.current, options);
      } else {
        observingTargetRef.current = targetRef.current;
        observerRef.current.disconnect();
        observerRef.current.observe(observingTargetRef.current);
      }
    }
  });
}
