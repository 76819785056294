import {loadSamplePrices, Payload} from 'api/sample/loadSamplePrices';
import {attach} from 'effector';
import {createClientEffect, createEvent, createStore} from 'lib/effector';
import {ParamsWithRequestId} from 'lib/effector/types';
import {SamplePricesResponse} from 'lib/sample/types';
import uuid from 'uuid';

type SamplePricesState = {
  loading: boolean;
  loadingError?: unknown;
  prices?: SamplePricesResponse;
  requestId?: string;
};

export const $samplePrices = createStore<SamplePricesState>('samplePrices', {loading: false});

export const resetSamplePrices = createEvent();

export const loadSamplePricesWithReqIdFx = createClientEffect<ParamsWithRequestId<Payload>, SamplePricesResponse>(
  loadSamplePrices,
);

export const loadSamplePricesFx = attach({
  effect: loadSamplePricesWithReqIdFx,
  mapParams: (params: Payload & {requestId?: string}) => {
    return {
      ...params,
      requestId: uuid(),
    };
  },
});
