import {isRecord} from 'lib/guards';

export type DaysRange = {
  maxDays: number;
  minDays: number;
  type: 'days';
};

export type TimestampRange = {
  endTimeMs: number;
  startTimeMs: number;
  type: 'ms';
};

export type DateTimeRange = DaysRange | TimestampRange;

export function isDaysRange(range: DateTimeRange): range is DaysRange {
  return isRecord(range) && range.type === 'days';
}

export function isTimestampRange(range: DateTimeRange): range is TimestampRange {
  return isRecord(range) && range.type === 'ms';
}

export enum Month {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export enum Case {
  ACCUSATIVE = 'accusative',
  DATIVE = 'dative',
  GENETIVE = 'genitive',
  INSTRUMENTAL = 'instrumental',
  NOMINATIVE = 'nominative',
  PREPOSITIONAL = 'prepositional',
}
