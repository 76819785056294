import {InputField} from 'components/Form';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {InputTestId} from 'uikit/Input';

export type CompanyNameFieldTestId = InputTestId;

type Props = TestIdProp<CompanyNameFieldTestId> & {
  required?: boolean;
};

export function CompanyNameField({required, testId}: Props): React.ReactElement {
  return <InputField name='companyName' required={required} testId={testId} type='text' />;
}
