import {RequestPasswordChangeCredential} from 'components/Auth/types';
import {Client} from 'lib/client';
import {formValidationError} from 'lib/form/types';
import {isRecord} from 'lib/guards';
import {isTransportResponse} from 'lib/transport/types';
import {User} from 'lib/user/types';
import {PayloadWithUser} from './types';
import {extractUser, handleCredentialsValidationError} from './utils';

type InvalidTokenError = {
  payload: {
    generalValidationMessage: string;
  };
  type: 'emailauth.not_found';
};

// TODO: PRO-194 change backend to validationError
function isInvalidTokenError(error: unknown): error is InvalidTokenError {
  return isRecord(error) && error.type === 'emailauth.not_found';
}

export function requestPasswordChange(client: Client, credential: RequestPasswordChangeCredential): Promise<User> {
  return client.mainApi
    .post<PayloadWithUser>('auth/email/setNewPassword', {body: credential})
    .then(extractUser)
    .catch((error) => {
      handleCredentialsValidationError(error);
      if (isTransportResponse(error) && isInvalidTokenError(error.body)) {
        throw formValidationError({}, error.body.payload.generalValidationMessage);
      }

      throw error;
    });
}
