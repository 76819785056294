const OLD = '\\d+-\\d+-\\d+-\\d+-\\d+'; // keep it simple, grouping is not supported
const NEW = '[a-f\\d]{24}';

export const NEW_ID_REG_EXP = new RegExp(`^${NEW}$`);

export const ID_REG_EXP = new RegExp(`^${NEW}$|^${OLD}$`);

export const ID_ROUTE_PARAM_PATTERN = `${NEW}|${OLD}`;

export const FORBIDDEN_ID = '0-0-0-0-0';

export const FRIENDLY_ID_REG_EXP = /^(JMS-)?[\w\d]{5,8}$/;
