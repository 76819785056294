import {ContentList, ItemContentType} from 'lib/contentList/types';
import {ProductLite} from 'lib/productLite/types';

export function extractProductItems(contentList: ContentList): ProductLite[] {
  return contentList.reduce<ProductLite[]>((result, item) => {
    if (item.type === ItemContentType.PRODUCT) {
      result.push(item.content);
    }

    return result;
  }, []);
}
