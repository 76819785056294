import {isArray, isRecord} from 'lib/guards';
import {ImageBundle} from './types';

export function isImageBundle(target: unknown): target is ImageBundle {
  return isRecord(target) && isArray(target.images);
}

export function srcSetItemTemplate(url: string, width: string | number): string {
  return `${url} ${width}w`;
}

export function makeSrcSet(image: ImageBundle): string | undefined {
  return image.images.map((img) => srcSetItemTemplate(img.url, img.width)).join(',');
}

export function getSrcByWidth({images}: ImageBundle, width: number): string {
  let image = images[0];

  for (let i = 1; i < images.length; i++) {
    if (image.width >= width) {
      break;
    }

    image = images[i];
  }

  return image.url;
}

export function getImageKey(image: ImageBundle): string | undefined {
  return image.id || image.images[0]?.url;
}
