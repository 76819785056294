import {SECOND} from 'lib/datetime';
import {analyticsLogger} from 'lib/logger';
import {AnalyticsInterface} from '../types';

const BOUNCE_CHECK_TIMEOUT = 10 * SECOND;

type BounceCheckForceReason = 'pageClosed';

type BounceCheck = {
  abort: () => void;
  forceSend: (reason: BounceCheckForceReason) => void;
  fulfilled: boolean;
};

export function startBounceCheck(analytics: AnalyticsInterface, pageUrl: string): BounceCheck {
  const startTimeMs = Date.now();
  let fulfilled = false;
  let timerId = 0;

  const sendEvent = (forceSendReason?: BounceCheckForceReason) => {
    if (fulfilled) {
      return;
    }

    window.clearTimeout(timerId);
    fulfilled = true;

    try {
      analytics.sendEvent({
        payload: {
          pageUrl,
          sentReason: forceSendReason || 'timerExpired',
          sinceOpenMs: Date.now() - startTimeMs,
        },
        type: 'bounceCheck',
      });
    } catch (error) {
      analyticsLogger.error('bounceCheck', error);
    }
  };

  timerId = window.setTimeout(sendEvent, BOUNCE_CHECK_TIMEOUT);

  return {
    abort: () => {
      window.clearTimeout(timerId);
      fulfilled = true;
    },
    forceSend: (reason: BounceCheckForceReason) => sendEvent(reason),
    fulfilled,
  };
}
