import {useEvent, useStore} from 'lib/effector';
import {UserUpdatePayload} from 'lib/user/types';
import {
  $ssRegistrationDialog,
  updateUserFx,
  resetDataState,
  cancelSsRegistrationDialog,
} from 'models/ssRegistrationDialog';
import {$user} from 'models/user';
import React, {useCallback} from 'react';
import {CoreDialog} from './CoreDialog';

export function RegistrationDialog(): React.ReactElement | null {
  const dialogState = useStore($ssRegistrationDialog);
  const user = useStore($user);
  const closeDialog = useEvent(cancelSsRegistrationDialog);

  const updateUser = useEvent(updateUserFx);
  const handleSubmit = useCallback(async (data: UserUpdatePayload) => updateUser(data), [updateUser]);
  const handleTryAgain = useEvent(resetDataState);

  if (!dialogState.opened) {
    return null;
  }
  return (
    <CoreDialog
      dataState={dialogState.dataState}
      dealInProgressSubtitleVisible={dialogState.showCheckoutPausedMessage}
      onClose={closeDialog}
      onSubmit={handleSubmit}
      onTryAgain={handleTryAgain}
      successVisible={dialogState.successVisible}
      user={user}
    />
  );
}
