"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsoleProvider = void 0;
const Provider_1 = require("../providers/Provider");
const Level_1 = require("../common/Level");
const isNode_1 = require("../utils/isNode");
function log(type, event) {
    const { meta: { requestId = '-' }, name, args, timestamp, level, } = event;
    const consoleArgs = args.map((item) => {
        if ((0, isNode_1.isNode)() && item instanceof Error) {
            return {
                message: item.message,
                name: item.name,
                stack: item.stack,
            };
        }
        return item;
    });
    const timeString = new Date(timestamp).toISOString();
    const signature = `${level.name} [${name}][${timeString}][#${requestId}]`;
    if (globalThis.console[type]) {
        globalThis.console[type](signature, ...consoleArgs);
    }
    else {
        globalThis.console.log(signature, ...consoleArgs);
    }
}
class ConsoleProvider extends Provider_1.Provider {
    processInternal(data) {
        if (!global.console) {
            return;
        }
        switch (data.level.value) {
            case Level_1.Level.FATAL.value:
            case Level_1.Level.ERROR.value:
                log('error', data);
                break;
            case Level_1.Level.WARN.value:
                log('warn', data);
                break;
            case Level_1.Level.INFO.value:
                log('info', data);
                break;
            case Level_1.Level.TRACE.value:
                log('trace', data);
                break;
            default:
                log('log', data);
                break;
        }
    }
}
exports.ConsoleProvider = ConsoleProvider;
