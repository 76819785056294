import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {isFunction} from 'lib/guards';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {useCallback} from 'react';
import {messages} from '../messages';
import {ValidateHandler, Validator} from '../types';

type Props<T> = {
  required?: boolean;
  requiredMessage?: ExtMessageDescriptor;
  validate?: ValidateHandler<T>;
};

export function useDefaultValidator<T>({
  requiredMessage = messages.required,
  required,
  validate,
}: Props<T>): Validator<T> {
  return useCallback(
    (value) => {
      if (required) {
        if (isNullish(value)) {
          return requiredMessage;
        }

        if (typeof value === 'string') {
          if (value === '') {
            return requiredMessage;
          }
        }

        if (Array.isArray(value) && value.length === 0) {
          return requiredMessage;
        }
      }

      if (isFunction(validate)) {
        return validate(value);
      }

      return undefined;
    },
    [required, requiredMessage, validate],
  );
}
