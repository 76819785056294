import {useDefaultValidator, useFormMessages} from 'lib/finalForm/hooks';
import React from 'react';
import {RadioButton, Props as RadioButtonProps} from 'uikit/RadioButton';
import {useRadio} from './useRadio';

export type RadioFieldProps<T extends string | number> = Omit<
  RadioButtonProps,
  'checked' | 'onChange' | 'children' | 'align'
> & {
  name: string;
  required?: boolean;
  value: T;
};

export function RadioField<T extends string | number>({
  name,
  label,
  disabled,
  id,
  value,
  required,
}: RadioFieldProps<T>): React.ReactElement {
  const handleValidate = useDefaultValidator({
    required,
  });
  const {inputProps} = useRadio({name, validate: handleValidate, value});
  const messages = useFormMessages();
  const inputLabel = label || messages[name].label;

  return (
    <RadioButton
      align='center'
      checked={inputProps.checked}
      disabled={disabled}
      id={id}
      label={inputLabel}
      name={name}
      onChange={inputProps.onChange}
      value={inputProps.value}
    />
  );
}
