import {Category} from 'lib/category/types';
import {Color} from 'lib/color/types';
import {ObfuscatedCount} from 'lib/obfuscatedCount/types';
import {OriginalFilterType} from './common';

// https://github.com/joomcode/api/blob/a90601ea0ba315289a51888833a7a63b49659a80/src/joom/app/clientapp/client/clientsearch/clientsearchfilter/types.go
// https://github.com/joomcode/api/blob/a90601ea0ba315289a51888833a7a63b49659a80/src/joom/app/search/searchquery/filter.go

/* ORIGINAL FILTERS */

/* Category filter */

export type OriginalFilterCategoryValue = {
  items?: Category[];
  totalCount?: ObfuscatedCount;
  type: OriginalFilterType.CATEGORIES;
};

export type OriginalFilterCategorySimpleValue = Pick<OriginalFilterCategoryValue, 'type'> & {
  items: Pick<Category, 'id'>[];
};

/* Color filter */

export type OriginalFilterColorItem = {
  colors: Color[];
  count: ObfuscatedCount;
  disabled?: boolean;
  id: string;
};

export type OriginalFilterColorValue = {
  items?: OriginalFilterColorItem[];
  supportsMultipleChoice?: boolean;
  totalCount?: ObfuscatedCount;
  type: OriginalFilterType.COLORS;
};

export type OriginalFilterColorSimpleValue = Pick<OriginalFilterColorValue, 'type'> & {
  items: Pick<OriginalFilterColorItem, 'id'>[];
};

/* Tree filter */

export type OriginalFilterTreeItem = {
  children?: OriginalFilterTreeItem[];
  collapsed?: boolean;
  collapsible?: boolean;
  count?: ObfuscatedCount;
  disabled?: boolean;
  id: string;
  name: string;
};

export type OriginalFilterTreeItemList = OriginalFilterTreeItem[];

export type OriginalFilterTreeValue = {
  items?: OriginalFilterTreeItem[];
  supportsMultipleChoice?: boolean;
  totalCount?: ObfuscatedCount;
  totalTitle?: string;
  type: OriginalFilterType.TREE;
};

export type OriginalFilterTreeSimpleValue = Pick<OriginalFilterTreeValue, 'type'> & {
  items: Pick<OriginalFilterTreeItem, 'id'>[];
};

/* Store filter */

export type OriginalFilterStoreItem = {
  id: string;
  name: string;
};

export type OriginalFilterStoreValue = {
  items?: OriginalFilterStoreItem[];
  type: OriginalFilterType.STORES;
};

export type OriginalFilterStoreSimpleValue = Pick<OriginalFilterStoreValue, 'type'> & {
  items: Pick<OriginalFilterStoreItem, 'id'>[];
};

/* MoneyRange filter */

export type OriginalFilterMoneyRangeValue = {
  currency?: string;
  max?: number;
  min?: number;
  type: OriginalFilterType.MONEY_RANGE;
};

export type OriginalFilterMoneyRangeSimpleValue = Pick<
  OriginalFilterMoneyRangeValue,
  'type' | 'min' | 'max' | 'currency'
>;

/* Checkbox filter */

export type OriginalFilterCheckboxValue = {
  checked: boolean;
  count?: ObfuscatedCount;
  type: OriginalFilterType.CHECKBOX;
};

export type OriginalFilterCheckboxSimpleValue = Pick<OriginalFilterCheckboxValue, 'type' | 'checked'>;

/* Search tag filter */
export type OriginalFilterSearchTagItem = {
  id: string;
  name: string;
};

export type OriginalFilterSearchTagValue = {
  items?: OriginalFilterSearchTagItem[];
  type: OriginalFilterType.SEARCH_TAG;
};

export type OriginalFilterSearchTagSimpleValue = Pick<OriginalFilterSearchTagValue, 'type'> & {
  items: Pick<OriginalFilterSearchTagItem, 'id'>[];
};

/* composition */

type OriginalFilterValue =
  | OriginalFilterCategoryValue
  | OriginalFilterColorValue
  | OriginalFilterTreeValue
  | OriginalFilterStoreValue
  | OriginalFilterMoneyRangeValue
  | OriginalFilterCheckboxValue
  | OriginalFilterSearchTagValue;

export type OriginalFilter<Value> = {
  disabled?: boolean;
  id: string;
  name: string;
  value: Value;
};

export type OriginalFilterList = OriginalFilter<OriginalFilterValue>[];

export type OriginalFilterSimpleValue =
  | OriginalFilterCategorySimpleValue
  | OriginalFilterCheckboxSimpleValue
  | OriginalFilterColorSimpleValue
  | OriginalFilterStoreSimpleValue
  | OriginalFilterTreeSimpleValue
  | OriginalFilterMoneyRangeSimpleValue
  | OriginalFilterSearchTagSimpleValue;

export type OriginalSimpleFilter<Value> = {
  id: string;
  value: Value;
};

export type OriginalSimpleFilterList = OriginalSimpleFilter<OriginalFilterSimpleValue>[];

type FilterValueByType<T extends OriginalFilterType, V> = V extends {type: T} ? V : never;

export function isOriginalFilter<T extends OriginalFilterType, V extends OriginalFilterValue = OriginalFilterValue>(
  item: OriginalFilter<V>,
  type: T,
): item is OriginalFilter<FilterValueByType<T, V>> {
  return item.value.type === type;
}

export function isOriginalSimpleFilter<
  T extends OriginalFilterType,
  V extends OriginalFilterSimpleValue = OriginalFilterSimpleValue,
>(item: OriginalSimpleFilter<V>, type: T): item is OriginalSimpleFilter<FilterValueByType<T, V>> {
  return item.value.type === type;
}
