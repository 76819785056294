import classNamesBind from 'classnames/bind';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

type Props = TestIdProp & {
  children: React.ReactNode;
  noBottomSpacing?: boolean;
  noPadding?: boolean;
  noTopSpacing?: boolean;
};

export function DialogContent({children, noBottomSpacing, noTopSpacing, noPadding, testId}: Props): React.ReactElement {
  return (
    <div className={cn('contentWrap', {noBottomSpacing, noPadding, noTopSpacing})} data-test-id={testId}>
      <div>{children}</div>
    </div>
  );
}
