import {createDataStore} from '@joomcode/joompro-core';
import {loadDeliveryDate, ProductDeliveryDatePayload} from 'api/product/loadDeliveryDate';
import {createClientEffect, createEvent} from 'lib/effector';
import {DeliveryDate} from 'lib/product/types';

export const [$deliveryDate, deliveryDateBinders] = createDataStore<DeliveryDate | undefined>(
  {data: undefined},
  {name: 'deliveryDate'},
);

export const resetDeliveryDates = createEvent();

export const loadProductDeliveryDateFx = createClientEffect<ProductDeliveryDatePayload, DeliveryDate | undefined>(
  loadDeliveryDate,
);
