import {useEvent} from 'lib/effector';
import {ModalsManagerContext} from 'lib/modalsManager';
import {ModalsManager} from 'lib/modalsManager/types';
import {closeDialog, openDialog} from 'models/modalsManager';
import React, {useMemo} from 'react';

type Props = {
  children: React.ReactNode;
};

export function ModalsManagerProvider({children}: Props): React.ReactElement {
  const dialogDidOpen = useEvent(openDialog);
  const dialogWillClose = useEvent(closeDialog);

  const methods = useMemo<ModalsManager>(
    () => ({
      dialogDidOpen,
      dialogWillClose,
    }),
    [dialogDidOpen, dialogWillClose],
  );

  return <ModalsManagerContext.Provider value={methods}>{children}</ModalsManagerContext.Provider>;
}
