import {createPopper} from '@popperjs/core';
import {useIsomorphicLayoutEffect} from 'lib/hooks';
import {TestIdProp} from 'lib/testing/types';
import React, {useRef} from 'react';
import {ModalOverlay} from 'uikit/ModalOverlay';
import styles from './index.module.scss';

export type MenuTestId = unknown;

type Props = TestIdProp<MenuTestId> & {
  children: React.ReactNode;
  minWidth?: number;
  onClose: () => void;
  targetRef: React.RefObject<Element>;
};

export function Menu({children, minWidth = 0, onClose, targetRef, testId}: Props): React.ReactElement {
  const menuRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    const referenceElement = targetRef.current;

    if (!referenceElement || !menuRef.current) {
      return undefined;
    }

    const popper = createPopper(referenceElement, menuRef.current, {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 16,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          enabled: true,
          fn: ({state}) => {
            // eslint-disable-next-line no-param-reassign
            state.styles.popper.minWidth = `${Math.max(state.rects.reference.width, minWidth)}px`;
          },
          name: 'sameWidth',
          phase: 'beforeWrite',
          requires: ['computeStyles'],
        },
      ],
      placement: 'bottom-start',
    });

    return () => {
      popper.destroy();
    };
  }, [targetRef, minWidth]);

  return (
    <ModalOverlay onClose={onClose} transparent>
      <div className={styles.menu} data-test-id={testId} ref={menuRef}>
        {children}
      </div>
    </ModalOverlay>
  );
}
