import type {AnalyticsEvent} from 'events/index';
import {analyticsEmulatorLogger} from 'lib/logger';
import type {GtmData} from './gtm/types';
import type {AnalyticsInterface, SendEventOptions} from './types';

export class AnalyticsEmulator implements AnalyticsInterface {
  dataLayer = (data: GtmData): void => {
    analyticsEmulatorLogger.log('dataLayer', {data});
  };

  sendEvent = (event: AnalyticsEvent, options?: SendEventOptions): void => {
    analyticsEmulatorLogger.log('sendEvent', {event, options});
  };
}
