import {FormApi} from 'final-form';
import {isString, isExtMessageDescriptor} from 'lib/guards';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {FieldMetaState} from 'react-final-form';

export function getDirtyValues<Values extends Record<string, string>>(
  formApi: FormApi<Values>,
): Record<string, string> {
  const {dirtyFields, values} = formApi.getState();

  return Object.keys(dirtyFields).reduce((acc: Record<string, string>, key) => {
    if (dirtyFields[key]) {
      /* eslint-disable no-param-reassign */
      acc[key] = isString(values[key]) ? values[key] : '';
    }

    return acc;
  }, {});
}

export function getErrorMessage<T = string>(meta: FieldMetaState<T>): ExtMessageDescriptor | undefined {
  if (meta.touched) {
    if (isExtMessageDescriptor(meta.submitError) && !meta.dirtySinceLastSubmit) {
      return meta.submitError;
    }
    if (isExtMessageDescriptor(meta.error)) {
      return meta.error;
    }
  }
  return undefined;
}
