import {InputField} from 'components/Form';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {InputTestId} from 'uikit/Input';

export type FullNameFieldTestId = InputTestId;

type Props = TestIdProp<FullNameFieldTestId> & {
  required?: boolean;
};

export function FullNameField({required, testId}: Props): React.ReactElement {
  return <InputField name='fullName' required={required} testId={testId} type='text' />;
}
