import {Form, InputField} from 'components/Form';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {defineMessages} from 'react-intl';
import {Button} from 'uikit/Button';
import {InputTestId} from 'uikit/Input';
import {formTexts} from '../messages';
import {SignTabs, SignTabsTestId} from '../SignTabs';
import {SubAction, SubActionTestId} from '../SubAction';
import {ViewChangeHandler, AuthViewName, SubmitHandler, SignInByEmailCredential} from '../types';
import {View, ViewActions, ViewError, ViewFields, ViewHeader, ViewExtraContent, ViewTabs} from '../View';

const messages = defineMessages({
  button: {
    defaultMessage: 'Sign in',
    description: 'Login form: "Login" button',
  },
  error: {
    defaultMessage: 'Incorrect email address or password',
    description: 'Login form: authorization error',
  },
  forgot: {
    defaultMessage: 'Forgot your password?',
    description: 'Login form: “Forgot your password?” button',
  },
  title: {
    defaultMessage: 'Sign in',
    description: 'Login form: header',
  },
});

export type SignInByEmailTestId = {
  forgotPasswordAction: SubActionTestId;
  form: {
    email: InputTestId;
    password: InputTestId;
    submit: unknown;
  };
  tabs: SignTabsTestId;
};

type Props = TestIdProp<SignInByEmailTestId> & {
  error?: unknown;
  onSubmit: SubmitHandler<SignInByEmailCredential>;
  onViewChange: ViewChangeHandler;
  pending?: boolean;
};

export function SignInView({error, onSubmit, onViewChange, pending, testId}: Props): React.ReactElement {
  const handleForgotClick = useCallback(() => {
    onViewChange(AuthViewName.PASSWORD_RECOVERY);
  }, [onViewChange]);

  return (
    <View testId={testId}>
      <ViewHeader image='/illustrations/auth.svg' title={messages.title} />
      <ViewTabs>
        <SignTabs onViewChange={onViewChange} testId={testId?.tabs} view={AuthViewName.SIGN_IN} />
      </ViewTabs>
      <Form<SignInByEmailCredential> onSubmit={onSubmit} testId={testId?.form} texts={formTexts}>
        <ViewFields>
          <InputField autoComplete='email' name='email' required testId={testId?.form.email} type='email' />
          <InputField
            autoComplete='current-password'
            name='password'
            required
            testId={testId?.form.password}
            type='password'
          />
        </ViewFields>
        {Boolean(error) && <ViewError error={messages.error} />}
        <ViewActions>
          <Button
            color='black'
            disabled={pending}
            label={messages.button}
            loading={pending}
            testId={testId?.form.submit}
            type='submit'
          />
        </ViewActions>
      </Form>
      <ViewExtraContent>
        <SubAction label={messages.forgot} onClick={handleForgotClick} testId={testId?.forgotPasswordAction} />
      </ViewExtraContent>
    </View>
  );
}
