import {createCommunicationStore} from 'lib/communication';

const {
  $store: $paymentsConsultationDialog,
  openDialog: openPaymentsConsultationDialog,
  closeDialog: closePaymentsConsultationDialog,
  sendRequestFx: sendPagePaymentsConsultationDataFx,
} = createCommunicationStore('paymentsConsultation');

export {
  $paymentsConsultationDialog,
  openPaymentsConsultationDialog,
  closePaymentsConsultationDialog,
  sendPagePaymentsConsultationDataFx,
};
