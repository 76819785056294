import {TestIdProp} from 'lib/testing/types';
import React from 'react';

export {ViewActions} from './ViewActions';
export {ViewError} from './ViewError';
export {ViewFields} from './ViewFields';
export {ViewHeader} from './ViewHeader';
export {ViewExtraContent} from './ViewExtraContent';
export {ViewTabs} from './ViewTabs';
export {ViewFormGeneralError} from './ViewFormGeneralError';

type Props = TestIdProp & {
  children: React.ReactNode;
};

export function View({children, testId}: Props): React.ReactElement {
  return <div data-test-id={testId}>{children}</div>;
}
