import {InputField} from 'components/FinalForm/InputMuiField';
import {validateEmail} from 'lib/finalForm/validators';
import React from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const Email = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  return (
    <InputField
      label={intl.formatMessage(fieldMessages.email)}
      name={fieldName}
      required={required}
      type='email'
      validate={validateEmail}
    />
  );
};
