export enum ItemContentType {
  GO_TO_CATALOG_CARD = 'goToCatalogCard',
  PRODUCT = 'product',
  RFQ_BANNER = 'rfqBanner',
  SEARCH_RESULT_FEEDBACK_BANNER = 'searchResultFeedbackBanner',
  SEARCH_SUGGESTION = 'searchSuggestion',
  SIGN_UP_BANNER = 'signUpBanner',
  TOP_PRODUCTS_BANNER = 'topProductsBanner',
  UNIQUE_SELLING_POINT_BANNER = 'uniqueSellingPointBanner',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OriginalContentListBaseItem<Type extends ItemContentType, Content> = {
  content: {
    [K in Type]: Content;
  };
  id: string;
};

export type ContentListBaseItem<Type, Content> = {
  content: Content;
  id: string;
  type: Type;
};
