import {ImageBundle} from 'lib/image/types';

export enum ProductTranslationsStatus {
  FINISHED = 'finished',
  IDLE = 'idle',
  PROCESSING = 'processing',
}

export enum ProductTranslationsTarget {
  DESCRIPTION = 'description',
  GALLERY = 'gallery',
}

export type ProductGalleryTranslations = {
  bundles: Record<string, ImageBundle | undefined>;
  status: ProductTranslationsStatus;
};

export type ProductDescriptionTranslations = {
  images: Record<string, string | undefined>;
  status: ProductTranslationsStatus;
};

export type ProductTranslations = {
  [ProductTranslationsTarget.GALLERY]?: ProductGalleryTranslations;
  [ProductTranslationsTarget.DESCRIPTION]?: ProductDescriptionTranslations;
};
