import {useEffect, useRef, EffectCallback, DependencyList} from 'react';

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const wasMounted = useRef(false);

  useEffect(() => {
    if (wasMounted.current) {
      return effect();
    }

    wasMounted.current = true;
    return undefined;
  }, deps);
}
