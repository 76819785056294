import {ItemContentType} from '../types';
import {SignUpBannerItemCreator} from './types';

export const createSignUpBannerItem: SignUpBannerItemCreator = (id: string) => {
  return {
    content: undefined,
    id,
    type: ItemContentType.SIGN_UP_BANNER,
  };
};
