import {useCallbackWithoutArgs} from 'lib/hooks/useCallbackWithoutArgs';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

export type HeaderTestId = {
  button: unknown;
};

type Props = TestIdProp<HeaderTestId> & {
  onClick?(): void;
};

export function Header({testId, onClick}: Props): React.ReactElement {
  const handleClick = useCallbackWithoutArgs(onClick);

  return (
    <div className={styles.container} data-test-id={testId}>
      <button className={styles.backButton} data-test-id={testId?.button} onClick={handleClick} type='button' />
    </div>
  );
}
