import cn from 'classnames';
import React from 'react';
import {useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';
import {messages} from './messages';

type Props = {
  disabled?: boolean;
  nextButton?: React.ReactNode;
  showSubmit: boolean;
  submitButton?: React.ReactNode;
  submitting?: boolean;
  validating?: boolean;
};

export const Actions = ({submitting, validating, disabled, showSubmit, ...props}: Props): React.ReactElement => {
  const intl = useIntl();
  const nextButton = props.nextButton || `${intl.formatMessage(messages.next)} →`;
  const submitButton = props.submitButton || intl.formatMessage(messages.submit);
  return (
    <div className={cn(styles.root)}>
      <Button
        color='black'
        disabled={submitting || disabled || validating}
        loading={submitting || validating}
        size='large'
        type='submit'
      >
        {showSubmit ? submitButton : nextButton}
      </Button>
    </div>
  );
};
