import {PaymentsPartnersDialog as PaymentsPartnersDialogComponent} from 'components/PaymentsPartnersDialog';
import {useEvent, useStore} from 'lib/effector';
import {useFormSuccessEvent} from 'lib/hooks';
import {
  $paymentsPartnersDialog,
  closePaymentsPartnersDialog,
  sendPagePaymentsPartnersDataFx,
} from 'models/paymentsPartners';
import React from 'react';
import {rootTestId} from 'testId';

export function PaymentsPartnersDialog(): React.ReactElement | null {
  const dialogState = useStore($paymentsPartnersDialog);
  const handleClose = useEvent(closePaymentsPartnersDialog);
  const handleSubmit = useEvent(sendPagePaymentsPartnersDataFx);
  const {onSuccess} = useFormSuccessEvent();

  if (!dialogState.opened) {
    return null;
  }

  return (
    <PaymentsPartnersDialogComponent
      error={dialogState.error}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      processing={dialogState.processing}
      success={dialogState.success}
      testId={rootTestId?.consultationDialog}
    />
  );
}
