import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  successSubtitle: {
    defaultMessage: 'We will contact you as soon as possible',
    description: 'Registration dialog success sub title',
  },
  successTitle: {
    defaultMessage: 'Thank you for registration!',
    description: 'Registration dialog success title',
  },
});
