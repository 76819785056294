export interface SelectionInterface {
  hasSelection(): boolean;
  setSelection(start: number, end: number): void;
}

export class Selection implements SelectionInterface {
  private _start: number;

  private _end: number;

  constructor(start: number, end: number) {
    this._start = start;
    this._end = end;
  }

  get start(): number {
    return this._start;
  }

  get end(): number {
    return this._end;
  }

  setSelection(start: number, end: number): void {
    this._start = Math.min(start, end);
    this._end = Math.max(start, end);
  }

  hasSelection(): boolean {
    return this.end > this.start;
  }
}
