import {memoize} from '../memoize';

export enum DataState {
  IDLE = 'idle',
  LOADING = 'loading',
  LOADED = 'loaded',
  FAILED = 'failed',
}

export type LoadableData<Data> =
  | {dataState: DataState.IDLE}
  | {dataState: DataState.LOADING}
  | {dataState: DataState.FAILED}
  | {data: Data; dataState: DataState.LOADED};

export const isLoadingOrLoaded = (dataState: DataState) => {
  return dataState === DataState.LOADED || dataState === DataState.LOADING;
};

const consolidationPriorityByDataState: {[key in DataState]: number} = {
  [DataState.FAILED]: 0,
  [DataState.LOADING]: 1,
  [DataState.IDLE]: 2,
  [DataState.LOADED]: 3,
};

export const getConsolidatedDataState = memoize(
  (...dataStates: DataState[]) =>
    dataStates.reduce(
      (result, current) =>
        consolidationPriorityByDataState[current] < consolidationPriorityByDataState[result] ? current : result,
      DataState.LOADED,
    ),
  {length: false},
);

export const getConsolidatedAccessDependentDataState = memoize(
  (...values: [DataState, boolean][]) =>
    getConsolidatedDataState(
      ...values.reduce((acc, [dataState, hasAccess]) => (hasAccess ? [...acc, dataState] : acc), []),
    ),
  {length: false, normalizer: JSON.stringify},
);
