export enum ErrorCode {
  BANK_ACCOUNT = 'bankAccount',
  BIC = 'bic',
  EMAIL = 'email',
  MAX_LENGTH = 'maxLength',
  MIN_LENGTH = 'minLength',
  NON_NUMERIC = 'nonNumeric',
  PHONE = 'phone',
  REQUIRED = 'required',
  URL = 'url',
}
