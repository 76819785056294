import classNamesBind from 'classnames/bind';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type OptionItemTestId = unknown;

type Props<Option> = TestIdProp<OptionItemTestId> & {
  active?: boolean;
  children: React.ReactNode;
  highlight?: boolean;
  item: Option;
  onSelect(item: Option): void;
};

export function OptionItem<Option>({
  item,
  active,
  children,
  highlight,
  testId,
  onSelect,
}: Props<Option>): React.ReactElement {
  const handleMouseDown = useCallback(() => onSelect(item), [item]);

  return (
    <button
      className={cn('item', {active, highlight})}
      data-test-id={testId}
      onMouseDown={handleMouseDown}
      tabIndex={-1}
      type='button'
    >
      {children}
    </button>
  );
}
