import {identity} from '@joomcode/deprecated-utils/function';
import {createId, REACT_IGNORED_KEYS} from 'chain-id';
import type {AuthViewsTestId} from 'components/Auth/types';
import type {CommunicationDialogTestId} from 'components/CommunicationDialog';
import type {HeaderTestId} from 'components/Header/types';
import type {PageErrorContainerTestId} from 'components/PageErrorContainer';
import type {PageLeaveDialogTestId} from 'components/PageLeaveDialog';
import type {ProductCardTestId} from 'components/ProductCard1688/types';
import type {ProductCatalogTestId} from 'components/ProductCatalog/types';
import type {PromotionTestId} from 'components/Promotion';
import {config} from 'config';
import type {AuthByTokenPageTestId} from 'containers/AuthByTokenPage';
import type {PasswordChangePageTestId} from 'containers/PasswordChangePage';

export type RootTestId = {
  authByTokenPage: AuthByTokenPageTestId;
  authDialog: AuthViewsTestId;
  authPage: AuthViewsTestId;
  consultationDialog: CommunicationDialogTestId;
  errorPage: PageErrorContainerTestId;
  mainHeader: HeaderTestId;
  pageLeaveDialog: PageLeaveDialogTestId;
  passwordChangePage: PasswordChangePageTestId;
  passwordSetPage: PasswordChangePageTestId;
  product: ProductCardTestId;
  promotion: PromotionTestId;
  search: ProductCatalogTestId;
};

export const rootTestId = config.testIdEnabled ? createId<RootTestId>({ignoreKeys: REACT_IGNORED_KEYS}) : undefined;

export const dataTestItem = config.testIdEnabled ? identity : () => undefined;
