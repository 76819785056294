import {attach, createDomain, Effect, Event, Store} from 'effector';
import type {Analytics} from 'lib/analytics';
import type {Client} from 'lib/client';
import type {CookieManager} from 'lib/cookie';
import {Language} from 'lib/language/types';
import {effectLogger} from 'lib/logger';

export const appDomain = createDomain('root');

export function createStore<State>(sid: string, defaultState: State): Store<State> {
  return appDomain.createStore<State>(defaultState, {sid});
}

export const $appGetClient = createStore<() => Client>('appClient', () => {
  throw new Error('Client getter must be initialized');
});

export const $appGetAnalytics = createStore<() => Analytics>('appAnalytics', () => {
  throw new Error('Analytics getter must be initialized');
});

export const $appGetCookieManager = createStore<() => CookieManager>('appCookieManager', () => {
  throw new Error('CookieManager getter must be initialized');
});

export const $appGetLanguage = createStore<() => Language>('appLanguage', () => {
  throw new Error('Language getter must be initialized');
});

export function createEvent<E = void>(): Event<E> {
  return appDomain.createEvent<E>();
}

export function createEffect<Params, Done, Fail = Error>(
  handler: (params: Params) => Done | Promise<Done>,
): Effect<Params, Done, Fail> {
  return appDomain.createEffect<Params, Done, Fail>(handler);
}

export function createClientEffect<Params extends void, Done, Fail = Error>(
  handler: (client: Client) => Done | Promise<Done>,
): Effect<Params, Done, Fail>;
export function createClientEffect<Params, Done, Fail = Error>(
  handler: (client: Client, params: Params) => Done | Promise<Done>,
): Effect<Params, Done, Fail>;
export function createClientEffect<Params, Done, Fail = Error>(
  handler: (client: Client, params?: Params) => Done | Promise<Done>,
): Effect<Params, Done, Fail> {
  const effect = appDomain.createEffect<[Client, Params], Done, Fail>((params) => handler(...params));

  const clientEffect = attach({
    effect,
    mapParams: (params: Params, getClient): [Client, Params] => [getClient(), params],
    source: $appGetClient,
  });

  clientEffect.failData.watch((error) => {
    if (error instanceof Error) {
      effectLogger.fatal('Critical error', error);
    }
  });

  return clientEffect;
}
