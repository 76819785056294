import React from 'react';
import {createPortal} from 'react-dom';

type Props = {
  children: React.ReactNode;
};

export function Portal({children}: Props): React.ReactElement | null {
  if (typeof document === 'undefined' || !document.body) {
    return null;
  }

  return createPortal(children, document.body);
}
