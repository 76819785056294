import {defineMessage} from 'react-intl';
import {DaysRange, Month, TimestampRange} from './types';

export function makeDaysRange(minDays: number, maxDays: number): DaysRange {
  return {
    maxDays,
    minDays,
    type: 'days',
  };
}

export function makeDateTimeRange(startTimeMs: number, endTimeMs: number): TimestampRange {
  return {
    endTimeMs,
    startTimeMs,
    type: 'ms',
  };
}

export function getMonthFromDate(date: Date): Month {
  return date.getMonth();
}

export const monthMessages = {
  [Month.JANUARY]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {January} genitive {January} dative {January} accusative {January} instrumental {January} prepositional {January} other {January}}',
    description: 'Name of the month in different cases',
  }),
  [Month.FEBRUARY]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {February} genitive {February} dative {February} accusative {February} instrumental {February} prepositional {February} other {February}}',
    description: 'Name of the month in different cases',
  }),
  [Month.MARCH]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {March} genitive {March} dative {March} accusative {March} instrumental {March} prepositional {March} other {March}}',
    description: 'Name of the month in different cases',
  }),
  [Month.APRIL]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {April} genitive {April} dative {April} accusative {April} instrumental {April} prepositional {April} other {April}}',
    description: 'Name of the month in different cases',
  }),
  [Month.MAY]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {May} genitive {May} dative {May} accusative {May} instrumental {May} prepositional {May} other {May}}',
    description: 'Name of the month in different cases',
  }),
  [Month.JUNE]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {June} genitive {June} dative {June} accusative {June} instrumental {June} prepositional {June} other {June}}',
    description: 'Name of the month in different cases',
  }),
  [Month.JULY]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {July} genitive {July} dative {July} accusative {July} instrumental {July} prepositional {July} other {July}}',
    description: 'Name of the month in different cases',
  }),
  [Month.AUGUST]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {August} genitive {August} dative {August} accusative {August} instrumental {August} prepositional {August} other {August}}',
    description: 'Name of the month in different cases',
  }),
  [Month.SEPTEMBER]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {September} genitive {September} dative {September} accusative {September} instrumental {September} prepositional {September} other {September}}',
    description: 'Name of the month in different cases',
  }),
  [Month.OCTOBER]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {October} genitive {October} dative {October} accusative {October} instrumental {October} prepositional {October} other {October}}',
    description: 'Name of the month in different cases',
  }),
  [Month.NOVEMBER]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {November} genitive {November} dative {November} accusative {November} instrumental {November} prepositional {November} other {November}}',
    description: 'Name of the month in different cases',
  }),
  [Month.DECEMBER]: defineMessage({
    defaultMessage:
      // eslint-disable-next-line max-len
      '{case, select, nominative {December} genitive {December} dative {December} accusative {December} instrumental {December} prepositional {December} other {December}}',
    description: 'Name of the month in different cases',
  }),
};
