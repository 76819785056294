import {isArray} from 'lib/guards';
import {SearchTagType} from 'lib/searchTag/types';
import * as creators from './creators';
import {
  isOriginalSearchSuggestionItem,
  isOriginalContentListProductItem,
  isOriginalUniqueSellingPointBannerItem,
  isOriginalRfqBannerItem,
  isOriginalSignUpBannerItem,
} from './guards';
import {ContentList, ItemContentType, OriginalContentList} from './types';

export function convertOriginalContentList(items?: OriginalContentList): ContentList {
  const result: ContentList = [];

  if (isArray(items)) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (isOriginalContentListProductItem(item)) {
        result.push(creators.createProductItem(item));
      } else if (isOriginalRfqBannerItem(item)) {
        result.push(creators.createRfqBannerItem(item.id));
      } else if (isOriginalUniqueSellingPointBannerItem(item)) {
        result.push(creators.createUniqueSellingPointItem(item));
      } else if (isOriginalSearchSuggestionItem(item)) {
        const searchSuggestionItem = creators.createSearchSuggestionItem(item);
        if (searchSuggestionItem) {
          result.push(searchSuggestionItem);
        }
      } else if (isOriginalSignUpBannerItem(item)) {
        result.push(creators.createSignUpBannerItem(item.id));
      }
    }
  }

  return result;
}

// In our best page layout products may view by 6, 4, 3 or 2 items per row.
// In this way 12 is the least common divisor for each of count.
// (12/6, 12/4, 12/3 and 12/2 divided without remainder).
const LEAST_COMMON_DIVISOR = 12;

const ONE_CELL_CONTENT_ITEMS: Set<ItemContentType> = new Set([ItemContentType.PRODUCT, ItemContentType.RFQ_BANNER]);

export function alignItemsByDivisor(items: ContentList): ContentList {
  let lastProductsCount = 0;

  for (let i = items.length; i--; ) {
    if (!ONE_CELL_CONTENT_ITEMS.has(items[i].type)) {
      break;
    }

    lastProductsCount++;
  }

  const countOfHidden = lastProductsCount % LEAST_COMMON_DIVISOR;
  const countOfView = countOfHidden === items.length ? items.length : items.length - countOfHidden;

  return countOfView !== items.length ? items.slice(0, countOfView) : items;
}

export function hasTopProducts(items: ContentList): boolean {
  return items.some(
    (item) =>
      item.type === ItemContentType.PRODUCT &&
      item.content.searchTags?.find((tag) => tag.tag === SearchTagType.TOP_PRODUCT),
  );
}
