import {isTransportResponse, BodyPayload, TransportResponse} from '../types';

export function extractPayload<Payload>(
  source: TransportResponse<BodyPayload<Payload>> | BodyPayload<Payload>,
): Payload {
  const body = isTransportResponse(source) ? source.body : source;

  return body.payload;
}

type PayloadItems<Items> = {
  items: Items;
};

export function extractPayloadItems<Items>(
  source: TransportResponse<BodyPayload<PayloadItems<Items>>> | BodyPayload<PayloadItems<Items>>,
): Items {
  return extractPayload(source).items;
}

type PayloadId<Id = string> = {
  id: Id;
};

export function extractPayloadId<Id>(
  source: TransportResponse<BodyPayload<PayloadId<Id>>> | BodyPayload<PayloadId<Id>>,
): Id {
  return extractPayload(source).id;
}
