import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  subtitle: {
    defaultMessage: 'We will contact you and tell you about the financial conditions',
    description: 'Home page. Dialog box for booking a consultation: text',
  },
  title: {
    defaultMessage: 'Consultation request',
    description: 'Home page. Dialog box for booking a consultation: title',
  },
});
