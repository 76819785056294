import classNamesBind from 'classnames/bind';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useState} from 'react';
import {TabSelectContext} from './contexts';
import styles from './index.module.scss';
import {Tab} from './Tab';
import {TabsSize, TabsChangeHandler, TabSelectHandler, TabValue} from './types';

export {Tab} from './Tab';

const cn = classNamesBind.bind(styles);

type TabsProps<V> = TestIdProp & {
  children: React.ReactComponentElement<typeof Tab>[];
  name?: string;
  onChange?: TabsChangeHandler<V>;
  size?: TabsSize;
  value?: TabValue;
};

export function Tabs<V = TabValue>({
  children,
  name,
  onChange,
  size = 'large',
  testId,
  value,
}: TabsProps<V>): React.ReactElement {
  const [currentIndex, setCurrentIndex] = useState(() => {
    let resultIndex = 0;

    React.Children.forEach(children, ({props: {active: childActive, value: childValue}}, index) => {
      if (value !== undefined) {
        if (value === childValue) {
          resultIndex = index;
        }
      } else if (childActive === true) {
        resultIndex = index;
      }
    });

    return resultIndex;
  });

  const handleTabSelect = useCallback<TabSelectHandler<V>>(
    (index, nextValue) => {
      setCurrentIndex(index);

      if (onChange) {
        onChange({name, value: nextValue});
      }
    },
    [name, onChange],
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={cn('tabs', `size-${size}`)} data-test-id={testId}>
      <TabSelectContext.Provider value={handleTabSelect}>
        {React.Children.map(children, (child, index) => (
          <child.type {...child.props} active={currentIndex === index} index={index} />
        ))}
      </TabSelectContext.Provider>
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}
