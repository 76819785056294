import {useCallbackWithoutArgs} from 'lib/hooks';
import {ToastData} from 'lib/toaster/types';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  data: ToastData;
  onClose: () => void;
};

export function ToastInternal({data, onClose}: Props): React.ReactElement {
  const handleCloseClick = useCallbackWithoutArgs(onClose);

  return (
    <div className={styles.toast}>
      <button className={styles.close} onClick={handleCloseClick} type='button' />
      {data.title && <div className={styles.title}>{data.title}</div>}
      <div className={styles.content}>{data.content}</div>
    </div>
  );
}
