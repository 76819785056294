import {IntlShape} from 'react-intl';
import {Currency, Money, MoneyAmount} from './types';

export function money(amount: MoneyAmount, ccy: Currency): Money {
  return {amount, ccy};
}

export const formatCurrency = (intl: IntlShape, currency: Currency): string => {
  return (
    intl
      .formatNumberToParts(0, {
        currency,
        currencyDisplay: 'narrowSymbol',
        style: 'currency',
      })
      .find((part) => part.type === 'currency')?.value ?? currency
  );
};

export const comparePrices = (previousPrice?: Money, price?: Money): -1 | 0 | 1 => {
  if ((!price && !previousPrice) || price?.ccy !== previousPrice?.ccy) {
    return 0;
  }

  const previousAmount = previousPrice?.amount || 0;
  const currentAmount = price?.amount || 0;

  if (previousAmount > currentAmount) {
    return -1;
  }

  if (previousAmount < currentAmount) {
    return 1;
  }

  return 0;
};
