import {useState, useEffect, useCallback, useRef, RefObject} from 'react';

type ReturnShape = {loaded: boolean; onLoad: () => void; ref: RefObject<HTMLImageElement>};

export const useImageLoaded = (): ReturnShape => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<HTMLImageElement>(null);

  const onLoad = useCallback(() => setLoaded(true), [setLoaded]);

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  }, [ref?.current?.complete]);

  return {loaded, onLoad, ref};
};
