import {Client} from 'lib/client';
import {DeliveryChannelType} from 'lib/deliveryChannel/types';
import {DeliveryDate} from 'lib/product/types';

type SelectedVariant = {
  quantity: number;
  variantId: string;
};

export type ProductDeliveryDatePayload = {
  productInfo: {
    productId: string;
    variants: SelectedVariant[];
  };
};

type ProductDeliveryDateRequest = ProductDeliveryDatePayload & {
  channelType: DeliveryChannelType;
};

export type DeliveryDateResponse = {
  payload: DeliveryDate;
};

export function loadDeliveryDate(client: Client, request: ProductDeliveryDatePayload): Promise<DeliveryDate> {
  const body: ProductDeliveryDateRequest = {
    ...request,
    channelType: 'sea',
  };

  return client.mainApi.post<DeliveryDateResponse>('deliveryDate/get', {body}).then(({body: {payload}}) => payload);
}
