import {ExtMessageDescriptor} from 'lib/intl/types';
import {validateUrl as validateUrlBase} from 'lib/validation';
import {defineMessage} from 'react-intl';

const errorMessage = defineMessage({
  defaultMessage: 'Url is not valid',
  description: 'Error for incorrect url',
});

export function validateUrl(value: unknown): ExtMessageDescriptor | undefined {
  return validateUrlBase(value) === 'url' ? errorMessage : undefined;
}
