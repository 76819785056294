import {produce} from 'immer';
import {$products, $promotions, loadProductsFx, loadPromotionFx} from './index';

$products.on(loadProductsFx.doneData, (state, result) => result);

$promotions
  .on(loadPromotionFx, (state, {id}) =>
    produce(state, (draft) => {
      draft.byIdLoading[id] = true;
    }),
  )
  .on(loadPromotionFx.done, (state, {params, result}) =>
    produce(state, (draft) => {
      draft.byId[params.id] = result;
      draft.byIdLoading[params.id] = false;
    }),
  )
  .on(loadPromotionFx.fail, (state, {params, error}) =>
    produce(state, (draft) => {
      draft.byIdLoadingError[params.id] = error;
      draft.byIdLoading[params.id] = false;
    }),
  );
