import {useCallback} from 'react';

type Params = undefined | ((...args: unknown[]) => void);

type CallbackWithoutArgs = () => void;

export function useCallbackWithoutArgs<Fn extends Params>(fn: Fn): CallbackWithoutArgs {
  return useCallback(() => {
    if (fn) {
      fn();
    }
  }, [fn]);
}
