import {InputMode, InputType} from './types';

const INPUT_MODE_BY_TYPE: Record<InputType, InputMode> = {
  email: 'email',
  money: 'decimal',
  number: 'numeric',
  password: 'text',
  phone: 'tel',
  text: 'text',
};

export function getInputModeByType(type: InputType = 'text'): InputMode {
  return INPUT_MODE_BY_TYPE[type];
}

export function normalizeValue(value?: string | number): string | number {
  return value === undefined ? '' : value;
}
