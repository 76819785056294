import {useCallbackWithoutArgs} from 'lib/hooks';
import {TestIdProp} from 'lib/testing/types';
import React, {useContext} from 'react';
import {HandlersContext} from '../contexts';
import {CloseHandler} from '../types';
import styles from './index.module.scss';

type Props = TestIdProp & {
  onClick?: CloseHandler;
};

export function DialogClose({onClick, testId}: Props): React.ReactElement {
  const {onClose} = useContext(HandlersContext);
  const handleClick = useCallbackWithoutArgs(onClick || onClose);

  return <button className={styles.closeButton} data-test-id={testId} onClick={handleClick} type='button' />;
}
