import {createStore, createEvent} from 'lib/effector';

export type CallbackState = 'filled' | 'cancel';
export type Callback = ({state}: {state: CallbackState}) => void;

export type RegistrationQueueState = {data: Callback | undefined};

export const $registrationCallback = createStore<RegistrationQueueState>('registrationCallback', {data: undefined});

export const addRegistrationCallback = createEvent<Callback>();
export const cleanRegistrationCallback = createEvent();
export const callRegistrationCallback = createEvent<{state: CallbackState}>();
