import {useContext} from 'react';
import {ToasterContext} from './contexts';
import {ToasterInterface} from './types';

export function useToaster(): ToasterInterface {
  const context = useContext(ToasterContext);

  if (!context) {
    throw new Error('Toaster is not defined');
  }

  return context;
}
