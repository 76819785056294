import {Client} from 'lib/client';
import {convertOriginalContentList} from 'lib/contentList';
import {ContentList, OriginalContentList} from 'lib/contentList/types';

export type SimilarProductsRequest = {
  productId: string;
};

type SimilarProductsResponse = {
  payload: {items: OriginalContentList};
};

export function loadSimilarProducts(client: Client, {productId}: SimilarProductsRequest): Promise<ContentList> {
  return client.mainApi
    .post<SimilarProductsResponse>('products/getSimilarProducts', {body: {productId}})
    .then((result): ContentList => {
      return convertOriginalContentList(result.body.payload.items);
    });
}
