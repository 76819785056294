import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  subtitle: {
    defaultMessage: 'We will contact you and tell you about our loyalty program',
    description: 'Home page. Loyalty program consultation dialog: text',
  },
  title: {
    defaultMessage: 'Loyalty program special offer',
    description: 'Home page. Loyalty Program Consultation Dialog: Header',
  },
});
