import {sample, Event, Unit} from 'effector';
import {AnalyticsInterface, AnalyticsEvent, GtmData, SendEventOptions} from 'lib/analytics/types';
import {$appGetAnalytics} from './domain';

const $dataLayer = $appGetAnalytics.map<AnalyticsInterface['dataLayer']>((getAnalytics) => (data) => {
  getAnalytics().dataLayer(data);
});

const $sendEvent = $appGetAnalytics.map<AnalyticsInterface['sendEvent']>((getAnalytics) => (event, options) => {
  getAnalytics().sendEvent(event, options);
});

export function analyticsDataLayer<ClockData>(clock: Unit<ClockData>, fn: (data: ClockData) => GtmData): Event<void> {
  return sample({
    clock,
    fn: (dataLayer, clockData) => {
      const data = fn(clockData);

      if (data) {
        dataLayer(data);
      }
    },
    source: $dataLayer,
  });
}

export function analyticsSendEvent<ClockData>(
  clock: Unit<ClockData>,
  fn: (data: ClockData) => AnalyticsEvent,
  options?: SendEventOptions,
): Event<void> {
  return sample({
    clock,
    fn: (sendEvent, clockData) => {
      const event = fn(clockData);

      if (event) {
        sendEvent(event, options);
      }
    },
    source: $sendEvent,
  });
}
