import {RequestRecoveryCredential} from 'components/Auth/types';
import {Client} from 'lib/client';
import {formValidationError} from 'lib/form/types';
import {isRecord} from 'lib/guards';
import {isTransportResponse} from 'lib/transport/types';
import {extractBody, handleCredentialsValidationError} from './utils';

type EmailNotFoundError = {
  payload: {
    email: string;
  };
  type: 'emailauth.not_found';
};

// TODO: PRO-194 change backend to validationError
function isEmailNotFoundError(error: unknown): error is EmailNotFoundError {
  return (
    isRecord(error) &&
    error.type === 'emailauth.credentials_validation_failed' &&
    isRecord(error.payload) &&
    'email' in error.payload
  );
}

type Payload = {
  payload: unknown;
};

function prepareCredential(credential: RequestRecoveryCredential): RequestRecoveryCredential {
  return {
    ...credential,
    email: credential.email.trim(),
  };
}

export function requestPasswordRecovery(client: Client, credential: RequestRecoveryCredential): Promise<Payload> {
  return client.mainApi
    .post<Payload>('auth/email/recoverPassword', {body: prepareCredential(credential)})
    .then(extractBody)
    .catch((error) => {
      handleCredentialsValidationError(error);
      if (isTransportResponse(error) && isEmailNotFoundError(error.body)) {
        throw formValidationError({}, error.body.payload.email);
      }
      throw error;
    });
}
