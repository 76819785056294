import {useState, useCallback} from 'react';

type ChangeStepPayload = {newStep: number; step: number};

export type WizardReturnShape = {
  goNext(): void;
  goPrev(): void;
  showBack: boolean;
  step: number;
};

type Props = {
  initialStep?: number;
  onNext?(data: ChangeStepPayload): void;
  onPrev?(data: ChangeStepPayload): void;
};

export const useWizard = ({onNext, onPrev, initialStep = 0}: Props = {}): WizardReturnShape => {
  const [step, setStep] = useState(initialStep);

  const goNext = useCallback(() => {
    const newStep = step + 1;
    if (onNext) {
      onNext({newStep, step});
    }
    setStep(newStep);
  }, [setStep, step, onNext]);

  const goPrev = useCallback(() => {
    if (step > 0) {
      const newStep = step - 1;
      if (onPrev) {
        onPrev({newStep, step});
      }
      setStep(newStep);
    }
  }, [setStep, onPrev, step]);

  return {
    goNext,
    goPrev,
    showBack: step > 0,
    step,
  };
};
