import {savePreferences} from 'api/device/savePreferences';
import {startSession} from 'api/device/startSession';
import {config} from 'config';
import {IncomingMessage} from 'http';
import {Analytics} from 'lib/analytics';
import {getOsName, getXff} from 'lib/client/utils';
import {CookieManager} from 'lib/cookie';
import {DevicevarsInfo} from 'lib/devicevars/types';
import {isString} from 'lib/guards';
import {transportLogger} from 'lib/logger';
import {safeDumpTransportResponse} from 'lib/logger/converters';
import {Preferences} from 'lib/preferences/types';
import {ApiTransport} from 'lib/transport/ApiTransport';
import {getEndpoints} from 'lib/transport/endpoints';
import {getSsrHeaderName} from 'lib/transport/headers';
import {LocalTransport} from 'lib/transport/LocalTransport';
import {TokensTransport} from 'lib/transport/TokensTransport';
import {Endpoints, isTransportResponse} from 'lib/transport/types';
import uuid from 'uuid/v4';
import {ClientConfig, ClientInitConfig, ClientPlatform} from './types';

export * from './contexts';
export * from './hooks';

export class Client {
  readonly config: ClientConfig;

  readonly platform: ClientPlatform;

  readonly cookies: CookieManager;

  readonly endpoints: Endpoints;

  readonly tokensApi: TokensTransport;

  readonly mainApi: ApiTransport;

  readonly localApi: LocalTransport;

  readonly analytics: Analytics;

  readonly userAgent: string | undefined;

  readonly ssrId: string | undefined;

  readonly clientAddress: string | undefined;

  private tokensProcessing: boolean;

  private tokensProcess: Promise<void>;

  readonly serverDate: Date | undefined;

  constructor(clientInitConfig: ClientInitConfig, req?: IncomingMessage) {
    const {router} = clientInitConfig;
    let {origin} = clientInitConfig;

    if (__SERVER__) {
      if (!req) {
        throw new Error('Request must be defined on the server');
      }
      const preparedRequestUrl = new URL(req.url ?? '', `${__DEVELOPMENT__ ? 'http' : 'https'}://${req.headers.host}`);

      // origin
      if (config.releaseStage === 'production') {
        if (config.allowedDomains.includes(preparedRequestUrl.hostname)) {
          origin = preparedRequestUrl.origin;
        } else {
          origin = config.defaultOrigin;
        }
      } else {
        origin = preparedRequestUrl.origin;
      }

      // ssr-id
      const ssrId = req.headers[getSsrHeaderName()];
      this.ssrId = isString(ssrId) ? ssrId : uuid();

      // client address (client IP)
      this.clientAddress = getXff(req);

      // user agent
      this.userAgent = req.headers['user-agent'];
      this.platform = {
        osName: getOsName(this.userAgent),
      };

      this.serverDate = new Date();
    } else {
      if (!clientInitConfig.platform) {
        throw new Error('Platform info is required in ClientConfig on client side');
      }

      if (!origin) {
        throw new Error('Origin must be defined');
      }

      this.platform = clientInitConfig.platform;
    }

    this.config = {
      ...clientInitConfig,
      baseUrl: new URL(origin),
      isBot: clientInitConfig.isBot ?? false,
      // TODO: PRO-4713 вынести вычисление baseUrl за пределеы класса Client
      origin,
      platform: this.platform,
      router,
    };
    this.endpoints = getEndpoints(this.config.baseUrl);
    this.cookies = new CookieManager(req ? req.headers.cookie || '' : undefined);
    this.tokensApi = new TokensTransport(this);
    this.mainApi = new ApiTransport(this);
    this.localApi = new LocalTransport(this);
    this.analytics = new Analytics(this);

    this.tokensProcess = Promise.resolve();
    this.tokensProcessing = false;
  }

  async initialize(): Promise<void> {
    if (__SERVER__) {
      const {isBot} = await this.tokensApi.refreshOrCreate(this.config.preferences);
      this.config.isBot = isBot;
      await this.startSession();
    }
  }

  async startSession(): Promise<DevicevarsInfo> {
    const devicevarsInfo = await startSession(this);
    this.config.devicevarsInfo = devicevarsInfo;
    return devicevarsInfo;
  }

  savePreferences(preferences: Preferences): Promise<Preferences> {
    return savePreferences(this, preferences);
  }

  processRequest<R>(fn: () => Promise<R>): Promise<R> {
    return this.tokensProcess.then(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private processTokens<F extends () => Promise<any>>(fn: F): Promise<void> {
    if (!this.tokensProcessing) {
      this.tokensProcessing = true;
      this.tokensProcess = this.tokensProcess.then(fn);
      this.tokensProcess.then(() => {
        this.tokensProcessing = false;
      });
    }

    return this.tokensProcess;
  }

  updateOrCreate(): Promise<void> {
    return this.processTokens(() => {
      return (
        this.tokensApi
          .refreshOrCreate()
          // eslint-disable-next-line no-empty-function
          .then(() => {})
          .catch((error) => {
            if (isTransportResponse(error)) {
              if (error.status === 401) {
                transportLogger.fatal('401 on refreshOrCreate', safeDumpTransportResponse(error));
              }
            }

            throw error;
          })
      );
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  toJSON(): ClientInitConfig {
    const {baseUrl, ...restConfig} = this.config;
    return restConfig;
  }
}
