import {Client} from 'lib/client';
import {CartItemInfo, PaymentMethod} from 'lib/deal/types';
import {DeliveryChannelType} from 'lib/deliveryChannel/types';

export type CreateDealPayload = {
  address: {country: string; zip: string};
  cartItemsData: CartItemInfo[];
  channelType: DeliveryChannelType;
  dealComment: string;
  paymentMethod: PaymentMethod;
};

export function createDeal(client: Client, body: CreateDealPayload): Promise<void> {
  return client.mainApi
    .post('/deal/create', {
      body,
    })
    .then(() => undefined);
}
