import {AuthViewName, AuthByPhoneViewName, SendOtpFormData} from 'components/Auth/types';
import {attach} from 'effector';
import {createEvent, createStore} from 'lib/effector';
import {
  requestPasswordChangeFx,
  requestRecoveryFx,
  signInByEmailFx,
  signUpByEmailFx,
  signInByPhoneFx,
  sendOtpFx,
} from '../index';

type AuthPagePhoneState = {
  phoneView: AuthByPhoneViewName;
  retryAfterMs?: number;
  sendOtpData?: SendOtpFormData;
  sendOtpError?: unknown;
  sendOtpLoading?: boolean;
};

type AuthPageState = AuthPagePhoneState & {
  passwordChangeError?: unknown;
  passwordChangePending?: boolean;
  passwordChanged?: boolean;
  requestRecoveryEmail?: string;
  requestRecoveryError?: unknown;
  requestRecoveryPending?: boolean;
  signInError?: unknown;
  signInPending?: boolean;
  signUpError?: unknown;
  signUpPending?: boolean;
  successView?: AuthViewName;
  view: AuthViewName;
};

type ChangeViewEvent = AuthViewName;

const initialState: AuthPageState = {
  phoneView: AuthByPhoneViewName.PHONE,
  view: AuthViewName.SIGN_IN_BY_PHONE,
};

export const $authPage = createStore<AuthPageState>('authPage', initialState);

export const changeAuthPageView = createEvent<ChangeViewEvent>();

export const changeAuthPagePhoneView = createEvent<AuthByPhoneViewName>();

export const authPageSignInByEmailFx = attach({effect: signInByEmailFx});

export const authPageSignUpByEmailFx = attach({effect: signUpByEmailFx});

export const authPageRequestRecoveryFx = attach({effect: requestRecoveryFx});

export const authPagePasswordChangeFx = attach({effect: requestPasswordChangeFx});

export const authPageSignInByPhoneFx = attach({effect: signInByPhoneFx});

export const authPageSendOtpFx = attach({effect: sendOtpFx});
