import {CommunicationDialog, CommunicationDialogTestId} from 'components/CommunicationDialog';
import {FormViewFields} from 'components/CommunicationDialog/types';
import {CommunicationReason} from 'lib/communication/types';
import {monthMessages} from 'lib/datetime';
import {Month, Case} from 'lib/datetime/types';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import {ConsultationData, SubmitHandler} from './types';

export type ConsultationDialogTestId = CommunicationDialogTestId;

type Props = TestIdProp<ConsultationDialogTestId> & {
  currentMonth: Month;
  error?: unknown;
  onClose: () => void;
  onSubmit: SubmitHandler;
  onSuccess?: () => void;
  processing?: boolean;
  reason?: CommunicationReason;
  section: string;
  success?: boolean;
};

const FORM_FIELDS: FormViewFields = ['phone', 'promocode'];

export function ConsultationDialog({
  reason,
  currentMonth,
  error,
  onClose,
  onSubmit,
  processing,
  section,
  success,
  testId,
  onSuccess,
}: Props): React.ReactElement {
  const intl = useIntl();
  const month = intl.formatMessage(monthMessages[currentMonth], {case: Case.GENETIVE});
  const subtitle = intl.formatMessage(messages.subtitle, {month});
  const language = useLanguage();

  return (
    <CommunicationDialog<ConsultationData>
      error={error}
      fields={FORM_FIELDS}
      illustration={language === Language.RU ? '/illustrations/consultation-ru.svg' : '/illustrations/consultation.svg'}
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      processing={processing}
      reason={reason}
      section={section}
      subtitle={subtitle}
      success={success}
      successIllustration={language === Language.RU ? '/illustrations/consultation-success-ru.svg' : undefined}
      testId={testId}
      title={messages.title}
    />
  );
}
