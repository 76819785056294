import {useContext} from 'react';
import {BaseUrlContext} from './context';

export function useBaseUrl(): URL {
  const baseUrl = useContext(BaseUrlContext);

  if (!baseUrl) {
    throw new Error('BaseUrl must be defined');
  }

  return baseUrl;
}
