import {isRecord} from 'lib/guards';
import {ImageBundle} from 'lib/image/types';

export type Category = {
  children?: Category[];
  description?: string;
  forAdults?: boolean;
  hasPublicChildren?: boolean;
  id: string;
  mainImage?: ImageBundle;
  name: string;
  parentId?: string;
  parents?: Omit<Category, 'children' | 'parents'>[];
};

export type RootCategory = {
  children?: Category[];
};

export type CategoryIndex = Record<string, Category>;

export function isRootCategory(target: unknown): target is RootCategory {
  return isRecord(target) && !('id' in target) && !('name' in target);
}
