// TODO: https://joom-team.atlassian.net/browse/PRO-4671
// NOTE: в перспективе строковые константы заменить на enum LanguageCode
import {Language} from './types';

const LANGUAGE_LONG_NAME: Record<Language, string> = {
  [Language.EN]: 'English',
  [Language.RU]: 'Русский',
  [Language.PT_BR]: 'Português (Brasil)',
};

const LANGUAGE_SHORT_NAME: Record<Language, string> = {
  [Language.EN]: 'Eng',
  [Language.RU]: 'Рус',
  [Language.PT_BR]: 'Por (Brasil)',
};

export function getLanguageLongName(languageCode: string): string {
  return LANGUAGE_LONG_NAME[languageCode as Language] ?? languageCode;
}

export function getLanguageShortName(languageCode: string): string {
  return LANGUAGE_SHORT_NAME[languageCode as Language] ?? languageCode;
}

export type LanguageNameFormat = 'short' | 'long';
export type GetLanguageNameOptions = {
  format?: LanguageNameFormat;
};

export function getLanguageName(languageCode: string, {format}: GetLanguageNameOptions = {}): string {
  switch (format) {
    case 'short':
      return getLanguageShortName(languageCode);
    case 'long':
    default:
      return getLanguageLongName(languageCode);
  }
}
