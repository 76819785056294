import type {Client} from 'lib/client';
import {transportLogger} from 'lib/logger';
import {safeDumpTransportResponse} from 'lib/logger/converters';
import {isTransportResponse} from 'lib/transport/types';

export function processAuthorization<R>(client: Client, onRequest: () => Promise<R>): Promise<R> {
  return client.processRequest(() =>
    onRequest().catch((error) => {
      if (isTransportResponse(error) && error.status === 401) {
        return client.updateOrCreate().then(() =>
          onRequest().catch((retryError) => {
            if (isTransportResponse(retryError) && error.status === 401) {
              transportLogger.fatal('401 after 401 on request', [
                safeDumpTransportResponse(error),
                safeDumpTransportResponse(retryError),
              ]);
            }

            throw retryError;
          }),
        );
      }

      throw error;
    }),
  );
}
