import {isMessageDescriptor} from 'lib/guards';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export type HeaderTestId = {
  image: unknown;
  subtitle: unknown;
  title: unknown;
};

type Props = TestIdProp<HeaderTestId> & {
  illustration?: string;
  subtitle?: ExtMessageDescriptor | React.ReactNode;
  title?: ExtMessageDescriptor;
};

export function Header({illustration, title, subtitle, testId}: Props): React.ReactElement {
  const intl = useIntl();
  const hasIllustration = Boolean(illustration);
  const imageStyle = useMemo((): React.CSSProperties | undefined => {
    if (!illustration) {
      return undefined;
    }

    return {
      backgroundImage: `url('${illustration}')`,
    };
  }, [illustration]);

  return (
    <div className={styles.header} data-test-id={testId}>
      {hasIllustration && <div className={styles.image} data-test-id={testId?.image} style={imageStyle} />}
      {hasIllustration && title && (
        <div className={styles.title} data-test-id={testId?.title}>
          {buildMessage(intl, title)}
        </div>
      )}
      {subtitle && (
        <div className={styles.subtitle} data-test-id={testId?.subtitle}>
          {isMessageDescriptor(subtitle) ? intl.formatMessage(subtitle) : subtitle}
        </div>
      )}
    </div>
  );
}
