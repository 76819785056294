import {SearchParams} from 'lib/search/types';

export enum SearchSuggestionKind {
  STORE = 'store',
  TEXT = 'text',
}

export type SearchSuggestionText = {
  /** Suggestion ID */
  id: string;
  /** Suggestion kind (text, store, ...) */
  kind: SearchSuggestionKind.TEXT;
  /** Suggestion search parameters */
  searchParams: SearchParams;
  /** Suggestion title */
  title: string;
};

export type SearchSuggestionStore = {
  /** Suggestion ID */
  id: string;
  /** Suggestion kind (text, store, ...) */
  kind: SearchSuggestionKind.STORE;
  /** Suggestion search parameters */
  searchParams: SearchParams;
  /** Suggestion title */
  title: string;
};

export type SearchSuggestion = SearchSuggestionText | SearchSuggestionStore;
