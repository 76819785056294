import {defineMessage, defineMessages} from 'react-intl';

export const messages = {
  error: defineMessages({
    phone: {
      defaultMessage: 'Incorrect phone number',
      description: 'General field "Phone". Error for invalid value',
    },
    required: {
      defaultMessage: 'Enter your phone number',
      description: 'General field "Phone". Error for empty value',
    },
  }),

  label: defineMessage({
    defaultMessage: 'Phone',
    description: 'General field "Phone". Signature',
  }),

  placeholder: defineMessage({
    defaultMessage: '+74950000000',
    description: 'General field "Phone". Clue',
  }),
};
