import {FORBIDDEN_ID, FRIENDLY_ID_REG_EXP, ID_REG_EXP, NEW_ID_REG_EXP} from './patterns';

export function getId<T extends {id: string}>(item: T): string {
  return item.id;
}

export function isId(id: string) {
  return ID_REG_EXP.test(id) && id !== FORBIDDEN_ID;
}

export function isFriendlyId(id: string) {
  return FRIENDLY_ID_REG_EXP.test(id);
}

export function parseId(id: string): string | null {
  return isId(id) ? id : null;
}

export function isNewFormatId(id: string): boolean {
  return NEW_ID_REG_EXP.test(id);
}
