import {AutocompleteField} from 'components/FinalForm/AutocompleteField';
import {companyRoles} from 'lib/user/companyRole';
import {formatCompanyRole} from 'lib/user/companyRole/messages';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const CompanyRole = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  const getOptionLabel = useCallback((item: string) => formatCompanyRole(item, intl), [intl]);
  return (
    <AutocompleteField
      disableClearable
      freeSolo
      getOptionLabel={getOptionLabel}
      label={intl.formatMessage(fieldMessages.companyRole)}
      name={fieldName}
      options={companyRoles}
      required={required}
    />
  );
};
