import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  next: {
    defaultMessage: 'Next',
    description: '[button] Form button',
  },
  submit: {
    defaultMessage: 'Send',
    description: '[button] Form button',
  },
});
