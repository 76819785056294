import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  button: {
    defaultMessage: 'Отправить',
    description: 'Главная страница. Диалоговое окно записи на консультацию: кнопка',
  },
  policy: {
    defaultMessage: 'Мы обрабатываем ваши данные на основании <a>политики конфиденциальности</a>',
    description: 'Главная страница. Диалоговое окно записи на консультацию: описание',
  },
  subtitle: {
    defaultMessage: 'Мы свяжемся с вами и расскажем как заработать на рекомендациях',
    description: 'Главная страница. Диалоговое окно записи на консультацию: текст',
  },
  title: {
    defaultMessage: 'Заявка на участие в реферальной программе',
    description: 'Главная страница. Диалоговое окно записи на консультацию: заголовок',
  },
});
